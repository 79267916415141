import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, OutlinedInput, Radio, RadioGroup, Typography } from "@mui/material";
import {observer} from 'mobx-react-lite';

import Line from "../../../../shared/ui/Line";
import {useAdverseEventsProduodopaStore} from '../../../../../store/hooks';
import {convertBoolValue, convertBoolValueHandler} from '../helper';
import {useDisabled} from '../useDisabled';

const componentsElements = [
  "Urządzenie do wprowadzania",
  "Podkładka kaniuli",
  "Kaniula",
  "Łącznik witryny",
  "Złącze typu Luer-Lock",
  "Rurki"
];

export const defectsElements = [
  "Powikłania skórne",
  "Zablokowany/okluzja",
  "Przemieszczenie i poluzowanie podkładki kaniuli (słaby klej)",
  "Oddzielone złącze pompy (Luer-Lock) od strzykawki",
  "Oddzielone złącze do obudowy kaniuli",
  "Błąd przycisku aktywacji",
  "Nieokreślone oddzielone / poluzowane złącze",
  "Zagięcie",
  "Przecięta rurka, otwór w rurce",
  "Nieszczelne złącze w miejscu strzykawki lub infuzji",
  "Uszkodzone opakowanie zewnętrzne/naruszona bariera sterylna",
  "Brakujący komponent/nieprawidłowy komponent w zestawie",
  "Ciała obce, zanieczyszczenia i materiały obce",
  "Wygasły termin przydatności do użycia",
  "Błąd użytkowania, prosimy o dodanie uzasadnienia:"
];

export const leakingConnectionsElements = [
  "Przewód infuzyjny (Luer-Lock) i strzykawka",
  "Złącze i obudowa kaniuli",
  "Wyciek poniżej podkładki pod kaniulę (między skórą a taśmą samoprzylepną)",
  "Inne:"
];

const solutionsElements = [
  "Ponownie podłączone złącze",
  "Wymieniono komponent",
  "Inne rozwiązanie:"
];

const InfusionSetProduct = observer(() => {
  const adverseEventsProduodopaStore = useAdverseEventsProduodopaStore()
  const {infusionInfo} = adverseEventsProduodopaStore;

  const {
    isDisabled: isDisabledDefectsJustification,
    changeDisabled: changeDisabledDefectsJustification
  } = useDisabled(defectsElements[defectsElements.length - 1], "defectsJustification", adverseEventsProduodopaStore.infusionInfoOnChange)

  const {
    isDisabled: isDisabledDefectsOther,
    changeDisabled: changeDisabledDefectsOther
  } = useDisabled("Inne zdarzenie, prosimy o wyjaśnienie:", "defectsOther", adverseEventsProduodopaStore.infusionInfoOnChange)


  const {
    isDisabled: isDisabledLeakingConnectionsOther,
    changeDisabled: changeDisabledLeakingConnectionsOther
  } = useDisabled(leakingConnectionsElements[leakingConnectionsElements.length - 1], "leakingConnectionsOther", adverseEventsProduodopaStore.infusionInfoOnChange)

  const {
    isDisabled: isDisabledSolutionsOther,
    changeDisabled: changeDisabledSolutionsOther
  } = useDisabled(solutionsElements[solutionsElements.length - 1], "solutionsOther", adverseEventsProduodopaStore.infusionInfoOnChange)


  return (
        <Grid container display='flex' mt={2} pl={2}>
            <Grid item className="subMenuSidebar">Zestaw infuzyjny</Grid>
            <Grid flexGrow={1} maxWidth="calc(100% - 100px)" container display='flex' flexDirection='column' >
                <Grid item sx={{border: '1px solid #9B9B9D'}} p={2} display='flex' flexDirection='column'>
                    <FormControl fullWidth>
                        <Typography variant='subtitle1'>Zaznacz odpowiedni komponent:</Typography>
                            <FormGroup
                              value={infusionInfo.components}
                              name="components"
                              onChange={(e) => adverseEventsProduodopaStore.infusionInfoOnChange("components", e.target.value)}>
                              {componentsElements.map(element =>  <FormControlLabel mt={1} key={element} value={element} control={<Checkbox />} label={element} />)}

                            </FormGroup>
                    </FormControl> 
                    <Line/>
                    
                        <Typography>Zaznacz odpowiednią wadę:</Typography>
                            <FormGroup
                              value={infusionInfo.defects}
                              name="defects"
                              onChange={(e) => {
                                adverseEventsProduodopaStore.infusionInfoOnChange("defects", e.target.value);
                                changeDisabledDefectsJustification(infusionInfo.defects, e.target.value);
                              }}>
                              {defectsElements.map(element =>  <FormControlLabel mt={1} key={element} value={element} control={<Checkbox />} label={element} />)}
                              
                            </FormGroup>
                            <FormControl fullWidth sx={{pl: 6, pt: 1}}>
                                        <OutlinedInput
                                          disabled={isDisabledDefectsJustification}
                                          name="defectsJustification"
                                          value={infusionInfo.defectsJustification}
                                          onChange={(e) => adverseEventsProduodopaStore.infusionInfoOnChange(e.target.name, e.target.value)}/>
                                    </FormControl>
                              <FormControlLabel mt={1} value="Inne zdarzenie, prosimy o wyjaśnienie:" control={<Checkbox  onChange={(e) => {
                                adverseEventsProduodopaStore.infusionInfoOnChange("defects", e.target.value);
                                changeDisabledDefectsOther(infusionInfo.defects, e.target.value);
                              }}/>} label="Inne zdarzenie, prosimy o wyjaśnienie:" />
                             <FormControl fullWidth sx={{pl: 6, pt: 1}}>
                                        <OutlinedInput
                                          disabled={isDisabledDefectsOther}
                                          name="defectsOther"
                                          value={infusionInfo.defectsOther}
                                          onChange={(e) => adverseEventsProduodopaStore.infusionInfoOnChange(e.target.name, e.target.value)}/>
                            </FormControl>
                </Grid>
                
                <Grid item sx={{border: '1px solid #9B9B9D'}} p={2} display='flex' gap={2} flexDirection='column'>
                    <Typography variant='subtitle1'>Wyciek zestawu infuzyjnego: </Typography>
                    <Typography sx={{fontSize: 14}} variant='subtitle1'>Jeśli zaobserwowano wyciek, należy wskazać, które połączenie zestawu infuzyjnego było nieszczelne (połączenie nierozdzielone): </Typography>
                    <FormGroup
                        value={infusionInfo.leakingConnections}
                        name="leakingConnections"
                        onChange={(e) => {
                                adverseEventsProduodopaStore.infusionInfoOnChange("leakingConnections", e.target.value);
                                changeDisabledLeakingConnectionsOther(infusionInfo.leakingConnections, e.target.value)
                              }}>
                              {leakingConnectionsElements.map(element =>  <FormControlLabel mt={1} key={element} value={element} control={<Checkbox />} label={element} />)}
                      </FormGroup>
                      <FormControl fullWidth sx={{pl: 6, pt: 1}}>
                                <OutlinedInput
                                    disabled={isDisabledLeakingConnectionsOther}
                                    name="leakingConnectionsOther"
                                    value={infusionInfo.leakingConnectionsOther}
                                    onChange={(e) => adverseEventsProduodopaStore.infusionInfoOnChange("leakingConnectionsOther", e.target.value)}/>
                       </FormControl>
                </Grid>

                <Grid item sx={{border: '1px solid #9B9B9D'}} p={2} display='flex' gap={2} flexDirection='column'>
                    <Typography variant='subtitle1'>Pytanie dodatkowe:</Typography>
                    <Typography>Czy adapter fiolki został sprawdzony po otrzymaniu?</Typography>
                    <div>
                        <FormControl fullWidth>
                                <RadioGroup row
                                            name="isAdapterChecked"
                                            value={convertBoolValue(infusionInfo.isAdapterChecked)}
                                            onChange={(e) =>  adverseEventsProduodopaStore.infusionInfoOnChange(e.target.name, convertBoolValueHandler(e.target.value))}>
                                        <FormControlLabel mt={1} value="Tak" control={<Radio />} label="Tak" />
                                        <FormControlLabel mt={1} value="Nie" control={<Radio />} label="Nie" />
                                </RadioGroup>
                        </FormControl> 
                        <Line/>
                        <FormControl fullWidth>
                            <Typography>Kiedy zaobserwowano problem? </Typography>
                            <OutlinedInput
                              name="problemNoticed"
                              value={infusionInfo.problemNoticed}
                              onChange={(e) => adverseEventsProduodopaStore.infusionInfoOnChange(e.target.name, e.target.value)}/>
                        </FormControl>     
                    </div> 
                    <FormControl fullWidth>
                        <Typography>Czy problem był widoczny w momencie odbioru? </Typography>
                        <OutlinedInput
                          name="problemVisible"
                          value={infusionInfo.problemVisible}
                          onChange={(e) => adverseEventsProduodopaStore.infusionInfoOnChange(e.target.name, e.target.value)}/>
                    </FormControl> 
                </Grid>
                
                <Grid item sx={{border: '1px solid #9B9B9D'}} p={2} display='flex' gap={2} flexDirection='column'>
                    <FormControl fullWidth>
                        <Typography variant='subtitle1'>Jeśli problem został rozwiązany, w jaki sposób? </Typography>
                            <FormGroup
                              value={infusionInfo.solutions}
                              name="solutions"
                              onChange={(e) =>{
                                adverseEventsProduodopaStore.infusionInfoOnChange("solutions", e.target.value);
                                changeDisabledSolutionsOther(infusionInfo.solutions, e.target.value)

                              }}>
                            {solutionsElements.map(element =>  <FormControlLabel mt={1} key={element} value={element} control={<Checkbox />} label={element} />)}

                            </FormGroup>
                    </FormControl> 
                    <FormControl fullWidth sx={{pl: 6}}>
                        <OutlinedInput
                          disabled={isDisabledSolutionsOther}
                          name="solutionsOther"
                          value={infusionInfo.solutionsOther}
                          onChange={(e) => adverseEventsProduodopaStore.infusionInfoOnChange(e.target.name, e.target.value)}/>
                    </FormControl> 
                </Grid>
            </Grid>
        </Grid>
    )

});

export default InfusionSetProduct;