import React, {useContext, useEffect} from "react";
import PageTitle from "../../shared/ui/PageTitle";
import {Button, Grid, Typography} from "@mui/material";
import HardwareProductsModal from "./HardwareProductsModal.js";
import HardwareTable from "./HardwareTable";
import {HardwareContext} from "../../../context/hardware/hardwareContext";
import {useModalController} from "../../../hooks/useModalController";
import {ADD_OPTION} from "../../../data/consts/modalOptions";

const Hardware = () => {
    const {getProductsList, hardwareState} = useContext(HardwareContext)

    const {
        modalId, modalIDHandler, modalInfo, openModal, closeModal
    } = useModalController();

    useEffect(() => {
        getProductsList();
        // eslint-disable-next-line
    }, [])

    return (<>
            <div className='hardwareOrders'>
                <PageTitle title="Lista produktów:"/>
                <Grid container justifyContent="flex-end" pb={1}>
                    <Button variant="contained" color="success" className="formButton" onClick={() => openModal(ADD_OPTION)}>dodaj
                        produkt</Button>
                </Grid>
                {hardwareState.hardware ? <HardwareTable openModal={openModal} hardwareIDHandler={modalIDHandler}/> :
                    <Typography>Brak danych</Typography>}
            </div>
            {modalInfo.isOpen &&
                <HardwareProductsModal openModal={openModal} closeModal={closeModal} modalOption={modalInfo.option}
                                       productID={modalId}/>}

        </>)
}

export default Hardware;