import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../../context/auth/authContext";

export const CheckUser = () => {
    const [authLoaded, setAuthLoaded] = useState(false);
    const { isAuth } = useContext(AuthContext);

    useEffect(() => {
        if(!authLoaded){
            void isAuth();
            setAuthLoaded(true)
        }

    }, [authLoaded, isAuth]);

    return null;
};
