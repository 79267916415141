import React, {useContext, useEffect} from 'react';
import PageTitle from '../../shared/ui/PageTitle';
import {AuthContext} from '../../../context/auth/authContext';
import {Button, Grid, Typography} from '@mui/material';
import PatientModal from './PatientModal';
import {PatientContext} from '../../../context/patient/patientContext';
import PatientsDataTable from './PatientsDataTable';
import {ADD_OPTION} from "../../../data/consts/modalOptions";
import {ROLE_ADMIN} from "../../../data/consts/users";
import {useModalController} from "../../../hooks/useModalController";


const Patients = () => {

    const {role} = useContext(AuthContext);
    const {getPatients, clearPatients, patientState} = useContext(PatientContext);

    const {
        modalId, modalIDHandler, modalInfo, openModal, closeModal
    } = useModalController();
    useEffect(() => {
        getPatients()
        return () => clearPatients()
        // eslint-disable-next-line
    }, [])

    return (<>
            <div className='patients'>
                <PageTitle title="Pacjenci:"/>
                {role === ROLE_ADMIN && <Grid container justifyContent="flex-end" pb={1}>
                    <Button variant="contained" color="success" className="formButton"
                            onClick={() => openModal(ADD_OPTION)}>
                        dodaj pacjenta
                    </Button>
                </Grid>}
                {patientState.patients ? <PatientsDataTable openModal={openModal} userIDHandler={modalIDHandler}/> :
                    <Typography>Brak danych</Typography>}
            </div>
            {modalInfo.isOpen && <PatientModal
                closeModal={closeModal}
                modalOption={modalInfo.option}
                userID={modalId}
            />}
        </>)
}

export default Patients;