export const coachingValidate = (date, duration, makers) => {
    if (!date) {
        return ({
            isError: true,
            defRes: {
                data: {
                    code: 422,
                    message: 'Wybierz datę',
                },
            },
        })
    }

    if (duration < 1) {
        return ({
            isError: true,
            defRes: {
                data: {
                    code: 422,
                    message: 'Sprawdź zakres',
                },
            },
        })
    }

    if (!makers.length) {
        return ({
            isError: true,
            defRes: {
                data: {
                    code: 422,
                    message: 'Wybierz uczestników',
                },
            },
        })
    }

    return ({
        isError: false,
        defRes: null,
    })
}