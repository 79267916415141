import { useMemo } from 'react';
import axios from 'axios';

export const useApiCall = () => {
	const api = useMemo(() => {
		axios.defaults.baseURL = process.env.REACT_APP_API_URL;
		axios.defaults.withCredentials = true;
		axios.defaults.headers.common['Content-Type'] = 'application/json';
		
		const responseBody = (response) => response.data;

		const apiInstance = {
			get: async (url, config) => {
				return axios
					.get(url, config)
					.then(responseBody)
					.catch((error) => console.log(error))
			},

			post: async (url, body) => {
				return axios
					.post(url, body)
					.then(responseBody)
					.catch((error) => console.log(error))
			},

			put: async (url, body) => {
				return axios
					.put(url, body)
					.then(responseBody)
					.catch((error) => console.log(error))
			},
			patch: async (url, body) => {
				return axios
					.patch(url, body)
					.then(responseBody)
					.catch((error) => console.log(error))
			},

			delete: async (url) => {
				return axios
					.delete(url)
					.then(responseBody)
					.catch((error) => console.log(error))
			}
		};

		return { apiInstance };
	}, []);

	return { api };
}