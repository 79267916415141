import React, { createContext, useContext } from 'react';

import { RootStore } from './rootStore';

let store;

const RootStateContext = createContext(undefined);

const initializeStore = () => {
	const _store = store ?? new RootStore();

	if (typeof window === 'undefined') {
		return _store;
	}

	if (!store) {
		store = _store;
	}

	return _store;
};

export const RootStateProvider = ({ children }) => (
	<RootStateContext.Provider value={initializeStore()}>{children}</RootStateContext.Provider>
);

export const useRootStore = () => useContext(RootStateContext);