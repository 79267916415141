import React from 'react';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    OutlinedInput,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';

const PumpSubModule = ({pompSubmodule, pompSubmoduleHandler}) => {
    return (
        <Grid container border={`1px solid #071d49`} display="flex" direction="row">
            <Grid item className="subMenuSidebar">Pompa</Grid>
            <Grid item flexGrow={1} maxWidth="calc(100% - 100px)" container direction="column">
                <Grid item container borderBottom={`1px solid #071d49`}>
                    <Grid item xs={12} lg={6} p={2}>
                        <Typography variant="subtitle1">Czy pompa wyświetliła alarm lub komunikat o
                            błędzie?</Typography>
                        <RadioGroup
                            name="isAlarm"
                            value={pompSubmodule.isAlarm}
                            onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.value)}
                        >
                            <FormControlLabel value="Tak" control={<Radio/>} label="Tak"/>
                            <FormControlLabel value="Nie" control={<Radio/>} label="Nie"/>
                        </RadioGroup>
                    </Grid>
                    {
                        pompSubmodule.isAlarm === 'Tak' && <Grid item xs={12} lg={6} p={2}>
                            <Typography variant="subtitle1">Proszę zaznaczyć odpowiedni rodzaj alarmu i opisać go
                                szczegółowo, uwzględniając wszystkie kody błędu i wyświetlone komunikaty:</Typography>
                            <FormGroup>
                                <FormControlLabel control={
                                    <Checkbox
                                        name="continuousAlarm"
                                        checked={pompSubmodule.continuousAlarm}
                                        onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
                                    />
                                } label="alarm ciągły"/>
                                <FormControlLabel control={
                                    <Checkbox
                                        name="noConnect"
                                        checked={pompSubmodule.noConnect}
                                        onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
                                    />
                                } label="brak podłączonego zestawu jednorazowego"/>
                                <FormControlLabel control={
                                    <Checkbox
                                        name="highPressureAlarm"
                                        checked={pompSubmodule.highPressureAlarm}
                                        onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
                                    />
                                } label="alarm wysokiego ciśnienia"/>
                                <FormControlLabel control={
                                    <Checkbox
                                        name="fourDigitError"
                                        checked={pompSubmodule.fourDigitError}
                                        onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
                                    />
                                } label="4-cyfrowy kod błędu"/>
                                <FormControlLabel control={
                                    <Checkbox
                                        name="unspecifiedAlarm"
                                        checked={pompSubmodule.unspecifiedAlarm}
                                        onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
                                    />
                                } label="niesprecyzowany alarm / komunikat"/>
                            </FormGroup>
                        </Grid>
                    }
                </Grid>
                <Grid item container borderBottom={`1px solid #071d49`}>
                    <Grid item xs={12} p={2}>
                        <Typography variant="subtitle1">Czy problem z pompą ma charakter fizyczny, funkcjonalny,
                            związany z oprogramowaniem? Proszę zaznaczyć właściwy problem:</Typography>
                    </Grid>
                    <Grid item xs={12} lg={6} p={2}>
                        <Typography variant="subtitle1">Fizyczny:</Typography>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox
                                    name="waterDamage"
                                    checked={pompSubmodule.waterDamage}
                                    onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
                                />
                            } label="uszkodzenie na skutek działania wody / płynu"/>
                            <FormControlLabel control={
                                <Checkbox
                                    name="physicalDamage"
                                    checked={pompSubmodule.physicalDamage}
                                    onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
                                />
                            } label="uszkodzenie fizyczne"/>
                            <FormControlLabel control={
                                <Checkbox
                                    name="dropping"
                                    checked={pompSubmodule.dropping}
                                    onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
                                />
                            } label="upuszczenie"/>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6} p={2}>
                        <Typography variant="subtitle1">Funkcjonalny:</Typography>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox
                                    name="batteryProblem"
                                    checked={pompSubmodule.batteryProblem}
                                    onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
                                />
                            } label="problem z akumulatorem"/>
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox
                                    name="batteryCover"
                                    checked={pompSubmodule.batteryCover}
                                    onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
                                />
                            } label="pokrywa akumulatora"/>
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox
                                    name="keyboard"
                                    checked={pompSubmodule.keyboard}
                                    onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
                                />
                            } label="klawiatura"/>
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox
                                    name="drugDelivery"
                                    checked={pompSubmodule.drugDelivery}
                                    onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
                                />
                            } label="problem z podawaniem leku"/>
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox
                                    name="cassetteHolder"
                                    checked={pompSubmodule.cassetteHolder}
                                    onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
                                />
                            } label="mocowanie kasetki"/>
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox
                                    name="display"
                                    checked={pompSubmodule.display}
                                    onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
                                />
                            } label="wyświetlacz"/>
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox
                                    name="onOrOff"
                                    checked={pompSubmodule.onOrOff}
                                    onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
                                />
                            } label="włączanie lub wyłączanie"/>
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox
                                    name="programmingProblem"
                                    checked={pompSubmodule.programmingProblem}
                                    onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
                                />
                            } label="problem z programowaniem"/>
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox
                                    name="feedRate"
                                    checked={pompSubmodule.feedRate}
                                    onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
                                />
                            } label="widoczna lub słyszalna zmiana prędkości podawania"/>
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox
                                    name="usageError"
                                    checked={pompSubmodule.usageError}
                                    onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
                                />
                            } label="błąd użytkowania / inny (należy podać, jaki):"/>
                        </FormGroup>
                        <Box minHeight={56}>
                            {
                                pompSubmodule.usageError && <FormControl fullWidth>
                                    <OutlinedInput
                                        id="usageErrorValue"
                                        name="usageErrorValue"
                                        value={pompSubmodule.usageErrorValue}
                                        onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.value)}
                                    />
                                </FormControl>
                            }
                        </Box>
                    </Grid>
                </Grid>
                <Grid item container borderBottom={`1px solid #071d49`}>
                    <Grid item xs={12} lg={6} p={2}>
                        <Typography variant="subtitle1">Czy problem udało się rozwiązać?</Typography>
                        <RadioGroup
                            name="problemResolved"
                            value={pompSubmodule.problemResolved}
                            onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.value)}
                        >
                            <FormControlLabel value="Tak" control={<Radio/>} label="Tak"/>
                            <FormControlLabel value="Nie" control={<Radio/>} label="Nie"/>
                        </RadioGroup>
                    </Grid>
                    {
                        pompSubmodule.problemResolved === 'Tak' && <Grid item xs={12} lg={6} p={2}>
                            <Typography variant="subtitle1">Jeżeli problem został rozwiązany, to, w jaki
                                sposób?</Typography>
                            <FormGroup>
                                <FormControlLabel control={
                                    <Checkbox
                                        name="redPlugsRemoved"
                                        checked={pompSubmodule.redPlugsRemoved}
                                        onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
                                    />
                                } label="Otwarto zaciski, usunięto czerwone zatyczki."/>
                                <FormControlLabel control={
                                    <Checkbox
                                        name="batteryReplaced"
                                        checked={pompSubmodule.batteryReplaced}
                                        onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
                                    />
                                } label="Wymieniono baterie w pompie."/>
                                <FormControlLabel control={
                                    <Checkbox
                                        name="reLaunchPomp"
                                        checked={pompSubmodule.reLaunchPomp}
                                        onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
                                    />
                                } label="Wyłączono i ponownie włączono pompę."/>
                                <FormControlLabel control={
                                    <Checkbox
                                        name="reLaunchCassette"
                                        checked={pompSubmodule.reLaunchCassette}
                                        onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
                                    />
                                }
                                                  label="Wyjęto i włożono ponownie tę samą kasetkę (jeżeli włożono nową kasetkę, należy zaznaczyć to w formularzu)."/>
                                <FormControlLabel control={
                                    <Checkbox
                                        name="reLaunchCover"
                                        checked={pompSubmodule.reLaunchCover}
                                        onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
                                    />
                                } label="Pokrywa akumulatora nie została poprawnie zamknięta przez użytkownika."/>
                                <FormControlLabel control={
                                    <Checkbox
                                        name="noDisposable"
                                        checked={pompSubmodule.noDisposable}
                                        onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
                                    />
                                }
                                                  label="Pompa wyświetla komunikat „No Disposable” i nie daje się uruchomić: zatrzymano pompę, zamocowano kasetkę i pompa wznowiła pracę bez problemu."/>
                            </FormGroup>
                        </Grid>
                    }
                </Grid>
                <Grid item container>
                    <Grid item xs={12} lg={6} p={2}>
                        <Typography variant="subtitle1">Czy dostępna była pompa zastępcza?</Typography>
                        <RadioGroup
                            name="replacementPomp"
                            value={pompSubmodule.replacementPomp}
                            onChange={(e) => {
                                pompSubmoduleHandler(e.target.name, e.target.value)
                                pompSubmoduleHandler('replacementPompComment', '')
                            }}
                        >
                            <FormControlLabel value="Tak" control={<Radio/>} label="Tak"/>
                            <FormControlLabel value="Nie" control={<Radio/>} label="Nie"/>
                        </RadioGroup>
                    </Grid>
                    {
                        pompSubmodule.replacementPomp === 'Tak' && <Grid item xs={12} lg={6} p={2}>
                            <Typography variant="subtitle1">Jeśli tak, czy było to pomocne?</Typography>
                            <FormControl fullWidth>
                                <OutlinedInput
                                    id="replacementPompComment"
                                    name="replacementPompComment"
                                    value={pompSubmodule.replacementPompComment}
                                    onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PumpSubModule;