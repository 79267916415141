import {
    GET_ORDERS_INFO, GET_ORDER
}
    from
        '../types'


const handlers = {
    [GET_ORDERS_INFO]: (state, {payload}) => ({...state, ordersList: payload}),
    [GET_ORDER]: (state, {payload}) => ({...state, orderInfo: payload}),

    DEFAULT: state => state,
}

export const orderReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}