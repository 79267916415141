import {Box, Typography} from '@mui/material'
import React from 'react'
import WarningBox from './WarningBox'
import CheckedIcon from '../../../../assets/img/ui/checkedIcon.png';

export default function SummaryRejectConfim({clearFunction}) {
    const handleClose = () => {
        clearFunction()
    }
    return <WarningBox handleClose={handleClose}>
        <Box my={15} sx={{textAlign: 'center'}}>
            <Box>
                <img src={CheckedIcon} alt=""/>
            </Box>
            <Typography mt={4} className="settlements__addTitle" sx={{fontWeight: 'bold'}}>Rozliczenie zostało <br/>
                <span style={{color: '#228B21'}}> odrzucone </span> poprawnie.</Typography>
        </Box>
    </WarningBox>
};