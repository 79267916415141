import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../context/app/appContext";
import PageTitle from "../../shared/ui/PageTitle";
import {Box, Button, Stack, Typography} from "@mui/material";
import OverTimeUserTable from "./OvertimeUserTable";
import {AuthContext} from "../../../context/auth/authContext";
import AdditionalTimeAdd from "../settlements/AdditionalTimeAdd";
import OverTimeWarning from "./OverTimeWarning";
import {ReckoningsContext} from "../../../context/reckonings/reckoningsContext"
import {ROLE_ADMIN} from "../../../data/consts/users";
import {DRAFT_STATUS, REJECTED_STATUS} from "../../../data/consts/settlementsStatuses";
import {CLASS_BLUE} from '../settlements/settlementsMonthTable/helpers'

const OvertimeUser = () => {
    const {getAllWT} = useContext(AppContext);
    const {authState: {id: userId}, role} = useContext(AuthContext);
    const {getReckoning, reckoningsState} = useContext(ReckoningsContext);
    const [isAdding, setIsAdding] = useState(false);

    const status = reckoningsState.reckoning?.status

    useEffect(() => {
        getAllWT();
        role !== ROLE_ADMIN && getReckoning(`${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(-2)}`, userId)
        // eslint-disable-next-line
    }, []);

    const isReckoningAvailable = status === DRAFT_STATUS || status === REJECTED_STATUS || !status

    return (
        <>
            <Stack className='overtime'>
                <Box direction='row' mr={5} sx={{
                    flexGrow: 1,
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    rowGap: '0.3rem',
                    columnGap: '1rem'
                }}>
                    <Stack direction='row'>
                        <PageTitle title='Nadgodziny:'/>
                        {role !== ROLE_ADMIN &&
                            <Button variant='contained' className={CLASS_BLUE}
                                    style={{
                                        width: "290px", padding: "0 20px", color: "white", margin: '0 0 auto 4rem'
                                    }}
                                    onClick={() => {
                                        setIsAdding(!isAdding);
                                    }}
                            >
                                <Typography>złóż wniosek o nadgodziny</Typography>
                            </Button>
                        }
                    </Stack>
                    <Box><OverTimeWarning/></Box>
                </Box>

                {isAdding ? <AdditionalTimeAdd setIsAdding={setIsAdding} isReckoningAvailable={isReckoningAvailable}/> :
                    <OverTimeUserTable/>}
            </Stack>

        </>
    );
};

export default OvertimeUser;
