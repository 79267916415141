import { useRootStore } from '../RootStateContext';

export const useAdverseEventsProduodopaStore  = () => {
  const rootStore = useRootStore();

  if (!rootStore.adverseEventsProduodopaStore) {
    throw new Error('adverseEventsProduodopaStore store should be defined');
  }

  return rootStore.adverseEventsProduodopaStore;
}