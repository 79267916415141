export const headCells = [
    {
        id: 'id',
        label: ' LP',
    },
    {
        id: 'displayName',
        label: 'Inicjały',
    },
    {
        id: 'institution',
        label: 'Placówka',
    },
    {
        id: 'createdBy',
        label: 'Dodający',
    },
    {
        id: 'status',
        label: 'Status',
    },
    {
        id: 'działania',
        label: 'Działania',
    },
];
