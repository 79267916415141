import {CLEAR_PATIENT, CLEAR_PATIENTS, GET_ADDITIONAL_PATIENT_INFO, GET_PATIENT_INFO, GET_PATIENTS} from '../types';

const handlers = {
    [GET_ADDITIONAL_PATIENT_INFO]: (state, {payload}) => ({...state, ...payload}),
    [GET_PATIENTS]: (state, {payload}) => ({...state, patients: payload}),
    [GET_PATIENT_INFO]: (state, {payload}) => ({...state, patient: payload}),
    [CLEAR_PATIENT]: (state) => ({...state, patient: null}),
    [CLEAR_PATIENTS]: (state) => ({...state, patients: null}),
    DEFAULT: state => state,
}

export const patientReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}