import { Checkbox, FormControl, FormControlLabel, Grid, OutlinedInput, Radio, RadioGroup, Typography, FormGroup } from "@mui/material";
import { observer } from "mobx-react-lite";

import { useAdverseEventsProduodopaStore } from "../../../../../store/hooks";

const vialDefectsElements = [
    "Brakujący korek",
    "Uszkodzony korek",
    "Wyciek spowodowany uszkodzeniem korka",
    "Uszczelka nie jest prawidłowo zaciśnięta na korku",
    "Obce cząstki/włókna na korku",
    "Ślady na korku",
    "Pęknięta/ rozbita fiolka",
    "Porysowane/ plamki na fiolkach",
    "Brakująca plomba",
    "Uszkodzona plomba",
    "Niewłaściwy kolor plomby",
    "Widoczne zewnętrzne/wewnętrzne obce cząstki na fiolkach",
    "Brakująca nakrętka",
    "Uszkodzona lub przecięta nakrętka",
    "Inna nakrętka niż oryginalna",
    "Nakrętka z brudem/zanieczyszczeniami",
    "Zużycie nakrętki",
    "Opakowanie zewnętrzne",
    "Inne"
];

const drugDefectsElements = [
    "Obce cząstki/włókna",
    "Przebarwienia wykraczające poza zakres określony w instrukcji przygotowania roztworu IFU",
    "Zmętnienie",
    "Pusta fiolka",
    "Niepełna fiolka",
    "Fiolka przepełniona"
]


const DrugSolutionProduct = observer(() => {
    const adverseEventsProduodopaStore = useAdverseEventsProduodopaStore()
    const {drugInfo} = adverseEventsProduodopaStore;

    return (
        <Grid container display='flex' mt={2} pl={2}>
            <Grid item className="subMenuSidebar">Fiolka/ Roztwór Leku</Grid>
            <Grid flexGrow={1} maxWidth="calc(100% - 100px)" container display='flex' flexDirection='column' >
                <Grid item sx={{border: '1px solid #9B9B9D'}} p={2} display='flex' gap={2} flexDirection='column'>
                    <Typography variant='subtitle1'>Zaznacz odpowiednie pole dla otrzymanej reklamacji</Typography>
                    <FormControl fullWidth>
                        <RadioGroup row
                            value={drugInfo.receivedComplaint}
                            name="receivedComplaint"
                            onChange={(e) => adverseEventsProduodopaStore.drugInfoOnChange("receivedComplaint", e.target.value)}>
                                <FormControlLabel mt={1} value="Fiolka" control={<Radio />} label="Fiolka" />
                                <FormControlLabel mt={1} value="Roztwór leku" control={<Radio />} label="Roztwór leku" />
                        </RadioGroup>
                    </FormControl>  
                    <Typography variant='subtitle1'>Opisz reklamację, jeśli ma to zastosowanie:</Typography>

                    <FormControl fullWidth>
                        <Typography sx={{fontSize: 14}} variant='subtitle1'>Kiedy zaobserwowano problem? Czy zaobserwowano go w momencie odbioru?</Typography>
                        <OutlinedInput
                              name="problemNoticed"
                              value={drugInfo.problemNoticed}
                              onChange={(e) => adverseEventsProduodopaStore.drugInfoOnChange(e.target.name, e.target.value)}/>
                    </FormControl>

                    <FormControl fullWidth>
                        <Typography sx={{fontSize: 14}} variant='subtitle1'>Czy sprawdzane są fiolki po ich otrzymaniu?</Typography>
                        <OutlinedInput
                             name="vialsChecked"
                             value={drugInfo.vialsChecked}
                             onChange={(e) => adverseEventsProduodopaStore.drugInfoOnChange(e.target.name, e.target.value)}/>
                    </FormControl>

                    <FormControl fullWidth>
                        <Typography sx={{fontSize: 14}} variant='subtitle1'>Opis przechowywania produktu leczniczego przed użyciem:</Typography>
                        <OutlinedInput
                            name="drugStorage"
                            value={drugInfo.drugStorage}
                            onChange={(e) => adverseEventsProduodopaStore.drugInfoOnChange(e.target.name, e.target.value)}/>
                    </FormControl>
                </Grid>        
                <Grid item sx={{border: '1px solid #9B9B9D'}} p={2} display='flex' gap={2} flexDirection='column'>
                    <Typography variant='subtitle1'>Reklamacje związane z fiolkami (zaznacz poniżej odpowiednią wadę):</Typography>
                    <FormControl fullWidth>
                        <FormGroup 
                             name="vialDefects"
                             value={drugInfo.vialDefects}
                             onChange={(e) => adverseEventsProduodopaStore.drugInfoOnChange("vialDefects", e.target.value)}>
                                    {
                                        vialDefectsElements.map(element => <FormControlLabel key={element} mt={1} value={element} control={<Checkbox checked={drugInfo.vialDefects.includes(element)}/>} label={element} />)
                                    }
                        </FormGroup>
                    </FormControl>  
                </Grid> 
                <Grid item sx={{border: '1px solid #9B9B9D'}} p={2} display='flex' gap={2} flexDirection='column'>
                    <Typography variant='subtitle1'>Reklamacje związane z roztworem leku (zaznacz poniżej odpowiednią wadę):</Typography>
                    <FormControl fullWidth>
                        <FormGroup 
                                name="drugDefects"
                                value={drugInfo.drugDefects}
                                onChange={(e) => adverseEventsProduodopaStore.drugInfoOnChange("drugDefects", e.target.value)}>
                                    {
                                        drugDefectsElements.map(element => <FormControlLabel key={element} mt={1} value={element} control={<Checkbox checked={drugInfo.drugDefects.includes(element)} />} label={element} />)
                                    }
                        </FormGroup>
                    </FormControl>  
                </Grid> 
            </Grid>
        </Grid>
    )

});

export default DrugSolutionProduct;