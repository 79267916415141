import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from '../../../../context/app/appContext';
import {
    Box,
    Button,
    Divider,
    Grid,
    InputLabel,
    MenuItem,
    MenuList,
    OutlinedInput,
    Paper,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import GlobalLoader from '../../../shared/ui/GlobalLoader';
import {equalizerData} from '../../../../utils/helpfunc';
import {pl} from "date-fns/locale";
import {userDataInit} from "./helper";
import {ROLE_ADS, ROLE_COORD_ADS, ROLE_KAM} from "../../../../data/consts/users";
import {ADD_OPTION, DELETE_OPTION, EDIT_OPTION, SHOW_OPTION} from "../../../../data/consts/modalOptions";


const AdminUserModal = ({closeModal, userID, modalOption}) => {

    const {addUser, editUser, getAdditionalUserFormInfo, getUser, deleteUser, appState} = useContext(AppContext);

    const [userData, setUserData] = useState(userDataInit)

    useEffect(() => {
        getAdditionalUserFormInfo();
        if (modalOption === ADD_OPTION) {
            setUserData(userDataInit);
        }
        else if (modalOption === SHOW_OPTION || modalOption === EDIT_OPTION) {
            getUser(userID);
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (modalOption === SHOW_OPTION || modalOption === EDIT_OPTION) {

            const showUserInfo = appState.showUserInfo;
            if (showUserInfo) {
                setUserData({ ...showUserInfo });
            }
        }
    }, [appState.showUserInfo, modalOption])

    const formValueHandler = (name, value) => setUserData(prev => ({...prev, [name]: value}))

    const submitHandler = (e) => {
        e.preventDefault()
        modalOption === ADD_OPTION && addUser(userData) && closeModal()
        modalOption === EDIT_OPTION && editUser(userData, userID) && closeModal()
        modalOption === DELETE_OPTION && deleteUser(userID) && closeModal()
    }

    return userData
        ?
        <div className="customModal ">
            <Stack component="div" className="customModal__addMenu ">

                {modalOption === DELETE_OPTION ? <Box style={{ display: 'flex', justifyContent: 'center' }} > <Typography variant="h5" mb={2} mt={3}>
                    Czy jesteś pewny, że chcesz usunąć użytkownika?
                    </Typography></Box>:
                    <Typography variant="h4" mb={2}>
                        {
                            modalOption === ADD_OPTION ? 'Dodaj użytkownika'
                                : modalOption === SHOW_OPTION ? 'Dane użytkownika' : 'Edytuj'
                        }
                    </Typography>
                }
                <form onSubmit={(e) => submitHandler(e)}>
                    {modalOption !== DELETE_OPTION &&<Grid container spacing={2} display="flex" direction="column" wrap="nowrap"
                          className="customModal__scrollArea">
                        <Grid item>
                            <TextField
                                required
                                label="Imię"
                                name="firstName"
                                value={userData.firstName}
                                onChange={(e) => formValueHandler(e.target.name, e.target.value)}
                                fullWidth
                                disabled={(modalOption === SHOW_OPTION)}

                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                required
                                label="Nazwisko"
                                name="secondName"
                                value={userData.secondName}
                                onChange={(e) => formValueHandler(e.target.name, e.target.value)}
                                fullWidth
                                disabled={(modalOption === SHOW_OPTION)}
                            />
                        </Grid>
                        {
                            modalOption === ADD_OPTION &&
                            <Grid item>
                                <TextField
                                    required
                                    label="Hasło"
                                    name="password"
                                    type="password"
                                    autoComplete={'none'}
                                    value={userData.password}
                                    onChange={(e) => formValueHandler(e.target.name, e.target.value)}
                                    fullWidth
                                    disabled={(modalOption === SHOW_OPTION)}
                                />
                            </Grid>
                        }
                        {
                            modalOption === SHOW_OPTION
                                ?
                                <Grid item>
                                    <Paper mt={2}
                                           sx={{
                                               width: '100%',
                                               border: '0.5px solid #9e9e9e',
                                               color: 'text.disabled',
                                               borderBottom: 'none',
                                           }}>
                                        <Typography p={1.5}>Placówka:</Typography>
                                        <Divider/>

                                        <MenuList>
                                            {
                                                userData.institutions && appState.institutions && equalizerData(userData.institutions, appState.institutions).map((el, index) =>
                                                    <MenuItem key={index} sx={{cursor: 'default'}}> {el} </MenuItem>)
                                            }
                                        </MenuList>
                                    </Paper>
                                </Grid>
                                :
                                <Grid item>
                                    <FormControl fullWidth>
                                        <InputLabel id="institution">Placówka</InputLabel>
                                        <Select
                                            sx={{maxWidth: '550px'}}
                                            multiple
                                            name="institutions"
                                            label="Placówka"
                                            value={userData.institutions}
                                            onChange={(e) => formValueHandler(e.target.name, e.target.value)}
                                            disabled={(modalOption === SHOW_OPTION)}
                                            input={<OutlinedInput label="Placówka"/>
                                            }
                                        >
                                            {appState.institutions && appState.institutions.map(institution => (
                                                <MenuItem
                                                    key={institution.id}
                                                    value={institution.id}
                                                >
                                                    {institution.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                        }
                        <Grid item>
                            <TextField
                                required
                                label="Numer telefonu"
                                name="phoneNumber"
                                value={userData.phoneNumber}
                                onChange={(e) => formValueHandler(e.target.name, e.target.value)}
                                fullWidth
                                disabled={(modalOption === SHOW_OPTION)}
                            />
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth>
                                <InputLabel id="addUser-roles">Rola</InputLabel>
                                <Select
                                    labelId="addUser-roles"
                                    name="role"
                                    value={userData.role}
                                    label="Rola"
                                    disabled={(modalOption === 'show')}
                                    onChange={(e) => formValueHandler(e.target.name, e.target.value)}
                                >
                                    <MenuItem value="" disabled={true}><em>Wybierz z listy</em></MenuItem>
                                    <MenuItem value={ROLE_ADS}>ADS</MenuItem>
                                    <MenuItem value={ROLE_COORD_ADS}>KOORDYNATOR ADS</MenuItem>
                                    <MenuItem value={ROLE_KAM}>KAM</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth>
                                <InputLabel id="addUser-types">Zawód</InputLabel>
                                <Select
                                    labelId="addUser-types"
                                    name="type"
                                    value={userData.type}
                                    label="Zawód"
                                    disabled={(modalOption === 'show')}
                                    onChange={(e) => formValueHandler(e.target.name, e.target.value)}
                                > <MenuItem value="" disabled={true}><em>Wybierz z listy</em></MenuItem>

                                    <MenuItem value="LEKARZ">LEKARZ</MenuItem>
                                    <MenuItem value="PIELĘGNIARKA">PIELĘGNIARKA</MenuItem>
                                    <MenuItem value="PIELĘGNIARKA DYPLOMOWANA">PIELĘGNIARKA DYPLOMOWANA</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <TextField
                                required
                                label="Adres e-mail"
                                name="email"
                                value={userData.email}
                                disabled={(modalOption === SHOW_OPTION)}
                                onChange={(e) => formValueHandler(e.target.name, e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                required
                                label="Numer Identyfikatora"
                                name="id"
                                value={userData.id}
                                disabled={(modalOption === SHOW_OPTION)}
                                onChange={(e) => formValueHandler(e.target.name, e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth>
                                <InputLabel id="addUser-lang">Język</InputLabel>
                                <Select
                                    labelId="addUser-lang"
                                    name="language"
                                    multiple
                                    disabled={(modalOption === SHOW_OPTION)}
                                    value={userData.language}
                                    label="Język"
                                    onChange={(e) => formValueHandler(e.target.name, e.target.value)}
                                >
                                    <MenuItem value="" disabled={true}><em>Wybierz z listy</em></MenuItem>
                                    <MenuItem value="polski">Polski</MenuItem>
                                    <MenuItem value="angielski">Angielski</MenuItem>
                                    <MenuItem value="niemiecki">Niemiecki</MenuItem>
                                    <MenuItem value="ukraiński">Ukraiński</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <TextField
                                required
                                label="Numer umowy"
                                name="contractNO"
                                value={userData.contractNO}
                                disabled={(modalOption === SHOW_OPTION)}
                                onChange={(e) => formValueHandler(e.target.name, e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item marginBottom={2}>
                            <FormControl fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={pl}>
                                    <DatePicker
                                        allowSameDateSelection disableFuture
                                        label="Data podpisania umowy"
                                        inputFormat="dd/MM/yyyy"
                                        views={['year', 'month', 'day']}
                                        openTo="year"
                                        defaultValue={""}
                                        disabled={(modalOption === SHOW_OPTION)}
                                        value={userData.contractSignDate}
                                        onChange={(newValue) => formValueHandler('contractSignDate', newValue)}
                                        // onChange={(e) => [e.target.name: e.target.value)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                    </Grid>}
                    <div className={`customModal__addMenu-buttonArea ${modalOption === SHOW_OPTION ? 'alignButton' : ''} `}>
                        <Button onClick={closeModal} variant="contained" color="primary">Powrót</Button>
                        {
                            modalOption !== SHOW_OPTION &&
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary">
                                {modalOption === ADD_OPTION ? 'Dodaj' : modalOption === DELETE_OPTION ? 'Usuń': 'Zapisz'}
                            </Button>
                        }
                    </div>
                </form>
            </Stack>
        </div>
        :
        <GlobalLoader/>
}

export default AdminUserModal;
