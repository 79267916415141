import {
    CLEAR_USERS,
    GET_ADDITIONAL_ADVERSE_INFO,
    GET_ADDITIONAL_CREATE_USER_INFO,
    GET_INSTITUTIONS,
    GET_INSTITUTION_INFO,
    GET_USERS,
    GET_PROFILE_INFO,
    GET_USER_INFO,
    GET_USERS_FROM_INSTITUTIONS,
    GET_CERTIFICATES,
    CLEAR_CERTIFICATES,
    GET_WORKING_HOURS,
    GET_INTERACTIONS,
    GET_KNOWLEDGE_ITEMS,
// GET_DOCUMENT,
} from '../types';

const handlers = {
    [GET_KNOWLEDGE_ITEMS]: (state, {payload}) => ({...state, knowledge: payload}),
    [GET_WORKING_HOURS]: (state, {payload}) => ({...state, additionalWT: payload}),
    [GET_INTERACTIONS]: (state, {payload}) => ({...state, interactions: payload}),
    [GET_ADDITIONAL_ADVERSE_INFO]: (state, {payload}) => ({...state, ...payload}),
    [GET_ADDITIONAL_CREATE_USER_INFO]: (state, {payload}) => ({...state, ...payload}),
    [GET_INSTITUTIONS]: (state, {payload}) => ({...state, institutionsList: payload}),
    [GET_INSTITUTION_INFO]: (state, {payload}) => ({...state, showInstitutionInfo: payload}),
    [GET_USERS_FROM_INSTITUTIONS]: (state, {payload}) => ({...state, institutionsUserList: payload}),
    [GET_USERS]: (state, {payload}) => ({...state, users: payload}),
    [GET_CERTIFICATES]: (state, {payload}) => ({...state, certificates: payload}),
    // [GET_DOCUMENT]: (state, {payload}) => ({...state, certificateUrl: payload}),
    [GET_USER_INFO]: (state, {payload}) => ({...state, showUserInfo: {...payload}}),
    [GET_PROFILE_INFO]: (state, {payload}) => ({...state, showProfileInfoForUser: {...payload}}),
    [CLEAR_USERS]: (state) => ({...state, users: null}),
    [CLEAR_CERTIFICATES]: (state) => ({...state, certificates: null}),
    DEFAULT: state => state,
}

export const appReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}
