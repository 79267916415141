import React from 'react';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    OutlinedInput,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import {DatePickerComponent} from "../../../shared/ui/DatePickerComponent";

const CircumstancesInfo = ({circumstancesModule, circumstancesModuleHandler}) => {
    return (
        <Grid container border={`1px solid #071d49`}>
            <Grid item xs={12} bgcolor="#071d49" p={'10px 20px'}>
                <Typography
                    variant="h6"
                    color="white"
                >INFORMACJE O OKOLICZNOŚCIACH</Typography>
            </Grid>
            <Grid item p={2} container>
                <Grid item mb={2}>
                    <Typography variant="body2" color="#0f5e8d" sx={{fontStyle: 'italic'}}>(należy uwzględnić
                        czynności, jakie wykonywał pacjent w momencie zdarzenia oraz opisać stosowaną terapię, w tym
                        przepisane antybiotyki, wyniki badań diagnostycznych / laboratoryjnych):</Typography>
                </Grid>
                <Grid container justifyContent="center" alignItems="center" mb={2}>
                    <Grid item xs={12} lg={8}>
                        <FormControl fullWidth>
                            <Typography variant="subtitle1">Opis:</Typography>
                            <TextField
                                fullWidth
                                name="description"
                                multiline
                                minRows="4"
                                value={circumstancesModule.description}
                                onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12} lg={8}>
                        <FormControl fullWidth>
                            <Typography variant="subtitle1">Stan pacjenta / wynik zdarzenia:</Typography>
                            <TextField
                                fullWidth
                                name="condition"
                                multiline
                                minRows="4"
                                value={circumstancesModule.condition}
                                onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container className="dynamicTopBorder">
                <Grid item p={2} mb={2}>
                    <Typography variant="body2" color="#0f5e8d" sx={{fontStyle: 'italic'}}>Proszę zaznaczyć wszystkie
                        produkty z wymienionych, poniżej, które mają związek z opisywanym zdarzeniem niepożądanym, a
                        następnie uzupełnić części formularza dotyczące odpowiedniego produktu:</Typography>
                </Grid>
                <Grid item xs={12} container>
                    <Grid item xs={12} md={6} p={2}>
                        <Typography variant="subtitle1">Lista produktów:</Typography>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox
                                    name="pump"
                                    checked={circumstancesModule.pump}
                                    onChange={(e) => {
                                        circumstancesModuleHandler(e.target.name, e.target.checked)
                                        circumstancesModuleHandler('pumpSNo', '')
                                    }}
                                />} label="pompa"/>
                            <FormControlLabel control={
                                <Checkbox
                                    name="nj"
                                    checked={circumstancesModule.nj}
                                    onChange={(e) => {
                                        circumstancesModuleHandler(e.target.name, e.target.checked)
                                        circumstancesModuleHandler('startNJ', null)
                                    }}
                                />
                            } label="sonda nosowo-jelitowa (NJ)"/>
                            <FormControlLabel control={
                                <Checkbox
                                    name="peg"
                                    checked={circumstancesModule.peg}
                                    onChange={(e) => {
                                        circumstancesModuleHandler(e.target.name, e.target.checked)
                                        circumstancesModuleHandler('lastInstallPeg', null)
                                        circumstancesModuleHandler('pegSNo', '')
                                    }}
                                />
                            } label="sonda PEG"/>
                            <FormControlLabel control={
                                <Checkbox
                                    name="jejunum"
                                    checked={circumstancesModule.jejunum}
                                    onChange={(e) => {
                                        circumstancesModuleHandler(e.target.name, e.target.checked)
                                        circumstancesModuleHandler('lastInstallJejunum', null)
                                        circumstancesModuleHandler('jejunumSNo', '')
                                    }}
                                />
                            } label="sonda jelitowa (jelito czcze)"/>
                            <FormControlLabel control={
                                <Checkbox
                                    name="gel"
                                    checked={circumstancesModule.gel}
                                    onChange={(e) => {
                                        circumstancesModuleHandler(e.target.name, e.target.checked)
                                        circumstancesModuleHandler('gelSNo', '')
                                    }}
                                />
                            } label="żel / kasetka / lek"/>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="anotherProduct"
                                        checked={circumstancesModule.anotherProduct}
                                        onChange={(e) => {
                                            circumstancesModuleHandler(e.target.name, e.target.checked)
                                            if (!e.target.checked) {
                                                circumstancesModuleHandler('additionalProduct', '')
                                            }
                                        }}
                                    />}
                                label="inny (należy podać jaka)"
                            />
                            <Box minHeight={56}>
                                {
                                    circumstancesModule.anotherProduct && <FormControl fullWidth>
                                        <OutlinedInput
                                            id="additionalProduct"
                                            name="additionalProduct"
                                            value={circumstancesModule.additionalProduct}
                                            onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.value)}
                                        />
                                    </FormControl>
                                }
                            </Box>
                            <FormControlLabel control={
                                <Checkbox
                                    name="confirm"
                                    checked={circumstancesModule.confirm}
                                    onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.checked)}
                                />
                            } label="Potwierdzam, że dane o pacjencie (pompa/peg) są aktualne"/>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6} p={2}>
                        <Typography variant="subtitle1">Dostępna próbka reklamacyjna:</Typography>
                        <RadioGroup
                            name="complaintSample"
                            value={circumstancesModule.complaintSample}
                            onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.value)}
                        >
                            <FormControlLabel value="Tak" control={<Radio/>} label="Tak"/>
                            <FormControlLabel value="Nie" control={<Radio/>} label="Nie"/>
                        </RadioGroup>
                        {
                            circumstancesModule.complaintSample === 'Nie' &&
                            <>
                                <Typography variant="subtitle1">Jeżeli nie, należy zaznaczyć powód:</Typography>
                                <FormGroup>
                                    <FormControlLabel control={
                                        <Checkbox
                                            name="discarded"
                                            checked={circumstancesModule.discarded}
                                            onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.checked)}
                                        />
                                    } label="nie zachowano / wyrzucono"/>
                                    <FormControlLabel control={
                                        <Checkbox
                                            name="stillInUse"
                                            checked={circumstancesModule.stillInUse}
                                            onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.checked)}
                                        />
                                    } label="nadal w użyciu"/>
                                    <FormControlLabel control={
                                        <Checkbox
                                            name="submitSample"
                                            checked={circumstancesModule.submitSample}
                                            onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.checked)}
                                        />
                                    }
                                                      label="pacjent wyraził zgodę na przesłanie próbki, AbbVie podejmie kroki w celu otrzymania próbek"/>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="anotherReason"
                                                checked={circumstancesModule.anotherReason}
                                                onChange={(e) => {
                                                    circumstancesModuleHandler(e.target.name, e.target.checked)
                                                    if (!e.target.checked) {
                                                        circumstancesModuleHandler('additionalReason', '')
                                                    }
                                                }}
                                            />}
                                        label="inny powód (należy podać, jaki)"
                                    />
                                    <Box minHeight={56}>
                                        {
                                            circumstancesModule.anotherReason && <FormControl fullWidth>
                                                <OutlinedInput
                                                    id="additionalReason"
                                                    name="additionalReason"
                                                    value={circumstancesModule.additionalReason}
                                                    onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.value)}
                                                />
                                            </FormControl>
                                        }
                                    </Box>
                                </FormGroup>
                            </>
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container className="dynamicTopBorder">
                <Grid item xs={12} lg={6} p={2} container direction="column" justifyContent="space-between">
                    <DatePickerComponent
                        disableFuture
                        allowSameDateSelection
                        value={circumstancesModule.startTreatment}
                        onChange={(newValue) => {
                            circumstancesModuleHandler('startTreatment', newValue);
                        }}
                    >
                        <Typography variant="subtitle1" mr={3}>Data rozpoczęcia terapii
                            lekiem Duodopa:</Typography>
                    </DatePickerComponent>
                </Grid>
                <Grid item xs={12} lg={6} p={2} className="dynamicLeftBorder">
                    <DatePickerComponent
                        disableFuture
                        allowSameDateSelection
                        value={circumstancesModule.startPeg}
                        onChange={(newValue) => {
                            circumstancesModuleHandler('startPeg', newValue);
                        }}
                    >
                        <Typography variant="subtitle1" mr={3}>Data założenia pierwszej
                            sondy PEG i sondy jelitowej (jelito czcze):</Typography>
                    </DatePickerComponent>
                </Grid>
            </Grid>
            {
                circumstancesModule.pump &&
                <Grid item container borderTop={`1px solid #071d49`}>
                    <Grid item xs={12}>
                        <p className='subMenuHeader'>pompa</p>
                    </Grid>
                    <Grid item p={2} container justifyContent='center'>
                        <Grid item xs={12} lg={6}>
                            <FormControl fullWidth>
                                <Typography variant="subtitle1">Numer seryjny pompy:</Typography>
                                <OutlinedInput
                                    id="pumpSNo"
                                    name="pumpSNo"
                                    value={circumstancesModule.pumpSNo}
                                    onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {
                circumstancesModule.nj &&
                <Grid item container borderTop={`1px solid #071d49`}>
                    <Grid item xs={12}>
                        <p className='subMenuHeader'>sonda NJ</p>
                    </Grid>
                    <Grid item p={2} container justifyContent='center'>
                        <Grid item xs={12} lg={6}>
                            <DatePickerComponent
                                disableFuture
                                allowSameDateSelection
                                value={circumstancesModule.startNJ}
                                onChange={(newValue) => {
                                    circumstancesModuleHandler('startNJ', newValue);
                                }}
                            >
                                <Typography variant="subtitle1" mr={3}>Data założenia sondy NJ:</Typography>
                            </DatePickerComponent>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {
                circumstancesModule.peg &&
                <Grid item container borderTop={`1px solid #071d49`}>
                    <Grid item xs={12} lg={6} container>
                        <Grid item xs={12}>
                            <p className='subMenuHeader'>sonda PEG</p>
                        </Grid>
                        <Grid item p={2} container justifyContent='center'>
                            <Grid item xs={12}>
                                <DatePickerComponent
                                    disableFuture
                                    allowSameDateSelection
                                    value={circumstancesModule.lastInstallPeg}
                                    onChange={(newValue) => {
                                        circumstancesModuleHandler('lastInstallPeg', newValue);
                                    }}
                                >
                                    <Typography variant="subtitle1" mr={3}>Data ostatniej wymiany sondy
                                        PEG:</Typography>
                                </DatePickerComponent>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6} p={2} container alignItems='flex-end' className='dynamicLeftBorder'>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Typography variant="subtitle1">Numer serii obecnej sondy PEG:</Typography>
                                <OutlinedInput
                                    id="pegSNo"
                                    name="pegSNo"
                                    value={circumstancesModule.pegSNo}
                                    onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {
                circumstancesModule.jejunum &&
                <Grid item container borderTop={`1px solid #071d49`}>
                    <Grid item xs={12} lg={6} container>
                        <Grid item xs={12}>
                            <p className='subMenuHeader'>sonda jelitowa</p>
                        </Grid>
                        <Grid item p={2} container justifyContent='center'>
                            <Grid item xs={12}>
                                <DatePickerComponent
                                    disableFuture
                                    allowSameDateSelection
                                    value={circumstancesModule.lastInstallJejunum}
                                    onChange={(newValue) => {
                                        circumstancesModuleHandler('lastInstallJejunum', newValue);
                                    }}
                                >
                                    <Typography variant="subtitle1" mr={3}>Data ostatniej wymiany sondy
                                        jelitowej:</Typography>
                                </DatePickerComponent>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6} p={2} container alignItems='flex-end' className='dynamicLeftBorder'>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Typography variant="subtitle1">Numer serii obecnej sondy jelitowej:</Typography>
                                <OutlinedInput
                                    id="jejunumSNo"
                                    name="jejunumSNo"
                                    value={circumstancesModule.jejunumSNo}
                                    onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {
                circumstancesModule.gel &&
                <Grid item container borderTop={`1px solid #071d49`}>
                    <Grid item xs={12}>
                        <p className='subMenuHeader'>żel / kasetka</p>
                    </Grid>
                    <Grid item p={2} container justifyContent='center'>
                        <Grid item xs={12} lg={6}>
                            <FormControl fullWidth>
                                <Typography variant="subtitle1">Numer serii żelu / kasetki:</Typography>
                                <OutlinedInput
                                    id="gelSNo"
                                    name="gelSNo"
                                    value={circumstancesModule.gelSNo}
                                    onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {
                circumstancesModule.anotherProduct &&
                <Grid item container borderTop={`1px solid #071d49`}>
                    <Grid item xs={12}>
                        <p className='subMenuHeader'>inny</p>
                    </Grid>
                    <Grid item container>
                        <Grid item xs={12} lg={2} p={2}>
                            <Typography variant="subtitle1">Wymienione złącze:</Typography>
                            <RadioGroup
                                name="anotherProductReload"
                                value={circumstancesModule.anotherProductReload}
                                onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.value)}
                            >
                                <FormControlLabel value="Tak" control={<Radio/>} label="Tak"/>
                                <FormControlLabel value="Nie" control={<Radio/>} label="Nie"/>
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12} lg={5} p={2} container alignItems='center'>
                            <FormControl fullWidth>
                                <Typography variant="subtitle1">Numer serii:</Typography>
                                <OutlinedInput
                                    name="anotherProductSNo"
                                    value={circumstancesModule.anotherProductSNo}
                                    onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={5} p={2} container alignItems='center'>
                            <FormControl fullWidth>
                                <Typography variant="subtitle1">Akcesoria do noszenia / inne – numer serii</Typography>
                                <OutlinedInput
                                    name="anotherProductAccessoriesNo"
                                    value={circumstancesModule.anotherProductAccessoriesNo}
                                    onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            }
            <Grid item xs={12} p={2} borderTop={`1px solid #071d49`} container alignItems="center"
                  justifyContent="center" direction='column'>
                <Typography variant="subtitle1" mr={3}>Jeżeli numer serii jest nieznany, należy zaznaczyć, z jakiego
                    powodu:</Typography>
                <FormGroup>
                    <Stack direction="column" spacing={1}>
                        <FormControlLabel control={
                            <Checkbox
                                name="noPackage"
                                checked={circumstancesModule.noPackage}
                                onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.checked)}
                            />}
                                          label="osoba zgłaszająca nie posiada informacji na temat numeru serii, ponieważ (np. opakowanie produktu zostało wyrzucone)"/>
                        <FormControlLabel control={
                            <Checkbox
                                name="noAnswer"
                                checked={circumstancesModule.noAnswer}
                                onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.checked)}
                            />
                        } label="osoba zgłaszająca odmówiła podania informacji na temat numeru serii"/>
                        <FormControlLabel control={
                            <Checkbox
                                name="noContact"
                                checked={circumstancesModule.noContact}
                                onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.checked)}
                            />
                        }
                                          label="nie udało się skontaktować z osobą zgłaszającą w celu uzyskania informacji na temat numeru serii"/>
                        <FormControlLabel control={
                            <Checkbox
                                name="noContactInfo"
                                checked={circumstancesModule.noContactInfo}
                                onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.checked)}
                            />
                        } label="dane kontaktowe osoby zgłaszającej były niedostępne"/>
                        <FormControlLabel control={
                            <Checkbox
                                name="noRegistration"
                                checked={circumstancesModule.noRegistration}
                                onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.checked)}
                            />
                        } label="gabinet lekarski nie prowadzi rejestracji numerów serii"/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="anotherReasonNS"
                                    checked={circumstancesModule.anotherReasonNS}
                                    onChange={(e) => {
                                        circumstancesModuleHandler(e.target.name, e.target.checked)
                                        if (!e.target.anotherReasonNS) {
                                            circumstancesModuleHandler('additionalReasonNS', '')
                                        }
                                    }}
                                />}
                            label="inny powód (należy podać, jaki)"
                        />
                        <Box minHeight={56}>
                            {
                                circumstancesModule.anotherReasonNS && <FormControl fullWidth>
                                    <OutlinedInput
                                        id="additionalReasonNS"
                                        name="additionalReasonNS"
                                        value={circumstancesModule.additionalReasonNS}
                                        onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.value)}
                                    />
                                </FormControl>
                            }
                        </Box>
                    </Stack>
                </FormGroup>
            </Grid>
        </Grid>
    )
}

export default CircumstancesInfo;