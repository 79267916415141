import React from 'react';
import {NavLink} from 'react-router-dom';
import {GoHome, GoMail} from 'react-icons/go';
import {MdOutlineAddShoppingCart, MdPassword} from 'react-icons/md';
import {AiOutlineLogout, AiOutlineUser} from 'react-icons/ai';
import {RiUserAddLine} from 'react-icons/ri';
import {
    BsCardChecklist,
    BsExclamationTriangle,
} from 'react-icons/bs';
import {BiNotepad} from 'react-icons/bi';
import {GiRibbonMedal} from 'react-icons/gi';
import {HiOutlineOfficeBuilding, HiOutlineUserGroup} from 'react-icons/hi';
import {GrDocumentTime, GrMoney} from 'react-icons/gr';
import {VscServerProcess} from "react-icons/vsc";
import {ROLE_ADMIN, ROLE_ADS, ROLE_COORD_ADS, ROLE_KAM} from "../consts/users";

const UserLinks = (isLoggedIn, role, checkPassDate, userName, userSurname) => {
    const customStyle = {width: '30px', height: '30px', margin: '0 10px'}

    if (!isLoggedIn) {
        return <nav className="userMenu">
            <NavLink
                className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                to="/"
            >
                <GoHome style={customStyle}/>
                Home
            </NavLink>
        </nav>
    } else {
        if (checkPassDate) {
            switch (role) {
                case ROLE_ADS:
                    return <nav className="userMenu">
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/"
                        >
                            <GoHome style={customStyle}/>
                            Home
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/patients"
                        >
                            <RiUserAddLine style={customStyle}/>
                            Pacjenci
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/overtime"
                        >
                            <GrDocumentTime style={customStyle}/>
                            Nadgodziny
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/settlements"
                        >
                            <GrMoney style={customStyle}/>
                            Rozliczenia
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/interactions"
                        >
                            <BsCardChecklist style={customStyle}/>
                            Interakcje
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/messages"
                        >
                            <GoMail style={customStyle}/>
                            Wiadomości
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/knowledge_base"
                        >
                            <BiNotepad style={customStyle}/>
                            Baza wiedzy
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/adverse_events"
                        >
                            <BsExclamationTriangle style={customStyle}/>
                            Zdarzenia<br/>niepożądane
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/hardware_orders"
                        >
                            <MdOutlineAddShoppingCart style={customStyle}/>
                            Zamówienia<br/>sprzętowe
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/certification"
                        >
                            <GiRibbonMedal style={customStyle}/>
                            Certyfikacja
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/profile"
                        >
                            <AiOutlineUser style={customStyle}/>
                            Profil
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/logout"
                        >
                            <AiOutlineLogout style={customStyle}/>
                            Wyloguj<br/>{userName} {userSurname}
                        </NavLink>
                    </nav>
                case ROLE_ADMIN:
                    return <nav className="userMenu">
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/"
                        >
                            <GoHome style={customStyle}/>
                            Home
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/patients"
                        >
                            <RiUserAddLine style={customStyle}/>
                            Pacjenci
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/users"
                        >
                            <HiOutlineUserGroup style={customStyle}/>
                            Użytkownicy
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/hospitals"
                        >
                            <HiOutlineOfficeBuilding style={customStyle}/>
                            Placówki
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/overtime"
                        >
                            <GrDocumentTime style={customStyle}/>
                            Nadgodziny
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/reckonings"
                        >
                            <GrMoney style={customStyle}/>
                            Rozliczenia
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/interactions"
                        >
                            <BsCardChecklist style={customStyle}/>
                            Interakcje
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/messages"
                        >
                            <GoMail style={customStyle}/>
                            Wiadomości
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/knowledge_base"
                        >
                            <BiNotepad style={customStyle}/>
                            Baza wiedzy
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/hardware"
                        >
                            <VscServerProcess style={customStyle}/>Produkty</NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/hardware_orders"
                        >
                            <MdOutlineAddShoppingCart style={customStyle}/>
                            Zamówienia<br/>sprzętowe
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/certification"
                        >
                            <GiRibbonMedal style={customStyle}/>
                            Certyfikacja
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/profile"
                        >
                            <AiOutlineUser style={customStyle}/>
                            Profil
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/logout"
                        >
                            <AiOutlineLogout style={customStyle}/>
                            Wyloguj<br/>{userName} {userSurname}
                        </NavLink>
                    </nav>
                case ROLE_COORD_ADS:
                    return <nav className="userMenu">
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/"
                        >
                            <GoHome style={customStyle}/>
                            Home
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/patients"
                        >
                            <RiUserAddLine style={customStyle}/>
                            Pacjenci
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/overtime"
                        >
                            <GrDocumentTime style={customStyle}/>
                            Nadgodziny
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/settlements"
                        >
                            <GrMoney style={customStyle}/>
                            Rozliczenia
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/interactions"
                        >
                            <BsCardChecklist style={customStyle}/>
                            Interakcje
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/messages"
                        >
                            <GoMail style={customStyle}/>
                            Wiadomości
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/knowledge_base"
                        >
                            <BiNotepad style={customStyle}/>
                            Baza wiedzy
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/adverse_events"
                        >
                            <BsExclamationTriangle style={customStyle}/>
                            Zdarzenia<br/>niepożądane
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/hardware_orders"
                        >
                            <MdOutlineAddShoppingCart style={customStyle}/>
                            Zamówienia<br/>sprzętowe
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/certification"
                        >
                            <GiRibbonMedal style={customStyle}/>
                            Certyfikacja
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/profile"
                        >
                            <AiOutlineUser style={customStyle}/>
                            Profil
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/logout"
                        >
                            <AiOutlineLogout style={customStyle}/>
                            Wyloguj<br/>{userName} {userSurname}
                        </NavLink>
                    </nav>
                case ROLE_KAM:
                    return <nav className="userMenu">
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/"
                        >
                            <GoHome style={customStyle}/>
                            Home
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/hardware"
                        >
                            <VscServerProcess style={customStyle}/>Produkty</NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/hardware_orders"
                        >
                            <MdOutlineAddShoppingCart style={customStyle}/>
                            Zamówienia<br/>sprzętowe
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/profile"
                        >
                            <AiOutlineUser style={customStyle}/>
                            Profil
                        </NavLink>
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/logout"
                        >
                            <AiOutlineLogout style={customStyle}/>
                            Wyloguj<br/>{userName} {userSurname}
                        </NavLink>
                    </nav>
                default:
                    return <nav className="userMenu">
                        <NavLink
                            className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                            to="/"
                        >
                            <GoHome style={customStyle}/>
                            Home
                        </NavLink>
                    </nav>
            }
        } else {
            return <nav className="userMenu">
                <NavLink
                    className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                    to="/"
                >
                    <MdPassword style={customStyle}/>
                    Change Password
                </NavLink>
                <NavLink
                    className={({isActive}) => `userMenu__link ${isActive && 'userMenu__activeLink'}`}
                    to="/logout"
                >
                    <AiOutlineLogout style={customStyle}/>
                    Wyloguj
                </NavLink>
            </nav>
        }
    }
}

export default UserLinks;