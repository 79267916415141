export const patientModalValidate = (patientInfo, pumps, pegs, modalOption) => {
	
	if (patientInfo.nameInitial.length > 1) {
		return ({
			isError: true,
			defRes: {
				data: {
					code: 422,
					message: 'WPROWADŹ JEDYNIE INICJAŁY PACJENTA',
				},
			}
		})
	}
	
	if (patientInfo.surnameInitial.length > 1) {
		return ({
			isError: true,
			defRes: {
				data: {
					code: 422,
					message: 'WPROWADŹ JEDYNIE INICJAŁY PACJENTA',
				},
			}
		})
	}
	
	if (!pumps.length) {
		return ({
			isError: true,
			defRes: {
				data: {
					code: 422,
					message: 'DODAJ INFORMACJĘ O POMP',
				},
			}
		})
	}
	
	if (!pegs.length) {
		return ({
			isError: true,
			defRes: {
				data: {
					code: 422,
					message: 'DODAJ INFORMACJĘ O PEG',
				},
			}
		})
	}
	
	if (pumps.filter(el => !el.releaseDate).length > 0) {
		return ({
			isError: true,
			defRes: {
				data: {
					code: 422,
					message: 'WYBIERZ DATY DLA POMP',
				},
			}
		})
	}
	
	if (pegs.filter(el => !el.installDate).length > 0) {
		return ({
			isError: true,
			defRes: {
				data: {
					code: 422,
					message: 'WYBIERZ DATY DLA PEG',
				},
			}
		})
	}
	
	if (!patientInfo.probeInstallDate) {
		return ({
			isError: true,
			defRes: {
				data: {
					code: 422,
					message: 'DODAJ DATĘ INICJALIZACJI PROBE',
				},
			}
		})
	}
	
	if (modalOption !== 'edit' && !patientInfo.isDataProcessAgreement) {
		return ({
			isError: true,
			defRes: {
				data: {
					code: 422,
					message: 'Aby zapisać formularz należy przekazać informację o przetwarzaniu danych osobowych do pacjenta / opiekuna i zaznaczyć odpowiednią opcję',
				},
			}
		})
	}
	
	return ({
		isError: false,
		defRes: null
	})
}