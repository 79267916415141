import {Box, Stack} from '@mui/material';
import React from 'react'
import CloseIcon from '../../../assets/img/ui/closeIcon.png';


const WarningBox = ({children, setModal, handleClose, isAdmin}) => {
    return (
        <Box onClick={() => isAdmin ? handleClose() : setModal('closed')}
             sx={{position: 'absolute', inset: '0', display: 'grid', placeItems: 'center'}}
        >
            <Stack onClick={(e) => {
                e.stopPropagation()
            }} bgcolor='rgba(235,235,235,0.95)'
                   sx={{borderRadius: '25px', zIndex: '1', maxWidth: '930px', width: '80%'}}
            >
                <Stack direction="row" px={20} py={8}
                       sx={{position: 'relative', minHeight: '780px', display: 'grid', alignItems: 'center'}}
                >
                    <Box onClick={() => isAdmin ? handleClose() : setModal('closed')}
                         sx={{position: 'absolute', top: "1rem", right: '1rem', cursor: 'pointer'}}>
                        <img src={CloseIcon} alt=""/>
                    </Box>
                    {children}
                </Stack>
            </Stack>
        </Box>
    )
};

export default WarningBox