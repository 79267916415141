import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, OutlinedInput, Radio, RadioGroup, Typography } from "@mui/material";
import {observer} from 'mobx-react-lite';

import Line from "../../../../shared/ui/Line";
import { useAdverseEventsProduodopaStore } from "../../../../../store/hooks";
import { convertBoolValue, convertBoolValueHandler } from "../helper";
import {useDisabled} from '../useDisabled';

const errorDetailsElements = [
      "Alarm o wysokim priorytecie",
      "Alarm o niskim priorytecie",
      "Alarm zablokowania",
      "Komunikat informacyjny/alarm na ekranie (Zapisz poniżej komunikat o błędzie wyświetlany na ekranie, jeśli dotyczy)"
];

const physicalProblemsElements = [
      "Uszkodzenie przez wodę/ płyn",
      "Fizyczne uszkodzenie",
      "Upuszczony",
      "Brakująca część",
      "Popsuty zestaw ładujący"
  ];

const functionalProblemsElements = [
      "Problem z baterią",
      "Problem z zamknięciem baterii",
      "Awaria klucza/ przycisku",
      "Problem z ładowaniem",
      "Problem z podaniem leku/ wyciek",
      "Problem z zatrzaskiem"
  ];

export const softwareProblemsElements = [
      "Wyświetlacz",
      "Zwiększanie lub zmniejszanie mocy lub pozostawanie włączonym",
      "Błąd alarmu",
      "Problem z programowaniem",
      "Widoczna lub słyszalna zmiana prędkości",
      "Błąd użycia, należy dodać wyjaśnienie:"
  ];

const solutionsElements = [
      "Pokrywa została otwarta i ponowie zamknięta",
      "Wymieniono baterie w pompie",
      "Pompa została wyłączona i ponownie włączona",
      "Wymieniono istniejącą strzykawkę i włożono inną strzykawkę (jeśli użyto tej samej strzykawki, należy to odnotować w formularzu)",
      "Wymieniono zestaw infuzyjny i użyto innego zestawu infuzyjnego (jeśli użyto tego samego zestawu infuzyjnego, należy to odnotować w formularzu)",
      "Zamknięcie baterii nie zostało prawidłowo zamknięte przez użytkownika",
      "Pokrywa pompy nie została prawidłowo zatrzaśnięta przez użytkownika na obudowie pompy",
      'W przypadku wyświetlenia komunikatu "Priming Error" (Błąd zalewania) użytkownik zatrzymuje pompę, potwierdza, że system nie przecieka i ponownie uruchamia pompę bez żadnych problemów'
  ];


const PumpProduct = observer(() => {
    const adverseEventsProduodopaStore = useAdverseEventsProduodopaStore()
    const {pumpInfo} = adverseEventsProduodopaStore;

    const {
      isDisabled: isDisabledErrorDetailsOther,
      changeDisabled: changeDisabledErrorDetailsOther
    } = useDisabled(errorDetailsElements[errorDetailsElements.length - 1], "errorDetailsOther", adverseEventsProduodopaStore.pumpInfoOnChange)


    const {
      isDisabled: isDisabledSoftwareProblemsExplanation,
      changeDisabled: changeDisabledSoftwareProblemsExplanation
    } = useDisabled(softwareProblemsElements[softwareProblemsElements.length - 1], "softwareProblemsExplanation", adverseEventsProduodopaStore.pumpInfoOnChange)


    const {
      isDisabled: isDisabledSoftwareProblemsOther,
      changeDisabled: changeDisabledSoftwareProblemsOther
    } = useDisabled("Inne:", "softwareProblemsOther", adverseEventsProduodopaStore.pumpInfoOnChange)

    const {
      isDisabled: isDisabledAvailableReplacementPumpHelped,
      changeDisabledSingle: changeDisabledAvailableReplacementPumpHelped
    } = useDisabled(true, "availableReplacementPumpHelped", adverseEventsProduodopaStore.pumpInfoOnChange)

    return (
      <Grid container display='flex' mt={2} pl={2}>
        <Grid item className="subMenuSidebar">Pompa</Grid>
        <Grid flexGrow={1} maxWidth="calc(100% - 100px)" container display='flex' flexDirection='column'  >
            <Grid item sx={{border: '1px solid #9B9B9D'}} p={2} display='flex' gap={2} flexDirection='column'>

                 <FormControl fullWidth>
                    <Typography variant='subtitle1'>Czy w pompie wystąpił alarm lub komunikat o błędzie?</Typography>

                        <RadioGroup 
                            value={convertBoolValue(pumpInfo.isPumpError)}
                                name="isPumpError"
                                onChange={(e) => adverseEventsProduodopaStore.pumpInfoOnChange(e.target.name, convertBoolValueHandler(e.target.value))}>
                                <FormControlLabel mt={1} value="Tak" control={<Radio />} label="Tak" />
                                <FormControlLabel mt={1} value="Nie" control={<Radio />} label="Nie" />
                        </RadioGroup>
                  </FormControl>  
                  <Typography>Należy sprawdzić odpowiedni typ alarmu i opisać go szczegółowo, w tym wszelkie kody błędów lub komunikaty na wyświetlaczu. </Typography>
                  <div>
                    <FormControl fullWidth>
                          <FormGroup 
                                name="errorDetails"
                                value={pumpInfo.errorDetails}
                                onChange={(e) => {
                                  adverseEventsProduodopaStore.pumpInfoOnChange("errorDetails", e.target.value);
                                  changeDisabledErrorDetailsOther(pumpInfo.errorDetails, e.target.value)
                                }}>
                                    {errorDetailsElements.map(element => <FormControlLabel key={element} mt={1} value={element} control={<Checkbox />} label={element} /> )}
                          </FormGroup>
                    </FormControl> 
                    <FormControl fullWidth sx={{pl: 6, pt: 1}}>
                          <OutlinedInput
                                 disabled={isDisabledErrorDetailsOther}
                                  name="errorDetailsOther"
                                  value={pumpInfo.errorDetailsOther}
                                  onChange={(e) => adverseEventsProduodopaStore.pumpInfoOnChange(e.target.name, e.target.value)}/>
                    </FormControl>
                  </div>
            </Grid>

            <Grid item sx={{border: '1px solid #9B9B9D'}} p={2} display='flex' gap={2} flexDirection='column'>
                <Typography variant='subtitle1'>Czy problem z pompą ma charakter fizyczny, funkcjonalny lub związany z oprogramowaniem? Zaznacz poniżej odpowiednią wadę: </Typography>
                <div>
                <FormControl fullWidth>
                    <Typography>Fizyczny:</Typography>
                      <FormGroup 
                               name="physicalProblems"
                               value={pumpInfo.physicalProblems}
                               onChange={(e) => adverseEventsProduodopaStore.pumpInfoOnChange("physicalProblems", e.target.value)}>
                              {physicalProblemsElements.map(element => <FormControlLabel key={element} mt={1} value={element} control={<Checkbox />} label={element} /> )}
                      </FormGroup>
                </FormControl>  
                <Line/>
                <FormControl fullWidth>
                    <Typography>Funkcjonalny:</Typography>
                      <FormGroup 
                              name="functionalProblems"
                              value={pumpInfo.functionalProblems}
                              onChange={(e) => adverseEventsProduodopaStore.pumpInfoOnChange("functionalProblems", e.target.value)}>
                              {functionalProblemsElements.map(element => <FormControlLabel key={element} mt={1} value={element} control={<Checkbox />} label={element} /> )}
                      </FormGroup>
                </FormControl> 
                <Line/>
                
                    <Typography>Oprogramowanie:</Typography>
                      <FormGroup 
                              name="softwareProblems"
                              value={pumpInfo.softwareProblems}
                              onChange={(e) => {
                                adverseEventsProduodopaStore.pumpInfoOnChange('softwareProblems', e.target.value, softwareProblemsElements);
                                changeDisabledSoftwareProblemsExplanation(pumpInfo.softwareProblems, e.target.value);
                              }}>
                              {softwareProblemsElements.map(element => <FormControlLabel key={element} mt={1} value={element} control={<Checkbox />} label={element} /> )}
                      </FormGroup>
                          <FormControl FormControl fullWidth sx={{pl: 6, pt: 1}}>
                                <OutlinedInput
                                   disabled={isDisabledSoftwareProblemsExplanation}
                                    name="softwareProblemsExplanation"
                                    value={pumpInfo.softwareProblemsExplanation}
                                    onChange={(e) => adverseEventsProduodopaStore.pumpInfoOnChange(e.target.name, e.target.value)}/>
                              </FormControl>
                              <FormControlLabel mt={1} value={"Inne:"} control={<Checkbox onChange={(e) => {
                                adverseEventsProduodopaStore.pumpInfoOnChange('softwareProblems', e.target.value, softwareProblemsElements);
                                changeDisabledSoftwareProblemsOther(pumpInfo.softwareProblems, e.target.value);
                              }}/>} label="Inne:" />
                              <FormControl fullWidth sx={{pl: 6, pt: 1}}>
                                <OutlinedInput
                                    disabled={isDisabledSoftwareProblemsOther}
                                    name="softwareProblemsOther"
                                    value={pumpInfo.softwareProblemsOther}
                                    onChange={(e) => adverseEventsProduodopaStore.pumpInfoOnChange(e.target.name, e.target.value)}/>
                          </FormControl>
                </div>
          </Grid>

          <Grid item sx={{border: '1px solid #9B9B9D'}} p={2} display='flex' gap={2} flexDirection='column'>
                <Typography variant='subtitle1'>Jeśli problem został rozwiązany, w jaki sposób został rozwiązany?</Typography>
                <div>
                  <FormGroup 
                      name="solutions"
                      value={pumpInfo.solutions}
                      onChange={(e) => adverseEventsProduodopaStore.pumpInfoOnChange("solutions", e.target.value)}>
                        {solutionsElements.map(element => <FormControlLabel key={element} mt={1} value={element} control={<Checkbox />} label={element} /> )}
                  </FormGroup>
                  <Line/>       
                <Typography variant='subtitle1'>Wyjaśnij, w jaki sposób problem został rozwiązany, jeśli nie został wymieniony powyżej:</Typography>
                </div>
                <FormControl fullWidth>
                    <Typography>Czy była dostępna pompa zamienna do wypróbowania?</Typography>
                      <RadioGroup row
                              value={convertBoolValue(pumpInfo.isAvailableReplacementPump)}
                              name="isAvailableReplacementPump"
                              onChange={(e) =>{
                                adverseEventsProduodopaStore.pumpInfoOnChange(e.target.name, convertBoolValueHandler(e.target.value));
                                changeDisabledAvailableReplacementPumpHelped( convertBoolValueHandler(e.target.value));
                              }}>
                              <FormControlLabel mt={1} value="Tak" control={<Radio />} label="Tak" />
                              <FormControlLabel mt={1} value="Nie" control={<Radio />} label="Nie" />
                      </RadioGroup>
                </FormControl> 
                <Typography>Jeśli tak, czy to pomogło?</Typography>
                <FormControl fullWidth>
                   <OutlinedInput
                      disabled={isDisabledAvailableReplacementPumpHelped}
                      name="availableReplacementPumpHelped"
                      value={pumpInfo.availableReplacementPumpHelped}
                      onChange={(e) => adverseEventsProduodopaStore.pumpInfoOnChange(e.target.name, e.target.value)}/>
                </FormControl>
          </Grid>
        </Grid>
    </Grid>
    )
});

export default PumpProduct;