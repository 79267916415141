import React, {useContext, useEffect, useState} from 'react'
import {SettlementsContext} from '../../../context/settlements/settlementsContext';
import {Button, Grid, Stack, Typography} from '@mui/material';
import avatar from '../../../assets/img/ui/windowPerson.png';
import {dateFormat} from '../../../utils/helpfunc';
import TrainingAdd from './TrainingAdd';
import {AuthContext} from "../../../context/auth/authContext";
import SearchIcon from '@mui/icons-material/Search';
import {CLASS_BLUE, CLASS_GREEN} from './settlementsMonthTable/helpers'
import {ROLE_ADMIN} from "../../../data/consts/users";

const Trainings = ({settlementPeriod}) => {
    const {settlementsState, acceptTraining} = useContext(SettlementsContext);
    const {authState, role} = useContext(AuthContext)
    const [trainings, setTrainings] = useState([])
    const [openTrainingId, setOpenTrainingId] = useState(null)

    useEffect(() => {
        setTrainings([...settlementsState.trainings].sort((a, b) => new Date(b.date) - new Date(a.date)))
    }, [settlementsState])

    return trainings.length ?
        trainings.map(el => {
                const isAuthor = (authState.id === el.createdBy)
                const makers = el.makers.map((id) => settlementsState.makers.find(item => item.id === id).name).join(', ');
                return <Grid container key={el.id} p={1}>
                    {/*TRAINING TITLE*/}
                    <Grid
                        item
                        xs={12}
                        container
                        display="row"
                        wrap="nowrap"
                        p={1}
                        alignItems="center"
                        bgcolor="#f5f5f5"
                        padding={"30px 70px 40px"}
                    >
                        <Grid item pr={"45px"}>
                            <img src={avatar} alt="ava" className="settlements__blockImage_XL"/>
                        </Grid>
                        <Grid item ml={1} container justifyContent={'flex-start'}>
                            <Grid item xs={12} lg={5}>
                                <Stack className='settlements__status' direction="column">
                                    <Typography><b>Szkolenie:</b></Typography>
                                    <Typography>{el.subject}</Typography>
                                </Stack>
                                <Stack className='settlements__status' direction="column">
                                    <Typography><b>Data:</b></Typography>
                                    <Typography>{dateFormat(new Date(el.date))}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item container xs={12} lg={7}>
                                <Grid container item xs={12} lg={7} className='settlements__status'
                                      style={{visibility: role === ROLE_ADMIN ? 'hidden' : 'visible'}} direction="column"
                                      mb={1}>
                                    <Stack direction="row">
                                        <Typography mr={1}>Status </Typography>
                                        <Typography sx={{fontWeight: "bold"}}>szkolenia:</Typography>
                                    </Stack>
                                    <Typography className='settlements__firstLetter'
                                                sx={{color: `${el.status.includes('zaakcept') ? 'green' : el.status.includes('oczek') ? 'orange' : 'red'}`}}>{el.status}</Typography>
                                </Grid>
                                <Grid direction="column" container item xs={12} lg={5} mb={2}
                                      style={{visibility: makers.length ? 'visible' : 'hidden', minHeight: '5rem'}}>
                                    <Typography>Wykonujący szkolenie:</Typography>
                                    <Typography><i><b>{makers}</b></i></Typography>
                                </Grid>
                                <Stack direction="row" spacing={5} mb={'auto'} mt={-5}>
                                    {!el.accepted &&
                                        <Button onClick={() => acceptTraining(el.id)} className={CLASS_GREEN}
                                                variant="contained">akceptuj</Button>}
                                    <Button className={el.status.includes('nieuzup') ? CLASS_GREEN : CLASS_BLUE}
                                            variant="contained"
                                            onClick={() => {
                                                openTrainingId === el.id ? setOpenTrainingId(null) : setOpenTrainingId(el.id)
                                            }}>
                                        {(el.status.includes('zaakcept') || (!isAuthor && el.status.includes('oczek')) || role === ROLE_ADMIN) ?
                                            <SearchIcon/> : el.status.includes('oczek') ? 'edytuj' : 'dodaj'}
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*PATIENT INFO*/}
                    {
                        openTrainingId === el.id &&
                        <TrainingAdd settlementPeriod={settlementPeriod} trainingInfo={el} setIsAdding={setOpenTrainingId}
                                     isAuthor={isAuthor}/>
                    }
                </Grid>
            }
        )
        :
        <Grid container alignItems="center" justifyContent="center" p={2}>
            <Typography>Brak danych</Typography>
        </Grid>
}

export default Trainings;