import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, OutlinedInput, Radio, RadioGroup, Typography } from "@mui/material";
import {observer} from 'mobx-react-lite';

import Line from "../../../../shared/ui/Line";
import {DatePickerComponent} from "../../../../shared/ui/DatePickerComponent";
import {useAdverseEventsProduodopaStore} from '../../../../../store/hooks';
import {useDisabled} from '../useDisabled';
import {convertBoolValue, convertBoolValueHandler} from '../helper';

const defectsElements = [
  "Nie można połączyć strzykawki z zestawem infuzyjnym",
  "Poluzowany/odpadł od adaptera fiolki",
  "Poluzowany/ odpadł od zestawu infuzyjnego",
  "Złamane/uszkodzone (cylinder, tłok, zatyczka, kołnierze, końcówka Luer)",
  "Czytelność oznaczeń skali",
  "Przeciekanie",
  "Uszkodzone opakowanie podstawowe/naruszona bariera sterylna",
  "Ciała obce, zanieczyszczenia i materiały obce",
  "Wygasły termin przydatności do użycia"
];

const leakingConnectionsElements = [
  "Adapter strzykawki i fiolki",
  "Strzykawka i rurka infuzyjna (Luer-Lock)",
  "Inne:"
];

const SyringeProduct = observer(() => {
  const adverseEventsProduodopaStore = useAdverseEventsProduodopaStore()
  const {syringeInfo} = adverseEventsProduodopaStore;

  const {
    isDisabled: isDisabledLeakingConnectionsOther,
    changeDisabled: changeDisabledLeakingConnectionsOther,
  } = useDisabled(leakingConnectionsElements[leakingConnectionsElements.length - 1], "leakingConnectionsOther", adverseEventsProduodopaStore.syringeInfoOnChange)

  const {
    isDisabled: isDisabledAppearanceProblemDescription,
    changeDisabledSingle: changeDisabledAppearanceProblemDescription
  } = useDisabled(true, "appearanceProblemDescription", adverseEventsProduodopaStore.syringeInfoOnChange)


  return (
        <Grid container display='flex' mt={2} pl={2}>
            <Grid item className="subMenuSidebar">Strzykawka</Grid>
            <Grid flexGrow={1} maxWidth="calc(100% - 100px)" container display='flex' flexDirection='column' >
                <Grid item sx={{border: '1px solid #9B9B9D'}} p={2} display='flex' flexDirection='column'>
                    <FormControl fullWidth>
                        <Typography variant='subtitle1'>Zaznacz odpowiednią wadę</Typography>
                            <FormGroup
                              value={syringeInfo.defects}
                              name="defects"
                              onChange={(e) => adverseEventsProduodopaStore.syringeInfoOnChange("defects", e.target.value)}>
                                    {defectsElements.map(element => <FormControlLabel mt={1} key={element} value={element} control={<Checkbox />} label={element} />)}
                            </FormGroup>
                    </FormControl> 
                                
                </Grid>
                
                <Grid item sx={{border: '1px solid #9B9B9D'}} p={2} display='flex' gap={2} flexDirection='column'>
                    <div>
                        <Typography variant='subtitle1'>Nieszczelność strzykawki: </Typography>
                        <Typography sx={{fontSize: 14}} variant='subtitle1'>Jeśli zaobserwowano wyciek, należy wskazać, które połączenie strzykawki było nieszczelne: </Typography>
                    </div>
                    <FormGroup
                              value={syringeInfo.leakingConnections}
                              name="leakingConnections"
                              onChange={(e) => {
                                adverseEventsProduodopaStore.syringeInfoOnChange('leakingConnections', e.target.value);
                                changeDisabledLeakingConnectionsOther(syringeInfo.leakingConnections, e.target.value);
                              }}>
                              {leakingConnectionsElements.map(element => <FormControlLabel mt={1} key={element} value={element} control={<Checkbox />} label={element} />)}

                      </FormGroup>
                      <FormControl fullWidth sx={{pl: 6}}>
                           <OutlinedInput
                               disabled={isDisabledLeakingConnectionsOther}
                               name="leakingConnectionsOther"
                               value={syringeInfo.leakingConnectionsOther}
                               onChange={(e) => adverseEventsProduodopaStore.syringeInfoOnChange("leakingConnectionsOther", e.target.value)}/>
                      </FormControl>
                </Grid>

                <Grid item sx={{border: '1px solid #9B9B9D'}} p={2} display='flex' gap={1} flexDirection='column'>
                    <Typography variant='subtitle1'>Pytanie dodatkowe:</Typography>
                    <Typography variant='subtitle1'>Czy jest jakiś problem z wyglądem?</Typography>
                   
                        <FormControl fullWidth>
                                <RadioGroup
                                  name="isAppearanceProblem"
                                  value={convertBoolValue(syringeInfo.isAppearanceProblem)}
                                  onChange={(e) =>  {
                                    adverseEventsProduodopaStore.syringeInfoOnChange(e.target.name, convertBoolValueHandler(e.target.value));
                                    changeDisabledAppearanceProblemDescription(convertBoolValueHandler(e.target.value));
                                  }}>
                                        <FormControlLabel mt={1} value="Tak" control={<Radio />} label="Tak" />
                                        <FormControlLabel mt={1} value="Nie" control={<Radio />} label="Nie" />
                                </RadioGroup>
                        </FormControl> 
                        <FormControl fullWidth sx={{pl: 6, mt: 1}}>
                            <Typography>Opis:</Typography>
                            <OutlinedInput
                              disabled={isDisabledAppearanceProblemDescription}
                              name="appearanceProblemDescription"
                              value={syringeInfo.appearanceProblemDescription}
                              onChange={(e) => adverseEventsProduodopaStore.syringeInfoOnChange(e.target.name, e.target.value)}/>
                        </FormControl>  
                        <Line/>   
                         
                        <Typography variant='subtitle1'>Czy strzykawka została sprawdzona po otrzymaniu?</Typography>
                        <FormControl fullWidth>
                                <RadioGroup
                                    name="isSyringeChecked"
                                    value={convertBoolValue(syringeInfo.isSyringeChecked)}
                                    onChange={(e) =>  {
                                      adverseEventsProduodopaStore.syringeInfoOnChange(e.target.name, convertBoolValueHandler(e.target.value));
                                    }}>
                                        <FormControlLabel mt={1} value="Tak" control={<Radio />} label="Tak" />
                                        <FormControlLabel mt={1} value="Nie" control={<Radio />} label="Nie" />
                                </RadioGroup>
                        </FormControl> 
                        <Line/>  
                        <Typography variant='subtitle1'>Kiedy problem został zaobserwowany?</Typography>
                        <FormControl fullWidth>
                            <DatePickerComponent
                              disableFuture
                              label="DD/MM/RRRR"
                              allowSameDateSelection
                              name="date"
                              value={syringeInfo.date}
                              onChange={(value) => adverseEventsProduodopaStore.syringeInfoOnChange("date", value)}/>
                        </FormControl> 
                        <Line/>  
                        <Typography variant='subtitle1'>Czy problem był widoczny w momencie odbioru?</Typography>
                        <FormControl fullWidth>
                                <RadioGroup
                                  name="isProblemVisible"
                                  value={convertBoolValue(syringeInfo.isProblemVisible)}
                                  onChange={(e) =>  {
                                    adverseEventsProduodopaStore.syringeInfoOnChange(e.target.name, convertBoolValueHandler(e.target.value));
                                  }}>
                                        <FormControlLabel mt={1} value="Tak" control={<Radio />} label="Tak" />
                                        <FormControlLabel mt={1} value="Nie" control={<Radio />} label="Nie" />
                                </RadioGroup>
                        </FormControl> 
                </Grid>
                
                <Grid item sx={{border: '1px solid #9B9B9D'}} p={2} display='flex' gap={2} flexDirection='column'>
               
                    <FormControl fullWidth >
                    <Typography variant='subtitle1'>Jeśli problem został rozwiązany, w jaki sposób? </Typography>
                        <OutlinedInput
                          name="solution"
                          value={syringeInfo.solution}
                          onChange={(e) => adverseEventsProduodopaStore.syringeInfoOnChange(e.target.name, e.target.value)}/>
                    </FormControl> 
                    <FormControl fullWidth >
                    <Typography variant='subtitle1'>Opisz trudności funkcjonalne, jeśli dotyczy:</Typography>
                        <OutlinedInput
                          name="functionalDifficulty"
                          value={syringeInfo.functionalDifficulty}
                          onChange={(e) => adverseEventsProduodopaStore.syringeInfoOnChange(e.target.name, e.target.value)}/>
                    </FormControl> 
                </Grid>
            </Grid>
        </Grid>
    )
});
export default SyringeProduct;