import React, {useContext, useReducer} from 'react';
import {HardwareContext} from './hardwareContext';
import {hardwareReducer} from './hardwareReducer';
import axios from "../../axios/axios";
import {PoiContext} from "../poi/poiContext";
import {CLEAR_HARDWARE_STATE, GET_HARDWARE_INFO, GET_HARDWARE_LIST} from "../types";

export const HardwareState = ({children}) => {
    const {setModalError, setGlobalLoader, unsetGlobalLoader} = useContext(PoiContext);
    const initialState = {
        formSet: null,
        hardware: null,
        hardwareInfo: null,
    }

    const [state, dispatch] = useReducer(hardwareReducer, initialState);

    const addProduct = async (data) => {
        try {
            await axios.post(
                'product',
                data
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'Dodano nowy produkt',
                    },
                }
                setModalError(defRes);
                getProductsList()
                return true
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }

    const getProductsList = async () => {
        setGlobalLoader()
        try {
            await axios.get(
                `products`
            ).then(res => {
                const payload = {
                    formSet: res.data.institutions,
                    hardware: res.data.products,
                }
                dispatch({type: GET_HARDWARE_LIST, payload})
                unsetGlobalLoader()
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response)
        }
    }

    const getProduct = async (id) => {
        setGlobalLoader()
        try {
            await axios.get(
                `product/${id}`
            ).then(res => {
                dispatch({type: GET_HARDWARE_INFO, payload: res.data})
                unsetGlobalLoader()
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response)
        }
    }

    const editProduct = async (productID, data) => {
        setGlobalLoader()

        try {
            await axios.post(
                `product/${productID}`,
                data
            ).then(res => {
                    unsetGlobalLoader()
                    let defRes = {
                        data: {
                            code: 200,
                            message: 'Edycja produktu wprowadzona'

                        },
                    }
                    setModalError(defRes)
                    getProductsList()
                }
            )
        } catch
            (e) {
            unsetGlobalLoader()
            setModalError(e.response)

        }
    }

    const delProduct = async (productID) => {
        setGlobalLoader()
        try {
            await axios.delete(
                `product/${productID}`
            ).then(res => {
                unsetGlobalLoader();
                getProductsList()
                let defRes = {
                    data: {
                        code: 200,
                        message: "Produkt usunięty"
                    },
                }
                setModalError(defRes)
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response)
        }
    }

    const clearHardwareState = () => dispatch({type: CLEAR_HARDWARE_STATE})

    return (
        <HardwareContext.Provider value={{
            hardwareState: state,
            addProduct, getProductsList,
            editProduct,
            getProduct, delProduct,
            clearHardwareState,
        }}>
            {children}
        </HardwareContext.Provider>
    )

}
