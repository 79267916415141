import React, {useContext, useEffect, useState} from 'react';
import {PatientContext} from '../../../context/patient/patientContext';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import TablePagination from '@mui/material/TablePagination';
import {AuthContext} from '../../../context/auth/authContext';
import {EnhancedTableHead, getComparator, stableSort,} from "../../../utils/sortingTable";
import {Box} from "@mui/material";
import {getObjectsKeys, getObjectsValues} from "../../../utils/arrayOfObjects";
import Search from "../../shared/search/Search";
import {EDIT_OPTION, SHOW_OPTION} from "../../../data/consts/modalOptions";
import {ROLE_ADMIN} from "../../../data/consts/users";
import {useTableController} from "../../../hooks/useTableController";
import {headCells} from "./helper";

const PatientsDataTable = ({openModal, userIDHandler}) => {
    const {role} = useContext(AuthContext);
    const {patientState, delPatient} = useContext(PatientContext);

    const [filters, setFilters] = useState([]);

    const {
        filteredData,
        searchTerm,
        page,
        rowsPerPage,
        order,
        orderBy,
        handleChangePage,
        handleChangeRowsPerPage,
        handleRequestSort,
        handleSearchChange,
        setFilteredData
    } = useTableController(patientState.patients, ['status', 'institution', 'displayName'], {orderBy: 'displayName'})

    useEffect((prevState) => {
        setFilters(getObjectsKeys(patientState.patients).map((key, index) => {
            return {
                name: key,
                values: getObjectsValues(patientState.patients, key).sort(),
                selectedValue: !!prevState ? prevState[index]?.selectedValue : ''
            }
        }));
    }, [patientState.patients]);

    useEffect(() => {
        setFilteredData(patientState.patients.filter((patient) => {
            return filters.every((filter) => {
                return filter.selectedValue === '' || filter.selectedValue === patient[filter.name];
            })
        }))
    }, [filters, patientState.patients, setFilteredData]);

    const filterChangeHandler = (property, value) => {
        setFilters(filters.map((filter) => {
            filter.selectedValue = property === filter.name ? value : filter.selectedValue;
            return filter;
        }));
    };

    return (
        <>
            <Box justifyContent={'flex-end'} padding={1} sx={{display: 'flex'}}>
                <Search searchTerm={searchTerm} handleSearchChange={handleSearchChange}/>
            </Box>

            <Paper sx={{width: '100%', overflow: 'hidden'}}>

                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={patientState.patients.length}
                            headCells={headCells}
                            filters={filters}
                            onFilterChange={filterChangeHandler}
                        />
                        <TableBody>

                            {stableSort(filteredData, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {

                                    return (
                                        <TableRow
                                            hover
                                            role="row"
                                            tabIndex={-1}
                                            key={row.id}
                                        >
                                            <TableCell>
                                                {index + 1 + '.'}
                                            </TableCell>
                                            <TableCell>
                                                {row.displayName}
                                            </TableCell>
                                            <TableCell>
                                                {row.institution}
                                            </TableCell>
                                            <TableCell>
                                                {row.createdBy}
                                            </TableCell>
                                            <TableCell>
                                                {row.status}
                                            </TableCell>
                                            <TableCell>
                                                <div className="addUserTable">
                                                    <button
                                                        onClick={() => {
                                                            userIDHandler(row.id)
                                                            openModal(EDIT_OPTION)
                                                        }}
                                                    >
                                                        <EditIcon/>
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            userIDHandler(row.id)
                                                            openModal(SHOW_OPTION)
                                                        }}
                                                    >
                                                        <RemoveRedEyeIcon/>
                                                    </button>
                                                    {
                                                        role === ROLE_ADMIN &&
                                                        <button
                                                            onClick={() => delPatient(row.id)}
                                                        >
                                                            <DeleteIcon/>
                                                        </button>
                                                    }
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={patientState.patients.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage={"Elementy na stronie"}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    )
        ;
}

export default PatientsDataTable;