import React, {useContext, useEffect} from 'react';
import PageTitle from "../../../shared/ui/PageTitle";
import {Button, Grid, Typography} from "@mui/material";
import AddHospitalModal from "./AddHospitalModal";
import {AppContext} from "../../../../context/app/appContext";
import HospitalDataTable from "./HospitalDataTable";
import {useModalController} from "../../../../hooks/useModalController";
import {ADD_OPTION} from "../../../../data/consts/modalOptions";

const Hospitals = () => {
    const {getInstitutionListInfo, appState} = useContext(AppContext);

    const {
        modalId, modalIDHandler, modalInfo, openModal, closeModal
    } = useModalController();

    useEffect(() => {
        getInstitutionListInfo();
        // eslint-disable-next-line
    }, [])

    return (<>
        <div className='hospitals'>
            <PageTitle title='Placówki'/>
            <Grid container justifyContent="flex-end" pb={1}>
                <Button variant="contained" color="success" className="formButton" onClick={() => openModal(ADD_OPTION)}>Dodaj
                    placówkę</Button>
            </Grid>
            {appState.institutionsList ? <HospitalDataTable openModal={openModal} hospitalIDHandler={modalIDHandler}/> :
                <Typography>NO DATA</Typography>}
        </div>
        {modalInfo.isOpen && <AddHospitalModal
            modalOption={modalInfo.option}
            closeModal={closeModal}
            hospitalID={modalId}
        />}
    </>)
}

export default Hospitals;