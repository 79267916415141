import { useCallback, useState } from "react";
import { checkboxMultiChange } from "./helper";

export const useDisabled = (valueCheck, path, handleChange, extraChange)  => {
    const [isDisabled, setIsDisabled] = useState(true);

    const changeDisabled = useCallback((values, value) => {
        if(checkboxMultiChange(values, value).includes(valueCheck)){
            setIsDisabled(false);
        }
        else {
            setIsDisabled(true);
            handleChange(path, "");
            extraChange && extraChange();
        }
    }, [valueCheck, extraChange, handleChange, path]);

    const changeDisabledSingle = useCallback((value) => {
        if(value === valueCheck){
            setIsDisabled(false);
        }
        else {
            setIsDisabled(true);
            handleChange(path, "");
            extraChange && extraChange();
        }
    }, [valueCheck, extraChange, handleChange, path]);


    return {
        isDisabled, changeDisabled, changeDisabledSingle
    }
}