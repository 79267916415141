import React, {useContext, useState} from 'react';
import {
    Button,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import basket from '../../../../assets/img/ui/basket.png';
import FormControl from '@mui/material/FormControl';
import {certificateModalValidate, datePickerData, ROLES_ARRAY} from './helper';
import {PoiContext} from '../../../../context/poi/poiContext';
import {dateFormatForm} from '../../../../utils/helpfunc';
import {AppContext} from '../../../../context/app/appContext';
import {getStyles, MenuProps} from '../../settlements/settlementsData'
import {DatePickerComponent} from "../../../shared/ui/DatePickerComponent";

const AdminAddCertificateModal = ({closeModal, certificate}) => {

    const initialState = {
        title: certificate?.title || '',
        datePass: certificate?.datePass ? new Date(certificate.datePass) : null,
        dateValid: certificate?.datePass ? new Date(certificate.dateValid) : null
    }

    const theme = useTheme();
    const {setModalError} = useContext(PoiContext);
    const {addCertificate, updateCertificate} = useContext(AppContext);
    const [formValue, setFormValue] = useState(initialState)
    const [files, setFiles] = useState([]);
    const [roles, setRoles] = useState([...ROLES_ARRAY.map(el => el.value)])
    const [activeDropZone, setActiveDropZone] = useState(false);

    const formValueHandler = (name, value) => setFormValue(prev => ({...prev, [name]: value}))

    const rolesValueHandler = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === 'Wszystkie') {
            setRoles(roles.length === ROLES_ARRAY.length ? [] : [...ROLES_ARRAY.map(el => el.value)]);
            return;
        }
        setRoles(value);
    }

    const delFileHandler = (index) => setFiles(prev => [...prev.filter((el, i) => i !== index)])

    const onFilesInputChangeHandle = (event) => {
        event.preventDefault()
        setActiveDropZone(true)

        if(event.target.files && event.target.files.length>0){
            setFiles([event.target.files[0]]);
        }
    }

    const handleDrop = (event) => {
        event.preventDefault();
        if(event.dataTransfer.files && event.dataTransfer.files.length>0){
            setFiles([event.dataTransfer.files[0]]);
        }
    }


    const submitHandler = async (e) => {
        e.preventDefault()
        const isEdit = !!certificate;
        const validation = certificateModalValidate(files, formValue, roles, isEdit)
        if (validation.isError) {
            setModalError(validation.defRes)
        } else {
            const data = new FormData();
            data.append('title', formValue.title)
            data.append('datePass', dateFormatForm(formValue.datePass))
            data.append('dateValid', dateFormatForm(formValue.dateValid))
            data.append('file', files[0])
            roles.forEach((el, index) => data.append(`roles[${index}]`, el))
            if (certificate) {
                await updateCertificate(data, certificate.id)
            } else {
                await addCertificate(data)
            }
            closeModal()
        }
    }

    return (
        <div className="customModal">
            <Stack component="div" className="customModal__addMenu">
                <Typography variant="h4" mb={2}>Nowy Certyfikat</Typography>
                <form onSubmit={(e) => submitHandler(e)}>
                    <Grid container spacing={2} display="flex" direction="column" wrap="nowrap"
                          className="customModal__scrollArea">
                        <Grid item>
                            <FormControl fullWidth>
                                <TextField
                                    required
                                    label="Tytuł"
                                    name="title"
                                    value={formValue.title}
                                    onChange={(e) => formValueHandler(e.target.name, e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            {
                                certificate && (
                                    <>
                                        <Typography>Obecny plik: <strong>{certificate.originFile}</strong></Typography>
                                        <Typography mb={1} variant='body2'>Jeżeli chcesz podmienić plik, załaduj go w polu
                                            poniżej.</Typography>
                                    </>
                                )
                            }
                            <Box
                                component="div"
                                className={`dropZone ${activeDropZone ? 'activeZone' : ''}`}
                                onDragOver={event => {
                                    setActiveDropZone(true)
                                    event.preventDefault()
                                }}
                                onDragLeave={event => {
                                    setActiveDropZone(false)
                                    event.preventDefault();
                                }}
                                onDrop={event => {
                                    event.preventDefault();
                                    handleDrop(event)
                                }}
                            >
                                <ol>
                                    {
                                        files && files.length !== 0
                                            ?
                                            files.map((el, index) => (
                                                <li key={index}>
                                                    <span>{index + 1}. </span>
                                                    <p>{el.name.length > 25 ? `${el.name.slice(0, 15)} ... ${el.name.slice(-10)}` : el.name} ({(el.size / 1000000).toFixed(2)}Mb)</p>
                                                    <img
                                                        onClick={() => delFileHandler(index)}
                                                        src={basket}
                                                        alt="basket"
                                                    />
                                                </li>
                                            ))
                                            :
                                            null
                                    }
                                </ol>
                                <label className="addFile-label" htmlFor="addFileButton">
                                    <input
                                        id="addFileButton"
                                        className="addFile-input"
                                        type="file"
                                        name="files"
                                        multiple={false}
                                        accept={".pdf,.PDF"}
                                        onChange={(event) => onFilesInputChangeHandle(event)}
                                        onClick={(event) => event.target.value = null}
                                    />
                                </label>
                            </Box>
                        </Grid>
                        <>
                            {datePickerData.map(el => {
                                return (
                                    <Grid item key={el.name}>
                                        <DatePickerComponent
                                            allowSameDateSelection
                                            value={formValue[el.name]}
                                            label={el.label}
                                            onChange={(newValue) => formValueHandler(el.name, newValue)}
                                        />
                                    </Grid>
                                )
                            })}
                        </>
                        <Grid item>
                            <FormControl fullWidth>
                                <InputLabel id="roles-label">Przypisz do roli</InputLabel>
                                <Select
                                    labelId="roles-label"
                                    multiple
                                    value={roles}
                                    onChange={rolesValueHandler}
                                    input={<OutlinedInput
                                        label="Przypisz do roli"/>}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem
                                        name="Wszystkie"
                                        value="Wszystkie"
                                        style={{
                                            fontWeight: roles.length !== ROLES_ARRAY.length
                                                ? theme.typography.fontWeightRegular
                                                : theme.typography.fontWeightMedium,
                                        }}
                                    >Wszystkie</MenuItem>
                                    {
                                        ROLES_ARRAY.map(item =>
                                            <MenuItem
                                                key={item.value}
                                                value={item.value}
                                                style={getStyles(item.value, roles, theme)}
                                            >{item.name}</MenuItem>,
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <div className="customModal__addMenu-buttonArea">
                        <Button onClick={() => closeModal()} variant="contained" color="primary">Powrót</Button>
                        <Button type="submit" variant="contained"
                                color="primary">{certificate ? 'Zapisz' : 'Dodaj'}</Button>
                    </div>
                </form>
            </Stack>
        </div>
    )
}

export default AdminAddCertificateModal;

