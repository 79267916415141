import {GET_MESSAGES_LIST, GET_MESSAGES_RECEIVERS,} from '../types';

const handlers = {
    [GET_MESSAGES_LIST]: (state, {payload}) => ({...state, messagesList: payload}),
    [GET_MESSAGES_RECEIVERS]: (state, {payload}) => ({...state, formSet: payload}),
    // [GET_HARDWARE_INFO]: (state, {payload}) => ({...state, hardwareInfo: {...payload}}),
    // [CLEAR_HARDWARE_STATE]: (state) => ({
    //     formSet: null,
    //     messages: null,
    //
    // }),
    DEFAULT: state => state,
}

export const messagesReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}