import React, {useContext, useEffect, useState} from "react";
import {
    Box,
    Button,
    Collapse,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography
} from "@mui/material";
import {AppContext} from "../../../context/app/appContext";
import {dateFormat} from "../../../utils/helpfunc";
import {EnhancedTableHead, getComparator, stableSort,} from "../../../utils/sortingTable";
import avatar from '../../../assets/img/ui/addClockSmall.png';
import SearchIcon from '@mui/icons-material/Search';
import {AuthContext} from "../../../context/auth/authContext";
import RejectForm from "../settlements/infoElements/RejectForm";
import {SettlementsContext} from "../../../context/settlements/settlementsContext";
import InfoBanner from "../settlements/infoElements/infoBanner";
import RejectConfim from "../settlements/infoElements/AdditionalTimeRejectConfim";
import {PoiContext} from "../../../context/poi/poiContext";
import AcceptConfirm from "../settlements/infoElements/AdditionalTimeAcceptConfirm";
import {CLASS_BLUE, CLASS_GREEN, CLASS_RED} from '../settlements/settlementsMonthTable/helpers'
import {useTableController} from "../../../hooks/useTableController";
import {headCells} from "./helper";
import {ROLE_ADMIN} from "../../../data/consts/users";

const OverTimeTable = () => {
    const {appState} = useContext(AppContext);
    const {role} = useContext(AuthContext);
    const {isGlobalError} = useContext(PoiContext)
    const {
        settlementsState,
        rejectAdditionalTime,
        approveAdditionalTime,
        confirmSettlement
    } = useContext(SettlementsContext);

    const [additionalTime, setAdditionalTime] = useState([])
    const [modal, setModal] = useState(false)
    const [overtimeID, setOvertimeID] = useState('')
    const [error, setError] = useState(false);
    const [comment, setComment] = useState('')
    const [openedID, setOpenedID] = useState(null);

    const {
        page,
        rowsPerPage,
        order,
        orderBy,
        isSelected,
        selected,
        handleChangePage,
        handleChangeRowsPerPage,
        handleRequestSort,
        handleSelectedItem
    } = useTableController(appState.additionalWT, [], {orderBy: ''})

    useEffect(() => {
        if (appState.additionalWT?.length) {
            setAdditionalTime([...appState.additionalWT].sort((a, b) => new Date(b.date) - new Date(a.date)))
        }
    }, [appState.additionalWT])

    useEffect(() => {
        setError(isGlobalError)
    }, [isGlobalError])

    let headCellsAll = [...headCells]
    if (role === ROLE_ADMIN) headCellsAll.splice(2, 0, {id: "userName", label: "ADS"});

    const rejectProps = {submitFunction: rejectAdditionalTime, activityID: overtimeID, setModal, comment, setComment}

    return additionalTime ? <Paper sx={{width: "100%", overflow: "hidden"}}>
        {modal && <RejectForm {...rejectProps}>Wpisz powód odrzucenia wniosku:</RejectForm>}
        {!error && settlementsState.settlementCompleted &&
            <RejectConfim clearFunction={confirmSettlement}/>}
        {settlementsState.settlementCompleted && <AcceptConfirm clearFunction={confirmSettlement}/>}
        <TableContainer>
            <Table stickyHeader aria-label='sticky table'>
                <EnhancedTableHead numSelected={selected.length}
                                   order={order} orderBy={orderBy} onRequestSort={handleRequestSort}
                                   rowCount={additionalTime.length} headCells={headCellsAll}
                />
                <TableBody>
                    {stableSort(additionalTime, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((item, index) => {
                            const isItemSelected = isSelected(item.id);
                            return (
                                <React.Fragment key={index}>
                                    <TableRow sx={{'& > *': {borderBottom: 'none'}}}
                                              className="settlements__tableRow--white" hover
                                              onClick={(event) => handleSelectedItem(event, item.id)}
                                              role='row' aria-rowindex={isItemSelected} tabIndex={-1}
                                              key={index} selected={isItemSelected}
                                    >
                                        <TableCell style={{width: '30px', paddingRight: '0px'}} align={"left"}>
                                            <img src={avatar} alt="ava"/>
                                        </TableCell>
                                        <TableCell style={{width: '110px', paddingRight: '0px'}} align={"left"}>
                                            {dateFormat(new Date(item.date))}
                                        </TableCell>
                                        {role === ROLE_ADMIN && <TableCell style={{width: '200px',}} align={"left"}>
                                            {item.creator}
                                        </TableCell>}
                                        <TableCell style={{width: '130px', paddingLeft: '30px'}} align={"left"}>
                                            {item.duration}
                                        </TableCell>
                                        <TableCell style={{width: '30%', padding: '1rem 0px'}} align={"left"}>
                                            {item.reason.slice(0, 100)}{item.reason.length > 150 ? ' (...)' : ''}
                                        </TableCell>
                                        <TableCell style={{width: '20%', paddingRight: '0px'}} align={"left"}>
                                            {item.adminApproval.includes('odrzuc') &&
                                                <InfoBanner
                                                    text={item.adminRemarks ? item.adminRemarks : 'Nie podano'}> wniosek
                                                    odrzucony:</InfoBanner>}
                                        </TableCell>
                                        <TableCell align={"left"} style={{width: '25%'}}>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                minWidth: '19rem'
                                            }}>
                                                <Box
                                                    sx={{color: `${item.adminApproval.includes('zaakcept') ? 'green' : item.adminApproval.includes('oczek') ? 'orange' : 'red'}`}}>
                                                    {item.adminApproval}
                                                </Box>
                                                <Box sx={{textAlign: 'center'}}>
                                                    {role === ROLE_ADMIN && item.adminApproval.includes('oczek') &&
                                                        <Box pb={1} sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            width: '12rem'
                                                        }}>
                                                            <Button onClick={() => approveAdditionalTime(item.id)}
                                                                    className={CLASS_GREEN}
                                                                    variant="contained">
                                                                akceptuj
                                                            </Button>
                                                            <Button onClick={() => {
                                                                setOvertimeID(item.id);
                                                                setModal(true)
                                                            }} className={CLASS_RED}
                                                                    variant="contained">
                                                                odrzuć
                                                            </Button>
                                                        </Box>}
                                                    <Box sx={{width: '12rem'}}>
                                                        <Button
                                                            onClick={() => setOpenedID(id => item.id === id ? '' : item.id)}
                                                            className={CLASS_BLUE} variant="contained">
                                                            <SearchIcon/>
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                                            <Collapse in={openedID === item.id} timeout="auto" unmountOnExit>
                                                <Grid container columnSpacing={7} p={'2.5rem 4rem'}>
                                                    <Grid item xs={12} lg={6.5}>
                                                        <Typography variant="h6" gutterBottom component="div">
                                                            Opis wniosku:
                                                        </Typography>
                                                        <Typography>
                                                            {item.reason}
                                                        </Typography>
                                                    </Grid>

                                                    {item.adminApproval.includes('odrzuc') && (
                                                        <Grid item xs={12} lg={5.5}>
                                                            <Typography variant="h6" gutterBottom component="div">
                                                                Przyczyna odrzucenia wniosku:
                                                            </Typography>
                                                            <Typography>
                                                                {item.adminRemarks ? item.adminRemarks : 'Nie podano'}
                                                            </Typography>
                                                        </Grid>)}
                                                </Grid>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            );
                        })}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component='div'
            count={additionalTime.length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage={"Elementy na stronie"}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </Paper> : <Typography>Brak danych</Typography>
};
export default OverTimeTable;
