import React, {useContext} from 'react';
import {AppContext} from "../../../../context/app/appContext";
import {Box, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Stack, Typography,} from '@mui/material';
import {DatePickerComponent} from "../../../shared/ui/DatePickerComponent";

const PatientInfo = ({patientModule, patientModuleHandler}) => {
    const {appState} = useContext(AppContext);

    return (
        <Grid container border={`1px solid #071d49`}>
            <Grid item xs={12} bgcolor="#071d49" p={'10px 20px'}>
                <Typography
                    variant="h6"
                    color="white"
                >INFORMACJE O PACJENCIE</Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} lg={6} p={2}>
                        <Stack spacing={3}>
                            <FormControl fullWidth>
                                <InputLabel id="initials-label">Inicjały pacjenta:</InputLabel>
                                <Select
                                    labelId="initials-label"
                                    id="initials"
                                    name="initials"
                                    value={patientModule.initials}
                                    label="Zdarzenie numer:"
                                    onChange={(e) => patientModuleHandler(e.target.name, e.target.value)}
                                >
                                    <MenuItem value="" disabled={true}><em>Wybierz z listy</em></MenuItem>
                                    {
                                        appState.adversePatients && appState.adversePatients.map((el, index) =>
                                            <MenuItem value={el} key={index}>{el}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                            <FormControl fullWidth>
                                <Typography variant="subtitle1">Numer identyfikacyjny pacjenta:</Typography>
                                <OutlinedInput
                                    id="id"
                                    name="id"
                                    value={patientModule.id}
                                    onChange={(e) => patientModuleHandler(e.target.name, e.target.value)}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="gender-label" sx={{width: '60px'}}>Płeć:</InputLabel>
                                <Select
                                    labelId="gender-label"
                                    id="gender"
                                    name="gender"
                                    value={patientModule.gender}
                                    label="Płeć:"
                                    onChange={(e) => patientModuleHandler(e.target.name, e.target.value)}
                                >
                                    <MenuItem value="" disabled={true}><em>Wybierz z listy</em></MenuItem>
                                    <MenuItem value="K">K</MenuItem>
                                    <MenuItem value="M">M</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} lg={6} p={2} className="dynamicLeftBorder">
                        <Stack spacing={2}>
                            <Stack>
                                <Grid container flexDirection="row" alignItems="center">
                                    <Typography variant="subtitle1" mr={3}>Wiek:</Typography>
                                    <Typography>{patientModule.age}</Typography>
                                </Grid>
                                <Box minHeight={24}>
                                    {
                                        patientModule.dateOfBirth
                                        && patientModule.dateOfEvent
                                        && patientModule.age <= 0
                                        && <Typography color="error">Podane daty są nieprawidłowe</Typography>}
                                </Box>
                            </Stack>
                            <Stack spacing={3}>
                                <DatePickerComponent
                                    label="data urodzenia"
                                    disableFuture
                                    allowSameDateSelection
                                    inputFormat="MM/yyyy"
                                    views={['year', 'month']}
                                    value={patientModule.dateOfBirth}
                                    onChange={(newValue) => {
                                        patientModuleHandler('dateOfBirth', newValue);
                                    }}
                                />
                                <DatePickerComponent
                                    allowSameDateSelection
                                    label="data zdarzenia"
                                    disableFuture
                                    inputFormat="MM/yyyy"
                                    views={['year', 'month']}
                                    value={patientModule.dateOfEvent}
                                    onChange={(newValue) => {
                                        patientModuleHandler('dateOfEvent', newValue);
                                    }}
                                />
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PatientInfo;