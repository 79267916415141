export const getObjectsKeys = (obj, firstCall = true) => {
    return Object.entries(obj).reduce((collector, [key, val]) => {
        const newKeys = firstCall ? [] : [...collector, key]
        if (Object.prototype.toString.call(val) === '[object Object]') {
            const otherKeys = getObjectsKeys(val, false)
            return [...newKeys, ...otherKeys]
        }
        return newKeys
    }, [])
}

export const getObjectsValues = (arrayOfObjects, property) => {
    return arrayOfObjects.map((item) => item[property]).filter((value, index, self) => self.indexOf(value) === index);
}