import React, {useContext} from 'react';
import {Paper, Table, TableBody, TableCell} from '@mui/material/';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import {Box, Tooltip} from "@mui/material";
import {MessagesContext} from "../../../../context/messages/messagesContext";
import {AuthContext} from "../../../../context/auth/authContext";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import {EnhancedTableHead, getComparator, stableSort} from "../../../../utils/sortingTable";
import Search from "../../../shared/search/Search";
import {headCells} from "./helper";
import {useTableController} from "../../../../hooks/useTableController";
import {ROLE_ADMIN} from "../../../../data/consts/users";

const MessagesDataTable = ({openModal, userIDHandler}) => {
    const {messageState, delMessage, readMessage} = useContext(MessagesContext);
    const {role} = useContext(AuthContext);

    const {
        filteredData,
        searchTerm,
        page,
        rowsPerPage,
        order,
        orderBy,
        isSelected,
        selected,
        handleChangePage,
        handleChangeRowsPerPage,
        handleRequestSort,
        handleSearchChange,
        handleSelectedItem
    } = useTableController(messageState.messagesList, ['user', 'title', 'content'], {orderBy: 'user'});

    return (
        <>
            <Box justifyContent={'flex-end'} paddingBottom={2} sx={{display: 'flex'}}>
                <Search searchTerm={searchTerm} handleSearchChange={handleSearchChange}/>
            </Box>
            <Paper sx={{width: '100%', overflow: 'hidden'}}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <EnhancedTableHead
                            numSelected={selected}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={filteredData.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {stableSort(filteredData, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((item, index) => {
                                    const isItemSelected = isSelected(item.id);
                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleSelectedItem(event, item.id)}
                                            role="row"
                                            aria-rowindex={isItemSelected}
                                            tabIndex={-1}
                                            key={item.id}
                                            selected={isItemSelected}
                                        >
                                            <TableCell>
                                                {index + 1 + "."}
                                            </TableCell>
                                            <TableCell>
                                                {item.user}
                                            </TableCell>
                                            <TableCell>
                                                {item.title}
                                            </TableCell>
                                            <TableCell>
                                                {item.content}
                                            </TableCell>
                                            <TableCell>
                                                {item.read === false ? 'Nie' : 'Tak'} </TableCell>
                                            <TableCell>
                                                <div className='addUserTable'>
                                                    <Tooltip title="Wyświetl">
                                                        <button onClick={() => {
                                                            userIDHandler(item.id)
                                                            openModal('show')
                                                        }}><RemoveRedEyeIcon/></button>
                                                    </Tooltip>
                                                    {role === ROLE_ADMIN && <Tooltip title="Usuń">
                                                        <button onClick={() => {
                                                            delMessage(item.id)
                                                        }}
                                                        >
                                                            <DeleteIcon/></button>
                                                    </Tooltip>}
                                                    {role !== ROLE_ADMIN && item.read === false &&
                                                        <Tooltip title="Przeczytane">
                                                            <button onClick={() => {
                                                                readMessage(item.id)
                                                            }}
                                                            >
                                                                <EventAvailableIcon/></button>
                                                        </Tooltip>}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={messageState.messagesList.length}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage={"Elementy na stronie"}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>

    );
}
export default MessagesDataTable
