export const headCells = [
    {
        id: 'id',
        label: 'LP',
    },
    {
        id: 'hospitalName',
        label: 'Placówka',
    },
    {
        id: 'user',
        label: 'ADS',
    },
    {
        id: 'createdAt',
        label: 'Data dodania',
    },
    {
        id: 'patient',
        label: 'Pacjent',
    },
    {
        id: 'type',
        label: 'Typ',
    },
    {
        id: 'actions',
        label: 'Działania',
    },
];
