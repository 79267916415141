import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

const AdverseEvents = () => {
    const navigate = useNavigate();

    return <Grid container display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100%'} height={'100%'} flexDirection={'column'} gap={2}>
        <Grid item>
            <Button onClick={() => navigate('/adverse_events/duodopa')} size="medium" color='secondary' 
                sx={{
                    background: '#30A6A6', 
                    color: 'white', 
                    width: 220,
                    '&:hover': {
                        background: '#30A6A6',
                        opacity: 0.9
                    }}}>Formularz Duodopa</Button>
        </Grid>
        <Grid item>
            <Button onClick={() => navigate('/adverse_events/produodopa')} size="medium" color='secondary' 
            sx={{
                background: '#30A6A6', 
                color: 'white', 
                width: 220,
                '&:hover': {
                    background: '#30A6A6',
                    opacity: 0.9
                }}}>Formularz ProDuodopa</Button>
        </Grid>
    </Grid>
}

export default AdverseEvents;