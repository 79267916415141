import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, OutlinedInput, Radio, RadioGroup, Typography } from "@mui/material";
import {observer} from 'mobx-react-lite';

import Line from "../../../../shared/ui/Line";
import {DatePickerComponent} from "../../../../shared/ui/DatePickerComponent";
import {useAdverseEventsProduodopaStore} from '../../../../../store/hooks';
import {convertBoolValue, convertBoolValueHandler} from '../helper';
import {useDisabled} from '../useDisabled';

const componentsElements = [
  "Pasy/paski do przenoszenia",
  "Futerał",
  "Inne:"
];

const defectsElements = [
  "Brak możliwości zapięcia paska z powodu awarii mechanizmu zapinającego",
  "Niemożność zamknięcia zamka błyskawicznego na futerale",
  "Uszkodzony komponent"
];

const TransferAccessoriesProduct = observer(() => {
  const adverseEventsProduodopaStore = useAdverseEventsProduodopaStore()
  const {accessoriesInfo} = adverseEventsProduodopaStore;

  const {
    isDisabled: isDisabledComponentsOther,
    changeDisabled: changeDisabledComponentsOther,
  } = useDisabled(componentsElements[componentsElements.length - 1], "componentsOther", adverseEventsProduodopaStore.accessoriesInfoOnChange)

  const {
    isDisabled: isDisabledAppearanceProblemDescription,
    changeDisabledSingle: changeDisabledAppearanceProblemDescription,
  } = useDisabled(true, "appearanceProblemDescription", adverseEventsProduodopaStore.accessoriesInfoOnChange)

  return (
        <Grid container display='flex' mt={2} pl={2}>
        <Grid item className="subMenuSidebar">Akcesoria do przenoszenia</Grid>
        <Grid flexGrow={1} maxWidth="calc(100% - 100px)" container display='flex' flexDirection='column' >
            <Grid item sx={{border: '1px solid #9B9B9D'}} p={2} display='flex' flexDirection='column'>
                <FormControl fullWidth>
                    <Typography variant='subtitle1'>Wskaż, na który komponent ma to wpływ: </Typography>
                        <FormGroup
                          value={accessoriesInfo.components}
                          name="components"
                          onChange={(e) => {
                            adverseEventsProduodopaStore.accessoriesInfoOnChange('components', e.target.value);
                            changeDisabledComponentsOther(accessoriesInfo.components, e.target.value);
                          }}>
                          {componentsElements.map(element =>  <FormControlLabel mt={1} key={element} value={element} control={<Checkbox />} label={element} />)}
                        </FormGroup>
                </FormControl> 
                <FormControl fullWidth sx={{pl: 6, mt: 1}}>
                    <OutlinedInput
                      disabled={isDisabledComponentsOther}
                      name="componentsOther"
                      value={accessoriesInfo.componentsOther}
                      onChange={(e) => adverseEventsProduodopaStore.accessoriesInfoOnChange(e.target.name, e.target.value)}/>
                 </FormControl>
            </Grid>
            
            <Grid item sx={{border: '1px solid #9B9B9D'}} p={2} display='flex' gap={2} flexDirection='column'>
                <Typography variant='subtitle1'>Kwestia akcesoriów do przenoszenia</Typography>
                <FormControl fullWidth>
                        <FormGroup
                          value={accessoriesInfo.defects}
                          name="defects"
                          onChange={(e) => {
                            adverseEventsProduodopaStore.accessoriesInfoOnChange('defects', e.target.value);
                          }}>
                          {defectsElements.map(element =>  <FormControlLabel mt={1} key={element} value={element} control={<Checkbox />} label={element} />)}
                        </FormGroup>
                </FormControl>         
            </Grid>

            <Grid item sx={{border: '1px solid #9B9B9D'}} p={2} display='flex' gap={1} flexDirection='column'>
                <Typography variant='subtitle1'>Pytanie dodatkowe:</Typography>
                <Typography variant='subtitle1'>Czy jest jakiś problem z wyglądem? </Typography>
               
                    <FormControl fullWidth>
                            <RadioGroup
                              name="isAppearanceProblem"
                              value={convertBoolValue(accessoriesInfo.isAppearanceProblem)}
                              onChange={(e) =>  {
                                adverseEventsProduodopaStore.accessoriesInfoOnChange(e.target.name, convertBoolValueHandler(e.target.value));
                                changeDisabledAppearanceProblemDescription(convertBoolValueHandler(e.target.value));
                              }}>
                                    <FormControlLabel mt={1} value="Tak" control={<Radio />} label="Tak" />
                                    <FormControlLabel mt={1} value="Nie" control={<Radio />} label="Nie" />
                            </RadioGroup>
                    </FormControl> 
                    <FormControl fullWidth sx={{pl: 6}}>
                            <Typography>Opis:</Typography>
                            <OutlinedInput
                              disabled={isDisabledAppearanceProblemDescription}
                              name="appearanceProblemDescription"
                              value={accessoriesInfo.appearanceProblemDescription}
                              onChange={(e) => adverseEventsProduodopaStore.accessoriesInfoOnChange(e.target.name, e.target.value)}/>
                        </FormControl>  
                    <Line/>   
                     
                    <Typography variant='subtitle1'>Czy akcesoria do przenoszenia zostały sprawdzone po otrzymaniu?</Typography>
                    <FormControl fullWidth>
                            <RadioGroup
                              name="isAccessoriesChecked"
                              value={convertBoolValue(accessoriesInfo.isAccessoriesChecked)}
                              onChange={(e) =>  {
                                adverseEventsProduodopaStore.accessoriesInfoOnChange(e.target.name, convertBoolValueHandler(e.target.value));
                              }}>
                                    <FormControlLabel mt={1} value="Tak" control={<Radio />} label="Tak" />
                                    <FormControlLabel mt={1} value="Nie" control={<Radio />} label="Nie" />
                            </RadioGroup>
                    </FormControl> 
                    <Line/>  
                    <Typography variant='subtitle1'>Kiedy problem został zaobserwowany?</Typography>
                    <FormControl fullWidth>
                        <DatePickerComponent
                          disableFuture
                          label="DD/MM/RRRR"
                          allowSameDateSelection
                          name="date"
                          value={accessoriesInfo.date}
                          onChange={(value) => adverseEventsProduodopaStore.accessoriesInfoOnChange("date", value)}/>
                    </FormControl> 
                    <Line/>  
                    <Typography variant='subtitle1'>Czy problem był widoczny w momencie odbioru? </Typography>
                    <FormControl fullWidth>
                            <RadioGroup
                              name="isProblemVisible"
                              value={convertBoolValue(accessoriesInfo.isProblemVisible)}
                              onChange={(e) =>  {
                                adverseEventsProduodopaStore.accessoriesInfoOnChange(e.target.name, convertBoolValueHandler(e.target.value));
                              }}>
                                    <FormControlLabel mt={1} value="Tak" control={<Radio />} label="Tak" />
                                    <FormControlLabel mt={1} value="Nie" control={<Radio />} label="Nie" />
                            </RadioGroup>
                    </FormControl> 
            </Grid>
            
            <Grid item sx={{border: '1px solid #9B9B9D'}} p={2} display='flex' gap={2} flexDirection='column'>
           
                <FormControl fullWidth>
                <Typography variant='subtitle1'>Jeśli problem został rozwiązany, w jaki sposób? </Typography>
                    <OutlinedInput
                      name="solution"
                      value={accessoriesInfo.solution}
                      onChange={(e) => adverseEventsProduodopaStore.accessoriesInfoOnChange(e.target.name, e.target.value)}/>
                </FormControl> 
                <FormControl fullWidth >
                <Typography variant='subtitle1'>Opisz trudności funkcjonalne, jeśli dotyczy:</Typography>
                    <OutlinedInput
                      name="functionalDifficulty"
                      value={accessoriesInfo.functionalDifficulty}
                      onChange={(e) => adverseEventsProduodopaStore.accessoriesInfoOnChange(e.target.name, e.target.value)}/>
                </FormControl> 
            </Grid>
        </Grid>
    </Grid>)
});

export default TransferAccessoriesProduct;