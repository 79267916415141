import React from "react";
import {Grid, Stack} from "@mui/material";
import {InfoElement} from "../../shared/ui/infoElements/InfoElement";
import {InfoTable} from "../../shared/ui/infoElements/InfoTable";

const headerTitles = ['LP', 'Nazwa produktu', 'Kod produktu', 'Rozmiar', 'Ilość'];
const OrdersViewMode = ({currentOrderInfo}) => {

    return (
        <Grid item xs={12} p={2}>
            <Stack>
                <InfoElement title='Status zamówienia:' value={currentOrderInfo.status}/>
                <InfoElement title='Zamawiający:' value={currentOrderInfo.createdBy}/>
                <InfoElement title='Data zamówienia:' value={currentOrderInfo.createdAt}/>
                <InfoElement title='Instytucja:' value={currentOrderInfo.institution.name}/>
                <InfoElement title='Adres instytucji:'
                             value={`${currentOrderInfo.institution.zipCode}, ${currentOrderInfo.institution.city}, ${currentOrderInfo.institution.name}`}/>
                <InfoElement title='Akceptujący:' value={currentOrderInfo.acceptedBy}/>
                <InfoElement title='Data akceptacji:' value={currentOrderInfo.acceptedAt}/>
                <InfoElement title='Komentarz do zamówienia:' value={currentOrderInfo.comment}/>
                <InfoTable title='Zamówione produkty:' headerTitles={headerTitles}
                           products={currentOrderInfo.products}/>
            </Stack>
        </Grid>
    )
}

export default OrdersViewMode