import {Box, MenuItem, Select, TableCell, TableHead, TableRow} from "@mui/material";
import TableSortLabel from "@mui/material/TableSortLabel";
import {visuallyHidden} from "@mui/utils";
import React from "react";

export function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export function EnhancedTableHead(props) {
    const {order, orderBy, onRequestSort, headCells, filters, onFilterChange} =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => {
                        const currentFilter = filters?.find((filter) => headCell.id === filter.name && filter.name !== 'id');
                        return <TableCell
                            key={headCell.id}
                            align={"center"}
                            padding={headCell.id === 'reason' ? 'none' : 'normal'}
                            style={{background: '#ddd'}}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>

                            {!!currentFilter ? (
                                <Select
                                    sx={{display: 'block'}}
                                    labelId={`${currentFilter.name}-label`}
                                    name={`${currentFilter.name}`}
                                    value={currentFilter.selectedValue}
                                    onChange={(e) => onFilterChange(e.target.name, e.target.value)}
                                >
                                    <MenuItem value=""><em>Wybierz z listy</em></MenuItem>
                                    {
                                        !!currentFilter.values.length && currentFilter.values.map((el, index) =>
                                            <MenuItem
                                                key={index}
                                                value={el}
                                            >
                                                {el}
                                            </MenuItem>,
                                        )
                                    }
                                </Select>
                            ) : null}
                        </TableCell>
                    }
                )}
            </TableRow>
        </TableHead>
    );
}