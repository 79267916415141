export const checkboxesData = {
    contact: [
        {
            name: 'personal',
            label: 'Osobiście'
        },
        {
            name: 'phone',
            label: 'Telefonicznie'
        }
    ],
    activities: [
        {
            name: 'help',
            label: 'Pomoc merytoryczna dla ADS'
        },
        {
            name: 'meeting',
            label: 'Spotkanie z Abbvie (przekazanie informacji o projekcie)'
        }
    ]
}
