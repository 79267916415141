import React from 'react';
import WarningBox from "../../settlements/WarningBox";
import {Button, Stack, Typography} from "@mui/material";

export const ModalApprove = ({closeModal, relatedReckonings, approveReckoningHandler}) => {

    return (
        <WarningBox handleClose={closeModal} isAdmin={true}>
            <Typography
                variant='subtitle1'
                color='green'
            >
                Akceptując ten rachunek akceptujesz również powiązany z nim rachun(ek/ki) dla:
                <br/>
                {relatedReckonings.map((el, index) =>
                    `${el.userName}${index === relatedReckonings.length - 1 ? '' : ', '}`)}
            </Typography>
            <Stack
                direction="row"
                mt={4}
                sx={{justifyContent: 'center'}}
            >
                <Button
                    type="submit"
                    variant="contained"
                    onClick={() => {
                        approveReckoningHandler();
                        closeModal();
                    }}
                    sx={{
                        width: '240px',
                        background: 'green'
                    }}
                >
                    ZAAKCEPTUJ
                </Button>
            </Stack>
        </WarningBox>
    )
}