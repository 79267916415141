import React, {useContext, useEffect, useState} from 'react';
import {HardwareContext} from '../../../context/hardware/hardwareContext';
import {PoiContext} from '../../../context/poi/poiContext';
import {Button, Grid, MenuItem, Select, TextField, Typography} from '@mui/material';

const OrderProductInfo = ({productId, orderDetails, orderDetailsAddHandler}) => {
    const {setModalError} = useContext(PoiContext);
    const {hardwareState: {hardware}} = useContext(HardwareContext)
    const [product, setProduct] = useState(null);
    const [detail, setDetail] = useState(0);
    const [amount, setAmount] = useState('0')

    useEffect(() => {
        if (productId && !!hardware.length) {
            setProduct({...hardware.filter(el => el.id === productId)[0]})
            setDetail(0)
            setAmount('0')
        }
    }, [productId, hardware])

    useEffect(() => {
        if (detail !== 0) {
            let isSelected = false
            let index
            orderDetails && orderDetails.forEach((el, i) => {
                if (el.product === productId && el.detail === detail) {
                    isSelected = true
                    index = i;
                }
            })

            if (isSelected) {
                setAmount(orderDetails[index].amount)
            }
        } else {

        }

    }, [productId, detail, orderDetails])

    const addProduct = () => {
        if (detail === 0 || amount === '0') {
            let defRes = {
                data: {
                    code: 400,
                    message: 'Uzupełnij brakujące pola.',
                },
            }
            setModalError(defRes);
        } else {
            orderDetailsAddHandler(product.id, amount, detail);
        }
    }

    return productId && product &&
        <Grid container p={2}>
            <Grid item container flexDirection="row" alignItems="center">
                <Typography variant="subtitle1">Nazwa produktu:</Typography>
                <Typography ml={1}>{product.name}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle1">Wariant:</Typography>
                <Select
                    fullWidth
                    name="detail"
                    value={detail}
                    onChange={(e) => setDetail(e.target.value)}
                    sx={{maxWidth: '450px'}}
                >
                    <MenuItem value={0} disabled={true}><em>Wybierz z listy:</em></MenuItem>
                    {
                        product.details.map(el =>
                            <MenuItem
                                key={el.id}
                                value={el.id}>
                                {el.name} ({el.size}) - {el.code}
                            </MenuItem>,
                        )
                    }
                </Select>
            </Grid>
            <Grid item xs={12} container mt={2} flexDirection="row" justifyContent="space-between"
                  alignItems="flex-end">
                <Grid item xs={7}>
                    <Typography variant="subtitle1">Ilość ({product.package}):</Typography>
                    <TextField
                        name="amount"
                        value={amount}
                        type="number"
                        variant="outlined"
                        InputProps={{inputProps: {min: '0', step: '1'}}}
                        onChange={(e) => setAmount(e.target.value)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Button
                        onClick={addProduct}
                        variant="contained"
                        color="success"
                    >
                        Dodaj do zamówenia
                    </Button>
                </Grid>
            </Grid>
        </Grid>
}

export default OrderProductInfo;