import React, {useContext, useEffect, useState} from "react";
import {Button, Grid, Stack, TextField, Typography} from "@mui/material";
import { ReckoningsContext } from "../../../../context/reckonings/reckoningsContext";
import { PoiContext } from "../../../../context/poi/poiContext";


export const EditPatientModal = ({patientData, closeModal}) => {
  const {putPatientInReckonings} = useContext(ReckoningsContext);
  const {setModalError} = useContext(PoiContext);
  const [patient, setPatient] = useState(null);

  useEffect(() => {
    if(patient === null){
      setPatient(patientData)
    }
  }, [patient, patientData])

  const changeInfoHandler = (name, value) => {
    setPatient(prevState => ({...prevState, [name]: value}))
  }

  const savePatientHandler = () => {
    const message = patient.nameInitial.length !== 1 || patient.surnameInitial.length !== 1 ? 'Wprowadź jedynie inicjały pacjenta' : (patient.pesel.length !== 4 ? 'Wprowadź 4 ostatnbie cyfry PESEL.' : null)
    if (message) {
      setModalError({
        data: {
          code: 422,
          message,
        },
      })
    }
    else{
      void putPatientInReckonings(patient.id, patient)
    }
  }

  return <div className="customModal ">
    <Stack component="div" className="customModal__addMenu ">
      <Typography variant="h4" mb={2}>
        Edytuj pacjenta
      </Typography>
      {patient &&
        <Grid container pb={2} justifyContent="space-between">
          <Grid
            item
            xs={12}
            lg={4} pr={2}>
            <TextField
              name="nameInitial"
              label="IMIĘ (inicjały)"
              fullWidth
              required
              value={patient.nameInitial}
              onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={4} pr={2}>
            <TextField
              name="surnameInitial"
              label="NAZWISKO (inicjały)"
              fullWidth
              required
              value={patient.surnameInitial}
              onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={4}>
            <TextField
              name="pesel"
              label="4 ostatnie cyfry PESEL"
              fullWidth
              required
              type="number"
              inputProps={{maxLength: 4}}
              onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}
              value={patient.pesel}
            />
          </Grid>
        </Grid>}
        <div className={'customModal__addMenu-buttonArea'}>
          <Button onClick={closeModal} color="primary" variant="contained">Powrót</Button>
          <Button onClick={savePatientHandler} color="primary" variant="contained"> Zapisz
          </Button>
        </div>
    </Stack>
  </div>
}