import React, { useContext, useEffect, useState } from 'react';
import { PoiContext } from '../../../context/poi/poiContext';
import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { PatientContext } from '../../../context/patient/patientContext';
import { PumpSubModal } from './PumpSubModal';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PegSubModal from './PegSubModal';
import { patientModalValidate } from './patientModalValidate';
import GlobalLoader from '../../shared/ui/GlobalLoader';
import { pl } from 'date-fns/locale';
import {DatePickerComponent} from "../../shared/ui/DatePickerComponent";
import {ADD_OPTION} from "../../../data/consts/modalOptions";
import {dateFormatForm} from '../../../utils/helpfunc';

const PatientModal = ({ closeModal, modalOption, userID }) => {

	const { setModalError } = useContext(PoiContext)
	const {
		getAdditionalPatientInfo,
		patientState,
		addPatient,
		getPatient,
		clearPatient,
		editPatient,
	} = useContext(PatientContext);

	const [patientInfo, setPatientInfo] = useState(null)

	const [pumps, setPumps] = useState([])

	const [pegs, setPegs] = useState([])

	useEffect(() => {
		getAdditionalPatientInfo();
		if (modalOption !== 'add') {
			getPatient(userID);
		} else {
			setPatientInfo({
				displayName: '',
				nameInitial: '',
				surnameInitial: '',
				pesel: 0,
				probeSerial: '',
				probeInstallDate: null,
				medicineInclusionDate: null,
				hospitalLeftDate: null,
				therapyReason: '',
				isDataProcessAgreement: false,
				institution: '',
				therapySuspensionReason: '',
				therapySuspensionStartDate: null,
				therapySuspensionEndDate: null,
				therapyLeftReason: '',
				therapyLeftDate: null,
				initiationDate: null
			})
		}
		return () => {
			clearPatient()
		}
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (patientState.patient) {
			setPatientInfo({ ...patientState.patient.info });
			setPumps([...patientState.patient.pumps]);
			setPegs([...patientState.patient.pegs]);
		}
	}, [patientState.patient])

	const changeInfoHandler = (name, value) => setPatientInfo(prev => ({ ...prev, [name]: value }))

	const pumpsTableHandler = (name, value, el, index) => {
		const tempArray = [];
		pumps.forEach(el => tempArray.push({ ...el }))
		tempArray[index] = { ...el, [name]: value };
		setPumps([...tempArray])
	}

	const pegsTableHandler = (name, value, el, index) => {
		const tempArray = [];
		pegs.forEach(el => tempArray.push({ ...el }))
		tempArray[index] = { ...el, [name]: value };
		setPegs([...tempArray])
	}

	const addPumpsTableRow = () => setPumps(prev => ([...prev, {
		serialNumber: '',
		releaseDate: null,
		isRunning: false,
	}]))

	const addPegsTableRow = () => setPegs(prev => [...prev, {
		serialNumber: '',
		installDate: null,
	}])

	const delPumpTableRow = (index) => setPumps(prev => ([...JSON.parse(JSON.stringify(prev.filter((el, i) => i !== index)))]))

	const delPegsTableRow = (index) => setPegs(prev => ([...JSON.parse(JSON.stringify(prev.filter((el, i) => i !== index)))]))

	const submitHandler = (e) => {
		e.preventDefault();

		const validation = patientModalValidate(patientInfo, pumps, pegs, modalOption);

		if (validation.isError) {
			setModalError(validation.defRes);
		} else {
			const data = {
				...patientInfo,
				pesel: `${patientInfo.pesel}`,
				therapyReason: patientInfo.therapyReason.length === 0 ? null : patientInfo.therapyReason,
				therapySuspensionReason: patientInfo.therapySuspensionReason.length === 0 ? null : patientInfo.therapySuspensionReason,
				therapyLeftReason: patientInfo.therapyLeftReason.length === 0 ? null : patientInfo.therapyLeftReason,
				pumps : pumps.map((pump) => ({...pump, releaseDate: pump.releaseDate ? dateFormatForm(new Date(pump.releaseDate)): null})),
				pegs: pegs.map((peg) => ({...peg, installDate: peg.installDate ? dateFormatForm(new Date(peg.installDate)): null})),
				probeInstallDate : patientInfo.probeInstallDate ? dateFormatForm(new Date(patientInfo.probeInstallDate)): null,
				initiationDate: patientInfo.initiationDate ? dateFormatForm(new Date(patientInfo.initiationDate)): null,
				medicineInclusionDate: patientInfo.medicineInclusionDate ? dateFormatForm(new Date(patientInfo.medicineInclusionDate)): null,
				therapyLeftDate: patientInfo.therapyLeftDate ? dateFormatForm(new Date(patientInfo.therapyLeftDate)): null,
				therapySuspensionEndDate: patientInfo.therapySuspensionEndDate ? dateFormatForm(new Date(patientInfo.therapySuspensionEndDate)): null,
				therapySuspensionStartDate: patientInfo.therapySuspensionStartDate ? dateFormatForm(new Date(patientInfo.therapySuspensionStartDate)): null,
				hospitalLeftDate: patientInfo.hospitalLeftDate ? dateFormatForm(new Date(patientInfo.hospitalLeftDate)): null,
			}
			if (modalOption === 'add') {
				addPatient(data);
			}
			if (modalOption === 'edit') {
				editPatient(userID, data);
			}
		}
	}

	return patientInfo
		?
		<div className="customModal">
			<Stack component="div" className="customModal__addMenu">
				<Typography variant="h4"
				            mb={2}>{modalOption === 'add' ? 'Nowy pacjent:' : modalOption === 'show' ? 'Dane pacjenta:' : 'Edytuj dane pacjenta'}</Typography>
				<form onSubmit={(e) => submitHandler(e)}>
					<Grid
						container
						display="flex"
						direction="column"
						wrap="nowrap"
						className="customModal__scrollArea"
					>
						{
							modalOption === 'add'
								?
								<>
									<Grid item container justifyContent="space-between">
										<Grid item xs={6} pr={1}>
											<FormControl fullWidth required>
												<Typography variant="subtitle1">Imię (inicjały):</Typography>
												<OutlinedInput
													name="nameInitial"
													value={patientInfo.nameInitial}
													onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}
												/>
											</FormControl>
										</Grid>
										<Grid item xs={6}>
											<FormControl fullWidth required>
												<Typography variant="subtitle1">Nazwisko (inicjały):</Typography>
												<OutlinedInput
													name="surnameInitial"
													value={patientInfo.surnameInitial}
													onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}
												/>
											</FormControl>
										</Grid>
									</Grid>
									<Grid item mt={2}>
										<FormControl fullWidth required>
											<Typography variant="subtitle1">4 ostatnie cyfry PESEL:</Typography>
											<OutlinedInput
												name="pesel"
												type="number"
												inputProps={{ min: 0, max: 9999 }}
												value={patientInfo.pesel}
												onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}
											/>
										</FormControl>
									</Grid>
								</>
								:
								<Grid item mt={2}>
									<FormControl fullWidth>
										<Typography variant="subtitle1">Inicjały:</Typography>
										<OutlinedInput
											name="Initials"
											value={patientInfo.displayName}
											disabled={(modalOption !== 'add')}
											onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}
										/>
									</FormControl>
								</Grid>
						}

						<PumpSubModal
							pumps={pumps}
							delPumpTableRow={delPumpTableRow}
							pumpsTableHandler={pumpsTableHandler}
							addPumpsTableRow={addPumpsTableRow}
							modalOption={modalOption}
						/>
						<PegSubModal
							pegs={pegs}
							addPegsTableRow={addPegsTableRow}
							pegsTableHandler={pegsTableHandler}
							delPegsTableRow={delPegsTableRow}
							modalOption={modalOption}
						/>
						<Grid item container justifyContent="space-between" mt={2}>
							<Grid item xs={6} pr={1}>
								<FormControl fullWidth required>
									<Typography variant="subtitle1">Sonda nr serii:</Typography>
									<OutlinedInput
										name="probeSerial"
										value={patientInfo.probeSerial}
										disabled={modalOption === 'show'}
										onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<FormControl fullWidth>
									<Typography variant="subtitle1">Data założenia sondy:</Typography>
										<DatePickerComponent
											disableFuture
											inputFormat="dd/MM/yyyy"
											openTo="year"
											views={['year', 'month', 'day']}
											value={patientInfo.probeInstallDate}
											disabled={modalOption === 'show'}
											onChange={(newValue) => changeInfoHandler('probeInstallDate', newValue)}
											renderInput={(params) => <TextField {...params} />}
										/>
								</FormControl>
							</Grid>
						</Grid>
						<Grid item container justifyContent="space-between" mt={2}>
							<Grid item xs={6} pr={1}>
								<FormControl fullWidth>
									<Typography variant="subtitle1">Data włączenia leku:</Typography>
									<LocalizationProvider dateAdapter={AdapterDateFns} locale={pl}>
										<DatePicker
											disableFuture
											inputFormat="dd/MM/yyyy"
											openTo="year"
											views={['year', 'month', 'day']}
											value={patientInfo.medicineInclusionDate}
											disabled={modalOption === 'show'}
											onChange={(newValue) => changeInfoHandler('medicineInclusionDate', newValue)}
											renderInput={(params) => <TextField {...params} />}
										/>
									</LocalizationProvider>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<FormControl fullWidth>
									<Typography variant="subtitle1">Data wypisu ze szpitala:</Typography>
									<LocalizationProvider dateAdapter={AdapterDateFns} locale={pl}>
										<DatePicker
											disableFuture
											inputFormat="dd/MM/yyyy"
											openTo="year"
											views={['year', 'month', 'day']}
											value={patientInfo.hospitalLeftDate}
											disabled={modalOption === 'show'}
											onChange={(newValue) => changeInfoHandler('hospitalLeftDate', newValue)}
											renderInput={(params) => <TextField {...params} />}
										/>
									</LocalizationProvider>
								</FormControl>
							</Grid>
						</Grid>
						<Grid item mt={2}>
							<FormControl fullWidth>
								<InputLabel id="therapyReason-label">Przyczyna włączenia do terapii:</InputLabel>
								<Select
									labelId="therapyReason-label"
									name="therapyReason"
									value={patientInfo.therapyReason}
									disabled={modalOption === 'show'}
									label="Przyczyna włączenia do terapii:"
									onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}
								>
									<MenuItem value="" disabled={true}><em>Wybierz z listy</em></MenuItem>
									{patientState.therapyReasons && patientState.therapyReasons.map((el, index) =>
										<MenuItem key={index}
										          value={el}>{el}</MenuItem>)}
								</Select>
							</FormControl>
						</Grid>
						{
							modalOption === ADD_OPTION &&
							<Grid item mt={2}>
								<DatePickerComponent
									allowSameDateSelection
									disableFuture
									required
									value={patientInfo.initiationDate}
									disabled={modalOption === 'show'}
									onChange={(newValue) => changeInfoHandler('initiationDate', newValue)}
								>
									<Typography variant="subtitle1">Miesiąc-Rok wdrożenia pacjenta</Typography>
								</DatePickerComponent>
							</Grid>
						}
						<Grid item mt={2}>
							<FormControl fullWidth required>
								<InputLabel id="institution-label">Ośrodek:</InputLabel>
								<Select
									labelId="institution-label"
									name="institution"
									value={patientInfo.institution}
									disabled={modalOption === 'show'}
									label="Ośrodek:"
									onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}
								>
									<MenuItem value="" disabled={true}><em>Wybierz z listy</em></MenuItem>
									{
										patientState.institutions
										&& patientState.institutions.map(el =>
											<MenuItem
												key={el.id}
												value={el.id}>{el.name}
											</MenuItem>,
										)
									}
								</Select>
							</FormControl>
						</Grid>
						{
							modalOption !== 'add' &&
							<>
								<Grid item mt={2}>
									<FormControl fullWidth>
										<InputLabel id="therapySuspensionReason-label">Przyczyna włączenia do
											terapii:</InputLabel>
										<Select
											labelId="therapySuspensionReason-label"
											name="therapySuspensionReason"
											value={patientInfo.therapySuspensionReason}
											disabled={modalOption === 'show'}
											label="Przyczyna włączenia do terapii:"
											onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}
										>
											<MenuItem value="" disabled={true}><em>Wybierz z listy</em></MenuItem>
											{
												patientState.therapySuspensionReasons
												&& patientState.therapySuspensionReasons.map((el, index) =>
													<MenuItem
														key={index}
														value={el}
													>
														{el}
													</MenuItem>,
												)
											}
										</Select>
									</FormControl>
								</Grid>
								<Grid item container justifyContent="space-between" mt={2}>
									<Grid item xs={6} pr={1}>
										<FormControl fullWidth>
											<Typography variant="subtitle1">Data zawieszenia terapii początek:</Typography>
											<LocalizationProvider dateAdapter={AdapterDateFns} locale={pl}>
												<DatePicker
													disableFuture
													inputFormat="dd/MM/yyyy"
													openTo="year"
													views={['year', 'month', 'day']}
													value={patientInfo.therapySuspensionStartDate}
													disabled={modalOption === 'show'}
													onChange={(newValue) => changeInfoHandler('therapySuspensionStartDate', newValue)}
													renderInput={(params) => <TextField {...params} />}
												/>
											</LocalizationProvider>
										</FormControl>
									</Grid>
									<Grid item xs={6}>
										<FormControl fullWidth>
											<Typography variant="subtitle1">Data zawieszenia terapii koniec:</Typography>
											<LocalizationProvider dateAdapter={AdapterDateFns} locale={pl}>
												<DatePicker
													disableFuture
													inputFormat="dd/MM/yyyy"
													openTo="year"
													views={['year', 'month', 'day']}
													value={patientInfo.therapySuspensionEndDate}
													disabled={modalOption === 'show'}
													onChange={(newValue) => changeInfoHandler('therapySuspensionEndDate', newValue)}
													renderInput={(params) => <TextField {...params} />}
												/>
											</LocalizationProvider>
										</FormControl>
									</Grid>
								</Grid>
								<Grid item mt={2}>
									<FormControl fullWidth>
										<Typography variant="subtitle1">Data wypadnięcia z terapii:</Typography>
										<LocalizationProvider dateAdapter={AdapterDateFns} locale={pl}>
											<DatePicker
												disableFuture
												inputFormat="dd/MM/yyyy"
												openTo="year"
												views={['year', 'month', 'day']}
												value={patientInfo.therapyLeftDate}
												disabled={modalOption === 'show'}
												onChange={(newValue) => changeInfoHandler('therapyLeftDate', newValue)}
												renderInput={(params) => <TextField {...params} />}
											/>
										</LocalizationProvider>
									</FormControl>
								</Grid>
								<Grid item mt={2}>
									<FormControl fullWidth>
										<InputLabel id="therapyLeftReason-label">Przyczyna wypadnięcia z terapii:</InputLabel>
										<Select
											labelId="therapyLeftReason-label"
											name="therapyLeftReason"
											value={patientInfo.therapyLeftReason}
											disabled={modalOption === 'show'}
											label="Przyczyna wypadnięcia z terapii:"
											onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}
										>
											<MenuItem value=""><em>Wybierz z listy</em></MenuItem>
											{
												patientState.therapyLeftReasons
												&& patientState.therapyLeftReasons.map((el, index) =>
													<MenuItem
														key={index}
														value={el}
													>
														{el}
													</MenuItem>,
												)
											}
										</Select>
									</FormControl>
								</Grid>
							</>
						}
						<Grid item mt={2}>
							<FormControlLabel control={
								<Checkbox
									name="isDataProcessAgreement"
									checked={patientInfo.isDataProcessAgreement}
									disabled={modalOption === 'show'}
									onChange={(e) => changeInfoHandler(e.target.name, e.target.checked)}
								/>}
							                  label="Czy przekazano informacje o przetwarzaniu danych osobowych dla pacjenta/opiekuna?"/>
						</Grid>
					</Grid>
					<div className={`customModal__addMenu-buttonArea ${modalOption === 'show' ? 'alignButton' : ''} `}>
						<Button onClick={closeModal} variant="contained" color="primary">Powrót</Button>
						{
							modalOption !== 'show' &&
							<Button
								type="submit"
								variant="contained"
								color="primary">
								{modalOption === 'add' ? 'Dodaj' : 'Edytuj'}
							</Button>
						}
					</div>
				</form>
			</Stack>
		</div>
		:
		<GlobalLoader/>
}

export default PatientModal;