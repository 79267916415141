export const coachingCheckboxesData = {
    coachings: [
        {
            name: 'personalCoaching',
            label: 'Osobiście'
        },
        {
            name: 'phoneCoaching',
            label: 'Telefonicznie'
        }
    ],
    checkboxes: [
        {
            name: 'patientSituation',
            label: 'Omówienie sytuacji pacjentów w placówce'
        },
        {
            name: 'duodopaSystem',
            label: 'Odpowiadanie na merytoryczne pytania związane z systemem duodopa'
        },
        {
            name: 'adverseEventReports',
            label: 'Raportowanie AE (przypomnienie o konieczności raportowania zdarzeń niepożądanych)'
        },
        {
            name: 'coachingNeeds',
            label: 'Rozpoznanie potrzeb szkoleniowych (merytorycznych)'
        },
        {
            name: 'bestPractice',
            label: 'Analiza wizyt ADS - omówienie sytuacji vs best practice - wsparcie, wskazówki'
        }
    ]
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}