import React, {useState} from 'react';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

const SideBar = ({links}) => {

    const [isOpen, setIsOpen] = useState(false);
    const {width} = useWindowDimensions();

    let draw

    const toggleMenu = () => setIsOpen(prev => !prev);

    if (width >= 1024) {
        draw = (
            <div className="sideBar">
                {links}
            </div>
        )
    } else {
        draw = (
            <div className="menu" style={{marginLeft: isOpen ? '0px' : '-200px'}}>
                <div className="menu__buttonArea" onClick={toggleMenu}>
                    {
                        isOpen
                            ?
                            <MenuOpenIcon fontSize="large" style={{cursor: 'pointer'}}/>
                            :
                            <MenuIcon fontSize="large" style={{cursor: 'pointer'}}/>
                    }
                </div>
                {isOpen && links}
                {/*<ul style={{color: 'white'}}>*/}
                {/*	<li style={{display:'flex', alignItems:'center', fontSize:'14px', marginLeft: '10px', height: '50px'}}><GoHome style={customStyle}/>Home</li>*/}
                {/*	<li style={{display:'flex', alignItems:'center', fontSize:'14px', marginLeft: '10px', height: '50px'}}><HiOutlineUserGroup style={customStyle}/>Użytkownicy</li>*/}
                {/*	<li style={{display:'flex', alignItems:'center', fontSize:'14px', marginLeft: '10px', height: '50px'}}><GiMedicalDrip style={customStyle}/>Produkty</li>*/}
                {/*	<li style={{display:'flex', alignItems:'center', fontSize:'14px', marginLeft: '10px', height: '50px'}}><HiOutlineOfficeBuilding style={customStyle}/>Placówki</li>*/}
                {/*	<li style={{display:'flex', alignItems:'center', fontSize:'14px', marginLeft: '10px', height: '50px'}}><RiUserAddLine style={customStyle}/>Pacjenci</li>*/}
                {/*	<li style={{display:'flex', alignItems:'center', fontSize:'14px', marginLeft: '10px', height: '50px'}}><BsClipboardPlus style={customStyle}/>Raportowanie</li>*/}
                {/*	<li style={{display:'flex', alignItems:'center', fontSize:'14px', marginLeft: '10px', height: '50px'}}><BsClipboardData style={customStyle}/>Raporty</li>*/}
                {/*	<li style={{display:'flex', alignItems:'center', fontSize:'14px', marginLeft: '10px', height: '50px'}}><BsCardChecklist style={customStyle}/>Interakcje</li>*/}
                {/*	<li style={{display:'flex', alignItems:'center', fontSize:'14px', marginLeft: '10px', height: '50px'}}><GoMail style={customStyle}/>Wiadomości</li>*/}
                {/*	<li style={{display:'flex', alignItems:'center', fontSize:'14px', marginLeft: '10px', height: '50px'}}><BiNotepad style={customStyle}/>Baza wiedzy</li>*/}
                {/*	<li style={{display:'flex', alignItems:'center', fontSize:'14px', marginLeft: '10px', height: '50px'}}><BsCash style={customStyle}/>Rachunek</li>*/}
                {/*	<li style={{display:'flex', alignItems:'center', fontSize:'14px', marginLeft: '10px', height: '50px'}}><BsExclamationTriangle style={customStyle}/>Zdarzenia<br/>niepożądane</li>*/}
                {/*	<li style={{display:'flex', alignItems:'center', fontSize:'14px', marginLeft: '10px', height: '50px'}}><MdOutlineAddShoppingCart style={customStyle}/>Zamówienia<br/>sprzętowe</li>*/}
                {/*	<li style={{display:'flex', alignItems:'center', fontSize:'14px', marginLeft: '10px', height: '50px'}}><GiRibbonMedal style={customStyle}/>Certyfikacja</li>*/}
                {/*	<li style={{display:'flex', alignItems:'center', fontSize:'14px', marginLeft: '10px', height: '50px'}}><AiOutlineUser style={customStyle}/>Profil</li>*/}
                {/*	<li style={{display:'flex', alignItems:'center', fontSize:'14px', marginLeft: '10px', height: '50px'}}><AiOutlineLogout style={customStyle}/>Wyloguj</li>*/}
                {/*</ul>*/}
            </div>
        )
    }

    return draw
}

export default SideBar;