export function exists(data){
    return data !== null && data !== undefined
}

//date format dd-mm-yyyy
export const dateFormat = (date, withoutDay) => {
    if (!date) {
        return null
    }
    if (withoutDay) {
        return `${validNumberStr(date.getMonth() + 1)}-${date.getFullYear()}`
    } else {
        return `${validNumberStr(date.getDate())}-${validNumberStr(date.getMonth() + 1)}-${date.getFullYear()}`
    }
}
//date format yyyy-mm-dd
export const dateFormatForm = date => `${date.getFullYear()}-${validNumberStr(date.getMonth() + 1)}-${validNumberStr(date.getDate())}`

export const dateFormatFormDay = date => `${validNumberStr(date.getDate())}-${validNumberStr(date.getMonth() + 1)}-${date.getFullYear()}`

//another date format
export const validDateStr = (date) => {
    const day = validNumberStr(date.getDate()).toString();
    const month = validNumberStr(date.getMonth()).toString();
    const year = validNumberStr(date.getFullYear()).toString();
    return `${day}.${month}.${year}`
}


//function returned numbers 0..9 in format -> 00, 01, 02
export const validNumberStr = number => {
    if (number <= 9 && number >= 0) {
        return `0${number}`;
    } else {
        return number;
    }
};

//ZIP CODE FORMAT VALIDATE
export const isZipCode = string => /\d{2}-\d{3}/.test(string)


//OBJECT ELEMENTS SUM
function obj_values(obj) {
    const results = [];
    for (let property in obj)
        results.push(obj[property]);
    return results;
}

function list_sum(list) {
    return list.reduce(function (previousValue, currentValue, index, array) {
        return previousValue + currentValue;
    });
}

export const object_values_sum = obj => list_sum(obj_values(obj));

export const checkPdf = (files) => {
    if(files.length > 0){
        const name = files[0].name;

        if(name.contains('pdf') || name.contains('PDF') ){
            return true;
        }
    }
    return false;
}

//function returned blob file from base64 string
export function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], {type: mimeString});
}

//sorting objects
export const sortArray = (flag, array, sortParam) => {
    if (array.length !== 0) {
        const tempArray = [...array];
        return typeof array[0][sortParam] === 'number' || typeof array[0][sortParam] === 'bigint' || typeof array[0][sortParam] === 'object' || typeof array[0][sortParam] === 'boolean'
            ?
            [...tempArray.sort((a, b) => flag ? a[sortParam] - b[sortParam] : b[sortParam] - a[sortParam])]
            :
            [...tempArray.sort((a, b) => flag ? a[sortParam].localeCompare(b[sortParam]) : b[sortParam].localeCompare(a[sortParam]))];
    } else return array
};

//ifData ? string : null
export const ifData = data => data ? `${data}` : null;


//type DATA to console.log
export const dataToLog = (data) => {
    for (let pair of data.entries()) {
        console.log(pair[0] + ', ' + pair[1]);
    }
}

export const equalizerData = (userArray, dataArray) => {
    const productArray = [];
    userArray.forEach(userElement => dataArray.forEach(el => {
        if (el.id === userElement) {
            productArray.push(`${el.name} ${el.surname ? el.surname : ''}`)
        }
    }))
    return productArray;
}

export const uniqUsers = (instArray) => {
    if (instArray) {
        let potentialMakersTemp = []
        instArray.forEach(inst => {
            inst.users.forEach(user => {
                let isExist = false
                potentialMakersTemp.forEach(userTemp => {
                    if (userTemp.id === user.id) {
                        isExist = true
                    }
                })
                if (!isExist) {
                    potentialMakersTemp.push(user)
                }
            })
        })
        return potentialMakersTemp
    }
}

export const userIdentification = (userList, userId) => {
    const {name, surname} = userList.find(el => el.id === userId) || {name: 'no', surname: 'identification'}
    return `${name} ${surname}`
}