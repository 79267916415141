import {LOGIN_USER, LOGOUT_USER, AUTH_USER} from '../types';

const handlers = {
	[AUTH_USER]:  (state) => ({...state, sendIsAuth: true}),
	[LOGIN_USER]: (state, {payload}) => ({...state, isLoggedIn: true, ...payload}),
	[LOGOUT_USER]: () => ({
		isLoggedIn: false,
		id: null,
		role: null,
		name: null,
		surname: null,
		email: null,
		telephone: null,
		checkPassDate: null,
		sendIsAuth: true
		// tokenExpirationDate: null,
	}),
	DEFAULT: state => state,
}

export const authReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}