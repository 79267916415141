import React, {useContext, useEffect, useState} from 'react'
import {SettlementsContext} from '../../../context/settlements/settlementsContext';
import {Button, Grid, Stack, Typography} from '@mui/material';
import implementationImg from '../../../assets/img/ui/addPerson.png';
import TreatmentInfo from './TreatmentInfo';
import SearchIcon from '@mui/icons-material/Search';
import {AuthContext} from "../../../context/auth/authContext";
import {ROLE_ADMIN} from "../../../data/consts/users";
import {
    ACCEPTED_STATUS,
    DRAFT_STATUS,
    REJECTED_STATUS,
    WAITING_STATUS
} from "../../../data/consts/settlementsStatuses";
import {CLASS_BLUE, CLASS_GRAYDISABLED, CLASS_GREEN} from './settlementsMonthTable/helpers'

const TreatmentsPatient = ({settlementPeriod, reckoningStatus}) => {

    const {settlementsState, acceptProcedure} = useContext(SettlementsContext);
    const {authState: {id: userID}, role, hasCoworkers} = useContext(AuthContext)

    const [patients, setPatients] = useState([])
    const [openPatientID, setOpenPatientID] = useState(null);

    useEffect(() => {
        setPatients([...settlementsState.treatmentPatients].sort((a, b) => b.id - a.id))
    }, [settlementsState])

    return patients.length ?
        patients.map(el => {
                const isAuthor = userID === el.procedures[0].createdBy || el.procedures[0].createdBy === null;
                const status = el.procedures[0].statusSlug;
                const statusColor = status === 'accepted' ? 'green' : status === 'waiting' ? 'orange' : 'red';

                const buttonDisabled = ['waiting', 'accepted', 'closed'].includes(reckoningStatus) && ['not completed', 'waiting'].includes(status);
                const buttonContent = ((status === 'accepted' && (hasCoworkers || !['draft', 'rejected'].includes(reckoningStatus))) || (!isAuthor && status === 'waiting')) ?
                    <SearchIcon/> : status === 'waiting' || (status === 'accepted' && !hasCoworkers) ? 'edytuj' : 'dodaj';
                const buttonColor = buttonDisabled ? CLASS_GRAYDISABLED : status === 'not completed' ? CLASS_GREEN : CLASS_BLUE;

                const makers = el.procedures[0].makers.map((id) => settlementsState.makers.find(item => item.id === id).name).join(', ');

                const isEditable = role !== ROLE_ADMIN && [DRAFT_STATUS, REJECTED_STATUS, undefined].includes(reckoningStatus) && (['not completed'].includes(status) || (isAuthor && status === WAITING_STATUS) || (status === ACCEPTED_STATUS && !hasCoworkers));

                return <Grid container key={el.id} p={1}>
                    {/*PATIENT TITLE*/}
                    <Grid
                        item
                        xs={12}
                        container
                        display="row"
                        wrap="nowrap"
                        alignItems="center"
                        bgcolor="#f5f5f5"
                        padding={"30px 70px 40px"}
                    >
                        <Grid item pr={"45px"}>
                            <img src={implementationImg} alt="ava" className="settlements__blockImage_XL"/>
                        </Grid>
                        <Grid item ml={1} container justifyContent={'flex-start'}>
                            <Grid item xs={12} lg={5}>
                                <Stack className='settlements__status' direction="column">
                                    <Typography><b>Inicjały:</b></Typography>
                                    <Typography>{el.name}</Typography>
                                </Stack>
                                <Stack className='settlements__status' direction="column">
                                    <Typography><b>Szpital:</b></Typography>
                                    <Typography>{el.institution}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item container xs={12} lg={7}>
                                <Grid item xs={12} lg={7.5} className='settlements__status'
                                      style={{visibility: role === ROLE_ADMIN ? 'hidden' : 'visible',}} container
                                      direction="column"
                                      mb={1}>
                                    <Stack direction="row">
                                        <Typography mr={1}>Status </Typography>
                                        <Typography><b>Monitoring stanu zdrowia pacjenta:</b></Typography>
                                    </Stack>
                                    <Typography className='settlements__firstLetter'
                                                sx={{color: statusColor}}>{el.procedures[0].status}</Typography>
                                </Grid>
                                <Grid item xs={12} lg={4.5} mb={2}
                                      style={{visibility: makers.length ? 'visible' : 'hidden'}}>
                                    <Typography>Wykonujący monitoring:</Typography>
                                    <Typography><i><b>{makers}</b></i></Typography>
                                </Grid>
                                <Stack direction="row" spacing={5} mb={'auto'}>
                                    {!el.procedures[0].accepted && el.procedures[0].id &&
                                        <Button onClick={() => acceptProcedure(el.procedures[0].id)} className={CLASS_GREEN}
                                                variant="contained">akceptuj</Button>}
                                    <Button className={buttonColor} variant="contained"
                                            onClick={() => openPatientID === el.id ? setOpenPatientID(null) : setOpenPatientID(el.id)}>
                                        {buttonContent}
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*PATIENT INFO*/}
                    {
                        openPatientID === el.id &&
                        <TreatmentInfo
                            treatment={el}
                            settlementPeriod={settlementPeriod}
                            closeForm={setOpenPatientID}
                            isEditable={isEditable}
                        />
                    }
                </Grid>
            }
        )
        :
        <Grid container alignItems="center" justifyContent="center" p={2}>
            <Typography>Brak danych</Typography>
        </Grid>
}

export default TreatmentsPatient;