import { makeAutoObservable, toJS} from 'mobx';
import {
    checkboxMultiChange,
    initAccessoriesInfo,
    initAdapterInfo,
    initComplaintInfo,
    initDoctorInfo,
    initDrugInfo,
    initEventInfo,
    initEvent,
    initInfusionInfo,
    initMainInfo,
    initPartTwoInfo,
    initPumpInfo,
    initSparePartInfo,
    initSyringeInfo,
    initDrug,
    initMedicalHistory,
    setDeepValue,
} from '../components/pages/adverseEvents/produodopaForm/helper';
import { softwareProblemsElements } from '../components/pages/adverseEvents/produodopaForm/products/PumpProduct';
import { defectsElements, leakingConnectionsElements } from '../components/pages/adverseEvents/produodopaForm/products/InfusionSetProduct';

export class AdverseEventsProduodopaStore {
    formSet = null;

    mainInfo = initMainInfo;

    complaintInfo = initComplaintInfo;
    doctorInfo = initDoctorInfo;
    eventInfo = initEventInfo;
    drugInfo = initDrugInfo;
    pumpInfo = initPumpInfo;
    infusionInfo = initInfusionInfo;
    adapterInfo = initAdapterInfo;
    syringeInfo = initSyringeInfo;
    accessoriesInfo = initAccessoriesInfo;
    sparePartInfo = initSparePartInfo;

    partTwoInfo = initPartTwoInfo;

    constructor() {
        makeAutoObservable(this);
    }

    setFormSet = (formSet) => {
        this.formSet = formSet;
    }

    mainInfoOnChange = (key, value) => {
        if (key === 'complaintTypes') {
            let newComplaintTypes= checkboxMultiChange(this.mainInfo.complaintTypes, value)

            if(!newComplaintTypes.includes('Reklamacja')){
                this.complaintInfo = initComplaintInfo;
                this.doctorInfo = initDoctorInfo;
                this.eventInfo = initEventInfo;
                this.drugInfo = initDrugInfo;
                this.pumpInfo = initPumpInfo;
                this.infusionInfo = initInfusionInfo;
                this.adapterInfo = initAdapterInfo;
                this.syringeInfo = initSyringeInfo;
                this.accessoriesInfo = initAccessoriesInfo;
                this.sparePartInfo = initSparePartInfo;
            }
            if(!newComplaintTypes.includes('Zdarzenie')){
                this.partTwoInfo = initPartTwoInfo;
            }

            this.mainInfo = {...this.mainInfo, [key] : newComplaintTypes};
        }
        else{
            this.mainInfo = {...this.mainInfo, [key] : value};
        }
    }

    complaintInfoOnChange = (key, value) => {
        const newState = {...this.complaintInfo};

        if(key === 'fromComes'){
            this.complaintInfo = {...this.complaintInfo, [key] : checkboxMultiChange(this.complaintInfo.fromComes, value)};
        }
        else{
            this.complaintInfo = setDeepValue(newState, key, value);
        }
    }

    doctorInfoOnChange = (key, value) => {
        if(key === 'specializations'){
            this.doctorInfo = {...this.doctorInfo, [key] : checkboxMultiChange(this.doctorInfo.specializations, value)};
        }
        else{
            this.doctorInfo = {...this.doctorInfo, [key] : value};
        }
    }

    eventInfoOnChange = (key, value) => {
        const newState = {...this.eventInfo};

        if (key === 'products') {
            const products = checkboxMultiChange(this.eventInfo.products, value);
            this.eventInfo = {...this.eventInfo, [key] : products};
            if(!products.includes(value)){
                this.sectionProductReset(value);
            }
        }
        else{
            this.eventInfo = setDeepValue(newState, key, value);
        }
    }

    sectionProductReset = (product) => {
        switch(product){
            case 'Lek' :
                this.drugInfo = initDrugInfo;
                this.eventInfo.drug = initEventInfo.drug;
            break;
            case 'Pompa' :
                this.pumpInfo = initPumpInfo;
                this.eventInfo.pump = initEventInfo.pump;
            break;
            case 'Zestaw infuzyjny' :
                this.infusionInfo = initInfusionInfo;
                this.eventInfo.infusion = initEventInfo.infusion;
            break;
            case 'Adapter fiolki' :
                this.adapterInfo = initAdapterInfo;
                this.eventInfo.vialAdapter = initEventInfo.vialAdapter;
            break;
            case 'Strzykawka' :
                this.syringeInfo = initSyringeInfo;
            break;
            case 'Akcesorium do noszenia' :
                this.accessoriesInfo = initAccessoriesInfo;
                this.eventInfo.wearableAccessory = initEventInfo.wearableAccessory;
            break;
            case 'Część zamienna' :
                this.sparePartInfo = initSparePartInfo;
                this.eventInfo.sparePart = initEventInfo.sparePart;
            break;
            default:
        }
    }

    drugInfoOnChange = (key, value) => {
        if(key === 'vialDefects'){
            this.drugInfo = {...this.drugInfo, [key] : checkboxMultiChange(this.drugInfo.vialDefects, value)};
        }
        else if(key === 'drugDefects'){
            this.drugInfo = {...this.drugInfo, [key] : checkboxMultiChange(this.drugInfo.drugDefects, value)};
        }
        else{
            this.drugInfo = {...this.drugInfo, [key] : value};
        }
    }

    pumpInfoOnChange = (key, value) => {
        if(key === 'errorDetails'){
            this.pumpInfo = {...this.pumpInfo, [key] : checkboxMultiChange(this.pumpInfo.errorDetails, value)};
        }
        else if(key === 'physicalProblems'){
            this.pumpInfo = {...this.pumpInfo, [key] : checkboxMultiChange(this.pumpInfo.physicalProblems, value)};
        }
        else if(key === 'functionalProblems'){
            this.pumpInfo = {...this.pumpInfo, [key] : checkboxMultiChange(this.pumpInfo.functionalProblems, value)};
        }
        else if(key === 'softwareProblems'){
            this.pumpInfo = {...this.pumpInfo, [key] : checkboxMultiChange(this.pumpInfo.softwareProblems, value, [...softwareProblemsElements, "Inne:"])};
        }
        else if(key === 'solutions'){
            this.pumpInfo = {...this.pumpInfo, [key] : checkboxMultiChange(this.pumpInfo.solutions, value)};
        }
        else{
            this.pumpInfo = {...this.pumpInfo, [key] : value};
        }
    }

    infusionInfoOnChange = (key, value) => {
        if(key === 'components'){
            this.infusionInfo = {...this.infusionInfo, [key] : checkboxMultiChange(this.infusionInfo.components, value)};
        }
        else if(key === 'defects'){
            this.infusionInfo = {...this.infusionInfo, [key] : checkboxMultiChange(this.infusionInfo.defects, value, [...defectsElements, "Inne zdarzenie, prosimy o wyjaśnienie:"])};
        }
        else if(key === 'leakingConnections'){
            this.infusionInfo = {...this.infusionInfo, [key] : checkboxMultiChange(this.infusionInfo.leakingConnections, value, leakingConnectionsElements)};
        }
        else if(key === 'solutions'){
            this.infusionInfo = {...this.infusionInfo, [key] : checkboxMultiChange(this.infusionInfo.solutions, value)};
        }
        else{
            this.infusionInfo = {...this.infusionInfo, [key] : value};
        }
    }
    
    adapterInfoOnChange = (key, value) => {
        if(key === 'leakingConnections'){
            this.adapterInfo = {...this.adapterInfo, [key] : checkboxMultiChange(this.adapterInfo.leakingConnections, value)};
        }
        else if(key === 'defects'){
            this.adapterInfo = {...this.adapterInfo, [key] : checkboxMultiChange(this.adapterInfo.defects, value)};
        }
        else{
            this.adapterInfo = {...this.adapterInfo, [key] : value};
        }
    }
 
    syringeInfoOnChange = (key, value) => {
        if(key === 'leakingConnections'){
            this.syringeInfo = {...this.syringeInfo, [key] : checkboxMultiChange(this.syringeInfo.leakingConnections, value)};
        }
        else if(key === 'defects'){
            this.syringeInfo = {...this.syringeInfo, [key] : checkboxMultiChange(this.syringeInfo.defects, value)};
        }
        else{
            this.syringeInfo = {...this.syringeInfo, [key] : value};
        }
    }

    accessoriesInfoOnChange = (key, value) => {
        if(key === 'components'){
            this.accessoriesInfo = {...this.accessoriesInfo, [key] : checkboxMultiChange(this.accessoriesInfo.components, value)};
        }
        else if(key === 'defects'){
            this.accessoriesInfo = {...this.accessoriesInfo, [key] : checkboxMultiChange(this.accessoriesInfo.defects, value)};
        }
        else{
            this.accessoriesInfo = {...this.accessoriesInfo, [key] : value};
        }
    }

    sparePartInfoOnChange = (key, value) => {
        if(key === 'components'){
            this.sparePartInfo = {...this.sparePartInfo, [key] : checkboxMultiChange(this.sparePartInfo.components, value)};
        }
        else if(key === 'batteryCoverProblems'){
            this.sparePartInfo = {...this.sparePartInfo, [key] : checkboxMultiChange(this.sparePartInfo.batteryCoverProblems, value)};
        }
        else if(key === 'batteryProblems'){
            this.sparePartInfo = {...this.sparePartInfo, [key] : checkboxMultiChange(this.sparePartInfo.batteryProblems, value)};
        }
        else if(key === 'chargingProblems'){
            this.sparePartInfo = {...this.sparePartInfo, [key] : checkboxMultiChange(this.sparePartInfo.chargingProblems, value)};
        }
        else{
            this.sparePartInfo = {...this.sparePartInfo, [key] : value};
        }
    }

    partTwoInfoOnChange = (key, value) => {
        this.partTwoInfo = {...this.partTwoInfo, [key] : value};
    }


    addEvent = () => {
        const allEvents = [...this.partTwoInfo.events ];
        allEvents.push({...initEvent})
        this.partTwoInfo.events = allEvents;
    }

    deleteEvent = (index) => {
        console.log(index)
        console.log(toJS(this.partTwoInfo.events));

        const allEvents = [...this.partTwoInfo.events ];
        allEvents.splice(index, 1);

        console.log(toJS(allEvents))

        this.partTwoInfo.events = allEvents;
    }

    eventOnChange = (index, key, value) => {
        const allEvents = [...this.partTwoInfo.events];
        allEvents[index] = {...allEvents[index], [key] : value};
        this.partTwoInfo.events = allEvents;
    }

    addDrug = () => {
        const allDrugs = [...this.partTwoInfo.drugs ];
        allDrugs.push({...initDrug})
        this.partTwoInfo.drugs = allDrugs;
    }

    deleteDrug = (index) => {
        const allDrugs = [...this.partTwoInfo.drugs ];
        allDrugs.splice(index, 1);
        this.partTwoInfo.drugs = allDrugs;
    }

    drugOnChange = (index, key, value) => {
        const allDrugs = [...this.partTwoInfo.drugs];
        allDrugs[index] = {...allDrugs[index], [key] : value};
        this.partTwoInfo.drugs = allDrugs;
    }
    
    
    addMedicalHistory = () => {
        const allMedicalHistories = [...this.partTwoInfo.medicalHistories ];
        allMedicalHistories.push({...initMedicalHistory})
        this.partTwoInfo.medicalHistories = allMedicalHistories;
    }

    deleteMedicalHistory = (index) => {
        const allMedicalHistories = [...this.partTwoInfo.medicalHistories ];
        allMedicalHistories.splice(index, 1);
        this.partTwoInfo.medicalHistories = allMedicalHistories;
    }

    medicalHistoryOnChange = (index, key, value) => {
        const allMedicalHistories = [...this.partTwoInfo.medicalHistories];
        allMedicalHistories[index] = {...allMedicalHistories[index], [key] : value};
        this.partTwoInfo.medicalHistories = allMedicalHistories;
    }

    clearDrugs = () => {
        this.partTwoInfo.drugs = []
    }

    clearMedicalHistory = () => {
        this.partTwoInfo.medicalHistories = []
    }
    
    resetForm = () => {
        this.mainInfo = initMainInfo;
        this.complaintInfo = initComplaintInfo;
        this.doctorInfo = initDoctorInfo;
        this.eventInfo = initEventInfo;
        this.drugInfo = initDrugInfo;
        this.pumpInfo = initPumpInfo;
        this.infusionInfo = initInfusionInfo;
        this.adapterInfo = initAdapterInfo;
        this.syringeInfo = initSyringeInfo;
        this.accessoriesInfo = initAccessoriesInfo;
        this.sparePartInfo = initSparePartInfo;
        this.partTwoInfo = initPartTwoInfo;
    }

}