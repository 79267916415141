import { Button, FormControl,  Grid, OutlinedInput,  Typography } from "@mui/material"
import { observer } from "mobx-react-lite"
import { DatePickerComponent } from "../../../../shared/ui/DatePickerComponent"
import { useAdverseEventsProduodopaStore } from "../../../../../store/hooks";

const HistoryElement = observer(({index }) => {
    const adverseEventsProduodopaStore = useAdverseEventsProduodopaStore()
    const {partTwoInfo} = adverseEventsProduodopaStore;
    const {medicalHistories} = partTwoInfo;
    const medicalHistory = medicalHistories[index];
    const number = index + 1;

    return (<Grid container sx={{position: 'relative'}} display='flex' p={2} pt={6}>
        <Button onClick={adverseEventsProduodopaStore.deleteMedicalHistory.bind(null, index)} sx={{position: 'absolute', background: '#EF5350', color: 'white', padding: '0 8px', textTransform: 'lowercase', right: 16, top: 4}}>{`usuń historie ${number}`}</Button>
        <div className="subMenuHeaderSmall"><span>{`Historia ${number}:`}</span></div>
       
        <Grid item xs={12} mt={2}>
            <FormControl fullWidth required >
                <Typography variant='subtitle1'>Historia medyczna:</Typography>
                <OutlinedInput
                  name="description"
                  value={medicalHistory.description}
                  onChange={(e) => adverseEventsProduodopaStore.medicalHistoryOnChange(index, e.target.name, e.target.value)}/>
            </FormControl>
        </Grid>
        <Grid item xs={12} mt={2}>
            <Typography variant="subtitle1">Data rozpoczęcia:</Typography>
            <DatePickerComponent
                disableFuture
                label="DD/MM/RRRR"
                allowSameDateSelection
                name="dateStart"
                value={medicalHistory.dateStart}
                onChange={(value) => adverseEventsProduodopaStore.medicalHistoryOnChange(index, "dateStart", value)} />
        </Grid>
        
        <Grid item xs={12}  mt={2}>
            <Typography variant="subtitle1">Data zakończenia:</Typography>
            <DatePickerComponent
                disableFuture
               label="DD/MM/RRRR"
               allowSameDateSelection
               name="dateEnd"
               value={medicalHistory.dateEnd}
               onChange={(value) => adverseEventsProduodopaStore.medicalHistoryOnChange(index, "dateEnd", value)} />
        </Grid>
    </Grid>)
});

export default HistoryElement