import { observer } from "mobx-react-lite";

import DrugSolutionProduct from "../products/DrugSolutionProduct";
import InfusionSetProduct from "../products/InfusionSetProduct";
import PumpProduct from "../products/PumpProduct";
import ReplacementPart from "../products/ReplacementPart";
import SyringeProduct from "../products/SyringeProduct";
import TransferAccessoriesProduct from "../products/TransferAccessoriesProduct";
import VialAdapterProduct from "../products/VialAdapterProduct";
import { useAdverseEventsProduodopaStore } from "../../../../../store/hooks";
import { productsElements } from "./EventInfo";

const ProductsInfo = observer(() => {
    const {eventInfo} = useAdverseEventsProduodopaStore();
    const {products} = eventInfo;
    
    return (
        <>
            {products.includes(productsElements[0]) && <DrugSolutionProduct/>}
            {products.includes(productsElements[1]) && <PumpProduct/>}
            {products.includes(productsElements[2]) && <InfusionSetProduct/>}
            {products.includes(productsElements[3]) && <VialAdapterProduct/>}
            {products.includes(productsElements[4]) && <SyringeProduct/>}
            {products.includes(productsElements[5]) && <TransferAccessoriesProduct/>}
            {products.includes(productsElements[6]) && <ReplacementPart/>}
        </>
    )
});

export default ProductsInfo;