export const additionalTimeValidate = (reason, date, duration) => {
    if (new Date(date).getDate() === new Date().getDate() && new Date(date).getMonth() === new Date().getMonth()) {
        return ({
            isError: true,
            defRes: {
                data: {
                    code: 422,
                    message: 'Wniosek jest możliwy tylko w odniesieniu do przyszłych dni',
                },
            },
        })
    }

    if (duration.length === 0) {
        return ({
            isError: true,
            defRes: {
                data: {
                    code: 422,
                    message: 'Podaj ilość',
                },
            },
        })
    }

    if (reason.length === 0) {
        return ({
            isError: true,
            defRes: {
                data: {
                    code: 422,
                    message: 'Podaj opis',
                },
            },
        })
    }

    return ({
        isError: false,
        defRes: null,
    })
}