import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {pl} from "date-fns/locale";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {TextField} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import React from "react";

export const DatePickerComponent = ({
                                        value,
                                        disabled,
                                        shouldDisableDate,
                                        label,
                                        views = ["year", "month", "day"],
                                        openTo = "year",
                                        inputFormat = 'dd/MM/yyyy',
                                        required,
                                        onChange,
                                        children,
                                        ...props
                                    }) => {
    return (
        <FormControl fullWidth required={required}>
            {children}
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={pl}>
                <DatePicker
                    {...props}
                    shouldDisableDate={shouldDisableDate}
                    disabled={disabled}
                    label={label}
                    inputFormat={inputFormat}
                    openTo={openTo}
                    views={views}
                    value={value}
                    onChange={onChange}
                    renderInput={(params) => <TextField {...params} />}
                    // textField={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
        </FormControl>
    )
}