import {Button, Grid, Typography} from "@mui/material";

const style = {
    boxStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: 'white',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }
}
export const AcceptModal = ({title, acceptHandler, closeModalHandler}) => {

    return (
        <Grid className='customModal' sx={{zIndex: 11000}}>
            <Grid className="customModal__addMenu" sx={style.boxStyle} flexDirection='column' alignItems='center'>
                <Typography variant='h6' textAlign='center' mb={2}>{title}</Typography>
                <Grid className='customModal__addMenu-buttonArea'>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={closeModalHandler}
                    >
                        Powrót
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            acceptHandler();
                            closeModalHandler();
                        }}
                    >
                        Potwierdzam
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}