import React, {useContext, useEffect, useState} from 'react';
import {
    Button,
    Grid,
    Stack,
    TextField, FormControl, Box,
    Typography,
} from '@mui/material';
import basket from "../../../assets/img/ui/basket.png";
import {HardwareContext} from "../../../context/hardware/hardwareContext";
import {AppContext} from "../../../context/app/appContext";

const KnowledgeModal = ({closeModal, modalOption, productID, openModal}) => {
    const {getProduct,} = useContext(HardwareContext);
    const {appState, addKnowledgeItem,} = useContext(AppContext);

    const [formValue, setFormValue] = useState({
        name: '',
        description: '',
    })
    // const [productData, setProductData] = useState([])
    const [files, setFiles] = useState([]);
    // const [images, setImages] = useState([])
    const [activeDropZone, setActiveDropZone] = useState(false);


    useEffect(() => {
        if (modalOption === 'show') {
            setFormValue(...appState.knowledge.filter(el => el.id === productID))
        }
        if (modalOption === 'edit') {
            getProduct(productID)
        }
        // eslint-disable-next-line
    }, [modalOption])


    const formValueHandler = (name, value) => setFormValue(prev => ({...prev, [name]: value}))

    const delFileHandler = (index) => setFiles(prev => [...prev.filter((el, i) => i !== index)])

    const onFilesInputChangeHandle = (event) => {
        event.preventDefault()
        setActiveDropZone(true)
        setFiles(prev => ([...prev, ...event.target.files]))
    }
    const handleDrop = (event) => {
        event.preventDefault();
        setFiles(prev => [...prev, ...event.dataTransfer.files])
    }


    const submitHandler = (e) => {
        e.preventDefault()

        const data = new FormData()
        data.append('name', formValue.name);
        data.append('description', formValue.description);

        if (!!files.length) {
            files.forEach((el, index) => data.append(`file`, el))
        }

        if (modalOption === 'add') {
            addKnowledgeItem(data);
            closeModal()
        }
        // if (modalOption === 'edit') {
        //     editProduct(productID, data)
        //     closeModal()
        // }
    }

    return (
        <div className="customModal">
            <Stack component="div" className="customModal__addMenu">
                <Typography variant="h4" mb={2}>
                    {modalOption === 'add' ? 'Nowy wpis:' : modalOption === 'show' ? 'Podgląd wiadomości:' : ''}
                </Typography>
                <form onSubmit={(e) => submitHandler(e)}>
                    <Grid container spacing={2} display="flex" direction="column" wrap="nowrap"
                          className="customModal__scrollArea">
                        <Grid item>
                            <FormControl fullWidth>
                                <TextField
                                    required
                                    label="Nazwa wpisu"
                                    name="name"
                                    disabled={(modalOption === 'show')}
                                    value={formValue.name}
                                    onChange={(e) => formValueHandler(e.target.name, e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth>
                                <TextField sx={{whiteSpace: "normal"}}
                                           required
                                           multiline
                                           minRows={4}
                                           label="Opis"
                                           disabled={(modalOption === 'show')}
                                           name="description"
                                           value={formValue.description}
                                           onChange={(e) => formValueHandler(e.target.name, e.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        {modalOption !== 'show' &&
                            <Grid item
                                  alignItems="center"
                                  justifyContent="space-between"
                                  flexDirection={"column"} mt={2}>
                                <Grid container bgcolor={'#F5F5F5'} p={2} alignItems="center">
                                    <Typography variant="subtitle1">Dodaj plik:</Typography>
                                </Grid>
                                <Grid item mt={2}>
                                    <Box
                                        component="div"
                                        width={'100%'}
                                        className={`dropZone ${activeDropZone ? 'activeZone' : ''}`}
                                        onDragOver={event => {
                                            setActiveDropZone(true)
                                            event.preventDefault()
                                        }}
                                        onDragLeave={event => {
                                            setActiveDropZone(false)
                                            event.preventDefault();
                                        }}
                                        onDrop={event => {
                                            event.preventDefault();
                                            handleDrop(event)
                                        }}
                                    >
                                        <ol>
                                            {
                                                files.length !== 0
                                                    ?
                                                    files.map((el, index) => (
                                                        <li key={index}>
                                                            <span>{index + 1}. </span>
                                                            <p>{el.name.length > 40 ? `${el.name.slice(0, 15)} ... ${el.name.slice(-10)}` : el.name} ({(el.size / 1000000).toFixed(2)}Mb)</p>
                                                            <img
                                                                onClick={() => delFileHandler(index)}
                                                                src={basket}
                                                                alt="basket"
                                                            />
                                                        </li>
                                                    ))
                                                    :
                                                    null
                                            }
                                        </ol>
                                        <label className="addFile-label" htmlFor="addFileButton">
                                            <input
                                                id="addFileButton"
                                                className="addFile-input"
                                                type="file"
                                                name="files"
                                                accept={"application/pdf"}
                                                multiple
                                                onChange={(event) => onFilesInputChangeHandle(event)}
                                                onClick={(event) => event.target.value = null}
                                            />
                                        </label>
                                    </Box>
                                </Grid>
                            </Grid>}
                    </Grid>
                    <div
                        className={`customModal__addMenu-buttonArea ${modalOption === 'show' ? 'alignButton' : ''} `}>
                        <Button onClick={closeModal} variant="contained" color="primary">Powrót</Button>
                        {
                            modalOption !== 'show' &&
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary">
                                {modalOption === 'add' ? 'Dodaj' : 'Zapisz'}
                            </Button>
                        }
                    </div>
                </form>
            </Stack>
        </div>
    )
}

export default KnowledgeModal;

