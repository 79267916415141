import React, {useContext, useEffect, useState} from 'react'
import {SettlementsContext} from '../../../context/settlements/settlementsContext';
import {Button, Grid, Stack, Typography} from '@mui/material';
import avatar from '../../../assets/img/ui/addClock.png';
import {dateFormat} from '../../../utils/helpfunc';
import SearchIcon from '@mui/icons-material/Search';
import AdditionalTimeAdd from './AdditionalTimeAdd';
import NoData from './NoData';
import {CLASS_BLUE} from './settlementsMonthTable/helpers'

const AdditionalTimeList = ({settlementPeriod}) => {
    const {settlementsState} = useContext(SettlementsContext);

    const [additionalTime, setAdditionalTime] = useState([])

    const [openedOverTimeId, setOpenedOverTimeId] = useState(null)

    useEffect(() => {
        setAdditionalTime([...settlementsState.additionalTime].sort((a, b) => new Date(b.date) - new Date(a.date)))
    }, [settlementsState])


    return !!additionalTime.length ?
        additionalTime.map(el => {
                if (!el.adminApproval.includes('zaakc')) return <NoData key={el.id}/>
                return <Grid container key={el.id} p={1}>
                    {/*OVERTIME TITLE*/}
                    <Grid
                        item
                        xs={12}
                        container
                        display="row"
                        wrap="nowrap"
                        alignItems="center"
                        bgcolor="#f5f5f5"
                        p={"30px 70px 40px"}
                    >
                        <Grid item pr={"45px"}>
                            <img src={avatar} alt="ava"
                                 className='settlements__blockImage_XL' /* style={{marginBottom:'1rem'}} */ />
                        </Grid>
                        <Grid item ml={1} container justifyContent={'flex-start'}>
                            <Grid item xs={12} lg={6}>
                                <Stack className='settlements__status' direction="column">
                                    <Typography><b>Data:</b></Typography>
                                    <Typography>{dateFormat(new Date(el.date))}</Typography>
                                </Stack>
                                <Stack pb={0} className='settlements__status' direction="column">
                                    <Typography><b>Czas trwania:</b></Typography>
                                    <Typography>{el.duration} min.</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Stack className='settlements__status' style={{visibility: 'hidden'}} direction="column"
                                       mb={1}>
                                    <Typography mr={1}>.</Typography>
                                    <Typography>.</Typography>
                                </Stack>
                                <Stack direction="row" spacing={5} mt={-5}>
                                    <Button className={CLASS_BLUE} variant="contained"
                                            onClick={() => {
                                                setOpenedOverTimeId(null);
                                                openedOverTimeId === el.id ? setOpenedOverTimeId(null) : setOpenedOverTimeId(el.id)
                                            }}><SearchIcon/>

                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*OVERTIME INFO     || (el.date.getMonth() > new Date().getMonth())  */}
                    {
                        openedOverTimeId === el.id &&
                        <AdditionalTimeAdd settlementPeriod={settlementPeriod} overTimeInfo={el}
                                           setIsAdding={setOpenedOverTimeId}/>
                    }
                </Grid>
            }
        )
        :
        <NoData/>
}

export default AdditionalTimeList;