import {ROLE_ADMIN, ROLE_ADS, ROLE_COORD_ADS, ROLE_KAM} from "../data/consts/users";

export const roleNormaliser = (serverRole) => {
    switch (serverRole) {
        case ROLE_ADS:
            return 'ads'
        case ROLE_KAM:
            return 'kam'
        case ROLE_COORD_ADS:
            return 'koord'
        case ROLE_ADMIN:
            return 'admin'
        default:
            return 'error'
    }
}