import {useTheme} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {PoiContext} from "../../../../context/poi/poiContext";
import {SettlementsContext} from "../../../../context/settlements/settlementsContext";
import {AuthContext} from "../../../../context/auth/authContext";
import {coachingValidate} from "../coachingValidate";
import {useParams} from "react-router-dom";
import {formatYearMonth} from "../../../../utils/formatYearMonth";
import {ROLE_ADMIN} from "../../../../data/consts/users";

export const useCoachings = (reckoningStatus, setIsAdding, coachingInfo) => {
    const theme = useTheme();
    const params = useParams()
    const {yearMonth} = params
    const {setModalError} = useContext(PoiContext)
    const {settlementsState, editCoaching, createCoaching} = useContext(SettlementsContext)
    const {authState: {id: userID}, role} = useContext(AuthContext);
    const [date, setDate] = useState(null)
    const [duration, setDuration] = useState('');
    const [makers, setMakers] = useState([]);
    const {lastDay, firstDay} = formatYearMonth(yearMonth)
    const [checkboxes, setCheckboxes] = useState({
        personalCoaching: false,
        phoneCoaching: false,
        patientSituation: false,
        duodopaSystem: false,
        adverseEventReports: false,
        coachingNeeds: false,
        bestPractice: false
    })

    useEffect(() => {
        if (coachingInfo) {
            setDate(coachingInfo.date)
            setDuration(coachingInfo.duration)
            setMakers([...coachingInfo.participants?.map(el => el.id)] || [])
            setCheckboxes(coachingInfo.checks.reduce((acc, {checkbox, check}) => ({...acc, [checkbox]: check}), {}))
        }
    }, [coachingInfo, userID])

    const checksHandler = (name, value) => setCheckboxes(prev => ({
        ...prev,
        [name]: value,
    }))

    const makersHandler = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "Wszystkie") {
            setMakers(makers.length === settlementsState.listOfUsers.length ? [] : [...settlementsState.listOfUsers.map((el) => el.id)]);
            return;
        }
        setMakers(value);
    }

    const submitHandler = (e) => {
        e.preventDefault()
        const validation = coachingValidate(date, duration, makers)

        if (validation.isError) {
            setModalError(validation.defRes);
        } else {
            const data = {
                duration: +duration,
                date,
                participants: [...makers.map(el => ({id: el}))],
                checks: [...Object.entries(checkboxes).map(([key, value]) => ({
                    checkbox: key,
                    checked: value
                }))]

            }
            coachingInfo?.id ? editCoaching(data, coachingInfo.id, date) : createCoaching(data);
            setIsAdding(false)
        }
    }

    const edition = role !== ROLE_ADMIN && ((reckoningStatus === 'draft' || reckoningStatus === 'rejected') || !reckoningStatus)

    return {
        edition,
        theme,
        checkboxes,
        submitHandler,
        makersHandler,
        checksHandler,
        date,
        duration,
        setDate,
        setDuration,
        makers,
        settlementsState,
        yearMonth,
        firstDay,
        lastDay
    }
}