import {useEffect, useState} from "react";

const ASC = 'asc'
const DESC = 'desc'

const initConfig = {
    orderBy: '',
    order: ASC,
    page: 0,
    rowsPerPage: 10,
    searchTerm: ''
}

const mergeArray = (array) => {
    const val = Array.isArray(array) ? array.reduce(
        (accumulator, currentValue) => `${accumulator === '' ? '' : `${accumulator} `}${currentValue.name === null ? '' : currentValue.name}`,
        ''
    ) : '';
    return val;
}

const defaultFilterData = (data, searchTerm, keysFilter) => {
    return data.filter((row) =>

        keysFilter.some((keysString) => {
                const keys = keysString.split('.');
                const value = keys.length === 2 ? row[keys[0]][keys[1]] : row[keys[0]];
                const convertValue = Array.isArray(value) ? mergeArray(value) : value;

                if (convertValue !== 'LP' || convertValue !== 'actions') {
                    return String(convertValue).toLowerCase().includes(searchTerm.toLowerCase())
                }
                return false;
            }
        )
    );
}

export const useTableController = (data, keysFilter, configTable = initConfig) => {
    const [page, setPage] = useState(configTable.page ?? initConfig.page);
    const [rowsPerPage, setRowsPerPage] = useState(configTable.rowsPerPage ?? initConfig.rowsPerPage);
    const [order, setOrder] = useState(configTable.order ?? initConfig.order);
    const [orderBy, setOrderBy] = useState(configTable.orderBy ?? initConfig.orderBy);
    const [searchTerm, setSearchTerm] = useState(configTable.searchTerm ?? initConfig.searchTerm)
    const [filteredData, setFilteredData] = useState(data);
    const [selected, setSelected] = useState([]);


    useEffect(() => {
        setFilteredData(data);
    }, [data]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(initConfig.page);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === ASC;
        setOrder(isAsc ? DESC : ASC);
        setOrderBy(property);
    };

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value)

        let results = [];
        if (!value) {
            results = data
        } else {
            results = defaultFilterData(data, value, keysFilter);
        }
        setFilteredData(results)
    };

    const handleSelectedItem = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    return {
        filteredData,
        searchTerm,
        page,
        rowsPerPage,
        order,
        orderBy,
        isSelected,
        selected,
        handleChangePage,
        handleChangeRowsPerPage,
        handleRequestSort,
        handleSearchChange,
        handleSelectedItem,
        setFilteredData
    }
}