import React from 'react';

const Footer = () => {
    return (
        <div className='footer'>
            <p>Help desk: <a href="mailto:piotr.arasimowicz@softwebo.pl">piotr.arasimowicz@softwebo.pl</a></p>
            <p>© <a href="https://softwebo.pl/" target='_blank' rel="noreferrer">Softwebo</a> 2022</p>
        </div>
    )
}

export default Footer;