import React from 'react'
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    OutlinedInput,
    Radio,
    RadioGroup,
    Typography
} from '@mui/material';

const GelSubModule = ({gelSubmodule, gelSubmoduleHandler}) => {

    return (
        <Grid container border={`1px solid #071d49`} display="flex" direction="row">
            <Grid item className="subMenuSidebar">Żel / kasetka / lek</Grid>
            <Grid item flexGrow={1} maxWidth="calc(100% - 100px)" container direction="column">
                <Grid item container borderBottom={`1px solid #071d49`}>
                    <Grid item xs={12} lg={6} p={2}>
                        <Typography variant="subtitle1">Czy kasetka była w użyciu?</Typography>
                        <RadioGroup
                            name="wasCassetteInUse"
                            value={gelSubmodule.wasCassetteInUse}
                            onChange={(e) => gelSubmoduleHandler(e.target.name, e.target.value)}
                        >
                            <FormControlLabel value="Tak" control={<Radio/>} label="Tak"/>
                            <FormControlLabel value="Nie" control={<Radio/>} label="Nie"/>
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} lg={6} p={2}>
                        <Typography variant="subtitle1">Ilość kasetek, których problem dotyczy?</Typography>
                        <FormControl fullWidth>
                            <OutlinedInput
                                id="cassetteCount"
                                name="cassetteCount"
                                type='number'
                                inputProps={{min: 0}}
                                value={gelSubmodule.cassetteCount}
                                onChange={(e) => gelSubmoduleHandler(e.target.name, e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item container borderBottom={`1px solid #071d49`}>
                    <Grid item xs={12} lg={6} p={2}>
                        <Typography variant="subtitle1">Która część kasetki przeciekała?</Typography>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox
                                    name="typeLuer"
                                    checked={gelSubmodule.typeLuer}
                                    onChange={(e) => gelSubmoduleHandler(e.target.name, e.target.checked)}
                                />
                            }
                                              label="Złącze typu luer pomiędzy kasetką a rurką (w miejscu połączenia z sondą jelitową za pomocą adaptera zatrzaskowego)"/>
                            <FormControlLabel control={
                                <Checkbox
                                    name="innerCase"
                                    checked={gelSubmodule.innerCase}
                                    onChange={(e) => gelSubmoduleHandler(e.target.name, e.target.checked)}
                                />
                            } label="Wewnętrzna obudowa kasetki"/>
                            <FormControlLabel control={
                                <Checkbox
                                    name="outerCase"
                                    checked={gelSubmodule.outerCase}
                                    onChange={(e) => gelSubmoduleHandler(e.target.name, e.target.checked)}
                                />
                            } label="Zewnętrzna obudowa kasetki"/>
                            <FormControlLabel control={
                                <Checkbox
                                    name="caseConnect"
                                    checked={gelSubmodule.caseConnect}
                                    onChange={(e) => gelSubmoduleHandler(e.target.name, e.target.checked)}
                                />
                            } label="Miejsce, w którym rurka połączona jest z workiem wewnątrz obudowy"/>
                            <FormControlLabel control={
                                <Checkbox
                                    name="anotherCassetteDamage"
                                    checked={gelSubmodule.anotherCassetteDamage}
                                    onChange={(e) => {
                                        gelSubmoduleHandler(e.target.name, e.target.checked)
                                        gelSubmoduleHandler('anotherCassetteDamageDescription', '')
                                    }}
                                />
                            } label="Inna (należy podać jaka):"/>
                            <Box minHeight={56}>
                                {
                                    gelSubmodule.anotherCassetteDamage &&
                                    <FormControl fullWidth>
                                        <OutlinedInput
                                            id="anotherCassetteDamageDescription"
                                            name="anotherCassetteDamageDescription"
                                            value={gelSubmodule.anotherCassetteDamageDescription}
                                            onChange={(e) => gelSubmoduleHandler(e.target.name, e.target.value)}
                                        />
                                    </FormControl>
                                }
                            </Box>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6} p={2}>
                        <Typography variant="subtitle1">Czerwona zatyczka kasetki</Typography>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox
                                    name="difficultTakeOff"
                                    checked={gelSubmodule.difficultTakeOff}
                                    onChange={(e) => gelSubmoduleHandler(e.target.name, e.target.checked)}
                                />
                            } label="Trudno / nie można jej zdjąć"/>
                            <FormControlLabel control={
                                <Checkbox
                                    name="loose"
                                    checked={gelSubmodule.loose}
                                    onChange={(e) => gelSubmoduleHandler(e.target.name, e.target.checked)}
                                />
                            } label="Obluzowana / odpadła"/>
                            <FormControlLabel control={
                                <Checkbox
                                    name="broken"
                                    checked={gelSubmodule.broken}
                                    onChange={(e) => gelSubmoduleHandler(e.target.name, e.target.checked)}
                                />
                            } label="Złamana / uszkodzona"/>
                        </FormGroup>
                        <Typography variant="subtitle1" mt={3}>Problem z rurką wychodzącą z kasetki</Typography>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox
                                    name="holeInPipe"
                                    checked={gelSubmodule.holeInPipe}
                                    onChange={(e) => gelSubmoduleHandler(e.target.name, e.target.checked)}
                                />
                            } label="Dziura w rurce w miejscu, gdzie wychodzi ona z kasetki"/>
                            <FormControlLabel control={
                                <Checkbox
                                    name="brokenLuer"
                                    checked={gelSubmodule.brokenLuer}
                                    onChange={(e) => gelSubmoduleHandler(e.target.name, e.target.checked)}
                                />
                            } label="Odłamane złącze typu luer pomiędzy kasetką a rurką (brak możliwości połączenia)"/>
                            <FormControlLabel control={
                                <Checkbox
                                    name="redLabel"
                                    checked={gelSubmodule.redLabel}
                                    onChange={(e) => gelSubmoduleHandler(e.target.name, e.target.checked)}
                                />
                            } label="Używając nożyczek do usunięcia czerwonej etykiety, przecięto rurkę"/>
                            <FormControlLabel control={
                                <Checkbox
                                    name="anotherProblem"
                                    checked={gelSubmodule.anotherProblem}
                                    onChange={(e) => {
                                        gelSubmoduleHandler(e.target.name, e.target.checked)
                                        gelSubmoduleHandler('anotherProblemValue', '')
                                    }}
                                />
                            } label="Inny (należy podać, jaki):"/>
                            {
                                gelSubmodule.anotherProblem &&
                                <FormControl fullWidth>
                                    <OutlinedInput
                                        id="anotherProblemValue"
                                        name="anotherProblemValue"
                                        value={gelSubmodule.anotherProblemValue}
                                        onChange={(e) => gelSubmoduleHandler(e.target.name, e.target.value)}
                                    />
                                </FormControl>
                            }
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid item container>
                    <Grid item xs={12} lg={6} p={2}>
                        <Typography variant="subtitle1">Czy problem jest związany z wyglądem leku?</Typography>
                        <RadioGroup
                            name="appearanceDrugProblem"
                            value={gelSubmodule.appearanceDrugProblem}
                            onChange={(e) => {
                                gelSubmoduleHandler(e.target.name, e.target.value)
                                if (e.target.value === 'Nie') {
                                    gelSubmoduleHandler('problemDescription', '')
                                    gelSubmoduleHandler('problemTime', '')
                                    gelSubmoduleHandler('isCassetteChecked', '')
                                    gelSubmoduleHandler('productStorage', '')
                                }
                            }}
                        >
                            <FormControlLabel value="Tak" control={<Radio/>} label="Tak"/>
                            <FormControlLabel value="Nie" control={<Radio/>} label="Nie"/>
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} lg={6} p={2}>
                        <Typography variant="subtitle1">Czy opakowanie zewnętrzne kasetki było uszkodzone?</Typography>
                        <RadioGroup
                            name="isCassetteCoverDamaged"
                            value={gelSubmodule.isCassetteCoverDamaged}
                            onChange={(e) => {
                                gelSubmoduleHandler(e.target.name, e.target.value)
                                if (e.target.value === 'Nie') {
                                    gelSubmoduleHandler('cassetteCoverDamageDescription', '')
                                }
                            }}
                        >
                            <FormControlLabel value="Tak" control={<Radio/>} label="Tak"/>
                            <FormControlLabel value="Nie" control={<Radio/>} label="Nie"/>
                        </RadioGroup>
                        {
                            gelSubmodule.isCassetteCoverDamaged === 'Tak' &&
                            <>
                                <Typography variant="subtitle1" mt={2}>Opis uszkodzenia fizycznego:</Typography>
                                <FormControl fullWidth>
                                    <OutlinedInput
                                        id="cassetteCoverDamageDescription"
                                        name="cassetteCoverDamageDescription"
                                        value={gelSubmodule.cassetteCoverDamageDescription}
                                        onChange={(e) => gelSubmoduleHandler(e.target.name, e.target.value)}
                                    />
                                </FormControl>
                            </>
                        }
                    </Grid>
                </Grid>
                {
                    gelSubmodule.appearanceDrugProblem === 'Tak' &&
                    <Grid item container borderTop={`1px solid #071d49`}>
                        <Grid item xs={12} lg={6} p={2}>
                            <Typography variant="subtitle1">Opis problemu:</Typography>
                            <FormControl fullWidth>
                                <OutlinedInput
                                    id="problemDescription"
                                    name="problemDescription"
                                    value={gelSubmodule.problemDescription}
                                    onChange={(e) => gelSubmoduleHandler(e.target.name, e.target.value)}
                                />
                            </FormControl>
                            <Typography variant="subtitle1" mt={2}>Kiedy problem został zauważony? W momencie otrzymania
                                leku?</Typography>
                            <FormControl fullWidth>
                                <OutlinedInput
                                    id="problemTime"
                                    name="problemTime"
                                    value={gelSubmodule.problemTime}
                                    onChange={(e) => gelSubmoduleHandler(e.target.name, e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6} p={2}>
                            <Typography variant="subtitle1">Czy kasetki są sprawdzane w momencie ich
                                otrzymania?</Typography>
                            <FormControl fullWidth>
                                <OutlinedInput
                                    id="isCassetteChecked"
                                    name="isCassetteChecked"
                                    value={gelSubmodule.isCassetteChecked}
                                    onChange={(e) => gelSubmoduleHandler(e.target.name, e.target.value)}
                                />
                            </FormControl>
                            <Typography variant="subtitle1" mt={2}>Proszę opisać sposób przechowywania produktu
                                leczniczego przed użyciem:</Typography>
                            <FormControl fullWidth>
                                <OutlinedInput
                                    id="productStorage"
                                    name="productStorage"
                                    value={gelSubmodule.productStorage}
                                    onChange={(e) => gelSubmoduleHandler(e.target.name, e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Grid>
    )
}

export default GelSubModule