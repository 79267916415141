import {Grid, Typography} from '@mui/material';

const Clause = ({ title, children, isLast = false}) => {
  return <>
    <Grid display="flex" alignItems="center" sx={{border: '2px solid black', borderRightWidth: 0, borderBottomWidth: isLast ? '2px solid black' : 0}} bgcolor={'#F5F5F5'} item xs={6} md={4} p={2}>
      <Typography sx={{fontWeight: 'bold'}}>{title}</Typography>
    </Grid>
    <Grid display="flex" justifyContent="center" flexDirection="column" gap={2} sx={{ border: '2px solid black', borderBottomWidth: isLast ? '2px solid black' : 0}} bgcolor={'#F5F5F5'} item xs={6} md={8} p={2}>
      {children}
    </Grid>
  </>
}

export default Clause;