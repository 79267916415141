import React from 'react'
import {ROLE_ADMIN, ROLE_COORD_ADS} from "../../../../data/consts/users";
import {MenuButton} from "./MenuButton";
import ImplementationPatientAdmin from "../../admin/reckonings/ImplementationPatient";
import {ImplementationPatient} from "../ImplementationPatient";
import TreatmentsPatient from "../TreatmentsPatient";
import Trainings from "../Trainings";
import AdditionalTimeList from "../AdditionalTimeList";
import ImplementationAdd from "../ImplementationAdd";
import TrainingAdd from "../TrainingAdd";
import Coachings from "../coaching/Coachings";
import AdditionalTimeAdd from "../AdditionalTimeAdd";
import OtherActivities from "../otherActivities/OtherActivities";
import OtherActivityAdd from "../otherActivities/OtherActivityAdd";
import CoachingAdd from "../coaching/CoachingAdd";
import Summary from "../summary/Summary";

export const renderMenuButtons = (reckoningUserRole, role, activeMenu, setActiveMenu, setIsAdding) => {
    const menuButtons = [
        {
            menuCount: 1,
            title: 'Wdrożenia',
            isVisible: true
        },
        {
            menuCount: 2,
            title: 'Monitoring stanu zdrowia pacjenta',
            isVisible: true
        },
        {
            menuCount: 3,
            title: 'Szkolenie personelu w placówce',
            isVisible: true
        },
        {
            menuCount: 4,
            title: 'Dodatkowe godziny',
            isVisible: true
        },
        {
            menuCount: 5,
            title: 'Coaching',
            isVisible: reckoningUserRole === ROLE_COORD_ADS || role === ROLE_COORD_ADS
        },
        {
            menuCount: 6,
            title: 'Inne aktywności',
            isVisible: reckoningUserRole === ROLE_COORD_ADS || role === ROLE_COORD_ADS
        },
        {
            menuCount: 7,
            title: 'Podsumowanie',
            isVisible: true
        },
    ];

    return menuButtons.map(({menuCount, title, isVisible}) => (
            <MenuButton
                key={menuCount}
                menuCount={menuCount}
                title={title}
                activeMenu={activeMenu}
                setActiveMenu={setActiveMenu}
                setIsAdding={setIsAdding}
                isVisible={isVisible}
            />
        )
    );
}

export const renderMenuItem = (isAdding, settlementPeriod, setIsAdding, role, reckoningStatus, reckoningsState, settlementsState, activeMenu) => {
    const menuItems = [
        {
            id: 1,
            component: isAdding ?
                <ImplementationAdd settlementPeriod={settlementPeriod} setIsAdding={setIsAdding}/> :
                (role === ROLE_ADMIN ?
                    <ImplementationPatientAdmin settlementPeriod={settlementPeriod}/> :
                    <ImplementationPatient settlementPeriod={settlementPeriod} reckoningStatus={reckoningStatus}/>)
        },
        {
            id: 2,
            component: <TreatmentsPatient settlementPeriod={settlementPeriod} reckoningStatus={reckoningStatus}/>
        },
        {
            id: 3,
            component: isAdding ?
                <TrainingAdd setIsAdding={setIsAdding}/> :
                <Trainings settlementPeriod={settlementPeriod}/>
        },
        {
            id: 4,
            component: isAdding ?
                <AdditionalTimeAdd setIsAdding={setIsAdding}/> :
                <AdditionalTimeList settlementPeriod={settlementPeriod}/>
        },
        {
            id: 5,
            component: isAdding ?
                <CoachingAdd setIsAdding={setIsAdding} reckoningStatus={reckoningStatus} coachingInfo={null}/> :
                <Coachings settlementPeriod={settlementPeriod} reckoningStatus={reckoningStatus}/>
        },
        {
            id: 6,
            component: isAdding ?
                <OtherActivityAdd setIsAdding={setIsAdding} settlementPeriod={settlementPeriod}
                                  reckoningStatus={reckoningStatus} activityInfo={null}/> :
                <OtherActivities settlementPeriod={settlementPeriod} reckoningStatus={reckoningStatus}/>
        },
        {
            id: 7,
            component: <Summary settlementCompleted={settlementsState.settlementCompleted}
                                reckoningsState={reckoningsState}/>
        },
    ];

    return (
        <>
            {menuItems.map(item => (
                activeMenu === item.id && (
                    <React.Fragment key={item.id}>
                        {item.component}
                    </React.Fragment>
                )
            ))}
        </>
    )
}
