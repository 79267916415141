import { Button, FormControl, Grid, OutlinedInput, Typography } from "@mui/material"
import { DatePickerComponent } from "../../../../shared/ui/DatePickerComponent"
import { useAdverseEventsProduodopaStore } from "../../../../../store/hooks";

const DrugElement = ({index}) => {
    const adverseEventsProduodopaStore = useAdverseEventsProduodopaStore()
    const {partTwoInfo} = adverseEventsProduodopaStore;
    const {drugs} = partTwoInfo;
    const drug = drugs[index];
    const number = index + 1;

    return (<Grid container sx={{position: 'relative'}} display='flex' p={2} pt={6}>
        <Button onClick={adverseEventsProduodopaStore.deleteDrug.bind(null, index)} sx={{position: 'absolute', background: '#EF5350', color: 'white', padding: '0 8px', textTransform: 'lowercase',  right: 16, top: 4}}>{`usuń lek ${number}`}</Button>
        <div className="subMenuHeaderSmall"><span>{`Lek ${number}:`}</span></div>
       
        <Grid item xs={12}>
            <FormControl fullWidth required >
                <Typography variant='subtitle1'>Nazwa leku:</Typography>
                <OutlinedInput
                  name="title"
                  value={drug.title}
                  onChange={(e) => adverseEventsProduodopaStore.drugOnChange(index, e.target.name, e.target.value)}/>
            </FormControl>
        </Grid>
        <Grid item xs={12} mt={2}>
            <Typography variant="subtitle1">Data rozpoczęcia stosowania:</Typography>
            <DatePickerComponent
                disableFuture
                 label="DD/MM/RRRR"
                 allowSameDateSelection
                 name="dateStart"
                 value={drug.dateStart}
                 onChange={(value) => adverseEventsProduodopaStore.drugOnChange(index, "dateStart", value)} />
        </Grid>
        <Grid item xs={12}  mt={2}>
            <Typography variant="subtitle1">Data zakończenia stosowania:</Typography>
            <DatePickerComponent
                disableFuture
                label="DD/MM/RRRR"
                allowSameDateSelection
                name="dateEnd"
                value={drug.dateEnd}
                onChange={(value) => adverseEventsProduodopaStore.drugOnChange(index, "dateEnd", value)} />
        </Grid>
        <Grid item xs={12} mt={2}>
            <FormControl fullWidth required >
                <Typography variant='subtitle1'>Dawka:</Typography>
                <OutlinedInput
                    name="dose"
                    value={drug.dose}
                    onChange={(e) => adverseEventsProduodopaStore.drugOnChange(index, e.target.name, e.target.value)}/>
            </FormControl>
        </Grid>
        <Grid item xs={12} mt={2}>
            <FormControl fullWidth required >
                <Typography variant='subtitle1'>Droga podania:</Typography>
                <OutlinedInput
                   name="routeOfAdministration"
                   value={drug.routeOfAdministration}
                   onChange={(e) => adverseEventsProduodopaStore.drugOnChange(index, e.target.name, e.target.value)}/>
            </FormControl>
        </Grid>
        <Grid item xs={12} mt={2}>
            <FormControl fullWidth required >
                <Typography variant='subtitle1'>Wskazanie:</Typography>
                <OutlinedInput
                   name="indication"
                   value={drug.indication}
                   onChange={(e) => adverseEventsProduodopaStore.drugOnChange(index, e.target.name, e.target.value)}/>
            </FormControl>
        </Grid>
        
    </Grid>)
}

export default DrugElement