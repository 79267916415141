import React from 'react'
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    OutlinedInput,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';

const PipeSubModule = ({pipeSubmodule, pipeSubmoduleHandler}) => {
    return (
        <Grid container border={`1px solid #071d49`} display="flex" direction="row">
            <Grid item className="subMenuSidebar">Rurki</Grid>
            <Grid item flexGrow={1} maxWidth="calc(100% - 100px)" container direction="column">
                <Grid item container>
                    <Grid item xs={12} lg={6} p={2}>
                        <Typography variant="subtitle1">Czy problem dotyczył rurek?</Typography>
                        <RadioGroup
                            name="isPipeProblem"
                            value={pipeSubmodule.isPipeProblem}
                            onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.value)}
                        >
                            <FormControlLabel value="Tak" control={<Radio/>} label="Tak"/>
                            <FormControlLabel value="Nie" control={<Radio/>} label="Nie"/>
                        </RadioGroup>
                    </Grid>
                    {
                        pipeSubmodule.isPipeProblem === 'Tak' && <Grid item xs={12} lg={6} p={2}>
                            <Typography variant="subtitle1">Proszę sprecyzować odpowiedni produkt:</Typography>
                            <FormGroup>
                                <FormControlLabel control={
                                    <Checkbox
                                        name="intestinalProbe"
                                        checked={pipeSubmodule.intestinalProbe}
                                        onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                    />
                                } label="sonda jelitowa (jelito czcze)"/>
                                <FormControlLabel control={
                                    <Checkbox
                                        name="ostomyProbe"
                                        checked={pipeSubmodule.ostomyProbe}
                                        onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                    />
                                } label="sonda PEG / stomijna"/>
                                <FormControlLabel control={
                                    <Checkbox
                                        name="nasoProbe"
                                        checked={pipeSubmodule.nasoProbe}
                                        onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                    />
                                } label="sonda nosowo-jelitowa (NJ)"/>
                                <FormControlLabel control={
                                    <Checkbox
                                        name="extensionPipe"
                                        checked={pipeSubmodule.extensionPipe}
                                        onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                    />
                                } label="rurka przedłużająca"/>
                            </FormGroup>
                        </Grid>
                    }
                    <Grid item xs={12} container p={2} direction="row" wrap="nowrap">
                        <Typography variant="caption" color="red" sx={{fontStyle: 'italic'}} mr={1}>Uwaga:</Typography>
                        <Typography variant="caption" color="#0f5e8d" sx={{fontStyle: 'italic'}}>Jeżeli problem związany
                            z rurką kasetki, należy przejść do punktu dotyczącego kasetki.</Typography>
                    </Grid>
                </Grid>
                {
                    pipeSubmodule.isPipeProblem === 'Tak' &&
                    <>
                        <Grid item container borderTop={`1px solid #071d49`}>
                            <Grid item xs={12} lg={6} p={2}>
                                <Typography variant="subtitle1">Wytwórca sondy:</Typography>
                                <FormGroup>
                                    <FormControlLabel control={
                                        <Checkbox
                                            name="isAbbVie"
                                            checked={pipeSubmodule.isAbbVie}
                                            onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                        />
                                    } label="AbbVie"/>
                                    <FormControlLabel control={
                                        <Checkbox
                                            name="isFreka"
                                            checked={pipeSubmodule.isFreka}
                                            onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                        />
                                    } label="Freka"/>
                                    <FormControlLabel control={
                                        <Checkbox
                                            name="isBostonScientific"
                                            checked={pipeSubmodule.isBostonScientific}
                                            onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                        />
                                    } label="Boston Scientific"/>
                                    <FormControlLabel control={
                                        <Checkbox
                                            name="isCook"
                                            checked={pipeSubmodule.isCook}
                                            onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                        />
                                    } label="Cook"/>
                                    <FormControlLabel control={
                                        <Checkbox
                                            name="isCovidien"
                                            checked={pipeSubmodule.isCovidien}
                                            onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                        />
                                    } label="Covidien"/>
                                    <FormControlLabel control={
                                        <Checkbox
                                            name="isUnknown"
                                            checked={pipeSubmodule.isUnknown}
                                            onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                        />
                                    } label="nieznany"/>
                                    <FormControlLabel control={
                                        <Checkbox
                                            name="isAnotherName"
                                            checked={pipeSubmodule.isAnotherName}
                                            onChange={(e) => {
                                                pipeSubmoduleHandler(e.target.name, e.target.checked)
                                                pipeSubmoduleHandler('anotherName', '')
                                            }}
                                        />
                                    } label="inny (należy podać nazwę)"/>
                                    <Box minHeight={56}>
                                        {
                                            pipeSubmodule.isAnotherName &&
                                            <FormControl fullWidth>
                                                <OutlinedInput
                                                    id="anotherName"
                                                    name="anotherName"
                                                    value={pipeSubmodule.anotherName}
                                                    onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.value)}
                                                />
                                            </FormControl>
                                        }
                                    </Box>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} lg={6} p={2}>
                                <Typography variant="subtitle1">Zdarzenie dotyczące rurki:</Typography>
                                <FormGroup>
                                    <FormControlLabel control={
                                        <Checkbox
                                            name="stomaRelated"
                                            checked={pipeSubmodule.stomaRelated}
                                            onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                        />
                                    } label="powikłania związane ze stomią"/>
                                    <FormControlLabel control={
                                        <Checkbox
                                            name="blockage"
                                            checked={pipeSubmodule.blockage}
                                            onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                        />
                                    } label="blokada / zatkanie (brak możliwości przepłukania)"/>
                                    <FormControlLabel control={
                                        <Checkbox
                                            name="locationChange"
                                            checked={pipeSubmodule.locationChange}
                                            onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                        />
                                    } label="zmiana położenia"/>
                                    <FormControlLabel control={
                                        <Checkbox
                                            name="pegDisconnected"
                                            checked={pipeSubmodule.pegDisconnected}
                                            onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                        />
                                    } label="odłączenie sondy PEG od złącza Y"/>
                                    <FormControlLabel control={
                                        <Checkbox
                                            name="snapDisconnected"
                                            checked={pipeSubmodule.snapDisconnected}
                                            onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                        />
                                    } label="odłączenie złącza Y od złącza zatrzaskowego"/>
                                    <FormControlLabel control={
                                        <Checkbox
                                            name="snapDetachment"
                                            checked={pipeSubmodule.snapDetachment}
                                            onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                        />
                                    } label="odłączenie sondy jelitowej od złącza zatrzaskowego"/>
                                    <FormControlLabel control={
                                        <Checkbox
                                            name="unspecifiedDisconnection"
                                            checked={pipeSubmodule.unspecifiedDisconnection}
                                            onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                        />
                                    } label="nieokreślone odłączenie / poluzowanie złącza"/>
                                    <FormControlLabel control={
                                        <Checkbox
                                            name="fold"
                                            checked={pipeSubmodule.fold}
                                            onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                        />
                                    } label="zagięcie"/>
                                    <FormControlLabel control={
                                        <Checkbox
                                            name="entanglement"
                                            checked={pipeSubmodule.entanglement}
                                            onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                        />
                                    } label="zaplątanie"/>
                                    <FormControlLabel control={
                                        <Checkbox
                                            name="isCut"
                                            checked={pipeSubmodule.isCut}
                                            onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                        />
                                    } label="przecięcie, rozcięcie, dziura"/>
                                    <FormControlLabel control={
                                        <Checkbox
                                            name="leakyConnector"
                                            checked={pipeSubmodule.leakyConnector}
                                            onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                        />
                                    } label="nieszczelne złącze (złącze nie zostało odłączone)"/>
                                    <FormControlLabel control={
                                        <Checkbox
                                            name="isUsageError"
                                            checked={pipeSubmodule.isUsageError}
                                            onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                        />
                                    } label="błąd w użytkowaniu (należy opisać):"/>
                                    <Box minHeight={56}>
                                        {
                                            pipeSubmodule.isUsageError &&
                                            <FormControl fullWidth>
                                                <OutlinedInput
                                                    id="isUsageErrorValue"
                                                    name="isUsageErrorValue"
                                                    value={pipeSubmodule.isUsageErrorValue}
                                                    onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.value)}
                                                />
                                            </FormControl>
                                        }
                                    </Box>
                                    <FormControlLabel control={
                                        <Checkbox
                                            name="isAnother"
                                            checked={pipeSubmodule.isAnother}
                                            onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                        />
                                    } label="Inne:"/>
                                    <Box minHeight={56}>
                                        {
                                            pipeSubmodule.isAnother &&
                                            <FormControl fullWidth>
                                                <OutlinedInput
                                                    id="isAnotherValue"
                                                    name="isAnotherValue"
                                                    value={pipeSubmodule.isAnotherValue}
                                                    onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.value)}
                                                />
                                            </FormControl>
                                        }
                                    </Box>
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Grid item container borderTop={`1px solid #071d49`}>
                            <Grid item xs={12} lg={6} p={2}>
                                <Typography variant="subtitle1">Czy problem udało się rozwiązać?</Typography>
                                <RadioGroup
                                    name="problemResolved"
                                    value={pipeSubmodule.problemResolved}
                                    onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.value)}
                                >
                                    <FormControlLabel value="Tak" control={<Radio/>} label="Tak"/>
                                    <FormControlLabel value="Nie" control={<Radio/>} label="Nie"/>
                                </RadioGroup>
                            </Grid>
                            {
                                pipeSubmodule.problemResolved === 'Tak' &&
                                <Grid item xs={12} lg={6} p={2}>
                                    <Typography variant="subtitle1">Jeżeli problem został rozwiązany, to w jaki
                                        sposób?</Typography>
                                    <FormGroup>
                                        <FormControlLabel control={
                                            <Checkbox
                                                name="rinse"
                                                checked={pipeSubmodule.rinse}
                                                onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                            />
                                        } label="Przepłukanie"/>
                                        <FormControlLabel control={
                                            <Checkbox
                                                name="reconnectConnector"
                                                checked={pipeSubmodule.reconnectConnector}
                                                onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                            />
                                        } label="Ponowne podłączenie złącza"/>
                                        <FormControlLabel control={
                                            <Checkbox
                                                name="connectorReplacement"
                                                checked={pipeSubmodule.connectorReplacement}
                                                onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                            />
                                        } label="Wymiana złącza"/>
                                        <FormControlLabel control={
                                            <Checkbox
                                                name="pegReplaced"
                                                checked={pipeSubmodule.pegReplaced}
                                                onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                            />
                                        } label="Sonda PEG wymagała wymiany"/>
                                        <FormControlLabel control={
                                            <Checkbox
                                                name="jejunumPipeReplace"
                                                checked={pipeSubmodule.jejunumPipeReplace}
                                                onChange={(e) => {
                                                    pipeSubmoduleHandler(e.target.name, e.target.checked)
                                                    pipeSubmoduleHandler('jejunumPipeReplaceDescription', '')
                                                }}
                                            />
                                        }
                                                          label="Sonda jelitowa wymagała wymiany *Jeżeli rurka została wymieniona, w jaki sposób."/>
                                        <Box minHeight={56}>
                                            {
                                                pipeSubmodule.jejunumPipeReplace &&
                                                <FormControl fullWidth>
                                                    <OutlinedInput
                                                        id="jejunumPipeReplaceDescription"
                                                        name="jejunumPipeReplaceDescription"
                                                        value={pipeSubmodule.jejunumPipeReplaceDescription}
                                                        onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.value)}
                                                    />
                                                </FormControl>
                                            }
                                        </Box>
                                        <FormControlLabel control={
                                            <Checkbox
                                                name="endoscopicSurgery"
                                                checked={pipeSubmodule.endoscopicSurgery}
                                                onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                            />
                                        } label="Zabieg endoskopowy"/>
                                        <FormControlLabel control={
                                            <Checkbox
                                                name="fluoroscopy"
                                                checked={pipeSubmodule.fluoroscopy}
                                                onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.checked)}
                                            />
                                        } label="Wykorzystanie fluoroskopii"/>
                                        <FormControlLabel control={
                                            <Checkbox
                                                name="isAnotherEvent"
                                                checked={pipeSubmodule.isAnotherEvent}
                                                onChange={(e) => {
                                                    pipeSubmoduleHandler(e.target.name, e.target.checked)
                                                    pipeSubmoduleHandler('isAnotherEventValue', '')
                                                }}
                                            />
                                        } label="Inne (należy opisać):"/>
                                        <Box minHeight={56}>
                                            {
                                                pipeSubmodule.isAnotherEvent &&
                                                <FormControl fullWidth>
                                                    <OutlinedInput
                                                        id="isAnotherEventValue"
                                                        name="isAnotherEventValue"
                                                        value={pipeSubmodule.isAnotherEventValue}
                                                        onChange={(e) => pipeSubmoduleHandler(e.target.name, e.target.value)}
                                                    />
                                                </FormControl>
                                            }
                                        </Box>
                                    </FormGroup>
                                </Grid>
                            }
                        </Grid>
                    </>
                }
            </Grid>
        </Grid>
    )
}

export default PipeSubModule;