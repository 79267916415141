import React, {useContext, useEffect, useState} from 'react'
import {Grid, Stack, Typography} from '@mui/material';
import {AppContext} from "../../../../context/app/appContext";

const AdminCertificate = ({completed, notCompleted, certificateId}) => {
    const {getCertificateFile} = useContext(AppContext);
    const [blob, setBlob] = useState(null);

    useEffect(() => {
        (async () => {
            const data = await getCertificateFile(certificateId)
            setBlob(data)
        })()
    }, [certificateId, getCertificateFile])


    return (
        <Grid container border={'1px solid #E0E0E2'}>
            <Grid item xs={12}>
                {blob !== null && <iframe title={`certificate${certificateId}`}
                                          src={blob} width='100%' height='400px'/>}
            </Grid>
            <Grid item xs={6} p={2}>
                <Typography variant='subtitle1' textAlign={'center'}>Zrealizowany:</Typography>
                {completed.map(user => (
                    <Grid key={user.id} borderBottom={'1px solid #E0E0E2'} mb={1}>
                        <Stack direction="row" alignItems={'center'}>
                            <Typography variant="subtitle1">Użytkownik:</Typography>
                            <Typography ml={2}>{user.name} {user.surname}</Typography>
                        </Stack>
                        <Stack direction="row" alignItems={'center'}>
                            <Typography variant="subtitle1">Email:</Typography>
                            <Typography ml={2}>{user.email}</Typography>
                        </Stack>
                        <Stack direction="row" alignItems={'center'} color={'green'}>
                            <Typography variant="subtitle1">Zrealizowany::</Typography>
                            <Typography ml={2}>{user.date}</Typography>
                        </Stack>
                    </Grid>
                ))}
            </Grid>
            <Grid item xs={6} p={2} borderLeft={'1px solid #E0E0E2'}>
                <Typography variant='subtitle1' textAlign={'center'}>Do realizacji:</Typography>
                {notCompleted.map(user => (
                    <Grid key={user.id} borderBottom={'1px solid #E0E0E2'} mb={1}>
                        <Stack direction="row" alignItems={'center'}>
                            <Typography variant="subtitle1">Użytkownik:</Typography>
                            <Typography ml={2}>{user.name} {user.surname}</Typography>
                        </Stack>
                        <Stack direction="row" alignItems={'center'}>
                            <Typography variant="subtitle1">Email:</Typography>
                            <Typography ml={2}>{user.email}</Typography>
                        </Stack>
                        <Stack direction="row" alignItems={'center'} color={'red'}>
                            <Typography variant="subtitle1">Na realizację pozostało:</Typography>
                            <Typography ml={2}>{user.date} dni</Typography>
                        </Stack>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}

export default AdminCertificate