import React, {useContext, useEffect, useState} from "react";
import {SettlementsContext} from "../../../../context/settlements/settlementsContext";
import {Button, Grid, Stack, Typography} from "@mui/material";
import avatar from "../../../../assets/img/ui/ADD_XL.svg";
import SearchIcon from "@mui/icons-material/Search";
import ImplementationPart from "../../settlements/ImplementationPart";
import {AuthContext} from "../../../../context/auth/authContext";
import {CLASS_BLUE, CLASS_GREEN} from '../../settlements/settlementsMonthTable/helpers'

const ImplementationPatient = ({settlementPeriod}) => {
    const {settlementsState} = useContext(SettlementsContext);
    const {authState} = useContext(AuthContext);
    const [patients, setPatients] = useState([]);
    const [openPatientEducation, setOpenPatientEducation] = useState(null);
    const [openPatientSupport, setOpenPatientSupport] = useState(null);

    useEffect(() => {
        setPatients([...settlementsState.implementationPatients]);
    }, [settlementsState]);

    return patients.length && settlementsState.makers !== null ? (
        patients.map((el) => {
            const status1 = el.procedures[0] ? el.procedures[0].status : '';
            const status2 = el.procedures[1] ? el.procedures[1].status : '';
            const isAuthor1 = el.procedures[0] ? authState.id === el.procedures[0].createdBy || el.procedures[0].createdBy === null : '';
            const isAuthor2 = el.procedures[1] ? authState.id === el.procedures[1].createdBy || el.procedures[1].createdBy === null : false;
            const makers1 = el.procedures[0] && el.procedures[0].makers.map((id) => settlementsState.makers.find(item => item.id === id).name).join(', ')
            const makers2 = el.procedures[1] && el.procedures[1].makers.map((id) => settlementsState.makers.find(item => item.id === id).name).join(', ')

            return (
                <Grid container key={el.id} p={1}>
                    {/*PATIENT TITLE*/}
                    <Grid item xs={12} container display='row' wrap='nowrap' p={1} bgcolor='#f5f5f5'
                          padding={"30px 70px 40px"}>
                        <Grid item paddingRight={"45px"}>
                            <img src={avatar} alt='ava' className='settlements__blockImage_XL'/>
                        </Grid>
                        <Grid item ml={1} container justifyContent={"flex-start"}>
                            <Grid item xs={12} lg={6}>
                                <Stack className='settlements__status' direction='column'>
                                    <Typography>
                                        {" "}
                                        <b>Inicjały:</b>
                                    </Typography>
                                    <Typography>{el.name}</Typography>
                                </Stack>
                                <Stack className='settlements__status' direction='column'>
                                    <Typography>
                                        <b>Szpital:</b>
                                    </Typography>
                                    <Typography>{el.institution}</Typography>
                                </Stack>
                            </Grid>

                            <Grid item container xs={8} lg={6} columnSpacing={2}>
                                {makers1 && <Grid container item lg={12}>
                                    <Grid item lg={10}>
                                        <Typography className='reckonings__actionButton__header'>
                                            Aktywności edukacyjno-szkoleniowe:
                                        </Typography>
                                    </Grid>
                                    <Grid item container lg={12} alignItems="center">
                                        <Grid lg={3} item sx={{minWidth: '6rem'}}>
                                            <Button
                                                className={status1.includes("nieuzupełnione") ? CLASS_GREEN : CLASS_BLUE}
                                                variant='contained'
                                                onClick={() => {
                                                    setOpenPatientSupport(null);
                                                    openPatientEducation === el.id ? setOpenPatientEducation(null) : setOpenPatientEducation(el.id);
                                                }}><SearchIcon/>
                                            </Button>
                                        </Grid>
                                        <Grid item lg={8}>
                                            <Typography>Wykonujcy Aktywność:</Typography>
                                            <Typography><i><b>{makers1}</b></i></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>}
                                {makers2 && <Grid container item lg={12} pt={4}>
                                    <Grid item lg={10}>
                                        <Typography className='reckonings__actionButton__header'>
                                            Wsparcie merytoryczne dla personelu:
                                        </Typography>
                                    </Grid>
                                    <Grid item container lg={12} alignItems="center">
                                        <Grid item lg={3} sx={{minWidth: '6rem'}}>
                                            <Button
                                                className={status2.includes("nieuzupełnione") ? CLASS_GREEN : CLASS_BLUE}
                                                variant='contained'
                                                onClick={() => {
                                                    setOpenPatientEducation(null);
                                                    openPatientSupport === el.id ? setOpenPatientSupport(null) : setOpenPatientSupport(el.id);
                                                }}><SearchIcon/>
                                            </Button>
                                        </Grid>
                                        <Grid item lg={8}>
                                            <Typography>Wykonujcy Wsparcie:</Typography>
                                            <Typography><i><b>{makers2}</b></i></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>}
                            </Grid>

                        </Grid>
                    </Grid>
                    {/*PATIENT INFO*/}
                    {openPatientEducation === el.id && (
                        <ImplementationPart procedure={el.procedures[0]} settlementPeriod={settlementPeriod} form={0}
                                            closeForm={setOpenPatientEducation} isAuthor={isAuthor1}/>
                    )}
                    {openPatientSupport === el.id && el.procedures[1] && (
                        <ImplementationPart procedure={el.procedures[1]} settlementPeriod={settlementPeriod} form={1}
                                            closeForm={setOpenPatientSupport} isAuthor={isAuthor2}/>
                    )}
                </Grid>
            );
        })
    ) : (
        <Grid container alignItems='center' justifyContent='center' p={2}>
            <Typography>NO DATA</Typography>
        </Grid>
    );
};

export default ImplementationPatient;
