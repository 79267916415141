import React, {useContext, useState} from "react";
import {useNavigate} from 'react-router-dom';
import {Paper, Button, Typography, Box} from "@mui/material"
import {ReckoningsContext} from "../../../../context/reckonings/reckoningsContext";
import ReckoningGenerateConfirm from "../infoElements/ReckoningGenerateConfirm";
import {AuthContext} from "../../../../context/auth/authContext";
import {SettlementsContext} from "../../../../context/settlements/settlementsContext";
import {ROLE_ADMIN} from "../../../../data/consts/users";
import {ACCEPTED_STATUS,} from "../../../../data/consts/settlementsStatuses";
import {getActionHelper} from "./helpers";


export const SettlementsMonthsTable = ({activeMonths}) => {
    const {getSettlementsDates} = useContext(SettlementsContext);
    const {generateReckoning, confirmReckoning} = useContext(ReckoningsContext);
    const {role} = useContext(AuthContext);
    const [showReckoningGenerated, setShowReckoningGenerated] = useState(false);
    const navigate = useNavigate();

    const handleClick = (index, status, reckoning) => {
        const date = activeMonths[index];
        const yearMonth = activeMonths[index].year + '-' + activeMonths[index].month;
        if (role === ROLE_ADMIN) {
            navigate(yearMonth, {replace: true, state: {date: date}});
        } else {
            if (status !== ACCEPTED_STATUS) {
                navigate(yearMonth, {replace: true, state: {date: date}});
            } else {
                generateReckoning(reckoning);
                setShowReckoningGenerated(true);
            }
        }
    };

    const confirmReckoningGenerated = () => {
        getSettlementsDates();
        confirmReckoning();
        setShowReckoningGenerated(false)
    };

    return (<>
        {showReckoningGenerated && <ReckoningGenerateConfirm clearFunction={confirmReckoningGenerated}/>}
        <Paper sx={{width: '100%', overflow: 'hidden', bgcolor: '#f5f5f5', padding: '40px 75px'}}>
            <ul className={`settlements__list`}>
                {activeMonths.map(({
                                       status,
                                       canWriting,
                                       month,
                                       monthPolish,
                                       year,
                                       reckoningId,
                                       isClosed,
                                       isEmpty,
                                       isAccepted,
                                   }, index) => {
                    const action = getActionHelper(status, isEmpty, isAccepted, role === ROLE_ADMIN && isClosed)
                    return (<li key={month + "_" + year} className={`settlements__list-item`}>
                            <Typography
                                className={`settlements__list-item__text`}>{month} {monthPolish}</Typography>
                            {
                                canWriting && (
                                    <>
                                        <Button
                                            key={index}
                                            className={action.class}
                                            variant="contained"
                                            onClick={() => {
                                                handleClick(index, status, reckoningId ?? null);
                                            }}
                                            disabled={action.disabled}
                                        >
                                            {action.content}
                                        </Button>
                                        <Box
                                            sx={{visibility: action.status.length ? 'visible' : 'hidden'}}
                                            className={`settlements__list-item__status`}>
                                            <Typography>Status:</Typography>
                                            <Typography sx={{color: action.color}}>{action.status}</Typography>
                                        </Box>
                                    </>
                                )
                            }
                        </li>
                    )
                })}
            </ul>
        </Paper>
    </>);
}