import {CLEAR_HARDWARE_STATE, GET_HARDWARE_INFO, GET_HARDWARE_LIST} from '../types';

const handlers = {
    [GET_HARDWARE_LIST]: (state, {payload}) => ({...state, ...payload}),
    [GET_HARDWARE_INFO]: (state, {payload}) => ({...state, hardwareInfo: {...payload}}),
    [CLEAR_HARDWARE_STATE]: (state) => ({
        formSet: null,
        hardware: null,
        hardwareInfo: null,
    }),
    DEFAULT: state => state,
}

export const hardwareReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}