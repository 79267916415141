import {useCallback, useReducer} from 'react';
import {poiReducer} from './poiReducer';
import {PoiContext} from './poiContext';
import {SET_GLOBAL_LOADER, SET_MODAL_ERROR, UNSET_GLOBAL_LOADER, UNSET_MODAL_ERROR} from '../types';


export const PoiState = ({children}) => {
    const initialState = {
        isGlobalLoader: false,
        isGlobalError: false,
        errorMessage: null,
        errorCode: null,
    }

    const [state, dispatch] = useReducer(poiReducer, initialState);

    const setGlobalLoader = useCallback(() => dispatch({type: SET_GLOBAL_LOADER}), [])

    const unsetGlobalLoader = useCallback(() => dispatch({type: UNSET_GLOBAL_LOADER}), [])

    const setModalError = useCallback((res) => {
        const errorCode = res.data.code === 0 ? 0 : res.data.code ? res.data.code : res.status ? res.status : 404;

        const payload = {
            errorCode,
            errorMessage: res.data.message ? res.data.message : res.data['hydra:description'] ? res.data['hydra:description'] : 'skontaktuj się z administratorem',
        }
        dispatch({type: SET_MODAL_ERROR, payload})
    }, [])

    const unsetModalError = () => dispatch({type: UNSET_MODAL_ERROR})

    return (
        <PoiContext.Provider value={{
            isGlobalLoader: state.isGlobalLoader,
            isGlobalError: state.isGlobalError,
            errorMessage: state.errorMessage,
            errorCode: state.errorCode,
            setGlobalLoader, unsetGlobalLoader,
            setModalError, unsetModalError,
        }}>
            {children}
        </PoiContext.Provider>
    )

}