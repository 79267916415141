import React, {useContext, useEffect} from 'react'
import {AppContext} from "../../../context/app/appContext";
import PageTitle from "../../shared/ui/PageTitle";
import {Typography} from "@mui/material";
import CertificationDataTable from "./CertificationDataTable";
import CertificationModal from "./CertificationModal";
import {useModalController} from "../../../hooks/useModalController";

const Certification = () => {
    const {appState: {certificates}, getCertificateList} = useContext(AppContext);

    const {
        modalId, modalIDHandler, modalInfo, openModal, closeModal
    } = useModalController();

    useEffect(() => {
        getCertificateList()
        // eslint-disable-next-line
    }, [])

    return (<>
            <div className="certification">
                <PageTitle title="Certyfikaty:"/>
                {certificates && !!certificates.length ?
                    <CertificationDataTable openModal={openModal} hardwareIDHandler={modalIDHandler}/> :
                    <Typography> Brak danych </Typography>}
            </div>
            {modalInfo.isOpen && <CertificationModal
                closeModal={closeModal}
                modalOption={modalInfo.option}
                currentCertificateInfo={modalId}
            />}
        </>)
}

export default Certification;