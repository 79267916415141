import React, {useContext, useEffect} from 'react';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {MessagesContext} from "../../../../context/messages/messagesContext";
import {AppContext} from "../../../../context/app/appContext";
import {dateFormat,} from "../../../../utils/helpfunc";
import {
    Paper, TableContainer, Table, TableRow, TableCell, TableBody, Box, Tooltip, TablePagination,
} from "@mui/material";

import {EnhancedTableHead, getComparator, stableSort} from "../../../../utils/sortingTable";
import Search from "../../../shared/search/Search";
import {useTableController} from "../../../../hooks/useTableController";
import {headCells} from "./helper";

const InteractionsDataTable = ({openModal, userIDHandler}) => {
    const {getMessagesList} = useContext(MessagesContext);
    const {appState} = useContext(AppContext);

    useEffect(() => {
        getMessagesList();
        // eslint-disable-next-line
    }, [])

    const {
        filteredData,
        searchTerm,
        page,
        rowsPerPage,
        order,
        orderBy,
        handleChangePage,
        handleChangeRowsPerPage,
        handleRequestSort,
        handleSearchChange
    } = useTableController(appState.interactions, ['hospitalName', 'patient', 'type'],
        {rowsPerPage: 50, orderBy: 'name'})

    return (<>
        <Box justifyContent={'flex-end'} paddingBottom={2} sx={{display: 'flex'}}>
            <Search searchTerm={searchTerm} handleSearchChange={handleSearchChange}/>
        </Box>
        <Paper sx={{width: '100%', overflow: 'hidden'}}>
            <TableContainer>
                <Table stickyHeader aria-labelledby="tableTitle"
                       size={'medium'}>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={filteredData.length}
                        headCells={headCells}
                    />
                    <TableBody>
                        {stableSort(filteredData, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                return (<TableRow
                                    hover
                                    role="row"
                                    tabIndex={-1}
                                    key={index}
                                >
                                    <TableCell
                                        component="th"
                                        scope="row"
                                    >
                                        {index + 1 + '.'}
                                    </TableCell>
                                    <TableCell>{row.hospitalName}</TableCell>
                                    <TableCell>
                                        {Array.isArray(row.users) ? row.users.join(', ') : ''}
                                    </TableCell>
                                    <TableCell>
                                        {dateFormat(new Date(row.createdAt))}
                                    </TableCell>
                                    <TableCell>
                                        {row.patient}
                                    </TableCell>
                                    <TableCell>
                                        {row.type}
                                    </TableCell>
                                    <TableCell>
                                        <Box className='addUserTable'>
                                            <Tooltip title="Wyświetl">
                                                <button onClick={() => {
                                                    userIDHandler(index)
                                                    openModal('show')
                                                }}><RemoveRedEyeIcon/>
                                                </button>
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>);
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage={"Elementy na stronie"}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    </>);
}
export default InteractionsDataTable
