import React, {useContext} from 'react';
import {Button, Grid, Typography} from "@mui/material";
import {AppContext} from "../../../../context/app/appContext";

export const AdminDeleteCertificateModal = ({deleteCertificateId, closeDeleteModel, deleteCertificateTitle}) => {
    const {deleteCertificate} = useContext(AppContext);

    const deleteHandler = () => {
        deleteCertificate(deleteCertificateId);
        closeDeleteModel();
    }

    return (
        <Grid container className='customModal'>
            <Grid container className='customModal__addMenu' flexDirection='column' alignItems='center' maxWidth='500px'
                  p={3}>
                <Typography variant="h5" mb={4}>Czy chcesz
                    usunąć <strong>{deleteCertificateTitle}</strong>?</Typography>
                <Grid container justifyContent='space-around'>
                    <Button variant='contained' sx={{backgroundColor: 'darkRed'}} color='error'
                            onClick={deleteHandler}>TAK</Button>
                    <Button variant='contained' onClick={closeDeleteModel}>NIE</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}