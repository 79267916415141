import React from "react";
import finderIco from './../../../assets/img/ui/finderIco.svg'

const Search = ({handleSearchChange, searchTerm}) => {
    return (
        <form onSubmit={(e) => e.preventDefault()} className={'search'}>
            <input
                type="search"
                name='name'
                placeholder='Wyszukaj...'
                onChange={handleSearchChange}
                value={searchTerm}
            />
            <img src={finderIco} alt=""/>
        </form>
    )
}

export default Search;