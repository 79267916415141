import {useContext, useEffect, useState} from "react";
import {SettlementsContext} from "../../../../context/settlements/settlementsContext";
import {PoiContext} from "../../../../context/poi/poiContext";
import {AuthContext} from "../../../../context/auth/authContext";
import {otherActivitiesValidate} from "./otherActivitiesValidate";
import {useParams} from "react-router-dom";
import {formatYearMonth} from "../../../../utils/formatYearMonth";
import {ROLE_ADMIN} from "../../../../data/consts/users";

export const useOtherActivities = (activityInfo, setIsAdding, reckoningStatus) => {
    const {setModalError} = useContext(PoiContext);
    const {createActivity, editActivity} = useContext(SettlementsContext);
    const {role} = useContext(AuthContext);
    const params = useParams()
    const {yearMonth} = params
    const {lastDay, firstDay} = formatYearMonth(yearMonth)
    const [date, setDate] = useState(null)
    const [duration, setDuration] = useState('');
    const [textArea, setTextArea] = useState('')

    const [checkboxes, setCheckboxes] = useState({
        help: false,
        meeting: false,
        personal: false,
        phone: false
    })
    const checksHandler = (name, value) => setCheckboxes(prev => ({
        ...prev,
        [name]: value,
    }))

    const submitHandler = (e) => {
        e.preventDefault()
        const validation = otherActivitiesValidate(date, duration)

        if (validation.isError) {
            setModalError(validation.defRes);
        } else {
            const data = {
                duration: +duration,
                date,
                description: textArea,
                checks: [...Object.entries(checkboxes).map(([key, value]) => ({
                    checkbox: key,
                    checked: value,
                }))]
            }

            activityInfo?.id ? editActivity(data, activityInfo.id, date) : createActivity(data);
            setIsAdding(false)
        }
    }
    const edition = role !== ROLE_ADMIN && ((reckoningStatus === 'draft' || reckoningStatus === 'rejected') || !reckoningStatus)

    useEffect(() => {
        if (activityInfo) {
            setDate(activityInfo.date)
            setTextArea(activityInfo.description)
            setDuration(activityInfo.duration)
            setCheckboxes(activityInfo.checks.reduce((acc, {checkbox, check}) => ({...acc, [checkbox]: check}), {}))
        }
    }, [activityInfo])

    return {
        checksHandler,
        submitHandler,
        edition,
        checkboxes,
        date,
        duration,
        setDate,
        setDuration,
        textArea,
        setTextArea,
        yearMonth,
        firstDay,
        lastDay
    }
}