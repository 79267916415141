import React from 'react'
import {Typography} from '@mui/material';

const DimensionError = () => {
    return (
        <div className='dimensionError'>
            <Typography variant='h4' padding={5}>Sorry but this webpage not work with screen width less then
                780px</Typography>
        </div>
    )
}

export default DimensionError