import React, {useContext, useReducer} from 'react';
import {PoiContext} from '../poi/poiContext';
import {patientReducer} from './patientReducer';
import {PatientContext} from './patientContext';
import {AuthContext} from '../auth/authContext';
import axios from '../../axios/axios';
import {CLEAR_PATIENT, CLEAR_PATIENTS, GET_ADDITIONAL_PATIENT_INFO, GET_PATIENT_INFO, GET_PATIENTS} from '../types';
import {SettlementsContext} from '../settlements/settlementsContext';
import {ROLE_ADMIN} from "../../data/consts/users";

export const PatientState = ({children}) => {

    const {setModalError, setGlobalLoader, unsetGlobalLoader} = useContext(PoiContext);
    const {role} = useContext(AuthContext);
    const {getSettlementsInfo, settlementsState} = useContext(SettlementsContext);

    const initialState = {
        patients: null,
        patient: null,
        institutions: null,
        therapyReasons: null,
        therapySuspensionReasons: null,
        therapyLeftReasons: null,
    }

    const [state, dispatch] = useReducer(patientReducer, initialState);

    const getAdditionalPatientInfo = async () => {
        setGlobalLoader();
        try {
            await axios.get(
                `patients/form-set`
            ).then(res => {
                const payload = {
                    institutions: res.data.institutions,
                    therapyReasons: res.data.therapyReasons,
                    therapySuspensionReasons: res.data.therapySuspensionReasons,
                    therapyLeftReasons: res.data.therapyLeftReasons,
                }
                dispatch({type: GET_ADDITIONAL_PATIENT_INFO, payload});
                unsetGlobalLoader();
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }

    const addPatient = async (data) => {
        setGlobalLoader();
        try {
            await axios.post(
                `patients`,
                data
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'PACJENT DODANY',
                    },
                }
                setModalError(defRes);
                if (role === ROLE_ADMIN) {
                    getPatients();
                } else {
                    getSettlementsInfo(settlementsState.globalSettlementPeriod)
                }
                return true
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }

    const editPatient = async (userID, data) => {
        setGlobalLoader();
        try {
            await axios.put(
                `patients/${userID}`,
                data
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'EDYCJA WPROWADZONA',
                    },
                }
                setModalError(defRes);
                getPatients();
                return true
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }

    const getPatients = async () => {
        setGlobalLoader();
        try {
            await axios.get(
                `patients`
            ).then(res => {
                dispatch({type: GET_PATIENTS, payload: res.data});
                unsetGlobalLoader();
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }

    const getPatient = async (userID) => {
        setGlobalLoader()
        try {
            await axios.get(
                `patients/${userID}`
            ).then(res => {
                const payload = {
                    info: {
                        displayName: res.data.displayName,
                        nameInitial: res.data.nameInitial,
                        surnameInitial: res.data.surnameInitial,
                        pesel: res.data.pesel,
                        probeSerial: res.data.probeSerial,
                        probeInstallDate: res.data.probeInstallDate ? new Date(res.data.probeInstallDate) : null,
                        medicineInclusionDate: res.data.medicineInclusionDate ? new Date(res.data.medicineInclusionDate) : null,
                        hospitalLeftDate: res.data.hospitalLeftDate ? new Date(res.data.hospitalLeftDate) : null,
                        therapyReason: res.data.therapyReason ? res.data.therapyReason : '',
                        initiationDate: new Date(res.data.initiationDate),
                        isDataProcessAgreement: res.data.isDataProcessAgreement,
                        institution: res.data.institution.id,
                        therapySuspensionReason: res.data.therapySuspensionReason ? res.data.therapySuspensionReason : '',
                        therapySuspensionStartDate: res.data.therapySuspensionStartDate ? new Date(res.data.therapySuspensionStartDate) : null,
                        therapySuspensionEndDate: res.data.therapySuspensionEndDate ? new Date(res.data.therapySuspensionEndDate) : null,
                        therapyLeftReason: res.data.therapyLeftReason ? res.data.therapyLeftReason : '',
                        therapyLeftDate: res.data.therapyLeftDate ? new Date(res.data.therapyLeftDate) : null,
                    },
                    pumps: [...res.data.pumps.map(el => ({...el, releaseDate: new Date(el.releaseDate)}))],
                    pegs: [...res.data.pegs.map(el => ({...el, installDate: new Date(el.installDate)}))],
                }
                dispatch({type: GET_PATIENT_INFO, payload})
                unsetGlobalLoader();
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }

    const delPatient = async (userID) => {
        setGlobalLoader()
        try {
            await axios.delete(
                `patients/${userID}`
            ).then(res => {
                unsetGlobalLoader();
                getPatients()
                let defRes = {
                    data: {
                        code: 200,
                        message: 'PACJENT USUNIĘTY',
                    },
                }
                setModalError(defRes);
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response)
        }
    }

    const clearPatient = () => dispatch({type: CLEAR_PATIENT})
    const clearPatients = () => dispatch({type: CLEAR_PATIENTS})

    return (
        <PatientContext.Provider value={{
            patientState: state,
            getAdditionalPatientInfo,
            getPatients,
            addPatient,
            editPatient,
            delPatient,
            getPatient,
            clearPatient,
            clearPatients,
        }}>
            {children}
        </PatientContext.Provider>
    )
}