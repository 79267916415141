import {useState} from "react";

const configModal = (isOpen = false, option = '') => {
    return {isOpen, option}
}
export const useModalController = () => {
    const [modalInfo, setModalInfo] = useState(configModal());
    const [modalId, setModalID] = useState('')

    const openModal = (flag = '') => setModalInfo(configModal(true, flag))
    const closeModal = () => setModalInfo(configModal())

    const modalIDHandler = (id) => setModalID(id)

    return {
        modalId,
        modalIDHandler,
        modalInfo,
        openModal,
        closeModal
    }
}