import {useApiCall} from './apiCall/useApiCall';
import {useAdverseEventsProduodopaStore} from '../store/hooks';
import {useCallback} from 'react';

export const useAdverseEventsApi = () => {
  const { api } = useApiCall();
  const adverseEventsProduodopaStore = useAdverseEventsProduodopaStore();

  const getFormSetProduodopaForm = useCallback(async () => {
    const res = await api.apiInstance.get('adverse-events/produodopa/form-set', true)

    if (res) {
      adverseEventsProduodopaStore.setFormSet(res);
    }
  }, [api.apiInstance, adverseEventsProduodopaStore])

  const postProduodopaForm = useCallback(async (produodopaForm) => {
    const res = await api.apiInstance.post('adverse-events/produodopa', produodopaForm, true)

    if (res) {
      adverseEventsProduodopaStore.resetForm();
      return true;
    }
  }, [api.apiInstance, adverseEventsProduodopaStore])


  return {
    getFormSetProduodopaForm,
    postProduodopaForm
  }
}