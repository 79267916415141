export const treatmentCreateNormalizer = (comment, localPartInfo, makers, patientId, dateStart, dateEnd, type) => {

    const procedureChecks = []

    localPartInfo.forEach(procedure =>
        procedure.values.forEach(element =>
            element.values.forEach(task =>
                procedureChecks.push({
                    checkbox: task.name,
                    checked: task.checked,
                    amount: null,
                }),
            )))

    const editedMakers = []

    makers.forEach(maker => editedMakers.push({
        id: maker,
    }))

    return {
        patient: {
            id: patientId,
        },
        type,
        comment,
        procedureChecks,
        makers: editedMakers,
        dateStart,
        dateEnd,
    }
}