import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from '../../../../context/app/appContext';
import {AuthContext} from '../../../../context/auth/authContext';
import {Button, FormControl, Grid, OutlinedInput, Typography} from '@mui/material';
import PageTitle from '../../../shared/ui/PageTitle';
import MainInfo from './MainInfo';
import Line from '../../../shared/ui/Line';
import PatientInfo from './PatientInfo';
import DoctorInfo from './DoctorInfo';
import Information from './Information';
import CircumstancesInfo from './CircumstancesInfo';
import PumpSubModule from './PumpSubModule';
import PipeSubModule from './PipeSubModule';
import GelSubModule from './GelSubModule';
import AdverseEventsTable from './AdverseEventsTable';
import DrugTable from './DrugTable';
import MedicalInterviewsTable from './MedicalInterviewsTable';
import {dateFormat} from '../../../../utils/helpfunc';

const AdverseEventsDuodopa = () => {
    const {authState} = useContext(AuthContext);
    const {getAdditionalAdverseInfo, sendAdverseEvent} = useContext(AppContext);

    const [mainModule, setMainModule] = useState({
        type: 'nowe',
        number: '',
        patientContact: 'Tak',
        fromPatient: true,
        fromFamily: false,
        fromHealthCare: false,
        fromAnother: false,
        additionalContact: '',
    })

    const [patientModule, setPatientModule] = useState({
        initials: '',
        id: '',
        gender: '',
        age: '',
        dateOfBirth: null,
        dateOfEvent: null,
    })

    const [doctorModule, setDoctorModule] = useState({
        contactApprove: 'Tak',
        gastrologist: false,
        neurologist: false,
        radiologist: false,
        other: false,
        additionalSpecialization: '',
        name: '',
        address: '',
        voivodeship: '',
        telephone: '',
        city: '',
        zipCode: '',
    })

    const [circumstancesModule, setCircumstancesModule] = useState({
        description: '',
        condition: '',
        pump: false,
        pumpSNo: '',
        nj: false,
        startNJ: null,
        peg: false,
        lastInstallPeg: null,
        pegSNo: '',
        jejunum: false,
        lastInstallJejunum: null,
        jejunumSNo: '',
        gel: false,
        gelSNo: '',
        anotherProduct: false,
        additionalProduct: '',
        anotherProductReload: 'Tak',
        anotherProductSNo: '',
        anotherProductAccessoriesNo: '',
        confirm: false,
        complaintSample: 'Tak',
        discarded: false,
        stillInUse: false,
        submitSample: false,
        anotherReason: false,
        additionalReason: '',
        startTreatment: null,
        startPeg: null,
        noPackage: false,
        noAnswer: false,
        noContact: false,
        noContactInfo: false,
        noRegistration: false,
        anotherReasonNS: false,
        additionalReasonNS: '',
    })

    const [pompSubmodule, setPompSubmodule] = useState({
        isAlarm: 'Tak',
        continuousAlarm: false,
        noConnect: false,
        highPressureAlarm: false,
        fourDigitError: false,
        unspecifiedAlarm: false,
        waterDamage: false,
        physicalDamage: false,
        dropping: false,
        batteryProblem: false,
        batteryCover: false,
        keyboard: false,
        drugDelivery: false,
        cassetteHolder: false,
        display: false,
        onOrOff: false,
        programmingProblem: false,
        feedRate: false,
        usageError: false,
        usageErrorValue: '',
        problemResolved: 'Nie',
        redPlugsRemoved: false,
        batteryReplaced: false,
        reLaunchPomp: false,
        reLaunchCassette: false,
        reLaunchCover: false,
        noDisposable: false,
        replacementPomp: 'Nie',
        replacementPompComment: '',
    });

    const [pipeSubmodule, setPipeSubmodule] = useState({
        isPipeProblem: 'Nie',
        intestinalProbe: false,
        ostomyProbe: false,
        nasoProbe: false,
        extensionPipe: false,
        isAbbVie: false,
        isFreka: false,
        isBostonScientific: false,
        isCook: false,
        isCovidien: false,
        isUnknown: false,
        isAnotherName: false,
        anotherName: '',
        stomaRelated: false,
        blockage: false,
        locationChange: false,
        pegDisconnected: false,
        snapDisconnected: false,
        snapDetachment: false,
        unspecifiedDisconnection: false,
        fold: false,
        entanglement: false,
        isCut: false,
        leakyConnector: false,
        isUsageError: false,
        isUsageErrorValue: '',
        isAnother: false,
        isAnotherValue: '',
        problemResolved: 'Nie',
        rinse: false,
        reconnectConnector: false,
        connectorReplacement: false,
        pegReplaced: false,
        jejunumPipeReplace: false,
        jejunumPipeReplaceDescription: '',
        endoscopicSurgery: false,
        fluoroscopy: false,
        isAnotherEvent: false,
        isAnotherEventValue: false,
    })

    const [gelSubmodule, setGelSubmodule] = useState({
        wasCassetteInUse: 'Tak',
        cassetteCount: 0,
        typeLuer: false,
        innerCase: false,
        outerCase: false,
        caseConnect: false,
        anotherCassetteDamage: false,
        anotherCassetteDamageDescription: '',
        difficultTakeOff: false,
        loose: false,
        broken: false,
        holeInPipe: false,
        brokenLuer: false,
        redLabel: false,
        anotherProblem: false,
        anotherProblemValue: '',
        appearanceDrugProblem: 'Nie',
        isCassetteCoverDamaged: 'Nie',
        cassetteCoverDamageDescription: '',
        problemDescription: '',
        problemTime: '',
        isCassetteChecked: '',
        productStorage: '',
    });

    const [adverseEventsTableValue, setAdverseEventsTableValue] = useState(
        [
            {
                details: '',
                dateStart: null,
                dateEnd: null,
                result: '',
                causation: '',
            },
        ],
    )

    const [isDrugTable, setIsDrugTable] = useState('Nie');

    const [drugTableValue, setDrugTableValue] = useState(
        [
            {
                name: '',
                dateStart: null,
                dateEnd: null,
                dosage: '',
                method: '',
                indication: '',
            },
        ],
    )

    const [isMedicalInterviewsTable, setIsMedicalInterviewsTable] = useState('Nie');

    const [medicalInterviewsTableValue, setMedicalInterviewsTableValue] = useState(
        [
            {
                period: '',
                dateStart: null,
                dateEnd: null,
                additionalInfo: '',
            },
        ],
    )

    const [drugAllergies, setDrugAllergies] = useState('');

    useEffect(() => {
        if (patientModule.dateOfBirth && patientModule.dateOfEvent) {
            patientModuleHandler('age', -Math.trunc((patientModule.dateOfBirth.getFullYear() * 12 + patientModule.dateOfBirth.getMonth() - patientModule.dateOfEvent.getFullYear() * 12 - patientModule.dateOfEvent.getMonth()) / 12));
        } else {
            patientModuleHandler('age', 0);
        }

    }, [patientModule.dateOfBirth, patientModule.dateOfEvent])

    useEffect(() => {
        getAdditionalAdverseInfo()
        // eslint-disable-next-line
    }, [])

    const mainModuleHandler = (name, value) => setMainModule(prev => ({...prev, [name]: value}))
    const patientModuleHandler = (name, value) => setPatientModule(prev => ({...prev, [name]: value}))
    const doctorModuleHandler = (name, value) => setDoctorModule(prev => ({...prev, [name]: value}))
    const circumstancesModuleHandler = (name, value) => setCircumstancesModule(prev => ({...prev, [name]: value}))
    const pompSubmoduleHandler = (name, value) => setPompSubmodule(prev => ({...prev, [name]: value}))
    const pipeSubmoduleHandler = (name, value) => setPipeSubmodule(prev => ({...prev, [name]: value}))
    const gelSubmoduleHandler = (name, value) => setGelSubmodule(prev => ({...prev, [name]: value}))
    const drugTableToggler = (value) => setIsDrugTable(value)
    const medicalInterviewsTableToggler = (value) => setIsMedicalInterviewsTable(value)

    const adverseEventsTableHandler = (name, value, el, index) => {
        const tempArray = [];
        adverseEventsTableValue.forEach(el => tempArray.push({...el}))

        if(name === "dateStart" && adverseEventsTableValue[0].dateEnd !== null && (new Date(adverseEventsTableValue[0].dateEnd) < new Date(value))){
            tempArray.dateStart = value;
            tempArray.dateEnd = value;
        }
        else if(name === "dateEnd" && adverseEventsTableValue[0].dateStart !== null && (new Date(adverseEventsTableValue[0].dateStart) >  new Date(value))){
            tempArray.dateStart = value;
            tempArray.dateEnd = value;
        }
        else {
            tempArray[index] = {...el, [name]: value};

        }
        setAdverseEventsTableValue([...tempArray]);
    }

    const drugTableHandler = (name, value, el, index) => {
        const tempArray = [];
        drugTableValue.forEach(el => tempArray.push({...el}))
        tempArray[index] = {...el, [name]: value};
        setDrugTableValue([...tempArray])
    }

    const medicalInterviewsTableHandler = (name, value, el, index) => {
        const tempArray = [];
        medicalInterviewsTableValue.forEach(el => tempArray.push({...el}))
        tempArray[index] = {...el, [name]: value};
        setMedicalInterviewsTableValue([...tempArray])
    }

    const delAdverseEventsTableRow = (index) => setAdverseEventsTableValue(prev => ([...JSON.parse(JSON.stringify(prev.filter((el, i) => i !== index)))]))

    const delDrugTableRow = (index) => setDrugTableValue(prev => ([...JSON.parse(JSON.stringify(prev.filter((el, i) => i !== index)))]))

    const delMedicalInterviewsTableRow = (index) => setMedicalInterviewsTableValue(prev => ([...JSON.parse(JSON.stringify(prev.filter((el, i) => i !== index)))]))

    const addAdverseEventsTableRow = () => setAdverseEventsTableValue(prev => ([...prev, {
        details: '',
        dateStart: null,
        dateEnd: null,
        result: '',
        causation: '',
    }]))

    const addDrugTableRow = () => setDrugTableValue(prev => ([...prev, {
        name: '',
        dateStart: null,
        dateEnd: null,
        dosage: '',
        method: '',
        indication: '',
    }]))

    const addMedicalInterviewsTableRow = () => setMedicalInterviewsTableValue(prev => ([...prev, {
        period: '',
        dateStart: null,
        dateEnd: null,
        additionalInfo: '',
    }]))

    const dataNormalize = () => {
        return ({
                eventType: mainModule.type, 		// "nowe", "uzupełniające"
                eventUpdateId: mainModule.number, 	// wymagane jeśli eventType = "uzupełnienie"
                applicantName: `${authState.name} ${authState.surname}`, 		// imię i nazwisko składającego ads/koordynatora
                applicantPhone: typeof authState.telephone == "boolean" ? "" : authState.telephone , // nr telefonu skłajadajcego ads/koordynatora
                infoSource: {
                    fromPatient: mainModule.fromPatient,
                    fromFamily: mainModule.fromFamily,
                    fromHealthCare: mainModule.fromHealthCare,
                    fromAnother: mainModule.additionalContact,
                }, // jeśli zaznaczona "Inna osoba" to po prostu wartość z pola tekstowego zamiast true
                isAnswerToSourceRequired: mainModule.patientContact, // boolean
                eventDate: dateFormat(patientModule.dateOfEvent, true), // data zdarzenia, na jej podstawie wyliczany wiek
                patientInitials: patientModule.initials, // inicjały i 4 cyfry pesel, z selecta pacjentow przypisanych do składającego ads/koordynatora
                patientIdNumber: patientModule.id,
                patientGender: patientModule.gender, // "M", "K"
                patientBirthDate: dateFormat(patientModule.dateOfBirth, true), // data, na jej podstawie wyliczany wiek
                patientAge: patientModule.age, // liczba wyliczana na podstawie eventDate oraz patientBirthDate

                isContactToDoctorAllow: doctorModule.contactApprove, // boolean
                doctorSpecializations: {
                    gastrolog: doctorModule.gastrologist,
                    neurolog: doctorModule.neurologist,
                    radiologist: doctorModule.radiologist,
                    other: doctorModule.additionalSpecialization,
                }, // jesli zaznaczona "inna specjalizacja" to wartość z pola tekstowego zamiast true
                doctorName: doctorModule.name,
                doctorPhone: doctorModule.telephone,
                doctorStreet: doctorModule.address,
                doctorCity: doctorModule.city,
                doctorVoivodeship: doctorModule.voivodeship, // 'dolnośląskie', 'kujawsko-pomorskie', 'lubelskie', 'lubuskie', 'łódzkie', 'małopolskie', 'mazowieckie', 'opolskie', 'podkarpackie', 'podlaskie', 'pomorskie', 'śląskie', 'świętokrzyskie', 'warmińsko-mazurskie', 'wielkopolskie', 'zachodniopomorskie'
                doctorZipCode: doctorModule.zipCode, // kod pocztowy w formacie 11-111

                eventDescription: circumstancesModule.description,
                patientCondition: circumstancesModule.condition,
                eventProducts: {
                    pompa: circumstancesModule.pump,
                    nj: circumstancesModule.nj,
                    peg: circumstancesModule.peg,
                    jejunum: circumstancesModule.jejunum,
                    gel: circumstancesModule.gel,
                    anotherProduct: circumstancesModule.additionalProduct,
                }, // jeśli zaznaczony "inny (nalezy podac)" to wartosc z pola tekstowego zamiast true
                isPatientDataCorrect: circumstancesModule.anotherProductReload, // boolean, "Potwierdzam, że dane o pacjencie (pompa/peg) są aktualne"
                isProductSample: circumstancesModule.complaintSample, // boolean
                missingSampleReasons: {
                    discarded: circumstancesModule.discarded,
                    stillInUse: circumstancesModule.stillInUse,
                    submitSample: circumstancesModule.submitSample,
                    anotherReason: circumstancesModule.additionalReason,
                }, // jesli zaznaczony "inny powód (należy podać jaki)" to wartosc z pola tekstowego zamiast true
                treatmentStartDate: dateFormat(circumstancesModule.startTreatment), // data
                firstProbesDate: dateFormat(circumstancesModule.startPeg), // data
                unknownSerialNumberReasons: {
                    noPackage: circumstancesModule.noPackage,
                    noAnswer: circumstancesModule.noAnswer,
                    noContact: circumstancesModule.noContact,
                    noContactInfo: circumstancesModule.noContactInfo,
                    noRegistration: circumstancesModule.noRegistration,
                    anotherReasonNS: circumstancesModule.additionalReasonNS,
                }, // jesli zaznaczony "inny powód (należy podać jaki)" to wartosc z pola tekstowego zamiast true

                pumpSerialNumber: circumstancesModule.pumpSNo,
                isPumpAlarm: pompSubmodule.isAlarm, // boolean
                pumpAlarmTypes: {
                    continuousAlarm: pompSubmodule.continuousAlarm,
                    noConnect: pompSubmodule.noConnect,
                    highPressureAlarm: pompSubmodule.highPressureAlarm,
                    fourDigitError: pompSubmodule.fourDigitError,
                    unspecifiedAlarm: pompSubmodule.unspecifiedAlarm,
                }, // wymagane jeśli "isPumpAlarm" jest "Tak"
                pumpPhysicalDamage: {
                    waterDamage: pompSubmodule.waterDamage,
                    physicalDamage: pompSubmodule.physicalDamage,
                    dropping: pompSubmodule.dropping,
                },
                pumpFunctionalDamage: {
                    batteryProblem: pompSubmodule.batteryProblem,
                    batteryCover: pompSubmodule.batteryCover,
                    keyboard: pompSubmodule.keyboard,
                    drugDelivery: pompSubmodule.drugDelivery,
                    cassetteHolder: pompSubmodule.cassetteHolder,
                    display: pompSubmodule.display,
                    onOrOff: pompSubmodule.onOrOff,
                    programmingProblem: pompSubmodule.programmingProblem,
                    feedRate: pompSubmodule.feedRate,
                    usageError: pompSubmodule.usageErrorValue,
                }, // jesli zaznaczony "bład użytkownika / inny (należy podać jaki)" to wartosc z pola tekstowego zamiast true
                isPumpProblemSolved: pompSubmodule.problemResolved, // boolean
                pumpProblemSolvedMethods: {
                    redPlugsRemoved: pompSubmodule.redPlugsRemoved,
                    batteryReplaced: pompSubmodule.batteryReplaced,
                    reLaunchPomp: pompSubmodule.reLaunchPomp,
                    reLaunchCassette: pompSubmodule.reLaunchCassette,
                    reLaunchCover: pompSubmodule.reLaunchCover,
                    noDisposable: pompSubmodule.noDisposable,
                }, // wymagane jeśli "isPumpProblemSolved" jest "Tak"
                isPumpReplacement: pompSubmodule.replacementPomp, // boolean
                pumpReplacementHelped: pompSubmodule.replacementPompComment, // wymagane jesli "isPumpReplacement" jest "Tak"

                njProbeDate: dateFormat(circumstancesModule.startNJ), // data
                lastPegProbeDate: dateFormat(circumstancesModule.lastInstallPeg), // data
                lastPegProbeSerialNumber: circumstancesModule.pegSNo,
                lastIntestinalProbeDate: dateFormat(circumstancesModule.lastInstallJejunum), // data
                lastIntestinalProbeSerialNumber: circumstancesModule.jejunumSNo,

                isTubesProblem: pipeSubmodule.isPipeProblem, // boolean
                tubesTypes: {
                    intestinalProbe: pipeSubmodule.intestinalProbe,
                    ostomyProbe: pipeSubmodule.ostomyProbe,
                    nasoProbe: pipeSubmodule.nasoProbe,
                    extensionPipe: pipeSubmodule.extensionPipe,
                }, // wymagane jeśli "isTubesProblem" jest "Tak"
                probeProducers: {
                    AbbVie: pipeSubmodule.isAbbVie,
                    Freka: pipeSubmodule.isFreka,
                    isBostonScientific: pipeSubmodule.isBostonScientific,
                    Cook: pipeSubmodule.isCook,
                    Covidien: pipeSubmodule.isCovidien,
                    nieznany: pipeSubmodule.isUnknown,
                    isAnotherName: pipeSubmodule.anotherName,
                }, // wymagane jeśli "isTubesProblem" jest "Tak", jesli zaznaczony "inny (należy podać nazwę)" to wartosc z pola tekstowego zamiast true
                tubesProblemTypes: {
                    stomaRelated: pipeSubmodule.stomaRelated,
                    blockage: pipeSubmodule.blockage,
                    locationChange: pipeSubmodule.locationChange,
                    pegDisconnected: pipeSubmodule.pegDisconnected,
                    snapDisconnected: pipeSubmodule.snapDisconnected,
                    snapDetachment: pipeSubmodule.snapDetachment,
                    unspecifiedDisconnection: pipeSubmodule.unspecifiedDisconnection,
                    fold: pipeSubmodule.fold,
                    entanglement: pipeSubmodule.entanglement,
                    isCut: pipeSubmodule.isCut,
                    leakyConnector: pipeSubmodule.leakyConnector,
                    isUsageError: pipeSubmodule.isUsageErrorValue,
                    isAnother: pipeSubmodule.isAnotherValue,
                }, // wymagane jeśli "isTubesProblem" jest "Tak", jesli zaznaczony "Inne" lub "bład użytkownika (należy opisać)" to wartosc z pól tekstowych zamiast true
                isTubesProblemSolved: pipeSubmodule.problemResolved, //boolean
                tubesProblemSolvedMethods: {
                    rinse: pipeSubmodule.rinse,
                    reconnectConnector: pipeSubmodule.reconnectConnector,
                    connectorReplacement: pipeSubmodule.connectorReplacement,
                    pegReplaced: pipeSubmodule.pegReplaced,
                    jejunumPipeReplace: pipeSubmodule.jejunumPipeReplaceDescription,
                    endoscopicSurgery: pipeSubmodule.endoscopicSurgery,
                    fluoroscopy: pipeSubmodule.fluoroscopy,
                    isAnotherEvent: pipeSubmodule.isAnotherEventValue,
                }, // wymagane jeśli "isTubesProblemSolved" jest "Tak", jesli zaznaczony "Inne (należy opisać)" to wartosc z pola tekstowego zamiast true

                gelCasketSerialNumber: circumstancesModule.gelSNo,
                isCasketUsed: gelSubmodule.wasCassetteInUse, // boolean
                casketAmount: gelSubmodule.cassetteCount, // number
                casketLeakingParts: {
                    typeLuer: gelSubmodule.typeLuer,
                    innerCase: gelSubmodule.innerCase,
                    outerCase: gelSubmodule.outerCase,
                    caseConnect: gelSubmodule.caseConnect,
                    anotherCassetteDamage: gelSubmodule.anotherCassetteDamageDescription,
                }, // jesli zaznaczona "Inna (należy podać jaka)" to wartosc z pola tekstowego zamiast true
                casketRedPlugProblemTypes: {
                    difficultTakeOff: gelSubmodule.difficultTakeOff,
                    loose: gelSubmodule.loose,
                    broken: gelSubmodule.broken,
                },
                casketTubeProblemTypes: {
                    holeInPipe: gelSubmodule.holeInPipe,
                    brokenLuer: gelSubmodule.brokenLuer,
                    redLabel: gelSubmodule.redLabel,
                    anotherProblem: gelSubmodule.anotherProblemValue,
                }, // jezli zazaczony "Inny (należy podać, jaki):" to wartosc z pola tekstowego zamiast true
                isMedicineProblem: gelSubmodule.appearanceDrugProblem, //boolean
                isCasketWrappingDamaged: gelSubmodule.isCassetteCoverDamaged, // boolean
                casketWrappingDesc: gelSubmodule.cassetteCoverDamageDescription, // wymagane jeśli "isCasketWrappingDamage" jest "Tak"
                medicineProblemDesc: gelSubmodule.problemDescription, // wymagane jeśli "isMedicineProblem" jest "Tak"
                medicineProblemWhen: gelSubmodule.problemTime, // wymagane jesli "isMedicineProblem" jest "Tak"
                casketCheckDesc: gelSubmodule.isCassetteChecked, // wymagane jesli "isMedicineProblem" jest "Tak"
                medicineStorageMethod: gelSubmodule.productStorage, // wymagane jesli "isMedicineProblem" jest "Tak"

                isConnectorChanged: circumstancesModule.anotherProductReload, //boolean
                connectorSerialNumber: circumstancesModule.anotherProductSNo,
                accessoriesSerialNumber: circumstancesModule.anotherProductAccessoriesNo,

                eventOccurrences: [...adverseEventsTableValue.map(el => ({
                        details: el.details,
                        dateStart: dateFormat(el.dateStart),
                        dateEnd: dateFormat(el.dateEnd),
                        result: el.result,
                        causation: el.causation,
                    }),
                )],

                areParallelMedicines: isDrugTable,

                parallelMedicines: [...drugTableValue.map(el => ({
                    name: el.name,
                    dateStart: dateFormat(el.dateStart),
                    dateEnd: dateFormat(el.dateEnd),
                    dosage: el.dosage,
                    method: el.method,
                    indication: el.indication,
                })),
                ],

                areMedicalInterviews: isMedicalInterviewsTable,
                medicalInterviews: [...medicalInterviewsTableValue.map(el => ({
                    period: el.period,
                    dateStart: dateFormat(el.dateStart),
                    dateEnd: dateFormat(el.dateEnd),
                    additionalInfo: el.additionalInfo,
                })),
                ],

                medicinesAllergiesDesc: drugAllergies,
        })
    }

    const submitHandler = (e) => {
        e.preventDefault();
        const data = dataNormalize();
        sendAdverseEvent(data)
    }

    return (
        <form onSubmit={(e) => submitHandler(e)} className="adverseEvents">
            <PageTitle title="Formularz zgłaszania zdarzenia niepożądanego:"/>
            <MainInfo mainModuleHandler={mainModuleHandler} mainModule={mainModule}/>
            <Line/>
            <PatientInfo patientModuleHandler={patientModuleHandler} patientModule={patientModule}/>
            <Line/>
            <DoctorInfo doctorModuleHandler={doctorModuleHandler} doctorModule={doctorModule}/>
            <Line/>
            <Typography color="#00f">Część 1: Informacje dotyczące reklamacji</Typography>
            <Typography mb={1}>Formularz musi zawierać wszystkie strony. Należy zastosować format daty:
                DD/MM/RRRR</Typography>
            <CircumstancesInfo circumstancesModuleHandler={circumstancesModuleHandler}
                               circumstancesModule={circumstancesModule}/>
            <Line/>
            <Grid container p={2} border={`1px solid #071d49`} direction="row" wrap="nowrap">
                <Typography variant="h6" color="red" mr={1}>Uwaga:</Typography>
                <Typography variant="h6">Próbki żelu, którego wygląd wzbudził zastrzeżenia, należy przechowywać w
                    lodówce do momentu przekazania ich AbbVie.</Typography>
            </Grid>
            <Line/>
            {
                circumstancesModule.pump &&
                <>
                    <PumpSubModule pompSubmodule={pompSubmodule} pompSubmoduleHandler={pompSubmoduleHandler}/>
                    <Line/>
                </>
            }
            {
                circumstancesModule.nj || circumstancesModule.peg || circumstancesModule.jejunum || circumstancesModule.anotherProduct ?
                    <>
                        <PipeSubModule pipeSubmodule={pipeSubmodule} pipeSubmoduleHandler={pipeSubmoduleHandler}/>
                        <Line/>
                    </>
                    : null
            }
            {
                circumstancesModule.gel &&
                <>
                    <GelSubModule gelSubmodule={gelSubmodule} gelSubmoduleHandler={gelSubmoduleHandler}/>
                    <Line/>
                </>
            }
            <Typography color="#00f" mb={2}>Część 2: Zdarzenie niepożądane</Typography>
            <AdverseEventsTable
                adverseEventsTableValue={adverseEventsTableValue}
                adverseEventsTableHandler={adverseEventsTableHandler}
                addAdverseEventsTableRow={addAdverseEventsTableRow}
                delAdverseEventsTableRow={delAdverseEventsTableRow}
            />
            <Line/>
            <DrugTable
                isDrugTable={isDrugTable}
                addDrugTableRow={addDrugTableRow}
                delDrugTableRow={delDrugTableRow}
                drugTableHandler={drugTableHandler}
                drugTableToggler={drugTableToggler}
                drugTableValue={drugTableValue}
            />
            <Line/>
            <MedicalInterviewsTable
                isMedicalInterviewsTable={isMedicalInterviewsTable}
                addMedicalInterviewsTableRow={addMedicalInterviewsTableRow}
                delMedicalInterviewsTableRow={delMedicalInterviewsTableRow}
                medicalInterviewsTableHandler={medicalInterviewsTableHandler}
                medicalInterviewsTableToggler={medicalInterviewsTableToggler}
                medicalInterviewsTableValue={medicalInterviewsTableValue}
            />
            <Line/>
            <Grid p={2} border={`1px solid #071d49`} container justifyContent="center">
                <Grid item xs={12} lg={6}>
                    <FormControl fullWidth>
                        <Typography variant="subtitle1">Alergie na leki:</Typography>
                        <OutlinedInput
                            id="allergia"
                            name="allergia"
                            value={drugAllergies}
                            onChange={(e) => setDrugAllergies(e.target.value)}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Line/>
            <Information/>
            <Grid container justifyContent="center" direction="column" alignItems="center">
                <Typography variant="h4" mb={2} mt={2}>KONIEC FORMULARZA</Typography>
                <Button variant="contained" color="primary" type="submit">Wyślij</Button>
            </Grid>
        </form>
    )
}

export default AdverseEventsDuodopa;

