import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, OutlinedInput, Radio, RadioGroup, Typography } from "@mui/material";
import {observer} from 'mobx-react-lite';

import Line from "../../../../shared/ui/Line";
import {DatePickerComponent} from "../../../../shared/ui/DatePickerComponent";
import {useAdverseEventsProduodopaStore} from '../../../../../store/hooks';
import {useDisabled} from '../useDisabled';
import {convertBoolValue, convertBoolValueHandler} from '../helper';

const componentsElements = [
  "Klapa baterii",
  "Bateria",
  'Akcesoria do przenoszenia (użyj sekcji "Akcesoria do przenoszenia")',
  "Zestaw do ładowania",
  "Inne:"
];

const batteryCoverProblemsElements = [
  "Nie można zamknąć obudowy pompy",
  "Uszkodzone"
];

const batteryProblemsElements = [
  "Nie ładuje",
  "Uszkodzona",
  "Nie działa z pompą"
]

const chargingProblemsElements = [
  "Nie ładuje",
  "Uszkodzona",
  "Brakuje komponentu",
  "Inne, wyjaśnienie:"
]
const ReplacementPart = observer(() => {
  const adverseEventsProduodopaStore = useAdverseEventsProduodopaStore()
  const {sparePartInfo} = adverseEventsProduodopaStore;

  const {
    isDisabled: isDisabledComponentsOther,
    changeDisabled: changeDisabledComponentsOther,
  } = useDisabled(componentsElements[componentsElements.length - 1], "componentsOther", adverseEventsProduodopaStore.sparePartInfoOnChange)

  const {
    isDisabled: isDisabledChargingProblemsOther,
    changeDisabled: changeDisabledChargingProblemsOther,
  } = useDisabled(chargingProblemsElements[chargingProblemsElements.length - 1], "chargingProblemsOther", adverseEventsProduodopaStore.sparePartInfoOnChange)

  const {
    isDisabled: isDisabledAppearanceProblemDescription,
    changeDisabledSingle: changeDisabledAppearanceProblemDescription,
  } = useDisabled(true, "appearanceProblemDescription", adverseEventsProduodopaStore.sparePartInfoOnChange)

    return (
        <Grid container display='flex' mt={2} pl={2}>
        <Grid item className="subMenuSidebar">Część zamienna</Grid>
        <Grid flexGrow={1} maxWidth="calc(100% - 100px)" container display='flex' flexDirection='column' >
            <Grid item sx={{border: '1px solid #9B9B9D'}} p={2} display='flex' flexDirection='column'>
                <FormControl fullWidth>
                    <Typography variant='subtitle1'>Wskaż, na który komponent ma to wpływ: </Typography>
                      <FormGroup
                        value={sparePartInfo.components}
                        name="components"
                        onChange={(e) => {
                          adverseEventsProduodopaStore.sparePartInfoOnChange('components', e.target.value);
                          changeDisabledComponentsOther(sparePartInfo.components, e.target.value);
                        }}>
                        {componentsElements.map(element =>  <FormControlLabel mt={1} key={element} value={element} control={<Checkbox />} label={element} />)}
                      </FormGroup>
                </FormControl> 
                <FormControl fullWidth sx={{pl: 6, mt: 1}}>
                    <OutlinedInput
                        disabled={isDisabledComponentsOther}
                        name="componentsOther"
                        value={sparePartInfo.componentsOther}
                        onChange={(e) => adverseEventsProduodopaStore.sparePartInfoOnChange(e.target.name, e.target.value)}/>
                 </FormControl>  
                            
            </Grid>
            
            <Grid item sx={{border: '1px solid #9B9B9D'}} p={2} display='flex' gap={2} flexDirection='column'>
                <div>
                <Typography variant='subtitle1'>Problem z klapą baterii</Typography>
                <FormControl fullWidth>
                      <FormGroup
                        value={sparePartInfo.batteryCoverProblems}
                        name="batteryCoverProblems"
                        onChange={(e) => {
                          adverseEventsProduodopaStore.sparePartInfoOnChange('batteryCoverProblems', e.target.value);
                        }}>
                        {batteryCoverProblemsElements.map(element =>  <FormControlLabel mt={1} key={element} value={element} control={<Checkbox />} label={element} />)}
                      </FormGroup>
                </FormControl>  
                <Line/>  
                <Typography variant='subtitle1'>Problem z baterią</Typography>
                <FormControl fullWidth>
                    <FormGroup
                      value={sparePartInfo.batteryProblems}
                      name="batteryProblems"
                      onChange={(e) => {
                        adverseEventsProduodopaStore.sparePartInfoOnChange('batteryProblems', e.target.value);
                      }}>
                      {batteryProblemsElements.map(element =>  <FormControlLabel mt={1} key={element} value={element} control={<Checkbox />} label={element} />)}
                    </FormGroup>
                </FormControl>  
                <Line/> 
                <Typography variant='subtitle1'>Zestaw ładujący</Typography>
                     <FormControl fullWidth>
                      <FormGroup
                        value={sparePartInfo.chargingProblems}
                        name="chargingProblems"
                        onChange={(e) => {
                          adverseEventsProduodopaStore.sparePartInfoOnChange('chargingProblems', e.target.value);
                          changeDisabledChargingProblemsOther(sparePartInfo.chargingProblems, e.target.value);
                        }}>
                        {chargingProblemsElements.map(element =>  <FormControlLabel mt={1} key={element} value={element} control={<Checkbox />} label={element} />)}
                      </FormGroup>
                </FormControl>  
                <FormControl fullWidth sx={{pl: 6, mt: 1}}>
                            <Typography>Opis:</Typography>
                            <OutlinedInput
                              disabled={isDisabledChargingProblemsOther}
                              name="chargingProblemsOther"
                              value={sparePartInfo.chargingProblemsOther}
                              onChange={(e) => adverseEventsProduodopaStore.sparePartInfoOnChange(e.target.name, e.target.value)}/>
                </FormControl>
                </div>      
            </Grid>

            <Grid item sx={{border: '1px solid #9B9B9D'}} p={2} display='flex' gap={1} flexDirection='column'>
                <Typography variant='subtitle1'>Pytanie dodatkowe:</Typography>
                <Typography variant='subtitle1'>Czy jest jakiś problem z wyglądem? </Typography>
               
                    <FormControl fullWidth>
                            <RadioGroup
                              name="isAppearanceProblem"
                              value={convertBoolValue(sparePartInfo.isAppearanceProblem)}
                              onChange={(e) =>  {
                                adverseEventsProduodopaStore.sparePartInfoOnChange(e.target.name, convertBoolValueHandler(e.target.value));
                                changeDisabledAppearanceProblemDescription(convertBoolValueHandler(e.target.value));
                              }}>
                                    <FormControlLabel mt={1} value="Tak" control={<Radio />} label="Tak" />
                                    <FormControlLabel mt={1} value="Nie" control={<Radio />} label="Nie" />
                            </RadioGroup>
                    </FormControl> 
                    <FormControl fullWidth sx={{pl: 6}}>
                            <Typography>Opis:</Typography>
                            <OutlinedInput
                              disabled={isDisabledAppearanceProblemDescription}
                              name="appearanceProblemDescription"
                              value={sparePartInfo.appearanceProblemDescription}
                              onChange={(e) => adverseEventsProduodopaStore.sparePartInfoOnChange(e.target.name, e.target.value)}/>
                        </FormControl>  
                    <Line/>   
                     
                    <Typography variant='subtitle1'>Czy część zamienna została sprawdzona po otrzymaniu?</Typography>
                    <FormControl fullWidth>
                            <RadioGroup
                              disabled={isDisabledAppearanceProblemDescription}
                              name="isSparePartChecked"
                              value={convertBoolValue(sparePartInfo.isSparePartChecked)}
                              onChange={(e) =>  {
                                adverseEventsProduodopaStore.sparePartInfoOnChange(e.target.name, convertBoolValueHandler(e.target.value));
                              }}>
                                    <FormControlLabel mt={1} value="Tak" control={<Radio />} label="Tak" />
                                    <FormControlLabel mt={1} value="Nie" control={<Radio />} label="Nie" />
                            </RadioGroup>
                    </FormControl> 
                    <Line/>  
                    <Typography variant='subtitle1'>Kiedy problem został zaobserwowany?</Typography>
                    <FormControl fullWidth>
                        <DatePickerComponent
                          disableFuture
                          label="DD/MM/RRRR"
                          allowSameDateSelection
                          name="date"
                          value={sparePartInfo.date}
                          onChange={(value) => adverseEventsProduodopaStore.sparePartInfoOnChange("date", value)} />
                    </FormControl> 
                    <Line/>  
                    <Typography variant='subtitle1'>Czy problem był widoczny w momencie odbioru? </Typography>
                    <FormControl fullWidth>
                            <RadioGroup
                              name="isProblemVisible"
                              value={convertBoolValue(sparePartInfo.isProblemVisible)}
                              onChange={(e) =>  {
                                adverseEventsProduodopaStore.sparePartInfoOnChange(e.target.name, convertBoolValueHandler(e.target.value));
                              }}>
                                    <FormControlLabel mt={1} value="Tak" control={<Radio />} label="Tak" />
                                    <FormControlLabel mt={1} value="Nie" control={<Radio />} label="Nie" />
                            </RadioGroup>
                    </FormControl> 
            </Grid>
            
            <Grid item sx={{border: '1px solid #9B9B9D'}} p={2} display='flex' gap={2} flexDirection='column'>
           
                <FormControl fullWidth>
                <Typography variant='subtitle1'>Jeśli problem został rozwiązany, w jaki sposób? </Typography>
                    <OutlinedInput
                      name="solution"
                      value={sparePartInfo.solution}
                      onChange={(e) => adverseEventsProduodopaStore.sparePartInfoOnChange(e.target.name, e.target.value)}/>
                </FormControl> 
                <FormControl fullWidth>
                <Typography variant='subtitle1'>Opisz trudności funkcjonalne, jeśli dotyczy:</Typography>
                    <OutlinedInput
                      name="functionalDifficulty"
                      value={sparePartInfo.functionalDifficulty}
                      onChange={(e) => adverseEventsProduodopaStore.sparePartInfoOnChange(e.target.name, e.target.value)}/>
                </FormControl> 
            </Grid>
        </Grid>
    </Grid>)
});

export default ReplacementPart;