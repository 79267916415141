import React, {useContext, useEffect} from 'react';
import PageTitle from '../../../shared/ui/PageTitle';
import {Grid, Typography} from '@mui/material';
import InteractionsAdminModal from './InteractionsAdminModal';
import {AppContext} from "../../../../context/app/appContext";
import InteractionsDataTable from "./InteractionsDataTable";
import {useModalController} from "../../../../hooks/useModalController";

const Interactions = () => {
    const {appState, getInteractions} = useContext(AppContext);

    const {
        modalId, modalIDHandler, modalInfo, openModal, closeModal
    } = useModalController();

    useEffect(() => {
        getInteractions();
        // eslint-disable-next-line
    }, [])

    return (<>
        <div className="users">
            <PageTitle title="Interakcje:"/>
            <Grid container justifyContent="flex-end" pb={1}>
            </Grid>
            {appState.interactions ? <InteractionsDataTable openModal={openModal} userIDHandler={modalIDHandler}/> :
                <Typography>Brak danych</Typography>}
        </div>
        {modalInfo.isOpen && <InteractionsAdminModal
            closeModal={closeModal}
            modalOption={modalInfo.option}
            userID={modalId}
        />}
    </>)
}

export default Interactions;
