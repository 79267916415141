export const trainingValidate = (participants, date, makers) => {
    if (!date) {
        return ({
            isError: true,
            defRes: {
                data: {
                    code: 422,
                    message: 'Wybierz datę',
                },
            },
        })
    }

    if (makers.length === 0) {
        return ({
            isError: true,
            defRes: {
                data: {
                    code: 422,
                    message: 'Dodaj prowadzącego',
                },
            },
        })
    }

    if (participants.length === 0) {
        return ({
            isError: true,
            defRes: {
                data: {
                    code: 422,
                    message: 'Dodaj uczestnika',
                },
            },
        })
    }

    return ({
        isError: false,
        defRes: null,
    })
}