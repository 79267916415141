import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../context/auth/authContext";
import {PoiContext} from '../context/poi/poiContext';
import Header from './shared/Header';
import SideBar from './shared/SideBar';
import Content from './shared/Content';
import Footer from './shared/Footer';
import GlobalLoader from './shared/ui/GlobalLoader';
import userRoutes from '../data/uiSettings/userRoutes';
import userLinks from '../data/uiSettings/userLinks';
import useWindowDimensions from '../hooks/useWindowDimensions';
import DimensionError from './shared/DimensionError';
import ErrorModal from "./shared/modals/ErrorModal";
import {CheckUser} from "./shared/appLayer/checkUser/CheckUser";

function App() {

    const {isGlobalLoader, isGlobalError} = useContext(PoiContext);
    const {isLoggedIn, sendIsAuth, authState} = useContext(AuthContext);

    const [routes, setRoutes] = useState(null);
    const [menuLinks, setMenuLinks] = useState(null);

    const {width} = useWindowDimensions();

    useEffect(() => {
        if(sendIsAuth){
            setRoutes(userRoutes(isLoggedIn, authState.role, authState.checkPassDate))
            setMenuLinks(userLinks(isLoggedIn, authState.role, authState.checkPassDate, authState.name, authState.surname))
        }

    }, [sendIsAuth, isLoggedIn, authState])

    return (
        <>
            <div className="app">
                <div className="app__leftSide">
                    <SideBar links={menuLinks}/>
                </div>
                <div className="app__rightSide">
                    <Header/>
                    <Content routes={routes}/>
                    <Footer/>
                </div>
            </div>
            <CheckUser/>
            {isGlobalError && <ErrorModal/>}
            {isGlobalLoader && <GlobalLoader/>}
            {width < 780 && <DimensionError/>}
        </>
    );
}

export default App;
