import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, MenuItem, Select, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";

import { DatePickerComponent } from "../../../shared/ui/DatePickerComponent";
import Line from "../../../shared/ui/Line";
import { useAdverseEventsProduodopaStore } from "../../../../store/hooks";

const MainInfo = observer(() => {
    const adverseEventsProduodopaStore = useAdverseEventsProduodopaStore()
    const {mainInfo, formSet} = adverseEventsProduodopaStore;
    
    return <Grid container pl={2} >
        <Grid item xs={12}>
            <Typography variant="subtitle1">Data otrzymania zgłoszenia:</Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
            <DatePickerComponent
                disableFuture
                label="DD/MM/RRRR"
                allowSameDateSelection
                name="applicationReceiptDate"
                value={mainInfo.applicationReceiptDate}
                onChange={(value) => adverseEventsProduodopaStore.mainInfoOnChange("applicationReceiptDate", value)}
                />
        </Grid>
        <Grid item xs={12} mt={2}>
            <Typography variant="subtitle1">Osoba wypełniająca formularz:</Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
            <Typography>{formSet.username}</Typography>
        </Grid>
        <Grid item xs={12} mt={2}> 
            <Typography variant="subtitle1">Adres email:</Typography>
        </Grid>
        <Grid item xs={12} mt={1}> 
            <Typography>{formSet.email}</Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
            <Typography variant="subtitle1">Numer telefonu:</Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
            <Typography>{formSet.phone}</Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
            <Typography variant="subtitle1">Nazwa usługodawcy:</Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
                    <Select
                       fullWidth
                       name="institutionId"
                       value={mainInfo.institutionId}
                       onChange={(e) => adverseEventsProduodopaStore.mainInfoOnChange("institutionId", e.target.value)}>   
                       {formSet.institutions.map(institution => <MenuItem value={institution.id}>{institution.name}</MenuItem> )}
                    </Select>
        </Grid>
        <Grid item xs={12} mt={2}>
            <Typography variant="subtitle1">Data poinformowania AbbVie:</Typography>
        </Grid>
        <Grid item xs={12} mt={1} mb={3}>
            <DatePickerComponent
                disableFuture
                name="notificationDate"
                value={mainInfo.notificationDate}
                onChange={(value) => adverseEventsProduodopaStore.mainInfoOnChange("notificationDate", value)}
                label="DD/MM/RRRR"
                allowSameDateSelection
                />
        </Grid>
        <Line/>
        <Grid item xs={12} mt={3} mb={3} p={2} sx={{border: '1px solid #9B9B9D'}}>
            <FormControl>
                <Typography variant="subtitle1">Wybierz rodzaj:</Typography>
                <FormGroup sx={{mt: 2, gap: 1}}
                    name="complaintTypes"
                    onChange={(e) => adverseEventsProduodopaStore.mainInfoOnChange("complaintTypes", e.target.value)}>
                    <FormControlLabel mt={1} value="Reklamacja" control={<Checkbox checked={mainInfo.complaintTypes.includes('Reklamacja')}/>} label="Reklamacja" />
                    <FormControlLabel mt={1} value="Zdarzenie" control={<Checkbox checked={mainInfo.complaintTypes.includes('Zdarzenie')}/>} label="Zdarzenie" />
                </FormGroup>
            </FormControl>
        </Grid>
        <Line/>
        
    </Grid>
});

export default MainInfo;