import React from 'react';
import {Button, Checkbox, FormControlLabel, Grid, TextField, Typography,} from '@mui/material';
import {DatePickerComponent} from "../../shared/ui/DatePickerComponent";

export const PumpSubModal = ({pumps, delPumpTableRow, pumpsTableHandler, addPumpsTableRow, modalOption}) => {
    return (<>
        <Grid item container mt={2} xs={7} lg={5.8} justifyContent='space-between'>
            <Grid item xs={4.5}>
                <Typography style={{fontSize: "1rem"}} sx={{borderBottom: "solid 1px #ccc", fontWeight: "bold"}}>Dane
                    pompy:</Typography>
            </Grid>
            <Grid item xs={6.8}>
                <Button variant="contained" fullWidth size='small' sx={{backgroundColor: "#228B21"}}
                        onClick={addPumpsTableRow}>Dodaj kolejną pompę
                </Button>
            </Grid>
        </Grid>
        <Grid
            item
            xs={12}
            container
            direction="column"
        >
            {
                pumps.map((el, index) =>
                    <Grid key={index} container mt={1} p={2} borderRadius={'5px'} bgcolor="#F5F5F5"
                          justifyContent='space-between'>
                        <Grid item xs={12} lg={5.8} mt={1}>
                            <TextField required name="serialNumber" label='Pompa NR SERII' value={el.serialNumber}
                                       disabled={modalOption === 'show'} fullWidth
                                       onChange={(e) => pumpsTableHandler(e.target.name, e.target.value, el, index)}/>
                        </Grid>
                        <Grid item xs={12} lg={5.8} mt={1}>
                            <DatePickerComponent
                                allowSameDateSelection
                                disableFuture
                                disabled={modalOption === 'show'}
                                value={el.releaseDate}
                                label="Data wydania:"
                                onChange={(newValue) => pumpsTableHandler('releaseDate', newValue, el, index)}
                            />
                        </Grid>
                        <Grid container mt={2} alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <FormControlLabel control={
                                    <Checkbox
                                        name="isRunning"
                                        checked={el.isRunning}
                                        disabled={modalOption === 'show'}
                                        onChange={(e) => pumpsTableHandler(e.target.name, e.target.checked, el, index)}
                                    />} label="Czy aktywna?"/>
                            </Grid>
                            {
                                modalOption !== 'show' &&
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        sx={{backgroundColor: '#D32F2F'}}
                                        className="formButton"
                                        onClick={() => delPumpTableRow(index)}
                                    >Usuń pole
                                    </Button>
                                </Grid>
                            }
                        </Grid>
                    </Grid>,
                )
            }
        </Grid>
    </>)
}