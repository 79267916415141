import React, {useState} from 'react'
import {Button, Stack, Typography, Box} from '@mui/material';
import InfoIcon from '../../../assets/img/ui/infoIcon.png';
import CheckedIcon from '../../../assets/img/ui/checkedIcon.png';
import WarningBox from './WarningBox';


const WarningRejected = ({text}) => {
    const [modal, setModal] = useState('info')
    const [moreInfo, setMoreInfo] = useState(true)

    if (modal === 'info') return (
        <WarningBox setModal={setModal}>
            <Box pt={0} sx={{textAlign: 'center'}}>
                <Box>
                    <img src={CheckedIcon} alt=""/>
                </Box>
                <Typography mt={4} className="settlements__addTitle" sx={{fontWeight: 'bold'}}>
                    Rozliczenie zostało <br/> <span style={{color: '#D32F2F'}}> nie rozliczone. <br/> Sprawdź uwagi </span> do
                    rozliczenia, wypełnij je jeszcze raz i rozlicz.
                </Typography>
            </Box>
        </WarningBox>
    )
    if (modal !== 'info') return <React.Fragment>
        <Stack color='#D32F2F' direction="row" px={1} py={0.6} bgcolor='rgba(211, 47, 47, 0.47)' spacing={1} sx={{
            flexGrow: 1,
            borderRadius: '4px',
            position: 'absolute',
            top: "auto",
            right: '2rem',
            zIndex: '1',
            maxWidth: '32rem',
            width: '27%',
            minWidth: '30rem',
            bottom: '5rem'
        }}>
            <Box my={0.8}>
                <img src={InfoIcon} alt=""/>
            </Box>
            <Box pt={0} sx={{width: '100%'}}>
                <Stack spacing={2} direction="row" sx={{justifyContent: 'space-between'}}>
                    <Typography mt={0.8}><b>UWAGI DO ROZLICZNIA:</b></Typography>
                    <Button onClick={() => {
                        setModal('comment');
                        setMoreInfo(false)
                    }} variant='contained'>ZOBACZ WSZYSTKIE UWAGI</Button>
                </Stack>
                {moreInfo && <Typography mt={0.5}
                                         variant='body2'>{text.slice(0, 110)}{text.length > 110 ? ' (...)' : ''}</Typography>}
            </Box>
        </Stack>
        {modal === 'comment' &&
            <WarningBox setModal={setModal}>
                <Box pt={0} color='#D32F2F'>
                    <Stack direction="row" spacing={2}>
                        <Box>
                            <img src={InfoIcon} alt=""/>
                        </Box>
                        <Typography><b>UWAGI DO ROZLICZNIA:</b></Typography>
                    </Stack>
                    <Typography mt={3}>{text}</Typography>
                </Box>
            </WarningBox>
        }
    </React.Fragment>
};

export default WarningRejected