import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, OutlinedInput, Radio, RadioGroup, Typography } from "@mui/material";
import {observer} from 'mobx-react-lite';

import Line from "../../../../shared/ui/Line";
import {useAdverseEventsProduodopaStore} from '../../../../../store/hooks';
import {useDisabled} from '../useDisabled';
import {convertBoolValue, convertBoolValueHandler} from '../helper';

const defectssElements = [
  "Nie można zatrzasnąć adaptera fiolki na fiolce z roztworem",
  "Poluzowany/odpadł z fiolki z roztworem",
  "Poluzowany/wypadł ze strzykawki",
  "Złamany/ Uszkodzony",
  "Uszkodzone opakowanie pierwszorzędowe/ naruszona bariera sterylna",
  "Ciała obce, zanieczyszczenia i materiały obce",
  "Wygasły termin przydatności do użycia"
];

const leakingConnectionsElements = [
  "Adapter fiolki i fiolka z roztworem",
  "Adapter fiolki i strzykawka",
  "Poluzowany/wypadł ze strzykawki",
  "Inne:"
]

const VialAdapterProduct = observer(() => {
  const adverseEventsProduodopaStore = useAdverseEventsProduodopaStore()
  const {adapterInfo} = adverseEventsProduodopaStore;

  const {
    isDisabled: isDisabledLeakingConnectionsOther,
    changeDisabled: changeDisabledLeakingConnectionsOther,
  } = useDisabled(leakingConnectionsElements[leakingConnectionsElements.length - 1], "leakingConnectionsOther", adverseEventsProduodopaStore.adapterInfoOnChange)

  const {
    isDisabled: isDisabledAppearanceProblemDescription,
    changeDisabledSingle: changeDisabledAppearanceProblemDescription
  } = useDisabled(true, "appearanceProblemDescription", adverseEventsProduodopaStore.adapterInfoOnChange)

  return (
        <Grid container display='flex' mt={2} pl={2}>
            <Grid item className="subMenuSidebar">Adapter fiolki</Grid>
            <Grid flexGrow={1} maxWidth="calc(100% - 100px)" container display='flex' flexDirection='column' >
                <Grid item sx={{border: '1px solid #9B9B9D'}} p={2} display='flex' flexDirection='column'>
                    <FormControl fullWidth>
                        <Typography variant='subtitle1'>Zaznacz odpowiednią wadę</Typography>
                            <FormGroup
                              value={adapterInfo.defects}
                              name="defects"
                              onChange={(e) => adverseEventsProduodopaStore.adapterInfoOnChange("defects", e.target.value)}>
                              {defectssElements.map(element =>  <FormControlLabel mt={1} key={element} value={element} control={<Checkbox />} label={element} />)}
                            </FormGroup>
                    </FormControl>  
                </Grid>
                <Grid item sx={{border: '1px solid #9B9B9D'}} p={2} display='flex' flexDirection='column' gap={2}>
                    <Typography variant='subtitle1'>Nieszczelny adapter fiolki:</Typography>
                    <Typography sx={{fontSize: 14}} variant='subtitle1'>Jeśli zaobserwowano wyciek, należy wskazać, które połączenie adaptera fiolki było nieszczelne: </Typography>

                    <FormControl fullWidth>
                            <FormGroup
                              value={adapterInfo.leakingConnections}
                              name="leakingConnections"
                              onChange={(e) => {
                                adverseEventsProduodopaStore.adapterInfoOnChange('leakingConnections', e.target.value);
                                changeDisabledLeakingConnectionsOther(adapterInfo.leakingConnections, e.target.value);
                              }}>
                              {leakingConnectionsElements.map(element =>  <FormControlLabel mt={1} key={element} value={element} control={<Checkbox />} label={element} />)}
                            </FormGroup>
                    </FormControl>
                    <FormControl fullWidth sx={{pl: 6}}>
                        <OutlinedInput
                          disabled={isDisabledLeakingConnectionsOther}
                          name="leakingConnectionsOther"
                          value={adapterInfo.leakingConnectionsOther}
                          onChange={(e) => adverseEventsProduodopaStore.adapterInfoOnChange(e.target.name, e.target.value)}/>
                    </FormControl>
                </Grid> 
                <Grid item sx={{border: '1px solid #9B9B9D'}} p={2} display='flex' flexDirection='column' gap={2}>
                    <Typography variant='subtitle1'>Pytania dodatkowe:</Typography>
                    <Typography sx={{fontSize: 14}} variant='subtitle1'>Czy jest jakiś problem z wyglądem?</Typography>

                    <FormControl fullWidth>
                            <RadioGroup
                              name="isAppearanceProblem"
                              value={convertBoolValue(adapterInfo.isAppearanceProblem)}
                              onChange={(e) =>  {
                                adverseEventsProduodopaStore.adapterInfoOnChange(e.target.name, convertBoolValueHandler(e.target.value));
                                changeDisabledAppearanceProblemDescription(convertBoolValueHandler(e.target.value));
                              }}>
                                    <FormControlLabel mt={1} value="Tak" control={<Radio />} label="Tak" />
                                    <FormControlLabel mt={1} value="Nie" control={<Radio />} label="Nie" />
                            </RadioGroup>
                    </FormControl>
                    <div>
                        <FormControl fullWidth sx={{pl: 6}}>
                          <Typography>Opis:</Typography>
                            <OutlinedInput
                              disabled={isDisabledAppearanceProblemDescription}
                              name="appearanceProblemDescription"
                              value={adapterInfo.appearanceProblemDescription}
                              onChange={(e) => adverseEventsProduodopaStore.adapterInfoOnChange(e.target.name, e.target.value)}/>
                        </FormControl>
                        <Line/>
                        <Typography sx={{fontSize: 14}} variant='subtitle1'>Czy adapter fiolki został sprawdzony po otrzymaniu?</Typography>
                        <FormControl fullWidth>
                                <RadioGroup
                                  name="isAdapterChecked"
                                  value={convertBoolValue(adapterInfo.isAdapterChecked)}
                                  onChange={(e) =>  {
                                    adverseEventsProduodopaStore.adapterInfoOnChange(e.target.name, convertBoolValueHandler(e.target.value));
                                  }}>
                                        <FormControlLabel mt={1} value="Tak" control={<Radio />} label="Tak" />
                                        <FormControlLabel mt={1} value="Nie" control={<Radio />} label="Nie" />
                                </RadioGroup>
                        </FormControl>
                    </div>
                </Grid>
                <Grid item sx={{border: '1px solid #9B9B9D'}} p={2} display='flex' flexDirection='column' gap={2}>
                       
                        <FormControl fullWidth>
                            <Typography sx={{fontSize: 14}} variant='subtitle1'>Jeśli problem został rozwiązany, w jaki sposób?</Typography>
                            <OutlinedInput
                              name="solution"
                              value={adapterInfo.solution}
                              onChange={(e) => adverseEventsProduodopaStore.adapterInfoOnChange(e.target.name, e.target.value)}/>
                        </FormControl>
                        <FormControl fullWidth>
                            <Typography sx={{fontSize: 14}} variant='subtitle1'>Opisz trudności funkcjonalne, jeśli dotyczy:</Typography>
                            <OutlinedInput
                              name="functionalDifficulty"
                              value={adapterInfo.functionalDifficulty}
                              onChange={(e) => adverseEventsProduodopaStore.adapterInfoOnChange(e.target.name, e.target.value)}/>
                        </FormControl>
                </Grid> 
            </Grid>
        </Grid>
    )
});

export default VialAdapterProduct;