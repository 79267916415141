import { Button, FormControl, Grid, MenuItem, Select, Typography } from "@mui/material"
import { observer } from "mobx-react-lite"

import { DatePickerComponent } from "../../../../shared/ui/DatePickerComponent"
import { useAdverseEventsProduodopaStore } from "../../../../../store/hooks";

const resultOptions = [ "Powrót do zdrowia", "W trakcie powrotu do zdrowia", "Brak powrotu do zdrowia", "Powrót do zdrowia z następstwami", "Zgon", "Nieznany"];
const causalRelationshipOptions = [ "Prawdopodobny", "Możliwy", "Prawdopodobnie nie", "Niezwiązany", "Nie zgłoszono", "Niemożliwy do oceny)"];

const EventElement = observer(({index}) => {
    const adverseEventsProduodopaStore = useAdverseEventsProduodopaStore()
    const {partTwoInfo} = adverseEventsProduodopaStore;
    const {events} = partTwoInfo;
    const event = events[index];
    const number = index + 1;

    return (<Grid container sx={{position: 'relative'}} display='flex' p={2} pt={6}>
          <Button onClick={adverseEventsProduodopaStore.deleteEvent.bind(null, index)} sx={{position: 'absolute', background: '#EF5350', color: 'white', padding: '0 8px', textTransform: 'lowercase', right: 16, top: 4}}>{`usuń zdarzenie ${number}`}</Button>
          <div className="subMenuHeaderSmall"><span>{`Zdarzenie ${number}:`}</span></div>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Data wystąpienia (objawy, oznaki i/lub diagnoza):</Typography>
            <DatePickerComponent
                disableFuture
                label="DD/MM/RRRR"
                allowSameDateSelection
                name="date"
                value={event.date}
                onChange={(value) => adverseEventsProduodopaStore.eventOnChange(index, "date", value)} />
           </Grid>
           <Grid item xs={12} mt={2}>
            <Typography variant="subtitle1">Data wystąpienia zdarzenia:</Typography>
            <DatePickerComponent
                disableFuture
                label="DD/MM/RRRR"
                allowSameDateSelection
                name="dateStart"
                value={event.dateStart}
                onChange={(value) => adverseEventsProduodopaStore.eventOnChange(index, "dateStart", value)} />
           </Grid>
           <Grid item xs={12} mt={2}>
            <Typography variant="subtitle1">Data zakończenia zdarzenia:</Typography>
            <DatePickerComponent
                disableFuture
                label="DD/MM/RRRR"
                allowSameDateSelection
                name="dateEnd"
                value={event.dateEnd}
                onChange={(value) => adverseEventsProduodopaStore.eventOnChange(index, "dateEnd", value)} />
           </Grid>
           <Grid item xs={12} mt={2}>
                <FormControl fullWidth required>
                    <Typography variant="subtitle1">Wynik:</Typography>
                    <Select
                        name="result"
                        value={event.result}
                        onChange={(e) => adverseEventsProduodopaStore.eventOnChange(index, e.target.name, e.target.value)}>
                           {resultOptions.map(option =>  <MenuItem key={option} value={option}>{option}</MenuItem>)}
                     </Select>
                </FormControl>
           </Grid>
           <Grid item xs={12} mt={2}>
                <FormControl fullWidth required>
                    <Typography variant="subtitle1">Związek przyczynowy z lekiem według osoby zgłaszającej:</Typography>
                    <Select
                        name="causalRelationship"
                        value={event.causalRelationship}
                        onChange={(e) => adverseEventsProduodopaStore.eventOnChange(index, e.target.name, e.target.value)}>

                           {causalRelationshipOptions.map(option => <MenuItem key={option} value={option}>{option}</MenuItem>)}
                        </Select>
                 </FormControl>
           </Grid>
        
    </Grid>)
});

export default EventElement