import React, {useContext, useEffect, useState} from 'react'
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import {PumpSubModal} from '../patients/PumpSubModal';
import PegSubModal from '../patients/PegSubModal';
import {PoiContext} from '../../../context/poi/poiContext';
import {PatientContext} from '../../../context/patient/patientContext';
import {patientModalValidate} from "../patients/patientModalValidate";
import tempImage from '../../../assets/img/ui/addPerson.png';
import GlobalLoader from '../../shared/ui/GlobalLoader';
import {DatePickerComponent} from "../../shared/ui/DatePickerComponent";
import {ADD_OPTION, EDIT_OPTION, SHOW_OPTION} from "../../../data/consts/modalOptions";


const ImplementationAdd = ({settlementPeriod, modalOption = ADD_OPTION, userID, setIsAdding}) => {

    const {setModalError} = useContext(PoiContext)
    const {
        getAdditionalPatientInfo,
        patientState,
        addPatient,
        getPatient,
        clearPatient,
        editPatient,
    } = useContext(PatientContext);


    const [patientInfo, setPatientInfo] = useState(null)
    const [pumps, setPumps] = useState([])
    const [pegs, setPegs] = useState([])

    useEffect(() => {
        getAdditionalPatientInfo();
        if (modalOption !== ADD_OPTION) {
            getPatient(userID);
        } else {
            setPatientInfo({
                displayName: '',
                nameInitial: '',
                surnameInitial: '',
                pesel: '',
                probeSerial: '',
                probeInstallDate: null,
                medicineInclusionDate: null,
                hospitalLeftDate: null,
                therapyReason: '',
                isDataProcessAgreement: false,
                institution: '',
                therapySuspensionReason: '',
                therapySuspensionStartDate: null,
                therapySuspensionEndDate: null,
                therapyLeftReason: '',
                therapyLeftDate: null,
                initiationDate: new Date(settlementPeriod)
            })
        }
        return () => {
            clearPatient()
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (patientState.patient) {
            setPatientInfo({...patientState.patient.info});
            setPumps([...patientState.patient.pumps]);
            setPegs([...patientState.patient.pegs]);
        }
    }, [patientState.patient])

    const changeInfoHandler = (name, value) => {

        if (name === 'pesel' && value.length > 4) return
        setPatientInfo(prev => ({...prev, [name]: value}))
    }

    const pumpsTableHandler = (name, value, el, index) => {
        const tempArray = [];
        pumps.forEach((el) => {
            el.isRunning = value && name === 'isRunning' ? false : el.isRunning;
            tempArray.push({...el})
        })
        tempArray[index] = {...el, [name]: value};
        setPumps([...tempArray])
    }

    const pegsTableHandler = (name, value, el, index) => {
        const tempArray = [];
        pegs.forEach(el => tempArray.push({...el}))
        tempArray[index] = {...el, [name]: value};
        setPegs([...tempArray])
    }

    const addPumpsTableRow = () => setPumps(prev => ([...prev, {
        serialNumber: '',
        releaseDate: null,
        isRunning: false,
    }]))

    const addPegsTableRow = () => setPegs(prev => [...prev, {
        serialNumber: '',
        installDate: null,
    }])

    const delPumpTableRow = (index) => setPumps(prev => ([...JSON.parse(JSON.stringify(prev.filter((el, i) => i !== index)))]))
    const delPegsTableRow = (index) => setPegs(prev => ([...JSON.parse(JSON.stringify(prev.filter((el, i) => i !== index)))]))

    const submitHandler = (e) => {
        e.preventDefault();

        const validation = patientModalValidate(patientInfo, pumps, pegs);

        if (validation.isError) {
            setModalError(validation.defRes);
        } else {
            const data = {
                ...patientInfo,
                pesel: `${patientInfo.pesel}`,
                institution: patientState.institutions && patientState.institutions[0].id,
                therapyReason: patientInfo.therapyReason.length === 0 ? null : patientInfo.therapyReason,
                therapySuspensionReason: patientInfo.therapySuspensionReason.length === 0 ? null : patientInfo.therapySuspensionReason,
                therapyLeftReason: patientInfo.therapyLeftReason.length === 0 ? null : patientInfo.therapyLeftReason,
                pumps,
                pegs,
                initiationDate: patientInfo.initiationDate
            }
            if (modalOption === ADD_OPTION) {
                addPatient(data);
            }
            if (modalOption === EDIT_OPTION) {
                editPatient(userID, data);
            }
            setIsAdding(false)
        }
    }
    return patientInfo ?
        <Grid item container className="settlements__DatePicker" direction="row" alignItems="center"
              mb={1}>
            <Box className="settlements__addBox">
                <Typography className="settlements__addTitle" sx={{
                    fontWeight: "bold",
                    maxWidth: "48rem",
                    borderBottom: "solid 2px #ccc"
                }}>Dodawanie nowego pacjenta</Typography>
                <Stack direction="row" sx={{marginTop: "1rem",}} spacing={4} alignItems="start">
                    <img src={tempImage} alt="" style={{marginTop: '1rem'}}/>
                    <form onSubmit={(e) => submitHandler(e)}>
                        <Grid container pb={4} justifyContent='space-between'>
                            <Grid item xs={12} lg={5.8} mt={2}>
                                <TextField name='nameInitial' label='IMIĘ (inicjały)' fullWidth required
                                           onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}/>
                            </Grid>
                            <Grid item xs={12} lg={5.8} mt={2}>
                                <TextField name='surnameInitial' label='NAZWISKO (inicjały)' fullWidth required
                                           onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}/>
                            </Grid>
                            <Grid container justifyContent='space-between'>
                                <Grid item xs={12} lg={5.8} mt={2}>
                                    <TextField name='pesel' label='4 ostatnie cyfry PESEL' fullWidth required
                                               type="number" inputProps={{maxLength: 4}}
                                               onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}
                                               value={patientInfo.pesel}/>
                                </Grid>
                                <Grid item xs={12} lg={5.8} mt={2}>
                                    {patientState.institutions &&
                                        <TextField name='institution' label='SZPITAL' fullWidth required disabled={true}
                                                   value={patientState.institutions[0].name}/>}
                                </Grid>
                            </Grid>
                            <Grid container mt={4} alignItems="center">
                                <PumpSubModal
                                    pumps={pumps}
                                    delPumpTableRow={delPumpTableRow}
                                    pumpsTableHandler={pumpsTableHandler}
                                    addPumpsTableRow={addPumpsTableRow}
                                    modalOption={modalOption}
                                />
                            </Grid>
                            <Grid container mt={4} alignItems="center">
                                <PegSubModal
                                    pegs={pegs}
                                    addPegsTableRow={addPegsTableRow}
                                    pegsTableHandler={pegsTableHandler}
                                    delPegsTableRow={delPegsTableRow}
                                    modalOption={modalOption}
                                />
                            </Grid>
                            <Grid container mt={2} justifyContent='space-between'>
                                <Grid item xs={12} lg={5.8} mt={2}>
                                    <TextField name='probeSerial' label='SONDA nr serii' fullWidth required
                                               onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}/>
                                </Grid>
                                <Grid item xs={12} lg={5.8} mt={2}>
                                    <DatePickerComponent
                                        allowSameDateSelection
                                        label='DATA założenia sondy'
                                        disableFuture
                                        value={patientInfo.probeInstallDate}
                                        onChange={(newValue) => changeInfoHandler('probeInstallDate', newValue)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent='space-between'>
                                <Grid item xs={12} lg={5.8} mt={2}>
                                    <DatePickerComponent
                                        allowSameDateSelection
                                        label='DATA włączenia leku'
                                        disableFuture
                                        value={patientInfo.medicineInclusionDate}
                                        onChange={(newValue) => changeInfoHandler('medicineInclusionDate', newValue)}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={5.8} mt={2}>
                                    <DatePickerComponent
                                        allowSameDateSelection
                                        label='DATA wypisu ze szpitala'
                                        disableFuture
                                        value={patientInfo.hospitalLeftDate}
                                        onChange={(newValue) => changeInfoHandler('hospitalLeftDate', newValue)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={5.8} mt={2}>
                                <FormControl fullWidth>
                                    <InputLabel id="therapySuspensionReason-label">Przyczyna włączenia do
                                        terapii:</InputLabel>
                                    <Select
                                        labelId="therapySuspensionReason-label"
                                        name="therapySuspensionReason"
                                        value={patientInfo.therapySuspensionReason}
                                        disabled={modalOption === SHOW_OPTION}
                                        label="Przyczyna włączenia do terapii:"
                                        onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}
                                    >
                                        <MenuItem value="" disabled={true}><em>Wybierz z listy</em></MenuItem>
                                        {
                                            patientState.therapySuspensionReasons
                                            && patientState.therapySuspensionReasons.map((el, index) =>
                                                <MenuItem
                                                    key={index}
                                                    value={el}
                                                >
                                                    {el}
                                                </MenuItem>,
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item mt={3}>
                                <FormControlLabel control={
                                    <Checkbox sx={{padding: 0, marginRight: 2}}
                                              name="isDataProcessAgreement"
                                              checked={patientInfo.isDataProcessAgreement}
                                              onChange={(e) => changeInfoHandler(e.target.name, e.target.checked)}
                                    />}
                                                  label="Czy przekazano informacje o przetwarzaniu danych osobowych dla pacjenta/opiekuna?"/>
                            </Grid>
                        </Grid>
                        <Stack sx={{borderTop: "solid 1px #ccc", width: "42rem", height: "5rem", margin: "auto"}}>
                            <Button type="submit" variant="contained" sx={{
                                margin: "auto",
                                backgroundColor: "#228B21"
                            }}>zapisz</Button>
                        </Stack>
                    </form>
                </Stack>
            </Box>
        </Grid>
        :
        <GlobalLoader/>
};

export default ImplementationAdd
