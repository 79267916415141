export const headCells = [
    {id: 'id', label: 'LP'},
    {id: 'name', label: 'Nr. placówki'},
    {id: 'identifier', label: 'Nazwa'},
    {id: 'address', label: 'Adres'},
    {id: 'zipCode', label: 'Kod pocztowy'},
    {id: 'voivodeship', label: 'Województwo'},
    {id: 'city', label: 'Miasto'},
    {id: 'users', label: 'Użytkownicy'},
    {id: 'actions', label: 'Działania'},
];

export const hospitalDataInit = {
    identifier: '',
    name: '',
    address: '',
    delivery: '',
    zipCode: '',
    voivodeship: [],
    city: '',
    roles: [],
};
