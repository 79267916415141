import React, {useContext, useState, useEffect} from 'react';
import {Button, Grid, Stack, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {AppContext} from '../../../context/app/appContext';
import {roleNormaliser} from '../../../utils/roleNormalizer';
import ProfileEditModal from './ProfileEditModal';
import {InfoElement} from "../../shared/ui/infoElements/InfoElement";
import {useModalController} from "../../../hooks/useModalController";

const Profile = () => {
    const {appState} = useContext(AppContext);
    const {getProfileInfoForUser} = useContext(AppContext)

    const {
        modalInfo,
        openModal,
        closeModal
    } = useModalController();

    const [profileData, setProfileData] = useState('',)

    useEffect(() => {
        getProfileInfoForUser()
// eslint-disable-next-line
    }, [],)

    useEffect(() => {
        if (appState.showProfileInfoForUser) {
            setProfileData({...appState.showProfileInfoForUser})
        }
    }, [appState.showProfileInfoForUser])

    const navigate = useNavigate();

    return (<div className="profile">

        <Grid sx={{display: 'flex', flexDirection: 'column'}}>
            <Grid item xs={6} bgcolor="#071d49" p={'10px 20px'}>
                <Typography variant="h6" color="white">Profil użytkownika</Typography>
            </Grid>
            <Grid item xs={6} lg={6}>
                <Grid container>
                    <Grid border={'1px solid #071d49'} item xs={12} lg={6} p={2}>
                        <Stack spacing={1}>
                            <InfoElement title='Imię:' value={profileData.firstName} titleMinWidth='115px'/>
                            <InfoElement title='Nazwisko:' value={profileData.secondName} titleMinWidth='115px'/>
                            <InfoElement title='Telefon:' value={profileData.phoneNumber} titleMinWidth='115px'/>
                            <InfoElement title='Adres email:' value={profileData.email} titleMinWidth='115px'/>
                            <InfoElement title='Konto bankowe:' value={profileData.bankAccount} titleMinWidth='115px'/>
                            {
                                profileData.zipCode || profileData.city || profileData.street ? (
                                        <InfoElement title='Dane adresowe:'
                                                     value={`${profileData.zipCode ? profileData.zipCode + ', ' : ''}${profileData.city ? profileData.city + ', ' : ''}${profileData.street ? profileData.street : ''}`}
                                                     titleMinWidth='115px'/>
                                    )
                                    : null
                            }
                        </Stack>
                    </Grid>
                    <Grid item border={'1px solid #071d49'} borderTop={{xs: 'none', lg: '1px solid #071d49'}}
                          borderLeft={{xs: '1px solid #071d49', lg: 'none'}} xs={12} lg={6} p={2}>
                        <Stack spacing={1}>
                            <InfoElement title='Rola:' value={roleNormaliser(profileData.role)} titleMinWidth='115px'/>
                            <InfoElement title='Typ:' value={profileData.type} titleMinWidth='115px'/>
                            <InfoElement title='Identyfikator:' value={profileData.id} titleMinWidth='115px'/>
                            <Grid container flexDirection="row" alignItems="center">
                                <Typography variant="subtitle1" minWidth='115px' mr={2}>Hasło:</Typography>
                                <Button
                                    variant={'contained'}
                                    onClick={() => navigate('/changePassword')}
                                >
                                    Reset hasła
                                </Button>
                            </Grid>
                            {profileData.institutions && (
                                <InfoElement
                                    title={profileData.institutions.length > 1 ? 'Placówki:' : 'Placówka:'}
                                    value={profileData.institutions.map((institution, index) => `${institution.name}${index === profileData.institutions.length - 1 ? '' : ', '}`)}
                                    titleMinWidth='115px'
                                />
                            )
                            }
                            {
                                profileData.language && (
                                    <InfoElement
                                        title={profileData.language.length > 1 ? 'Języki:' : 'Język:'}
                                        value={profileData.language.map((language, index) => `${language}${index === profileData.language.length - 1 ? '' : ', '}`)}
                                        titleMinWidth='115px'
                                    />
                                )
                            }
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid container justifyContent="center" direction="column" alignItems="center">
            <Typography variant="h6" mb={2} mt={2}>Edycja użytkownika</Typography>
            <Button onClick={() => openModal('edit')} variant="contained" color="primary" type="submit"
            >Edytuj</Button>
        </Grid>
        {modalInfo.isOpen && <ProfileEditModal
            openModal={openModal}
            modalOption={modalInfo.option}
            closeModal={closeModal}
            userID={profileData.id}
        />}
    </div>)
}

export default Profile;