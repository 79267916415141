export default function rejectFormValidate(comment) {
    if (comment.trim().length === 0) {
        return ({
            isError: true,
            defRes: {
                data: {
                    code: 422,
                    message: 'Dodaj komentarz',
                },
            },
        })
    }
    return ({
        isError: false,
        defRes: null,
    })
};