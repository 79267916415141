import React from 'react';
import {Circles} from 'react-loader-spinner';

const LocalLoader = () => {

    return (
        <div className="loader">
            <Circles
                color={'#071d49'}
                width={60}
                height={60}
            />
        </div>
    )
}

export default LocalLoader;