import React, { useContext, useEffect, useState } from 'react';
import { SettlementsContext } from '../../../context/settlements/settlementsContext';
import { Button, Grid, Stack, Typography } from '@mui/material';
import avatar from '../../../assets/img/ui/ADD_XL.svg';
import SearchIcon from '@mui/icons-material/Search';
import ImplementationPart from './ImplementationPart';
import { AuthContext } from '../../../context/auth/authContext';
import { CLASS_BLUE, CLASS_GRAYDISABLED, CLASS_GREEN } from './settlementsMonthTable/helpers';
import { ROLE_ADMIN } from '../../../data/consts/users';
import {EditPatientModal} from "./editPatientModal/EditPatientModal";
import {useModalController} from "../../../hooks/useModalController";
import { ACCEPTED_STATUS, CLOSED_STATUS } from '../../../data/consts/settlementsStatuses';

export const ImplementationPatient = ({ settlementPeriod, reckoningStatus }) => {
	const {settlementsState, acceptProcedure} = useContext(SettlementsContext);
	const {authState: {id: userID}, role, hasCoworkers} = useContext(AuthContext)
	const [patients, setPatients] = useState([])
	const [patientEdit, setPatientEdit] = useState(null)
	const [openPatientEducation, setOpenPatientEducation] = useState(null);
	const [openPatientSupport, setOpenPatientSupport] = useState(null);
	const {modalInfo, openModal, closeModal} = useModalController();

	useEffect(() => {
		setPatients([...settlementsState.implementationPatients].sort((a, b) => b.id - a.id))
	}, [settlementsState])

	const handleEdit = (patient) => {
		openModal();
		setPatientEdit(patient)
	}

	return <>{patients.length && settlementsState.makers !== null ?
		patients.map(el => {
				if (!el.procedures) return null
				const status1 = el.procedures[0].statusSlug;
				const status2 = el.procedures[1].statusSlug;
				const isAuthor1 = (userID === el.procedures[0].createdBy || el.procedures[0].createdBy === null);
				const isAuthor2 = (userID === el.procedures[1].createdBy || el.procedures[1].createdBy === null);
				const statusColor1 = status1 === 'accepted' ? 'green' : status1 === 'waiting' ? 'orange' : 'red';
				const statusColor2 = status2 === 'accepted' ? 'green' : status2 === 'waiting' ? 'orange' : 'red';
				const buttonDisabled1 = ['waiting', 'accepted', 'closed'].includes(reckoningStatus) && ['not completed', 'waiting'].includes(status1);
				const buttonDisabled2 = ['waiting', 'accepted', 'closed'].includes(reckoningStatus) && ['not completed', 'waiting'].includes(status2);
				const buttonContent1 = ((status1 === 'accepted' && (hasCoworkers || !['draft', 'rejected'].includes(reckoningStatus))) || (!isAuthor1 && status1 === 'waiting')) ?
					<SearchIcon/> : status1 === 'waiting' || (status1 === 'accepted' && !hasCoworkers) ? 'edytuj' : 'dodaj';
				const buttonContent2 = ((status2 === 'accepted' && (hasCoworkers || !['draft', 'rejected'].includes(reckoningStatus))) || (!isAuthor2 && status2 === 'waiting')) ?
					<SearchIcon/> : status2 === 'waiting' || (status2 === 'accepted' && !hasCoworkers) ? 'edytuj' : 'dodaj';
				const buttonColor1 = buttonDisabled1 ? CLASS_GRAYDISABLED : status1 === 'not completed' ? CLASS_GREEN : CLASS_BLUE
				const buttonColor2 = buttonDisabled2 ? CLASS_GRAYDISABLED : status2 === 'not completed' ? CLASS_GREEN : CLASS_BLUE

				const procedure1MakersIds = el.procedures[0].makers;
				const procedure1MakersNames = []

				if (procedure1MakersIds.length !== 0 && settlementsState.makers !== null && settlementsState.makers.length !== 0) {
					procedure1MakersIds.forEach(id => {
						const maker = settlementsState.makers.find(item => item.id === id)
						if (maker) procedure1MakersNames.push(maker.name)
					})
				}
				const procedure2MakersIds = el.procedures[1].makers;
				const procedure2MakersNames = []

				if (procedure2MakersIds.length !== 0 && settlementsState.makers !== null && settlementsState.makers.length !== 0) {
					procedure2MakersIds.forEach(id => {
						const maker = settlementsState.makers.find(item => item.id === id)
						if (maker) procedure2MakersNames.push(maker.name)
					})
				}

				// const makers1 = el.procedures[0].makers.map((id) => settlementsState.makers.find(item => item.id === id).name).join(', ');
				// const makers2 = el.procedures[1].makers.map((id) => settlementsState.makers.find(item => item.id === id).name).join(', ');

				const isEditable1 = role !== ROLE_ADMIN && ['draft', 'rejected', undefined].includes(reckoningStatus) && (['not completed'].includes(status1) || (isAuthor1 && status1 === 'waiting') || (status1 === 'accepted' && !hasCoworkers));
				const isEditable2 = role !== ROLE_ADMIN && ['draft', 'rejected', undefined].includes(reckoningStatus) && (['not completed'].includes(status2) || (isAuthor2 && status2 === 'waiting') || (status2 === 'accepted' && !hasCoworkers));

				return <Grid container key={el.id} p={1}>
					{/*PATIENT TITLE*/}
					<Grid
						item
						xs={12}
						container
						display="row"
						wrap="nowrap"
						bgcolor="#f5f5f5"
						p={'30px 0px 40px 70px'}

					>
						<Grid item pr={'45px'}>
							<img src={avatar} alt="ava" className="settlements__blockImage_XL"/>
						</Grid>
						<Grid item ml={1} container justifyContent={'flex-start'}>
							<Grid item xs={12} lg={5}>
								<Stack className="settlements__status" direction="column">
									<Typography> <b>Inicjały:</b></Typography>
									<Typography>{el.name}</Typography>
								</Stack>
								<Stack className="settlements__status" direction="column">
									<Typography><b>Szpital:</b></Typography>
									<Typography>{el.institution}</Typography>
								</Stack>
								{![ACCEPTED_STATUS, CLOSED_STATUS].includes(reckoningStatus) &&
									<Grid container>
										<Grid item xs={10} className="settlements__separator">
											<Button
												color="primary" variant="contained"
												style={{
													color: 'white'
												}}
												onClick={() => handleEdit(el)}>Edycja</Button>
										</Grid>
									</Grid>
								}
							</Grid>
							<Grid item container xs={7} lg={7}>
								{/* Aktywności edukacyjno-szkoleniowe */}
								<Grid item container>
									<Grid item lg={7} className="settlements__status">
										<Typography>Status <b>Aktywności edukacyjno-szkoleniowe:</b></Typography>
										<Typography className="settlements__firstLetter"
										            sx={{ color: statusColor1 }}>{el.procedures[0].status}</Typography>
									</Grid>
									<Grid item lg={5} className="settlements__status"
									      style={{ visibility: procedure1MakersNames.length ? 'visible' : 'hidden' }}>
										<Typography>Wykonujcy Aktywności:</Typography>
										<Typography><i><b>{procedure1MakersNames.join(', ')}</b></i><br/></Typography>
									</Grid>
								</Grid>
								{/* Wsparcie merytoryczne dla personelu: */}
								<Grid item container>
									<Grid item lg={7} className="settlements__status">
										<Typography>Status <b>Wsparcie merytoryczne dla personelu:</b></Typography>
										<Typography className="settlements__firstLetter"
										            sx={{ color: statusColor2 }}>{el.procedures[1].status}</Typography>
									</Grid>
									<Grid item lg={5} className="settlements__status"
									      style={{ visibility: procedure2MakersNames.length ? 'visible' : 'hidden', }}>
										<Typography>Wykonujcy Wsparcie:</Typography>
										<Typography><i><b>{procedure2MakersNames.join(', ')}</b></i></Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={8} lg={4}>
								<Typography className="settlements__actionButton__header">Aktywności
									edukacyjno-szkoleniowe:</Typography>
								<Stack direction="row" spacing={5}>
									{!el.procedures[0].accepted && el.procedures[0].id &&
										<Button
											onClick={() => acceptProcedure(el.procedures[0].id)}
											className={CLASS_GREEN} variant="contained">
											akceptuj
										</Button>}
									<Button className={buttonColor1} variant="contained"
									        onClick={() => {
										        setOpenPatientSupport(null);
										        openPatientEducation === el.id ? setOpenPatientEducation(null) : setOpenPatientEducation(el.id)
									        }}>
										{buttonContent1}
									</Button>
								</Stack>
							</Grid>
							<Grid item lg={1}></Grid>
							<Grid item xs={8} lg={4}>
								<Typography className="settlements__actionButton__header">Wsparcie merytoryczne dla
									personelu:</Typography>
								<Stack direction="row" spacing={5}>
									{!el.procedures[1].accepted && el.procedures[1].id &&
										<Button onClick={() => acceptProcedure(el.procedures[1].id)} className={CLASS_GREEN}
										        variant="contained">akceptuj</Button>}
									<Button className={buttonColor2} variant="contained"
									        onClick={() => {
										        setOpenPatientEducation(null);
										        openPatientSupport === el.id ? setOpenPatientSupport(null) : setOpenPatientSupport(el.id)
									        }}>
										{buttonContent2}
									</Button>
								</Stack>
							</Grid>
						</Grid>
					</Grid>
					{/*PATIENT INFO*/}
					{
						openPatientEducation === el.id &&
						<ImplementationPart procedure={el.procedures[0]} settlementPeriod={settlementPeriod} form={0}
						                    closeForm={setOpenPatientEducation} isEditable={isEditable1} patientId={el.id}/>
					}
					{
						openPatientSupport === el.id &&
						<ImplementationPart procedure={el.procedures[1]} settlementPeriod={settlementPeriod} form={1}
						                    closeForm={setOpenPatientSupport} isEditable={isEditable2} patientId={el.id}/>
					}
				</Grid>
			}
		)
		:
		<Grid container alignItems="center" justifyContent="center" p={2}>
			<Typography>NO DATA</Typography>
		</Grid>}
		{modalInfo.isOpen && <EditPatientModal patientData={patientEdit} closeModal={closeModal}/>}
		</>
}