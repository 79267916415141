import React from "react";
import logo from '../../assets/img/logo.svg';

const Header = () => {
    return <div className='header'>
        <a className='header__link' href='/'>
            <img className='header__logo' src={logo} alt='logo'/>
        </a>
    </div>
}

export default Header;