import React from 'react';
import {Grid} from '@mui/material';
import TreatmentPart from './TreatmentPart';

const TreatmentInfo = ({treatment, settlementPeriod, closeForm, isEditable}) => {
    return (
        <Grid container border='1px solid #f5f5f5' alignItems='flex-start'>
            <TreatmentPart treatment={treatment.procedures[0]} treatmentId={treatment.id}
                           settlementPeriod={settlementPeriod} closeForm={closeForm} isEditable={isEditable}/>
        </Grid>
    )
}

export default TreatmentInfo;