import React, {useContext, useEffect, useState} from 'react'
import {
    Box,
    Button,
    Grid,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    ThemeProvider,
    Typography
} from '@mui/material';
import {styled} from '@mui/material/styles';
import InfoBanner from "../infoElements/infoBanner"
import {AuthContext} from "../../../../context/auth/authContext";
import {ReckoningsContext} from "../../../../context/reckonings/reckoningsContext";
import {PoiContext} from '../../../../context/poi/poiContext';
import SummaryAcceptConfirm from '../infoElements/SummaryAcceptConfirm';
import {useParams} from 'react-router-dom';
import SummaryApproveConfirm from '../infoElements/SummaryApproveConfirm';
import SummaryRejectConfim from '../infoElements/SummaryRejectConfim';
import RejectForm from '../infoElements/RejectForm';
import {ModalApprove} from "../../admin/reckonings/ModalApprove";
import {ROLE_ADMIN, ROLE_COORD_ADS} from "../../../../data/consts/users";
import {text} from "./helpers";
import {colors, StyledTableCell, StyledTableRow, theme} from "./summaryStyles";

const Summary = ({reckoningsState: {reckoning, reckoningAccepted, reckoningRejected, reckoningHandled}}) => {

    const params = useParams();
    const adsID = params.adsID
    const settlementPeriod = params.yearMonth;
    const {userId, role} = useContext(AuthContext);
    const {isGlobalError} = useContext(PoiContext);
    const {getReckoning} = useContext(ReckoningsContext);
    const {
        sendReckoning,
        confirmReckoning,
        approveReckoning,
        rejectReckoning
    } = useContext(ReckoningsContext);


    const [reckoningUserRole, setReckoningUserRole] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalApprove, setModalApprove] = useState(false);
    const [comment, setComment] = useState('')
    const [error, setError] = useState(false);

    useEffect(() => {
        if (role === ROLE_ADMIN && adsID) {
            getReckoning(settlementPeriod, adsID);
        } else {
            getReckoning(settlementPeriod, userId)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!reckoningUserRole && !!reckoning?.userRole) {
            setReckoningUserRole(reckoning?.userRole);
        }
    }, [reckoningUserRole, reckoning]);

    useEffect(() => {
        setError(isGlobalError)
    }, [isGlobalError])

    const handleClick = () => {
        sendReckoning(reckoning.reckoningId);
    }

    const confirmReckoningSent = () => {
        getReckoning(settlementPeriod, userId);
        confirmReckoning();
    };

    const approveReckoningHandler = () => approveReckoning(reckoning.reckoningId, settlementPeriod)

    const StyledGrid = styled(Grid)(({theme}) => ({
        [theme.breakpoints.down('lgx')]: {
            marginTop: '40px'
        },
    }));

    const StyledFooter = styled(Grid)(({theme}) => ({
        [theme.breakpoints.down('lgx')]: {
            justifyContent: 'center'
        },
    }));


    if (!reckoning?.initiations) return (
        <Grid container alignItems="center" justifyContent="center" p={2}>
            <Typography>Brak danych</Typography>
        </Grid>)

    const canSubmit = reckoning.status.includes('draft') || reckoning.status.includes('reject')

    const rows = [
        {head: 'Wdrożenia', body: reckoning.initiations.records || [], sume: reckoning.initiations.sume},
        {head: 'Utrzymanie', body: reckoning.treatments.records || [], sume: reckoning.treatments.sume},
        {head: 'Szkolenia', body: reckoning.trainings.records || [], sume: reckoning.trainings.sume},
        {head: 'Nadgodziny', body: reckoning.extraWorkTimes.records || [], sume: reckoning.extraWorkTimes.sume},
    ];

    if (reckoningUserRole === ROLE_COORD_ADS) {
        rows.push({head: 'Coaching', body: reckoning.coachings.records || [], sume: reckoning.coachings.sume});
        rows.push({
            head: 'Inne Aktywnosci',
            body: reckoning.otherActivities.records || [],
            sume: reckoning.otherActivities.sume
        });
    }

    const modalApproveToggler = () => setModalApprove(prev => !prev);

    return (
        <Grid item container className="settlements__DatePicker" direction="row" alignItems="center"
              mb={1}>
            {
                modal &&
                <RejectForm
                    submitFunction={rejectReckoning}
                    activityID={reckoning.reckoningId}
                    setModal={setModal}
                    comment={comment}
                    setComment={setComment}
                    isSettlementRejectByAdmin={true}
                    relatedReckonings={reckoning.relatedReckonings || []}
                >
                    Wpisz uwagi i poprawki do rozliczenia:
                </RejectForm>
            }
            {
                modalApprove &&
                <ModalApprove
                    closeModal={modalApproveToggler}
                    relatedReckonings={reckoning.relatedReckonings || []}
                    approveReckoningHandler={approveReckoningHandler}
                />
            }

            {reckoningAccepted && role !== ROLE_ADMIN && <SummaryAcceptConfirm clearFunction={confirmReckoningSent}/>}
            {reckoningAccepted && role === ROLE_ADMIN && <SummaryApproveConfirm clearFunction={confirmReckoning}/>}
            {reckoningRejected && role === ROLE_ADMIN && <SummaryRejectConfim clearFunction={confirmReckoning}/>}

            <Box className="settlements__addBox" sx={{
                maxWidth: rows.length === 4 ? '1400px' : '1600px',
                width: '100%'
            }}>
                <Typography
                    className="settlements__addTitle"
                    sx={{
                        fontWeight: "bold",
                        maxWidth: "48rem",
                        borderBottom: "solid 2px #ccc"
                    }}
                >
                    Podsumowanie
                </Typography>
                <Grid>
                    <Grid container justifyContent='space-between' mb={5} sx={{width: '100%'}}>
                        <ThemeProvider theme={theme}>
                            {rows.slice(0, rows.length / 2).map((row, index) =>
                                <Grid item key={index} xs={rows.length === 4 ? 5.97 : 3.97}
                                      lgx={rows.length === 4 ? 2.97 : 1.97}
                                      mt={3}>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell
                                                        style={{backgroundColor: `${colors[index]}`}}>{row.head}</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {row.body.map((row, i) => (
                                                    <StyledTableRow key={i} style={{marginTop: '2px'}}>
                                                        <StyledTableCell>
                                                            <Stack direction='row' ml={0.5}
                                                                   sx={{justifyContent: 'space-between'}}>
                                                                <p>{row.record}</p>
                                                                <p>{Math.round(row.amount * 100) / 100} PLN</p></Stack>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>)}
                            {rows.slice(0, rows.length / 2).map((row, index) =>
                                <Grid item key={index} xs={rows.length === 4 ? 5.97 : 3.97}
                                      lgx={rows.length === 4 ? 2.97 : 1.97}
                                      className="settlements__hide__over1500">
                                    <TableContainer>
                                        <Table>
                                            <TableFooter>
                                                <TableRow>
                                                    <StyledTableCell style={{backgroundColor: `${colors[index]}`}}>
                                                        <Stack ml={0.5} direction='row'
                                                               sx={{justifyContent: 'space-between'}}>
                                                            <p>Suma:</p><p>{row.sume || 0}</p></Stack>
                                                    </StyledTableCell>
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </TableContainer>
                                </Grid>)}
                            {rows.slice(rows.length / 2, rows.length).map((row, index) =>
                                <StyledGrid item key={index} xs={rows.length === 4 ? 5.97 : 3.97}
                                            lgx={rows.length === 4 ? 2.97 : 1.97} mt={3}>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell
                                                        style={{backgroundColor: `${colors[index + rows.length / 2]}`}}>{row.head}</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {row.body.map((row, i) => (
                                                    <StyledTableRow key={i} style={{marginTop: '2px'}}>
                                                        <StyledTableCell>
                                                            <Stack ml={0.5} direction='row'
                                                                   sx={{justifyContent: 'space-between'}}>
                                                                <p>{row.record}</p>
                                                                <p>{Math.round(row.amount * 100) / 100} PLN</p></Stack>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </StyledGrid>)}
                            {rows.slice(rows.length / 2, rows.length).map((row, index) =>
                                <Grid item key={index} xs={rows.length === 4 ? 5.97 : 3.97}
                                      lgx={rows.length === 4 ? 1.97 : 2.97}
                                      className="settlements__hide__over1500">
                                    <TableContainer>
                                        <Table>
                                            <TableFooter>
                                                <TableRow>
                                                    <StyledTableCell
                                                        style={{backgroundColor: `${colors[index + rows.length / 2]}`}}>
                                                        <Stack ml={0.5} direction='row'
                                                               sx={{justifyContent: 'space-between'}}>
                                                            <p>Suma:</p><p>{row.sume || 0}</p></Stack>
                                                    </StyledTableCell>
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </TableContainer>
                                </Grid>)}
                            {rows.map((row, index) =>
                                <Grid item key={index} xs={6} lgx={rows.length === 4 ? 2.97 : 1.97}
                                      className="settlements__hide__below1500">
                                    <TableContainer>
                                        <Table>
                                            <TableFooter>
                                                <TableRow>
                                                    <StyledTableCell style={{backgroundColor: `${colors[index]}`}}>
                                                        <Stack ml={0.5} direction='row'
                                                               sx={{justifyContent: 'space-between'}}>
                                                            <p>Suma:</p><p>{row.sume || 0}</p></Stack>
                                                    </StyledTableCell>
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </TableContainer>
                                </Grid>)}
                        </ThemeProvider>
                        <Box mt={0.3} sx={{
                            bgcolor: '#071D49',
                            width: '100%',
                            textAlign: 'end',
                            color: 'white',
                            padding: '12px 20px'
                        }}>
                            <Typography className="settlements__summary__footer" sx={{fontWeight: '500'}}><span
                                style={{marginRight: '15px'}}>ŁĄCZNIE:</span> {(Math.round(reckoning.sume * 100) / 100).toFixed(2)} PLN</Typography>
                        </Box>
                    </Grid>

                    {role !== ROLE_ADMIN && modal !== 'closed' && canSubmit &&
                        <ThemeProvider theme={theme}>
                            <StyledFooter container sx={{justifyContent: 'space-between'}}>
                                <Grid lgx={4.5}></Grid>
                                <Grid lgx={2} mt={2}>
                                    <Button type="submit" onClick={() => {
                                        handleClick();
                                    }} variant="contained" style={{
                                        minWidth: '10.2rem',
                                        width: '100%',
                                        backgroundColor: 'green'
                                    }}>
                                        WYŚLIJ ROZLICZENIE
                                    </Button>
                                </Grid>
                                <Grid lgx={4.5} mt={2}>
                                    <InfoBanner text={text} noSlice>UWAGI DO ROZLICZNIA:</InfoBanner>
                                </Grid>
                            </StyledFooter>
                        </ThemeProvider>}
                    {role === ROLE_ADMIN && reckoning.status.includes('waiting') && !reckoningHandled &&
                        <Box>
                            {!error &&
                                <Stack direction='row' sx={{
                                    maxWidth: '14rem',
                                    margin: 'auto',
                                    justifyContent: 'space-between'
                                }}>
                                    <Button
                                        onClick={() => {
                                            if (reckoning.relatedReckonings.length > 0) {
                                                modalApproveToggler();
                                            } else {
                                                approveReckoningHandler();
                                            }
                                        }}
                                        sx={{
                                            maxWidth: '6rem',
                                            width: '100%',
                                            backgroundColor: '#228b21'
                                        }}
                                        variant="contained"
                                    >
                                        akceptuj
                                    </Button>
                                    <Button
                                        onClick={() => setModal(true)}
                                        sx={{
                                            maxWidth: '6rem',
                                            width: '100%',
                                            backgroundColor: '#D32F2F'
                                        }}
                                        variant="contained"
                                    >
                                        odrzuć
                                    </Button>
                                </Stack>}
                        </Box>}
                </Grid>
            </Box>
        </Grid>
    )
};

export default Summary
