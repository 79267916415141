import {ROLE_ADS, ROLE_COORD_ADS} from "../../../../data/consts/users";

export const initDeleteModalObject = {isOpen: false, id: null, title: null};
export const datePickerData = [{
    label: "Wybierz datę do kiedy użytkownicy muszą uzyskać certyfikat",
    name: 'datePass'
}, {
    label: "Wybierz datę kiedy certyfikat utraci swoją ważność",
    name: 'dateValid'
}]

export const ROLES_ARRAY = [
    {
        value: ROLE_ADS,
        name: 'ADS',
    },
    {
        value: ROLE_COORD_ADS,
        name: 'Koordynator ADS',
    },
]

const generateValidationObject = (message) => {
    return {
        isError: true, defRes: {
            data: {
                code: 422, message: message,
            },
        },
    }
}
export const certificateModalValidate = (files, formValue, roles, isEdit) => {

    if (files && files.length === 0) {
        return generateValidationObject('Dodaj plik z certyfikatem.');
    }

    if (!formValue.datePass || !formValue.dateValid) {
        return generateValidationObject('Uzupełnij daty.');
    }

    if (formValue.dateValid <= formValue.datePass) {
        return generateValidationObject('Data uzyskania nie może być większa niż datą ważności.');
    }

    if (!roles.length) {
        return generateValidationObject('Przypisz role użytkowników do certyfikatu.');
    }

    return ({
        isError: false, defRes: null,
    })
}