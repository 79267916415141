import React, {useContext, useEffect} from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button } from '@mui/material';

import PageTitle from '../../../shared/ui/PageTitle';
import PartOne from './PartOne';
import PartTwo from './PartTwo';
import MainInfo from './MainInfo';
import { useAdverseEventsProduodopaStore } from '../../../../store/hooks';
import { PoiContext } from '../../../../context/poi/poiContext';
import Clauses from './clauses/Clauses';
import { useAdverseEventsApi } from '../../../../apiHooks/useAdverseEventsApi';
import file from '../../../../assets/files/szablon-produodopa.pdf'; 

const AdverseEventsProduodopa = observer(() => {
    const {setModalError} = useContext(PoiContext);
    const {postProduodopaForm, getFormSetProduodopaForm} = useAdverseEventsApi();
    const {mainInfo, complaintInfo, doctorInfo, eventInfo, drugInfo, pumpInfo, infusionInfo, adapterInfo, syringeInfo, accessoriesInfo, sparePartInfo, partTwoInfo, formSet} = useAdverseEventsProduodopaStore()

    useEffect(() => {
      if(formSet === null){
        void getFormSetProduodopaForm();
      }
    }, [formSet, getFormSetProduodopaForm])

    const onSubmit = async () => {
        const data = {
            ...mainInfo,
            complaintInformation: complaintInfo,
            doctorInformation: doctorInfo,
            eventInformation: eventInfo,
            drugInformation: drugInfo,
            pumpInformation: pumpInfo,
            infusionInformation: infusionInfo,
            adapterInformation: adapterInfo,
            syringeInformation: syringeInfo,
            accessoriesInformation: accessoriesInfo,
            sparePartInformation: sparePartInfo,
            ...partTwoInfo
        }
        const isSuccess = await postProduodopaForm(data);
        
        if(isSuccess) {
            let defRes = {
                data: {
                    code: 200,
                    message: 'Formularz został przesłany prawidłowo',
                },
              }
            setModalError(defRes);
        }
    }

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = file;
        link.download = 'szablon-produodopa.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <form className="adverseEvents" position='relative'>
            <Button onClick={handleDownload} size="medium" color='secondary' 
                sx={{
                    background: '#30A6A6', 
                    color: 'white', 
                    position: 'absolute',
                    right: 27,
                    top: 70,
                    width: 160,
                    zIndex: 100,
                    '&:hover': {
                        background: '#30A6A6',
                        opacity: 0.9
                    }}}>Pobierz szablon</Button>
            <Box width={'calc(100% - 200px)'}>
                <PageTitle 
                    title="Formularz zgłaszania reklamacji i zdarzenia niepożądanego leku Produodopa:"/>
            </Box>
            {formSet && <MainInfo />}
            {mainInfo.complaintTypes.includes('Reklamacja') && <PartOne />}
            {mainInfo.complaintTypes.includes('Zdarzenie') && <PartTwo/>}
            {formSet  && <><Clauses/>
            <Box mt={4} mb={2}>
                <Button size="large" onClick={onSubmit} color='secondary' 
                    sx={{
                        background: '#30A6A6', 
                        color: 'white', 
                        px: 2,
                        margin: 'auto',
                        display: 'block',
                        '&:hover': {
                            background: '#30A6A6',
                            opacity: 0.9
                        }}}>Wyślij</Button>
            </Box></>}
        </form>
    )
});

export default AdverseEventsProduodopa;
