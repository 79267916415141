import React, {useCallback, useContext, useReducer} from 'react';
import {settlementsReducer} from './settlementsReducer';
import {SettlementsContext} from './settlementsContext';
import {PoiContext} from '../poi/poiContext';
import axios from '../../axios/axios';
import multipleAxios from 'axios';
import {AuthContext} from '../auth/authContext';
import {
    CLEAR_SETTLEMENTS_STATE,
    GET_SETTLEMENTS_DATES,
    GET_SETTLEMENTS_INFO,
    GET_SETTLEMENTS_LIST,
    SET_GLOBAL_SETTLEMENT_PERIOD,
    SETTLEMENT_COMPLETED,
    SETTLEMENT_CONFIRMED,
} from '../types';
import {AppContext} from '../app/appContext';
import {ROLE_ADMIN, ROLE_ADS, ROLE_COORD_ADS} from "../../data/consts/users";

export const SettlementsState = ({children}) => {

    const {setGlobalLoader, unsetGlobalLoader, setModalError} = useContext(PoiContext);
    const {role} = useContext(AuthContext);
    const {getAllWT} = useContext(AppContext);

    const initialState = {
        makers: null,
        implementationPatients: [],
        treatmentPatients: [],
        trainings: [],
        couching: [],
        otherActivities: [],
        couchingFormSet: [],
        listOfUsers: [],
        additionalTime: [],
        globalSettlementPeriod: null,
        settlementsList: [],
        settlementsDates: [],
        settlementCompleted: false,
    }

    const [state, dispatch] = useReducer(settlementsReducer, initialState);

    const setGlobalSettlementPeriod = (date) => dispatch({type: SET_GLOBAL_SETTLEMENT_PERIOD, payload: date});

    const getSettlementsInfo = async (date, userId = null) => {
        setGlobalLoader();
        if (role === ROLE_ADS) {
            try {
                await multipleAxios.all([
                    axios.get(
                        `procedures/initiations?date=${date}`),
                    axios.get(
                        `procedures/treatments?date=${date}`),
                    axios.get(
                        `trainings?date=${date}`),
                    axios.get(
                        `extra_work_times?date=${date}`),
                ]).then(multipleAxios.spread((res1, res2, res3, res4) => {
                    const makers = res1.data['form-set'].makers;
                    const implementationPatients = res1.data.patients;
                    const treatmentPatients = res2.data.patients;
                    const trainings = res3.data.trainings;
                    const additionalTime = res4.data;
                    dispatch({
                        type: GET_SETTLEMENTS_INFO,
                        payload: {makers, implementationPatients, treatmentPatients, trainings, additionalTime},
                    })
                    unsetGlobalLoader()
                }))
            } catch (e) {
                unsetGlobalLoader()
                setModalError(e.response);
            }
        } else if (role === ROLE_COORD_ADS) {
            try {
                await multipleAxios.all([
                    axios.get(
                        `procedures/initiations?date=${date}`),
                    axios.get(
                        `procedures/treatments?date=${date}`),
                    axios.get(
                        `trainings?date=${date}`),
                    axios.get(
                        `coachings?date=${date}`),
                    axios.get(
                        `extra_work_times?date=${date}`),
                    axios.get(
                        `otherActivities?date=${date}`),
                ]).then(multipleAxios.spread((res1, res2, res3, res4, res5, res6) => {
                    const makers = res1.data['form-set'].makers;
                    const implementationPatients = res1.data.patients;
                    const treatmentPatients = res2.data.patients;
                    const trainings = res3.data.trainings;
                    const couching = res4.data.coachings;
                    const listOfUsers = res4.data['form-set'].users;
                    const couchingFormSet = res4.data['form-set'].institutions;
                    const additionalTime = res5.data;
                    const otherActivities = res6.data.otherActivities;
                    dispatch({
                        type: GET_SETTLEMENTS_INFO,
                        payload: {
                            makers,
                            implementationPatients,
                            treatmentPatients,
                            trainings,
                            couching,
                            listOfUsers,
                            couchingFormSet,
                            additionalTime,
                            otherActivities,
                        },
                    })
                    unsetGlobalLoader()
                }))
            } catch (e) {
                unsetGlobalLoader()
                setModalError(e.response);
            }
        } else if (role === ROLE_ADMIN) {
            await axios.get(
                `reckonings/${userId}`).then((res) => {
                    const makers = res.data.makers;
                    const implementationPatients = res.data.reckoning.initiations ? res.data.reckoning.initiations.patients : [];
                    const treatmentPatients = res.data.reckoning.treatments ? res.data.reckoning.treatments.patients : [];
                    const trainings = res.data.reckoning.trainings ?? [];
                    const additionalTime = res.data.reckoning.extraWorkTimes ?? [];
                    const couching = res.data.reckoning.coachings ? res.data.reckoning.coachings : [];
                    const couchingFormSet = [];
                    const otherActivities = res.data.reckoning.otherActivities ? res.data.reckoning.otherActivities : [];

                    dispatch({
                        type: GET_SETTLEMENTS_INFO,
                        payload: {
                            makers,
                            implementationPatients,
                            treatmentPatients,
                            trainings,
                            couching,
                            couchingFormSet,
                            additionalTime,
                            otherActivities,
                        },
                    })
                    unsetGlobalLoader()
                }
            )
        }
    }

    const editImplementation = async (data, procedureId, date) => {
        setGlobalLoader()
        try {
            axios.put(
                `procedures/${procedureId}`, data).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'EDYCJA WPROWADZONA',
                    },
                }
                setModalError(defRes);
                // const tempArray = dateFormat(date, true).split('-')
                // getSettlementsInfo(`${tempArray[1]}-${tempArray[0]}`)
                getSettlementsInfo(date)
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }

    const createImplementation = async (data, date) => {
        setGlobalLoader()
        try {
            axios.post(
                `procedures`,
                data
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'DANE ZAPISANE',
                    },
                }
                setModalError(defRes);
                // const tempArray = dateFormat(date, true).split('-')
                // getSettlementsInfo(`${tempArray[1]}-${tempArray[0]}`)
                getSettlementsInfo(date)
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }

    const createTreatment = async (data, date) => {
        setGlobalLoader()
        try {
            axios.post(
                `procedures`,
                data
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'DODANO POMYŚLNIE',
                    },
                }
                setModalError(defRes);
                // const tempArray = dateFormat(date, true).split('-')
                // getSettlementsInfo(`${tempArray[1]}-${tempArray[0]}`)
                getSettlementsInfo(date)
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }

    const editTreatment = async (data, procedureId, date) => {
        setGlobalLoader()
        try {
            axios.put(
                `procedures/${procedureId}`,
                data
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'EDYCJA WPROWADZONA',
                    },
                }
                setModalError(defRes);
                // const tempArray = dateFormat(date, true).split('-')
                // getSettlementsInfo(`${tempArray[1]}-${tempArray[0]}`)
                getSettlementsInfo(date)
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }

    const editTraining = async (data, trainingId, date) => {
        setGlobalLoader()
        try {
            axios.put(
                `trainings/${trainingId}`,
                data
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'EDYCJA WPROWADZONA',
                    },
                }
                setModalError(defRes);
                // const tempArray = dateFormat(date, true).split('-')
                // getSettlementsInfo(`${tempArray[1]}-${tempArray[0]}`)
                getSettlementsInfo(date)
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }

    const editCoaching = async (data, trainingId, date) => {
        setGlobalLoader()
        try {
            axios.put(
                `coachings/${trainingId}`,
                data
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'EDYCJA WPROWADZONA',
                    },
                }
                setModalError(defRes);
                // const tempArray = dateFormat(date, true).split('-')
                // getSettlementsInfo(`${tempArray[1]}-${tempArray[0]}`)
                getSettlementsInfo(state.globalSettlementPeriod)
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }

    const editActivity = async (data, activityId, date) => {
        setGlobalLoader()
        try {
            axios.put(
                `otherActivities/${activityId}`,
                data
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'EDYCJA WPROWADZONA',
                    },
                }
                setModalError(defRes);
                getSettlementsInfo(state.globalSettlementPeriod)
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }

    const createTraining = (data, date) => {
        setGlobalLoader()
        try {
            axios.post(
                `trainings`,
                data
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'SZKOLENIE UTWORZONE',
                    },
                }
                setModalError(defRes);
                // const tempArray = dateFormat(date, true).split('-')
                // getSettlementsInfo(`${tempArray[1]}-${tempArray[0]}`)
                getSettlementsInfo(state.globalSettlementPeriod)
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }

    const createCoaching = (data, date) => {
        setGlobalLoader()
        try {
            axios.post(
                `coachings`,
                data
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'Utworzono nowy coaching',
                    },
                }
                setModalError(defRes);
                // const tempArray = dateFormat(date, true).split('-')
                // getSettlementsInfo(`${tempArray[1]}-${tempArray[0]}`)
                getSettlementsInfo(state.globalSettlementPeriod)
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }

    const createActivity = (data) => {
        setGlobalLoader()
        try {
            axios.post(
                `otherActivities`,
                data
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'Utworzono nową aktywność',
                    },
                }
                setModalError(defRes);
                getSettlementsInfo(state.globalSettlementPeriod)
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }

    const createAdditionalTime = async (data) => {
        setGlobalLoader()
        try {
            axios.post(
                `extra_work_times`,
                data
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'Wniosek wysłany poprawnie',
                    },
                }
                setModalError(defRes);
                getAllWT()
                return true
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }

    const approveAdditionalTime = async (id) => {
        setGlobalLoader()
        try {
            axios.put(
                `extra_work_times/${id}/approve`,
                {}
            ).then(res => {
                unsetGlobalLoader();
                dispatch({type: SETTLEMENT_COMPLETED})
                getAllWT()
                return true
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }

    const rejectAdditionalTime = async (id, data) => {
        setGlobalLoader()
        try {
            axios.put(
                `extra_work_times/${id}/reject`,
                data
            ).then(res => {
                unsetGlobalLoader();
                dispatch({type: SETTLEMENT_COMPLETED})
                getAllWT()
                return true
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }


    const acceptTraining = async (procedureId, date) => {
        setGlobalLoader()
        try {
            axios.put(
                `trainings/${procedureId}/accept`,
                {}
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'TRENING ZAAKCEPTOWANY',
                    },
                }
                setModalError(defRes);
                // const tempArray = dateFormat(date, true).split('-')
                // getSettlementsInfo(`${tempArray[1]}-${tempArray[0]}`)
                getSettlementsInfo(state.globalSettlementPeriod)
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }

    const acceptProcedure = async (procedureId, date) => {
        setGlobalLoader()
        try {
            axios.put(
                `procedures/${procedureId}/accept`,
                {}
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'FORMULARZ ZAAKCEPTOWANY',
                    },
                }
                setModalError(defRes);
                // const tempArray = dateFormat(date, true).split('-')
                // getSettlementsInfo(`${tempArray[1]}-${tempArray[0]}`)
                getSettlementsInfo(state.globalSettlementPeriod)
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }

    const acceptAll = async (date) => {
        setGlobalLoader()
        try {
            axios.post(
                'reckoning/accept',
                {date}
            ).then(res => {
                unsetGlobalLoader();
                const defRes = {
                    data: {
                        code: 0,
                        message: `Wszystko zaakceptowano`,
                    },
                }
                setModalError(defRes);
                getSettlementsInfo(state.globalSettlementPeriod)
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response);
        }
    }

    // const acceptAll = async (action, data) => {
    //     const request = action === 'initiations' ? 'wdrożenia'
    //         : action === 'treatments' ? 'monitoringi' : ''
    //     setGlobalLoader()
    //     try {
    //         axios.post(
    //             action !== 'trainings' ? `procedures/${action}/accept` : `trainings/accept`,
    //             data,
    //             {
    //                 headers: {Authorization: `Bearer ${token}`},
    //             }).then(res => {
    //             unsetGlobalLoader();
    //             let defRes = {
    //                 data: {
    //                     code: 200,
    //                     message: `Wszystkie ${request} zostały zaakceptowane`,
    //                 },
    //             }
    //             setModalError(defRes);
    //             getSettlementsInfo(state.globalSettlementPeriod)
    //         })
    //     } catch (e) {
    //         unsetGlobalLoader()
    //         setModalError(e.response);
    //     }
    // }

    const getSettlementsList = async () => {
        setGlobalLoader()
        try {
            await axios.get(
                `settlements`
            ).then(res => {
                    dispatch({type: GET_SETTLEMENTS_LIST, payload: res.data})
                    unsetGlobalLoader()
                }
            )
        } catch
            (e) {
            unsetGlobalLoader();
            setModalError(e.response)
        }
    }

    const getSettlementsDates = useCallback(async () => {
        setGlobalLoader()
        try {
            await axios.get(
                `reckonings/years`
            ).then(res => {
                    dispatch({type: GET_SETTLEMENTS_DATES, payload: Object.entries(res.data)})
                    unsetGlobalLoader()
                }
            )
        } catch
            (e) {
            unsetGlobalLoader();
            setModalError(e.response)
        }
    }, [setGlobalLoader, unsetGlobalLoader, setModalError])

    const clearSettlementsState = () => dispatch({type: CLEAR_SETTLEMENTS_STATE, payload: initialState})

    const confirmSettlement = () => dispatch({type: SETTLEMENT_CONFIRMED})

    return (
        <SettlementsContext.Provider value={{
            settlementsState: state,
            setGlobalSettlementPeriod,
            getSettlementsInfo,
            clearSettlementsState,
            confirmSettlement,
            createImplementation,
            editImplementation,
            createTreatment,
            editTreatment,
            acceptProcedure,
            createTraining,
            editTraining,
            acceptTraining,
            acceptAll,
            createCoaching,
            createActivity,
            createAdditionalTime,
            approveAdditionalTime,
            rejectAdditionalTime,
            editCoaching,
            editActivity,
            getSettlementsList,
            getSettlementsDates,
        }}>
            {children}
        </SettlementsContext.Provider>
    )

}