import React, {useContext} from 'react';
import {PoiContext} from '../../../context/poi/poiContext';
import {Button, Typography} from "@mui/material";

const ErrorModal = () => {
    const {errorMessage, errorCode, unsetModalError} = useContext(PoiContext);
    return (
        <div className="customModal errorModal">
            <div className="customModal__content">
                <Typography
                    variant='h3'>{errorCode === 0 || (errorCode >= 200 && errorCode < 300) ? 'Wiadomość' : 'Błąd'}</Typography>
                <div className='customModal__content-errorMessageArea'>
                    <p>
                        {errorMessage}
                    </p>
                </div>
                <Button onClick={unsetModalError} variant='contained'>Powrót</Button>
            </div>
        </div>
    )
}

export default ErrorModal;