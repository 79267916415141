import React, {useContext, useEffect, useState} from 'react'
import {Button, Grid, Stack, Typography} from '@mui/material';
import avatar from '../../../../assets/img/ui/windowPerson.png';
import {dateFormat} from '../../../../utils/helpfunc';
import SearchIcon from "@mui/icons-material/Search";
import OtherActivityAdd from "./OtherActivityAdd";
import {CLASS_BLUE} from '../settlementsMonthTable/helpers'
import {SettlementsContext} from "../../../../context/settlements/settlementsContext";

const OtherActivities = ({settlementPeriod, reckoningStatus}) => {
    const {settlementsState} = useContext(SettlementsContext);
    const [otherActivities, setOtherActivities] = useState([])
    const [openActivityId, setOpenActivityId] = useState(null)

    useEffect(() => {
        setOtherActivities([...settlementsState.otherActivities].sort((a, b) => new Date(b.date) - new Date(a.date)))
    }, [settlementsState])

    return otherActivities?.length !== 0 ?
        otherActivities.map(el => {
                return <Grid container key={el.id} p={1}>
                    {/*TRAINING TITLE*/}
                    <Grid
                        item
                        xs={12}
                        container
                        display="row"
                        wrap="nowrap"
                        p={1}
                        alignItems="center"
                        bgcolor="#f5f5f5"
                        padding={"30px 70px 40px"}
                    >
                        <Grid item pr={"45px"}>
                            <img src={avatar} alt="ava" className="settlements__blockImage_XL"/>
                        </Grid>
                        <Grid item ml={1} container justifyContent={'flex-start'}>
                            <Grid item xs={12} sm={5}>
                                <Stack className='settlements__status' direction="column">
                                    <Typography><b>Coaching</b></Typography>
                                </Stack>
                                <Stack className='settlements__status' direction="column">
                                    <Typography><b>Data:</b></Typography>
                                    <Typography>{dateFormat(new Date(el.date))}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item container xs={12} sm={7}>
                                <Grid direction="column" container item xs={12} sm={5} mb={2}>
                                    <Stack className='settlements__status' direction="column">
                                        <Typography>Czas trwania:</Typography>
                                        <Typography><i><b>{el.duration} min.</b></i></Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={5} mb={'auto'} mt={2}>
                                        <Button className={CLASS_BLUE} variant="contained"
                                                onClick={() => {
                                                    openActivityId === el.id ? setOpenActivityId(null) : setOpenActivityId(el.id)
                                                }}>
                                            <SearchIcon/>
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        openActivityId === el.id && <OtherActivityAdd activityInfo={el} settlementPeriod={settlementPeriod}
                                                                      reckoningStatus={reckoningStatus}
                                                                      setIsAdding={setOpenActivityId}/>
                    }
                </Grid>
            }
        )
        :
        <Grid container alignItems="center" justifyContent="center" p={2}>
            <Typography>Brak danych</Typography>
        </Grid>
}

export default OtherActivities;