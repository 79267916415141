import {Grid, Typography} from "@mui/material";

export const InfoElement = ({title, value, titleMinWidth}) => {
    if (value === null) return null

    return (
        <Grid container flexDirection='row' alignItems='center'>
            <Typography variant='subtitle1' mr={2} minWidth={titleMinWidth || '200px'}>{title}</Typography>
            <Typography variant='body1'>{value}</Typography>
        </Grid>
    )
}