import { Checkbox, FormControl, FormControlLabel, Grid, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Typography, FormGroup } from "@mui/material";
import { observer } from "mobx-react-lite";

import { DatePickerComponent } from "../../../../shared/ui/DatePickerComponent";
import { convertBoolValue, convertBoolValueHandler } from "../helper";
import Line from "../../../../shared/ui/Line";
import { useDisabled } from "../useDisabled";
import { useAdverseEventsProduodopaStore } from "../../../../../store/hooks";

const fromComesElements = ["Pacjent", "Członek rodziny", "Przedstawiciel ochrony zdrowia", "Inna osoba (należy określić funkcję)"];

const ComplaintInfo = observer(() => {
    const adverseEventsProduodopaStore = useAdverseEventsProduodopaStore()
    const {complaintInfo, formSet} = adverseEventsProduodopaStore;
    
    const {
        isDisabled,
        changeDisabled
    } = useDisabled(fromComesElements[fromComesElements.length - 1],  "fromComesOther", adverseEventsProduodopaStore.complaintInfoOnChange)

    return <Grid container pl={2}>
        
        <Grid item xs={12} mt={2}>
            <Typography sx={{color: '#2196F3'}}>Część 1: Informacje dotyczące reklamacji</Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
            <Typography>Formularz musi zawierać wszystkie strony. Należy zastosować format daty: DD/MM/RRRR</Typography>
        </Grid>

        <Grid item xs={12} mt={4} p={2} sx={{border: '1px solid #9B9B9D'}}>
            <FormControl fullWidth>
                <Typography variant='subtitle1'>Imię i nazwisko osoby zgłaszającej:</Typography>
                <OutlinedInput
                    name="fullName"
                    value={complaintInfo.fullName}
                    onChange={(e) => adverseEventsProduodopaStore.complaintInfoOnChange(e.target.name, e.target.value)}/>
             </FormControl>
        </Grid>
        <Grid item xs={12} p={2} sx={{border: '1px solid #9B9B9D'}} gap={2} display='flex' flexDirection='column'>
            <FormControl fullWidth>
                <Typography variant='subtitle1'>Numer telefonu:</Typography>
                <OutlinedInput
                    name="phone"
                    value={complaintInfo.phone}
                    onChange={(e) => adverseEventsProduodopaStore.complaintInfoOnChange(e.target.name, e.target.value)}/>
             </FormControl>
             <FormControl fullWidth mt={2}>
                <Typography variant='subtitle1'>Adres email:</Typography>
                <OutlinedInput
                    name="email"
                    type="email"
                    value={complaintInfo.email}
                    onChange={(e) => adverseEventsProduodopaStore.complaintInfoOnChange(e.target.name, e.target.value)}/>
             </FormControl>
        </Grid>

        <Grid item xs={12} p={2} sx={{border: '1px solid #9B9B9D'}}>
            <FormControl fullWidth>
                <Typography variant='subtitle1'>Informacja pochodzi od:</Typography>
                <FormGroup sx={{mt: 2}}
                    name="fromComes"
                    onChange={(e) => {
                        adverseEventsProduodopaStore.complaintInfoOnChange("fromComes", e.target.value);
                        changeDisabled(complaintInfo.fromComes, e.target.value)
                    }}>
                        {
                            fromComesElements.map(element => <FormControlLabel mt={1} key={element} value={element} control={<Checkbox checked={complaintInfo.fromComes.includes(element)} />} label={element} />)
                        }
                </FormGroup>
             </FormControl>
             <FormControl fullWidth sx={{pl: 6, mt: 1}}>
                <OutlinedInput 
                            disabled={isDisabled}
                            name="fromComesOther"
                            value={complaintInfo.fromComesOther}
                            onChange={(e) => adverseEventsProduodopaStore.complaintInfoOnChange(e.target.name, e.target.value)}
                            placeholder="Wpisz funkcję" />
             </FormControl>
             <Line/>
             <FormControl fullWidth>
                <Typography variant='subtitle1'>Wymagana odpowiedź do pacjenta:</Typography>
                    <RadioGroup row
                        name="isResponseToPatient"
                        value={convertBoolValue(complaintInfo.isResponseToPatient)}
                        onChange={(e) => adverseEventsProduodopaStore.complaintInfoOnChange(e.target.name, convertBoolValueHandler(e.target.value))}>
                        <FormControlLabel mt={1} value="Tak" control={<Radio />} label="Tak" />
                        <FormControlLabel mt={1} value="Nie" control={<Radio />} label="Nie" />
                    </RadioGroup>
             </FormControl>
        </Grid>

        <Grid item xs={12} p={2} sx={{border: '1px solid #9B9B9D'}}>
            <FormControl fullWidth>
                <Typography variant='subtitle1'>Inicjały pacjenta:</Typography>
                <Select
                        name="patient.id"
                        value={complaintInfo.patient?.id}
                        onChange={(e) => adverseEventsProduodopaStore.complaintInfoOnChange(e.target.name, e.target.value)}> 
                        
                         {formSet && formSet.patients.map(patient =>  <MenuItem key={patient.id} value={patient.id}>{patient.initials}</MenuItem>)}
                     </Select>
             </FormControl>
        </Grid>
        <Grid item xs={12} p={2} sx={{border: '1px solid #9B9B9D'}} gap={2} display='flex' flexDirection='column'>
            <FormControl fullWidth>
                <Typography variant='subtitle1'>Numer telefonu:</Typography>
                <OutlinedInput
                        name="patient.phone"
                        value={complaintInfo.patient?.phone}
                        onChange={(e) => adverseEventsProduodopaStore.complaintInfoOnChange(e.target.name, e.target.value)}/>        
             </FormControl>
             <FormControl fullWidth >
                <Typography variant='subtitle1'>Adres email:</Typography>
                <OutlinedInput
                        name="patient.email"
                        value={complaintInfo.patient?.email}
                        onChange={(e) => adverseEventsProduodopaStore.complaintInfoOnChange(e.target.name, e.target.value)}/>        
             </FormControl>
        </Grid>
        <Grid item xs={12} p={2} sx={{border: '1px solid #9B9B9D'}} gap={2} display='flex' flexDirection='column'>
            <FormControl fullWidth>
                <Typography variant='subtitle1'>Data urodzenia:</Typography>
                <DatePickerComponent
                    disableFuture    
                    label="DD/MM/RRRR"
                    allowSameDateSelection
                    name="patient.birthdate"
                    value={complaintInfo.patient?.birthdate}
                    onChange={(value) => adverseEventsProduodopaStore.complaintInfoOnChange("patient.birthdate", value)}/>   
             </FormControl>
             <FormControl fullWidth mt={2}>
                <Typography variant='subtitle1'>Płeć:</Typography>
                <Select
                       name="patient.gender"
                       value={complaintInfo.patient?.gender}
                       onChange={(e) => adverseEventsProduodopaStore.complaintInfoOnChange(e.target.name, e.target.value)}>   
                        <MenuItem value="Mężczyzna">Mężczyzna</MenuItem> 
                        <MenuItem value="Kobieta">Kobieta</MenuItem> 
                    </Select>
             </FormControl>
        </Grid>

        <Grid item xs={12} p={2} sx={{border: '1px solid #9B9B9D'}} gap={2} display='flex' flexDirection='column'>
            <FormControl fullWidth>
                <Typography variant='subtitle1'>Jeśli jest zarejestrowany w badaniu obserwacyjnym po wprowadzeniu na rynek:</Typography>
                <OutlinedInput
                    name="patient.participationInPostMarketStudy"
                    value={complaintInfo.patient?.participationInPostMarketStudy}
                    onChange={(e) => adverseEventsProduodopaStore.complaintInfoOnChange(e.target.name, e.target.value)}/>   
             </FormControl>
             <FormControl fullWidth mt={2}>
                <Typography variant='subtitle1'>Numer pacjenta w badaniu klinicznym:</Typography>
                <OutlinedInput
                    name="patient.number"
                    value={complaintInfo.patient?.number}
                    onChange={(e) => adverseEventsProduodopaStore.complaintInfoOnChange(e.target.name, e.target.value)}/>   
             </FormControl>
        </Grid>

    </Grid>
});

export default ComplaintInfo;