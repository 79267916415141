import React from 'react';
import {
    Button,
    FormControl,
    Grid,
    TextField,
    Typography,
} from '@mui/material';


const ProductSubModal = ({productData, modalOption, productTableHandler, delProductTableRow, addProductTableRow,}) => {
    return (
        <Grid
            item
            xs={12}
            container
            borderRadius={'5px'}
            direction="column"
        >
            <Grid container bgcolor={'#F5F5F5'} p={2} alignItems="center"
                  justifyContent="space-between">
                <Typography variant="subtitle1">Dane produktu:</Typography>
                <Button variant="contained" color="success" className="formButton"
                        disabled={(modalOption === 'show')}
                        onClick={addProductTableRow}>Dodaj nowy wariant produktu</Button>
            </Grid>
            {
                productData && productData.map((el, index) =>
                    <Grid
                        key={index}
                        container
                        direction="column"
                        m={1}
                        p={2}
                        borderRadius={'5px'}
                        bgcolor="#F5F5F5"
                    >
                        <Grid item mt={2}>
                            <FormControl fullWidth required>
                                <Typography marginBottom={2} variant="subtitle1"> Nazwa wariantu produktu</Typography>
                                <TextField
                                    required
                                    disabled={(modalOption === 'show')}
                                    label="Nazwa katalogowa"
                                    name="productCatalogName"
                                    value={el.productCatalogName}
                                    onChange={(e) => productTableHandler(e.target.name, e.target.value, el, index)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item mt={2}>
                            <FormControl fullWidth>
                                <TextField
                                    required
                                    disabled={(modalOption === 'show')}
                                    label="Kod produktu"
                                    name="productCode"
                                    value={el.productCode}
                                    onChange={(e) => productTableHandler(e.target.name, e.target.value, el, index)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item mt={2}>
                            <FormControl fullWidth>
                                <TextField
                                    disabled={(modalOption === 'show')}
                                    label="Rozmiar"
                                    name="productSize"
                                    value={el.productSize}
                                    onChange={(e) => productTableHandler(e.target.name, e.target.value, el, index)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid container mt={2} alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="error"
                                    disabled={(modalOption === 'show')}
                                    className="formButton"
                                    onClick={() => delProductTableRow(index)}
                                >
                                    Usuń pole
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>,
                )
            }
        </Grid>
    )
}

export default ProductSubModal;