import {Button, Grid, Typography} from "@mui/material";
import React from "react";

export const MenuButton = ({menuCount, title, setActiveMenu, activeMenu, setIsAdding, isVisible}) => {

    const onClickHandler = () => {
        setActiveMenu(menuCount);
        setIsAdding(false)
    }

    const classSetter = () => `settlements__nav-item ${activeMenu === menuCount ? "settlements__nav-activeItem" : ""}`

    return (
        <>
            {isVisible &&
                (<Grid p={0.5} flexGrow={1}>
                        <Button
                            variant='contained'
                            sx={styles.buttonStyle}
                            className={classSetter()}
                            onClick={onClickHandler}>
                            <Typography>{title}</Typography>
                        </Button>
                    </Grid>
                )
            }
        </>
    )
}

const styles = {
    buttonStyle: {
        padding: "5px 10px",
        width: "100%",
        height: "100%",
    }
}