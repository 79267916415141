export const HardwareModalValidate = (formData, productData, files) => {
    if (formData.productName.length < 2) {
        return ({
            isError: true,
            defRes: {
                data: {
                    code: 422,
                    message: 'Nazwa przedmiotu jest zbyt krótka'
                }
            }
        })
    }
    // if (formData.package.length < 3) {
    //     return ({
    //         isError: true,
    //         defRes: {
    //             data: {
    //                 code: 422,
    //                 message: 'Kwota zamówienia jest zbyt mała'
    //             }
    //         }
    //     })
    // }
    //TODO VALIDATION
    // if (files.File.length)  {
    //     return ({
    //         isError: true,
    //         defRes: {
    //             data: {
    //                 code: 422,
    //                 message: 'product photo required'
    //             }
    //         }
    //     })
    // }

    return ({
        isError: false,
        defRes: null
    })

}