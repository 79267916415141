import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import Login from '../../components/pages/auth/Login';
import ResetPasswordEmail from '../../components/pages/auth/ResetPasswordEmail';
import MainDash from '../../components/pages/MainDash';
import ChangePassword from '../../components/pages/auth/ChangePassword';
import Logout from "../../components/pages/auth/Logout";
import ResetPasswordToken from "../../components/pages/auth/ResetPasswordToken";
import Patients from "../../components/pages/patients/Patients";
import Interactions from "../../components/pages/admin/interactions/Interactions";
import Messages from "../../components/pages/admin/messages/Messages";
import KnowledgeBase from "../../components/pages/knowledgeBase/KnowledgeBase";
import AdverseEvents from "../../components/pages/adverseEvents/AdverseEvents";
import HardwareOrders from "../../components/pages/hardwareOrders/HardwareOrders";
import AdminCertification from "../../components/pages/admin/certification/AdminCertification";
import Profile from "../../components/pages/profiles/Profile";
import Users from "../../components/pages/admin/users/Users";
import Hospitals from "../../components/pages/admin/hospitals/Hospitals";
import Settlements from "../../components/pages/settlements/Settlements";
import Settlement from "../../components/pages/settlements/settlement/Settlement";
import Hardware from "../../components/pages/hardware/Hardware";
import Certification from '../../components/pages/certification/Certification';
import Reckonings from '../../components/pages/admin/reckonings/Reckonings';
import OvertimeUser from "../../components/pages/overtime/OvertimeUser";
import AdverseEventsDuodopa from '../../components/pages/adverseEvents/duodopaForm/AdverseEventsDuodopa';
import AdverseEventsProduodopa from '../../components/pages/adverseEvents/produodopaForm/AdverseEventsProduodopa';

import {ROLE_ADMIN, ROLE_ADS, ROLE_COORD_ADS, ROLE_KAM} from "../consts/users";

const userRoutes = (isLoggedIn, role, checkPassDate) => {
    if (!isLoggedIn) {
        return <Routes>
            <Route path="/" element={<Login/>}/>
            <Route path="/reset_password" element={<ResetPasswordEmail/>}/>
            <Route path="/reset_password/:token" element={<ResetPasswordToken/>}/>
            <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
    } else {
        if (checkPassDate) {
            switch (role) {
                case ROLE_ADS:
                    return <Routes>
                        <Route path="/" element={<MainDash/>}/>
                        <Route path="/patients" element={<Patients/>}/>
                        <Route path="/overtime" element={<OvertimeUser/>}/>
                        <Route path="/settlements" element={<Settlements/>}/>
                        <Route path="/settlements/:yearMonth" element={<Settlement/>}/>
                        <Route path="/interactions" element={<Interactions/>}/>
                        <Route path="/messages" element={<Messages/>}/>
                        <Route path="/knowledge_base" element={<KnowledgeBase/>}/>
                        {/*<Route path="/count" element={<Count/>}/>*/}
                        <Route path="/adverse_events" element={<AdverseEvents/>}/>
                        <Route path="/adverse_events/produodopa" element={<AdverseEventsProduodopa/>}/>
                        <Route path="/adverse_events/duodopa" element={<AdverseEventsDuodopa/>}/>
                        <Route path="/hardware_orders" element={<HardwareOrders/>}/>
                        <Route path="/certification" element={<Certification/>}/>
                        <Route path="/profile" element={<Profile/>}/>
                        <Route path="/changePassword" element={<ChangePassword/>}/>
                        <Route path="/logout" element={<Logout/>}/>
                        <Route path="*" element={<Navigate to="/"/>}/>
                    </Routes>
                case ROLE_ADMIN:
                    return <Routes>
                        <Route path="/" element={<MainDash/>}/>
                        <Route path="/patients" element={<Patients/>}/>
                        <Route path="/overtime" element={<OvertimeUser/>}/>
                        <Route path="/users" element={<Users/>}/>
                        <Route path="/hospitals" element={<Hospitals/>}/>
                        {/*<Route path="/overtime" element={<AdminOverTime />} />*/}
                        <Route path="/reckonings" element={<Settlements/>}/>
                        <Route path="/reckonings/:yearMonth" element={<Reckonings/>}/>
                        <Route path="/reckonings/:yearMonth/:adsID" element={<Settlement/>}/>
                        <Route path="/interactions" element={<Interactions/>}/>
                        <Route path="/messages" element={<Messages/>}/>
                        <Route path="/knowledge_base" element={<KnowledgeBase/>}/>
                        <Route path="/hardware" element={<Hardware/>}/>
                        <Route path="/hardware_orders" element={<HardwareOrders/>}/>
                        <Route path="/certification" element={<AdminCertification/>}/>
                        <Route path="/profile" element={<Profile/>}/>
                        <Route path="/changePassword" element={<ChangePassword/>}/>
                        <Route path="/logout" element={<Logout/>}/>
                        <Route path="*" element={<Navigate to="/"/>}/>
                    </Routes>
                case ROLE_COORD_ADS:
                    return <Routes>
                        <Route path="/" element={<MainDash/>}/>
                        <Route path="/patients" element={<Patients/>}/>
                        <Route path="/overtime" element={<OvertimeUser/>}/>
                        {/*<Route path="/overtime" element={<OverTime />} />*/}
                        <Route path="/settlements" element={<Settlements/>}/>
                        <Route path="/settlements/:yearMonth" element={<Settlement/>}/>
                        <Route path="/interactions" element={<Interactions/>}/>
                        <Route path="/messages" element={<Messages/>}/>
                        <Route path="/knowledge_base" element={<KnowledgeBase/>}/>
                        <Route path="/adverse_events" element={<AdverseEvents/>}/>
                        <Route path="/adverse_events/produodopa" element={<AdverseEventsProduodopa/>}/>
                        <Route path="/adverse_events/duodopa" element={<AdverseEventsDuodopa/>}/>             
                        <Route path="/hardware_orders" element={<HardwareOrders/>}/>
                        <Route path="/certification" element={<Certification/>}/>
                        <Route path="/profile" element={<Profile/>}/>
                        <Route path="/changePassword" element={<ChangePassword/>}/>
                        <Route path="/logout" element={<Logout/>}/>
                        <Route path="*" element={<Navigate to="/"/>}/>
                    </Routes>
                case ROLE_KAM:
                    return <Routes>
                        <Route path="/" element={<MainDash/>}/>
                        <Route path="/hardware" element={<Hardware/>}/>
                        <Route path="/hardware_orders" element={<HardwareOrders/>}/>
                        <Route path="/profile" element={<Profile/>}/>
                        <Route path="/changePassword" element={<ChangePassword/>}/>
                        <Route path="/logout" element={<Logout/>}/>
                        <Route path="*" element={<Navigate to="/"/>}/>
                    </Routes>
                default:
                    return <Routes>
                        <Route path="/" element={<Login/>}/>
                        <Route path="/resetPassword" element={<ResetPasswordEmail/>}/>
                        <Route path="/reset_password/:token" element={<ResetPasswordToken/>}/>
                        <Route path="*" element={<Navigate to="/"/>}/>
                    </Routes>
            }
        } else {
            return <Routes>
                <Route path="/" element={<ChangePassword/>}/>
                <Route path="/logout" element={<Logout/>}/>
                <Route path="*" element={<Navigate to="/"/>}/>
            </Routes>
        }
    }
}

export default userRoutes;