
import { Button, FormControl, FormControlLabel, Grid, OutlinedInput, Radio, RadioGroup, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";

import EventElement from "./partTwo/EventElement";
import DrugElement from "./partTwo/DrugElement";
import HistoryElement from "./partTwo/HistoryElement";
import Line from "../../../shared/ui/Line";
import { useAdverseEventsProduodopaStore } from "../../../../store/hooks";
import { useEffect } from "react";


const radioElements = ["Nie", "Nie zgłoszono", "Tak, określ poniżej:"];

const PartTwo = observer(() => {
    const adverseEventsProduodopaStore = useAdverseEventsProduodopaStore()
    const {partTwoInfo} = adverseEventsProduodopaStore;
    
    useEffect(() => {
        if(partTwoInfo.drugsTakenAtSameTime !== radioElements[2]){
                adverseEventsProduodopaStore.clearDrugs();
        }
    }, [adverseEventsProduodopaStore, partTwoInfo.drugsTakenAtSameTime]);

    useEffect(() => {
        if(partTwoInfo.medicalHistoriesExists !== radioElements[2]){
                adverseEventsProduodopaStore.clearMedicalHistory();
        }
    }, [adverseEventsProduodopaStore, partTwoInfo.medicalHistoriesExists]);


    return (
        <>
        <Grid container display='flex' mt={2} pl={2}>
            <Typography sx={{color: '#2196F3'}}>Część 2: Uzupełnij sekcję dla zdarzenia niepożądanego</Typography>
        </Grid>
        <Grid container display='flex' mt={2} pl={2}>
            <Grid item className="subMenuSidebar">Zdarzenia</Grid>
            <Grid flexGrow={1} maxWidth="calc(100% - 100px)" container display='flex' flexDirection='row' sx={{border: '1px solid #9B9B9D'}} >
                  
                    {partTwoInfo.events.map((_, index) => <EventElement key={index} index={index}/>)}
                    <Grid display='flex' alignItems='center' item xs={12} p={2}>
                        <Button onClick={adverseEventsProduodopaStore.addEvent} size="medium" color='secondary' 
                                sx={{
                                background: '#30A6A6', 
                                color: 'white', 
                                width: 160,
                                '&:hover': {
                                        background: '#30A6A6',
                                        opacity: 0.9
                                }}}>Dodaj zdarzenie</Button>
                        </Grid>
            </Grid>
        </Grid>
       <Grid container display='flex' mt={2} pl={2}>
                <Grid item className="subMenuSidebar">Leki</Grid>
                <Grid flexGrow={1} maxWidth="calc(100% - 100px)" container display='flex' flexDirection='row' sx={{border: '1px solid #9B9B9D'}} >
                        <Grid item xs={12} p={2}>
                        <FormControl fullWidth required >
                                <Typography variant="subtitle1">Leki przyjmowane jednocześnie:</Typography>
                                <RadioGroup 
                                        name="drugsTakenAtSameTime"
                                        value={partTwoInfo.drugsTakenAtSameTime}
                                        onChange={(e) => adverseEventsProduodopaStore.partTwoInfoOnChange("drugsTakenAtSameTime", e.target.value)}>
                                        {radioElements.map(element => <FormControlLabel key={element} mt={1} value={element} control={<Radio />} label={element} />)}
                                </RadioGroup>
                        </FormControl>
                        </Grid>
                        {partTwoInfo.drugs.map((_, index) => <DrugElement key={index} index={index}/>)}
                        {partTwoInfo.drugsTakenAtSameTime === radioElements[2] && <Grid p={2}>
                                <Button onClick={adverseEventsProduodopaStore.addDrug} size="medium" color='secondary' 
                                sx={{
                                        background: '#30A6A6', 
                                        color: 'white', 
                                        px: 2,
                                        '&:hover': {
                                        background: '#30A6A6',
                                        opacity: 0.9
                                        }}}>Dodaj lek</Button>
                        </Grid>}
                </Grid>
        </Grid>
        <Grid container display='flex' mt={2} pl={2}>
                <Grid item className="subMenuSidebar">Historia medyczna</Grid>
                <Grid flexGrow={1} maxWidth="calc(100% - 100px)" container display='flex' flexDirection='row' sx={{border: '1px solid #9B9B9D'}} >
                        <Grid item xs={12} p={2} pb={0}>
                        <FormControl fullWidth required >
                                <Typography variant="subtitle1">Historia medyczna:</Typography>
                                <RadioGroup 
                                      name="medicalHistoriesExists"
                                      value={partTwoInfo.medicalHistoriesExists}
                                      onChange={(e) => adverseEventsProduodopaStore.partTwoInfoOnChange("medicalHistoriesExists", e.target.value)}>
                                        {radioElements.map(element =>  <FormControlLabel key={element} mt={1} value={element} control={<Radio />} label={element} />)}
                                </RadioGroup>
                        </FormControl>
                        <Line/>
                        </Grid>
                        {partTwoInfo.medicalHistoriesExists === radioElements[2] && <Grid item xs={12} p={2} pt={0}>
                                <Typography variant='subtitle1'>Proszę opisać historię medyczną, chirurgiczną, psychospołeczną, historię palenia i spożywania alkoholu.</Typography>
                        </Grid>}

                        {partTwoInfo.medicalHistories.map((_, index) => <HistoryElement key={index} index={index}/>)}
                        <Grid item xs={12} p={2}>
                                {partTwoInfo.medicalHistoriesExists === radioElements[2] && <Button onClick={adverseEventsProduodopaStore.addMedicalHistory} size="medium" color='secondary' 
                                sx={{
                                        background: '#30A6A6', 
                                        color: 'white', 
                                        px: 2,
                                        '&:hover': {
                                        background: '#30A6A6',
                                        opacity: 0.9
                                        }}}>Dodaj</Button>}
                        </Grid>
                </Grid>
        </Grid>
        <Grid container mt={2} pl={2}>
            <Grid xs={12} item p={2} sx={{border: '1px solid #9B9B9D'}} >
                <FormControl fullWidth required >
                        <Typography variant='subtitle1'>Alergie na leki:</Typography>
                        <OutlinedInput
                           name="allergy"
                           value={partTwoInfo.allergy}
                           onChange={(e) => adverseEventsProduodopaStore.partTwoInfoOnChange(e.target.name, e.target.value)}/>
                </FormControl>
            </Grid>
              
        </Grid>
        </>
    )
});

export default PartTwo;