import {ROLE_ADMIN, ROLE_ADS, ROLE_COORD_ADS, ROLE_KAM} from "../consts/users";

export const mainLinkDash = (role) => {

    if (role === ROLE_ADS
        || role === ROLE_COORD_ADS) {
        return [
            {
                link: '/adverse_events',
                title: 'Zdarzenia niepożądane'
            },
            {
                link: '/hardware_orders',
                title: 'Zamówienia sprzętowe'
            },
            {
                link: '/settlements',
                title: 'Rozliczenia'
            },
        ]
    }

    if (role === ROLE_KAM) {
        return [
            {
                link: '/hardware_orders',
                title: 'Zamówienia sprzętowe'
            },
            {
                link: '/hardware',
                title: 'Produkty'
            },
        ]
    }

    if (role === ROLE_ADMIN) {
        return [
            {
                link: '/hardware_orders',
                title: 'Zamówienia sprzętowe'
            },
            {
                link: '/reckonings',
                title: 'Rozliczenia'
            },
        ]
    }


    return [];
}