import React, {useContext} from 'react';
import {Grid, Typography} from '@mui/material';
import {NavLink} from 'react-router-dom';
import {AuthContext} from "../../context/auth/authContext";
import {mainLinkDash} from "../../data/uiSettings/mainDashLinks";

const MainDash = () => {
    const {authState} = useContext(AuthContext)

    return (
        <div className='mainDash'>
            <Typography variant='h3'>Witamy w aplikacji ADS</Typography>
            <Typography variant='body1'>Dla nawigacji naciśnij na jeden z przycisków poniżej albo skorzystaj z menu po
                lewej stronie</Typography>
                <Grid container spacing={2} mt={4}>
                    {authState !== null && mainLinkDash(authState.role).map((el, index) => (
                        <Grid item xs={12} key={index}>
                            <NavLink className='mainDash__link' to={el.link}>{el.title}</NavLink>
                        </Grid>
                    ))}
                </Grid>
            </div>
    )
}

export default MainDash;