export const headCells = [
    {id: 'ID', label: 'ID'},
    {id: 'name', label: 'Imię'},
    {id: 'surname', label: 'Nazwisko'},
    {id: 'institutions', label: 'Placówka'},
    {id: 'number', label: 'Telefon'},
    {id: 'role', label: 'Rola'},
    {id: 'email', label: 'E-mail'},
    {id: 'actions', label: 'Działania'}
]

export const userDataInit = {
    firstName: '',
    secondName: '',
    institutions: [],
    phoneNumber: '',
    password: '',
    role: '',
    type: '',
    email: '',
    id: '',
    language: [],
    contractNO: '',
    contractSignDate: null,
};