import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import {BrowserRouter} from 'react-router-dom';
import {PoiState} from './context/poi/PoiState';
import {AuthState} from './context/auth/AuthState';
import {AppState} from './context/app/AppState';
import {createTheme, ThemeProvider, responsiveFontSizes} from '@mui/material';
import {PatientState} from './context/patient/PatientState';
import {HardwareState} from './context/hardware/HardwareState';
import {OrderState} from './context/orders/OrderState'
import {SettlementsState} from './context/settlements/SettlementsState';
import {ReckoningsState} from './context/reckonings/ReckoningsState';
import {MessagesState} from './context/messages/messagesState';
import { RootStateProvider } from './store/RootStateContext';
import './scss/main.scss';


let theme = createTheme({
    palette: {
        primary: {
            main: '#071d49',
        },
        clear: {
            main: '#D9D9D9',

        },
        corrected: {
            light: '#00a1ff',
            main: '#EB5757',
            contrastText: '#FFFFFF'

        },
        pending: {
            main: '#F2994A',
            contrastText: '#FFFFFF'

        },
        accepted: {
            main: '#219681',
            contrastText: '#FFFFFF'

        },
        pendingAccept: {
            main: '#6FCF97',
            contrastText: '#FFFFFF'

        },
        acceptedInvoiced: {
            main: "#219681",
            contrastText: '#FFFFFF'

        },

        text: {
            disabled: '#757575',
        },
        grey: {
            main: '#999',

        }

    },

    typography: {
        body1: {
            fontSize: '0.75rem',
            '@media (min-width:1200px)': {
                fontSize: '1rem'
            }
        },
        subtitle1: {
            color: '#071d49',
            fontWeight: 'bold',
        },
        subtitle2: {
            color: '#071d49',
            fontWeight: 'normal',
        },
        formHeader: {
            color: "#071D49",
            fontSize: "2.25rem",
            lineHeight: "1.3",
            fontWeight: "bold",
            width: "100%",
            maxWidth: "950px",
            borderBottom: "1px solid rgba(0, 0, 0, .12)",
            marginBottom: "40px",
        },
    },

    components: {
        MuiPopover: {
            styleOverrides: {
                root: {
                    zIndex: '100000',
                },
            },
        },
        MuiPopper: {
            styleOverrides: {
                root: {
                    zIndex: '100000 !important',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: '#4a5a7a',
                },
                green: {
                    backgroundColor: '#228b21',
                    color: '#FFF',
                }
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    pointerEvents: 'none'
                }
            }
        }
    },
})

theme = responsiveFontSizes(theme);

const app = (
    <ThemeProvider theme={theme}>
        <BrowserRouter>
            <PoiState>
                <AuthState>
                    <AppState>
                        <SettlementsState>
                            <PatientState>
                                <HardwareState>
                                    <OrderState>
                                        <ReckoningsState>
                                            <MessagesState>
                                                <RootStateProvider>
													<App/>
												</RootStateProvider>
                                            </MessagesState>
                                        </ReckoningsState>
                                    </OrderState>
                                </HardwareState>
                            </PatientState>
                        </SettlementsState>
                    </AppState>
                </AuthState>
            </PoiState>
        </BrowserRouter>
    </ThemeProvider>
)

ReactDOM.render(app, document.getElementById('root'));
