import {makeAutoObservable} from 'mobx';
import { AdverseEventsProduodopaStore } from './adverseEventsProduodopaStore';

export class RootStore {
  adverseEventsProduodopaStore = new AdverseEventsProduodopaStore();
 
  constructor() {
    makeAutoObservable(this, {
      adverseEventsProduodopaStore: false,
    });
  }
}