import {Grid, Typography} from '@mui/material';
import Clause from './Clause';

const Clauses = () => {
  return <Grid pl={2} container mt={2}>
    <Clause title={"Administrator danych osobowych"}>
      <Typography>
        AbbVie sp. z o.o. z siedzibą w Warszawie, przy ul. Postępu 21B, 02-676 Warszawa, wpisaną do
        Rejestru Przedsiębiorców przez Sąd Rejonowy dla m. st. Warszawy w Warszawie, XIII Wydział
        Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000394107, NIP: 525-25-15-835
        [„AbbVie”].
      </Typography>
      <Typography>Niniejszy komunikat został przygotowany w celu przekazania osobie zgłaszającej informacji, o których
        mowa w art. 13 rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia
        2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
        swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o
        ochronie danych) (Dz. U. UE. L. z 2016 r. Nr 119, str. 1 z późn. zm.), dalej: „RODO”</Typography>
    </Clause>
    <Clause title={"Dane kontaktowe"}>
      <Typography>
        Z administratorem danych można się skontaktować poprzez adres email <a style={{color: '#2196F3'}} href="mailto:privacyoffice@abbvie.com">privacyoffice@abbvie.com</a> lub
        pisemnie na adres siedziby administratora.
      </Typography>
    </Clause>
    <Clause title={"Cele i podstawa prawna przetwarzania danych osobowych"}>
      <Typography>
        Celem przetwarzania Pana/Pani danych osobowych jest zapewnienie wysokiego poziomu
        bezpieczeństwa i jakości świadczonych usług dotyczących ochrony zdrowia, w szczególności w ramach
        obowiązku monitorowania bezpieczeństwa produktów leczniczych, w tym prowadzenia rejestru
        zgłoszeń oraz zgłaszania przypadków działań niepożądanych właściwym organom.
      </Typography>
      <Typography>
        Podstawą prawna przetwarzania Pani/Pana danych osobowych jest:

      <ul style={{listStyle: 'disc', padding: 0, paddingLeft: 20}}>
        <li>
          niezbędność przetwarzania dla realizacji obowiązków prawnych AbbVie w zakresie
          monitorowania bezpieczeństwa produktów leczniczych, o których mowa w art. 36d oraz
          art. 36e ustawy z dnia 6 września 2001 r. Prawo farmaceutyczne (t.j. Dz. U. z 2017 r. poz.
          2211 z późn. zm.) oraz art. 28 ust. 2 i ust. 3 lit. d) rozporządzenia wykonawczego Komisji
          (UE) nr 520/2012 z dnia 19 czerwca 2012 r. w sprawie działań związanych z nadzorem nad
          bezpieczeństwem farmakoterapii, o których mowa w rozporządzeniu (WE) nr 726/2004
          Parlamentu Europejskiego i Rady i w dyrektywie 2001/83/WE Parlamentu Europejskiego i
          Rady (art. 6 ust. 1 lit. c);
        </li>
        <li>
          niezbędność przetwarzania ze względów związanych z interesem publicznym w dziedzinie
          zdrowia publicznego w zakresie monitorowania bezpieczeństwa produktów leczniczych w
          związku z obowiązkiem zachowywania danych niezbędnych do uzyskania dalszych
          informacji na temat zgłoszonego przypadku, w tym danych zgłaszającego (art. 9 ust. 1 lit. i)
          RODO).
        </li>
      </ul>
      </Typography>
    </Clause>
    <Clause title={"Informacje o odbiorcach danych osobowych lub kategoriach odbiorców, jeżeli istnieją"}>
      <Typography>
        Pani/Pana dane osobowe mogą zostać udostępnione:
      <ul style={{listStyle: 'disc', padding: 0, paddingLeft: 20}}>
        <li>podmiotom trzecim, które dostarczają AbbVie towary lub usługi,
        </li>
        <li>
          podmiotom lub organom upoważnionym do przetwarzania przedmiotowych danych na
          podstawie przepisów prawa, w tym Europejskiej Agencji Leków oraz Prezesowi Urzędu
          Produktów Leczniczych, Wyrobów Medycznych i Produktów Biobójczych.
        </li>
      </ul>
      </Typography>
    </Clause>
    <Clause title={"Przekazywanie danych osobowych do państw trzecich (poza EOG) lub organizacji międzynarodowych"}>
      <Typography>
        Pani / Pana dane osobowe przekazane AbbVie będą dostępne dla podmiotów powiązanych z grupy
        AbbVie, sprzedawców, agentów i/lub dostawców AbbVie w innych krajach, w których mogą nie
        funkcjonować takie same przepisy o ochronie danych jak w kraju Pani / Pana siedziby.
      </Typography>
      <Typography>
        Pani/Pana dane osobowe mogą być poddane operacjom przetwarzania takim jak zbieranie,
        przechowywanie, przekazywanie i wykorzystywanie Pani / Pana danych osobowych przez AbbVie i
        podmioty powiązane z grupy AbbVie w Stanach Zjednoczonych oraz w innych jurysdykcjach, w których
        AbbVie i jej spółki powiązane prowadzą działalność. W takich jurysdykcjach wobec danych osobowych
        mogą nie być stosowane takie same ustawowe zabezpieczenia jak w kraju Pani / Pana siedziby;
        niemniej AbbVie będzie stosować przyjętą politykę ochrony prywatności i poufności oraz będzie
        przestrzegać innych obowiązujących wymogów prawnych i podejmie racjonalne środki bezpieczeństwa,
        aby zapewnić bezpieczeństwo danych osobowych.
      </Typography>
      <Typography>
        Wewnątrzgrupowe przekazywanie Pani / Pana danych osobowych pomiędzy AbbVie a jej podmiotami
        powiązanymi podlega postanowieniom umowy o przekazywaniu danych osobowych w obrębie
        podmiotów powiązanych AbbVie, która zawiera modelowe klauzule umowne UE o przekazywaniu
        danych administratorom.
      </Typography>
      <Typography>
        Przekazywanie Pani / Pana danych osobowych przez AbbVie z państw członkowskich UE do państw niebędących
        członkami UE będzie odbywać się na podstawie umowy zawierającej modelowe klauzule umowne dotyczące
        przekazywania danych osobowych w przypadku powierzenia podmiotom przetwarzania danych osobowych,
        wszędzie tam, gdzie jest to wymagane. Kopie unijnych wzorów umów dotyczących przekazywania danych
        przez AbbVie można uzyskać, wysyłając wiadomość na adres <a style={{color: '#2196F3'}} href="mailto:privacyoffice@abbvie.com">privacyoffice@abbvie.com</a>.
      </Typography>
    </Clause>
    <Clause title={"Okres przechowywania danych osobowych"}>
      <Typography>
        Okres przechowywania Twoich danych osobowych jest równy okresowi ważności pozwolenia na dopuszczenie
        do obrotu produktu leczniczego, którego dotyczy zgłoszenie działania niepożądanego, oraz okresowi
        co najmniej 10 lat po wygaśnięciu tego pozwolenia zgodnie z art. 12 ust. 2 rozporządzenia wykonawczego
        Komisji (UE) nr 520/2012 z dnia 19 czerwca 2012 r. w sprawie działań związanych z nadzorem nad
        bezpieczeństwem farmakoterapii, o których mowa w rozporządzeniu (WE) nr 726/2004 Parlamentu Europejskiego
        i Rady i w dyrektywie 2001/83/WE Parlamentu Europejskiego i Rady.
      </Typography>
    </Clause>
    <Clause title={"Uprawnienia dotyczące przetwarzanych danych osobowych"}>
      <Typography>
        Zgodnie z obowiązującymi przepisami dotyczącymi ochrony danych ma Pani / Pan prawo do żądania uzyskania
        dostępu do i/lub poprawiania, zaprzestania przetwarzania lub usunięcia swoich danych osobowych, lub też
        wniesienia sprzeciwu wobec wykorzystywania takich danych, żądania ograniczenia przetwarzania danych lub
        przeniesienia do innego administratora.  Jeżeli AbbVie nie będzie w stanie przedstawić żądanych danych lub
        wprowadzić żądanej zmiany, osobie składającej odnośne żądanie zostaną przekazane informacje o przyczynie
        podjęcia takiej decyzji.
      </Typography>
      <Typography>
        Może Pani / Pan wykonać przysługujące mu prawa kontaktując się z Inspektorem Danych Osobowych AbbVie w
        Unii Europejskiej poprzez odwiedzenie strony internetowej abbvie.com/contactus.html i wybranie
        „Zapytanie dotyczące ochrony prywatności” („Privacy Inquiry”) lub wysyłając e-mail na adres&nbsp;
         <a style={{color: '#2196F3'}} href="mailto:privacyoffice@abbvie.com">privacyoffice@abbvie.com</a>.
      </Typography>
    </Clause>
    <Clause title={"Informacja, czy podanie danych osobowych jest wymogiem ustawowym lub umownym lub warunkiem " +
      "zawarcia umowy oraz czy osoba, której dane dotyczą, jest zobowiązana do ich podania i jakie są ewentualne konsekwencje niepodania danych."}>
      <Typography>
        Podanie danych osobowych jest  obowiązkowe na podstawie przepisów prawa powszechnie obowiązującego,
        w szczególności na podstawie art. 36e Prawa Farmaceutycznego. Konsekwencją niepodania danych osobowych
        jest brak możliwości wypełnienia przez AbbVie nałożonych na niego obowiązków prawnych w zakresie
        zgłoszenia działania niepożądanego produktu leczniczego, w tym  przyjęcia i obsługi zgłoszenia oraz
        przekazania go podmiotom i organom określonym w przepisach prawa powszechnie obowiązującego.
      </Typography>
    </Clause>
    <Clause title={"Informacja o prawie wniesienia skargi do organu nadzorczego"}>
      <Typography>
        Przysługuje Pani/Panu prawo do wniesienia skargi do organu nadzorczego tj. Prezesa Urzędu Ochrony
        Danych Osobowych w Warszawie, w związku z przetwarzaniem Pani/Pana danych osobowych przez administratora.
        Szczegółowe informacje dotyczące trybu i sposobu wniesienia skargi znajdują się na stronie internetowej
        organu nadzorczego.
      </Typography>
    </Clause>
    <Clause title={"Informacja o zautomatyzowanym podejmowaniu decyzji, w tym profilowaniu"} isLast>
      <Typography>
        Pani/Pana dane osobowe nie są poddawane zautomatyzowanemu podejmowaniu decyzji, w tym
        profilowaniu.
      </Typography>
    </Clause>
  </Grid>
}

export default Clauses;