import {Button, FormControl, Stack, TextField, Typography} from '@mui/material'
import {PoiContext} from '../../../../context/poi/poiContext'
import WarningBox from './WarningBox'
import rejectFormValidate from './rejectFormValidate'
import {useContext} from 'react'

export default function RejectForm({
                                       children,
                                       activityID,
                                       submitFunction,
                                       setModal,
                                       comment,
                                       setComment,
                                       isSettlementRejectByAdmin,
                                       relatedReckonings
                                   }) {
    const {setModalError} = useContext(PoiContext)

    const handleSubmit = (e) => {
        e.preventDefault();
        const validation = rejectFormValidate(comment)
        if (validation.isError) {
            setModalError(validation.defRes);
        } else {
            const data = {adminRemarks: comment}
            submitFunction(activityID, data);
            setModal(false)
        }
    }

    const handleClose = () => {
        setModal(false)
    }

    return <WarningBox handleClose={handleClose}>
        <form onSubmit={handleSubmit}>
            <Typography
                className="settlements__addTitle"
                sx={{
                    fontWeight: 'bold',
                    marginBottom: '2rem'
                }}
            >
                {children}
            </Typography>
            {
                isSettlementRejectByAdmin && relatedReckonings?.length &&
                <Typography
                    variant='subtitle1'
                    color='red'
                >
                    Odrzucając ten rachunek odrzucasz również powiązany z nim rachun(ek/ki) dla:
                    <br/>
                    {relatedReckonings.map((el, index) =>
                        `${el.userName}${index === relatedReckonings.length - 1 ? '' : ', '}`)}
                </Typography>
            }
            <FormControl fullWidth required>
                <TextField
                    fullWidth
                    name='description'
                    label='UWAGI'
                    multiline minRows='15'
                    inputProps={{maxLength: 2200}}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    sx={{background: 'white'}}
                />
            </FormControl>
            <Stack
                direction="row"
                mt={4}
                sx={{justifyContent: 'flex-end'}}
            >
                <Button
                    type="submit"
                    variant="contained"
                    sx={{
                        width: '240px',
                        background: '#D32F2F'
                    }}
                >
                    ZAPISZ I ODRZUĆ
                </Button>
            </Stack>
        </form>
    </WarningBox>
};