import DoctorInfo from "./DoctorInfo"
import ComplaintInfo from "./partOne/ComplaintInfo"
import EventInfo from "./partOne/EventInfo"
import ProductsInfo from "./partOne/ProductsInfo"

const PartOne = () => {

    return <>
      <ComplaintInfo/>
      <DoctorInfo />
      <EventInfo/>
      <ProductsInfo/>
    </>
}

export default PartOne