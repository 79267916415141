import {
    ACCEPTED_STATUS,
    CLOSED_STATUS,
    DRAFT_STATUS,
    REJECTED_STATUS,
    WAITING_STATUS
} from "../../../../data/consts/settlementsStatuses";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";

// classes for buttons
export const CLASS_BLUE = `settlements__list-item__button--blue`;
export const CLASS_GREEN = `settlements__list-item__button--green`;
export const CLASS_GRAY = `settlements__list-item__button--grey`;
export const CLASS_GRAYDISABLED = `settlements__list-item__button--greyDisabled`;
export const CLASS_RED = `settlements__list-item__button--red`;

export const getActionHelper = (status, isEmpty, isAccepted, isAdminClosed = false) => {

    if (isAdminClosed) {
        return {
            class: CLASS_BLUE,
            content: <SearchIcon/>,
            status: '',
            color: ''
        }
    }

    const {text, color} = getStatusHelper(isEmpty, isAccepted);
    switch (status) {
        case CLOSED_STATUS:
            return {
                class: CLASS_BLUE,
                content: <SearchIcon/>,
                status: '',
                color: ''
            }
        case ACCEPTED_STATUS:
            return {
                class: CLASS_GREEN,
                content: 'wyślij rachunek',
                status: 'Zaakceptowane (Wyślij rachunek)',
                color: 'green'
            }
        case REJECTED_STATUS:
            return {
                class: CLASS_GREEN,
                content: 'rozlicz',
                status: 'Odrzucone (wypełnij jeszcze raz)',
                color: 'red'
            }
        case WAITING_STATUS:
            return {
                class: CLASS_GRAY,
                content: 'rozlicz',
                status: 'Uzupełnione (Czeka na akceptacje)',
                color: 'orange'
            }
        case DRAFT_STATUS:
            return {
                class: CLASS_GREEN,
                content: 'rozlicz',
                status: text,
                color: color,
            }
        default:
            return {
                class: CLASS_GREEN,
                content: 'error',
                status: 'coś poszło nie tak',
                color: 'red',
                disabled: true
            }
    }
}

const getStatusHelper = (isEmpty, isAccepted) => {
    let text = '';
    let color = 'orange'
    if (!isEmpty) {
        text = isAccepted ? 'Możesz przesłać rozliczenie do Abbvie' : 'Rozliczenie w trakcie edycji'
        color = isAccepted ? 'green' : 'orange'
    }
    return {
        text,
        color
    }
}