import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";

import Line from "../../../shared/ui/Line";
import { useDisabled } from "./useDisabled";
import { convertBoolValue, convertBoolValueHandler } from "./helper";
import { useAdverseEventsProduodopaStore } from "../../../../store/hooks";
import { regions } from "../../../../utils/regionsNormalizer";


const specializationsElements = ["Gastrolog", "Neurolog", "Radiolog interwencyjny",  "Inna specjalizacja (należy podać jaka)"];

const DoctorInfo = observer(() => {
    const adverseEventsProduodopaStore = useAdverseEventsProduodopaStore()
    const {doctorInfo} = adverseEventsProduodopaStore;
    
    const {
        isDisabled,
        changeDisabled
    } = useDisabled(specializationsElements[specializationsElements.length - 1], "specializationsOther", adverseEventsProduodopaStore.doctorInfoOnChange)

    return (
        <Grid container pl={2} mt={4}>
            <Grid item xs={12} bgcolor="#071d49" p={'8px 16px'} >
                <Typography color='white' sx={{textTransform: 'uppercase'}}>Informacje dotyczące lekarza:</Typography>
            </Grid>
            <Grid item xs={12} p={2} sx={{border: '1px solid #9B9B9D'}}>
                <FormControl fullWidth>
                    <FormGroup 
                        name="specializations"
                        value={doctorInfo.specializations}
                        onChange={(e) => {
                            adverseEventsProduodopaStore.doctorInfoOnChange("specializations", e.target.value);
                            changeDisabled(doctorInfo.specializations, e.target.value)
                        }}>
                        {
                            specializationsElements.map(element => 
                                <FormControlLabel key={element} mt={1} value={element} control={<Checkbox checked={doctorInfo.specializations.includes(element)} />} label={element} />
                            )
                        }
                    </FormGroup>
                </FormControl>
                <FormControl fullWidth sx={{pl: 6, mt: 1}}>
                    <OutlinedInput
                        disabled={isDisabled}
                        name="specializationsOther"
                        value={doctorInfo.specializationsOther}
                        onChange={(e) =>  adverseEventsProduodopaStore.doctorInfoOnChange(e.target.name, e.target.value)}/>
                </FormControl>
                <Line/>
                <FormControl fullWidth>
                    <Typography variant='subtitle1'>Czy pacjent wyraża zgodę na skontaktowanie się z lekarzem prowadzącym? </Typography>
                        <RadioGroup row
                             name="isDoctorContact"
                             value={convertBoolValue(doctorInfo.isDoctorContact)}
                             onChange={(e) =>  adverseEventsProduodopaStore.doctorInfoOnChange(e.target.name, convertBoolValueHandler(e.target.value))}>
                            <FormControlLabel mt={1} value="Tak" control={<Radio />} label="Tak" />
                            <FormControlLabel mt={1} value="Nie" control={<Radio />} label="Nie" />
                        </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12} mt={4} p={2} sx={{border: '1px solid #9B9B9D'}}>
                <FormControl fullWidth>
                    <Typography variant='subtitle1'>Imię i nazwisko lekarza:</Typography>
                    <OutlinedInput
                        name="fullName"
                        value={doctorInfo.fullName}
                        onChange={(e) =>  adverseEventsProduodopaStore.doctorInfoOnChange(e.target.name, e.target.value)}/>
                </FormControl>
            </Grid>
            <Grid item xs={12} p={2} sx={{border: '1px solid #9B9B9D'}} gap={2} display='flex' flexDirection='column'>
                <FormControl fullWidth>
                    <Typography variant='subtitle1'>Numer telefonu:</Typography>
                    <OutlinedInput
                       name="phone"
                       value={doctorInfo.phone}
                       onChange={(e) =>  adverseEventsProduodopaStore.doctorInfoOnChange(e.target.name, e.target.value)}/>
                </FormControl>
                <FormControl fullWidth>
                    <Typography variant='subtitle1'>Adres email:</Typography>
                    <OutlinedInput
                        name="email"
                        type="email"
                        value={doctorInfo.email}
                        onChange={(e) =>  adverseEventsProduodopaStore.doctorInfoOnChange(e.target.name, e.target.value)}/>
                </FormControl>
            </Grid>
            <Grid item xs={12} p={2} sx={{border: '1px solid #9B9B9D'}} gap={2} display='flex' flexDirection='column'>
                <FormControl fullWidth>
                    <Typography variant='subtitle1'>Adres (ulica):</Typography>
                    <OutlinedInput
                        name="street"
                       
                        value={doctorInfo.street}
                        onChange={(e) =>  adverseEventsProduodopaStore.doctorInfoOnChange(e.target.name, e.target.value)}/>
                </FormControl>
                <FormControl fullWidth mt={2}>
                    <Typography variant='subtitle1'>Miejscowość:</Typography>
                    <OutlinedInput
                        name="city"
                        value={doctorInfo.city}
                        onChange={(e) =>  adverseEventsProduodopaStore.doctorInfoOnChange(e.target.name, e.target.value)}/>
                </FormControl>
                <FormControl fullWidth mt={2}>
                    <Typography variant='subtitle1'>Województwo:</Typography>
                    <Select
                        name="voivodeship"
                        value={doctorInfo.voivodeship}
                        onChange={(e) =>  adverseEventsProduodopaStore.doctorInfoOnChange(e.target.name, e.target.value)}>
                            {regions.map(region => <MenuItem key={region} value={region}>{region}</MenuItem>)}
                            </Select>
                        
                </FormControl>
                <FormControl fullWidth mt={2}>
                    <Typography variant='subtitle1'>Kod pocztowy:</Typography>
                    <OutlinedInput
                        name="postcode"
                        value={doctorInfo.postcode}
                        onChange={(e) =>  adverseEventsProduodopaStore.doctorInfoOnChange(e.target.name, e.target.value)}/>
                </FormControl>
            </Grid>
        </Grid>
    )

});

export default DoctorInfo;