import React, {useContext, useEffect} from 'react';
import UserDataTable from './UserDataTable';
import PageTitle from '../../../shared/ui/PageTitle';
import {Button, Grid, Typography} from '@mui/material';
import {AppContext} from '../../../../context/app/appContext';
import AdminUserModal from './AdminUserModal';
import {useModalController} from "../../../../hooks/useModalController";
import {ADD_OPTION} from "../../../../data/consts/modalOptions";

const Users = () => {
    const {getUsers, clearUsers, appState} = useContext(AppContext);
    const {
        modalId, modalIDHandler, modalInfo, openModal, closeModal
    } = useModalController();

    useEffect(() => {
        getUsers();
        return () => {
            clearUsers()
        }
        // eslint-disable-next-line
    }, [])

    return (<>
        <div className="users">
            <PageTitle title="Użytkownicy:"/>
            <Grid container justifyContent="flex-end" pb={1}>
                <Button variant="contained" color="success" className="formButton" onClick={() => openModal(ADD_OPTION)}>
                    dodaj użytkownika
                </Button>
            </Grid>
            {appState.users ? <UserDataTable openModal={openModal} userIDHandler={modalIDHandler}/> :
                <Typography>Brak danych</Typography>}
        </div>
        {modalInfo.isOpen && <AdminUserModal
            closeModal={closeModal}
            modalOption={modalInfo.option}
            userID={modalId}
        />}
    </>)
}

export default Users;
