import React, {useContext, useEffect, useState} from 'react';
import {SettlementsContext} from '../../../context/settlements/settlementsContext';
import {SettlementsMonthsTable} from './settlementsMonthTable/SettlementsMonthsTable';
import {Grid, Typography} from '@mui/material';
import {Button} from '@mui/material';

const Settlements = () => {
    const {settlementsState, getSettlementsDates} = useContext(SettlementsContext);
    const [activeMenu, setActiveMenu] = useState(1);

    useEffect(() => {
        void getSettlementsDates();
    }, [getSettlementsDates])

    useEffect(() => {
        if (!!settlementsState.settlementsDates.length) {
            setActiveMenu(settlementsState.settlementsDates.length);
        }
    }, [settlementsState, setActiveMenu])

    // const { rejectReckoning, rejectAdditionalTime } = useContext(ReckoningsContext);

    return !!settlementsState.settlementsDates.length
        ?
        <>
            {/* <WarningForm submitFunction={rejectAdditionalTime} activityID={10}>Wpisz powód odrzucenia wniosku:</WarningForm> */}
            <Grid container pb={1} sx={{mb: '20px'}}>
                {settlementsState.settlementsDates.map(([year, months], index) => +year >= 2022 ?
                    <Button key={year} onClick={() => setActiveMenu(index + 1)}
                            className={`settlements__nav-item ${activeMenu === index + 1 ? 'settlements__nav-activeItem' : ''}`}
                            variant="contained" sx={{
                        marginRight: '16px',
                        padding: '5px 50px'
                    }}>{year}</Button> : ''
                )}
            </Grid>
            {
                settlementsState.settlementsDates[activeMenu - 1][1].length
                    ? <SettlementsMonthsTable activeMonths={settlementsState.settlementsDates[activeMenu - 1][1]}/>
                    : <Typography>Brak danych</Typography>
            }
        </>
        :
        <Grid container alignItems="center" justifyContent="center" p={2}>
            <Typography>Brak danych</Typography>
        </Grid>
}

export default Settlements;