import * as React from 'react';
import {useTheme} from '@mui/material/styles';
import {HardwareContext} from '../../../context/hardware/hardwareContext';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import {autoPlay} from 'react-swipeable-views-utils';
import {useContext, useEffect, useState} from 'react';
import {Grid} from '@mui/material';
import noImage from '../../../assets/img/brak_zdjecia.jpg'
import noProduct from '../../../assets/img/wybierz_produkt.jpg'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Carousel = ({productId}) => {

    const {hardwareState: {hardware}} = useContext(HardwareContext);
    const HOST = process.env.REACT_APP_IMG_URL;
    const theme = useTheme();
    const [imageArray, setImageArray] = useState([]);
    const [maxSteps, setMaxSteps] = useState(0)
    const [activeStep, setActiveStep] = React.useState(0);

    useEffect(() => {
        if (!!hardware.length && productId) {
            setImageArray([...hardware.filter((el) => el.id === productId)[0].images])
            setMaxSteps(hardware.filter((el) => el.id === productId)[0].images.length)
        }
    }, [hardware, productId])

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return <Grid container height={'100%'} justifyContent="center" alignItems="center">
        {
            !!imageArray.length
                ?
                <Box sx={{maxWidth: 400, flexGrow: 1}}>
                    <AutoPlaySwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                    >
                        {!!imageArray.length && imageArray.map(image =>
                            <div key={image.id}>
                                <Box
                                    component="img"
                                    sx={{
                                        height: 255,
                                        display: 'block',
                                        maxWidth: 400,
                                        overflow: 'hidden',
                                        width: '100%',
                                    }}
                                    src={`${HOST}/uploads/products/${image.image}?w=164&h=255&fit=crop&auto=format`}
                                    alt={'product list'}
                                />
                            </div>,
                        )}
                    </AutoPlaySwipeableViews>
                    <MobileStepper
                        steps={maxSteps}
                        position="static"
                        activeStep={activeStep}
                        nextButton={
                            <Button
                                size={"small"}
                                variant={"contained"}
                                onClick={handleNext}
                                disabled={activeStep === maxSteps - 1}
                            >

                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowLeft/>
                                ) : (
                                    <KeyboardArrowRight/>
                                )}
                            </Button>
                        }
                        backButton={
                            <Button size={"small"} variant={"contained"}
                                    onClick={handleBack} disabled={activeStep === 0}>
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowRight/>
                                ) : (
                                    <KeyboardArrowLeft/>
                                )}

                            </Button>
                        }
                    />
                </Box>
                :
                productId
                    ?
                    <img src={noImage} alt="brak danych"/>
                    :
                    <img src={noProduct} alt="brak produktu"/>
        }
    </Grid>
}

export default Carousel;
