import React from 'react';
import {Typography} from '@mui/material';

const PageTitle = ({title, marginBottom}) => {
    return (
        <Typography
            variant="h4"
            component="h4"
            p={0.5}
            mb={marginBottom ?? 2}
        >
            {title}
        </Typography>
    )
}

export default PageTitle;