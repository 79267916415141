import React, {useContext} from "react";
import {
    Paper, TableContainer, Table, TableRow, TableCell, TableBody, TablePagination, Tooltip, Box
} from "@mui/material"
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {AppContext} from "../../../context/app/appContext";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import {EnhancedTableHead, getComparator, stableSort} from "../../../utils/sortingTable";
import Search from "../../shared/search/Search";
import {useTableController} from "../../../hooks/useTableController";
import {headCells} from "./helper";

const KnowledgeTable = ({openModal, hardwareIDHandler}) => {
    const {appState, getKnowledgeFile} = useContext(AppContext);

    const {
        filteredData,
        searchTerm,
        page,
        rowsPerPage,
        order,
        orderBy,
        isSelected,
        selected,
        handleChangePage,
        handleChangeRowsPerPage,
        handleRequestSort,
        handleSearchChange,
        handleSelectedItem
    } = useTableController(appState.knowledge, ['name', 'description'], {orderBy: 'description'})

    return (<>
            <Box justifyContent={'flex-end'} paddingBottom={2} sx={{display: 'flex'}}>
                <Search searchTerm={searchTerm} handleSearchChange={handleSearchChange}/>
            </Box>
            <Paper sx={{width: '100%', overflow: 'hidden'}}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={appState.knowledge.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {stableSort(filteredData, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((item, index) => {
                                    const isItemSelected = isSelected(item.id);
                                    return (<TableRow hover
                                                      onClick={(event) => handleSelectedItem(event, item.id)}
                                                      role="row"
                                                      aria-rowindex={isItemSelected}
                                                      tabIndex={-1}
                                                      key={index}
                                                      selected={isItemSelected}>
                                        <TableCell align={"center"}>
                                            {index + 1 + '.'}
                                        </TableCell>
                                        <TableCell align={"center"}>
                                            {item.name}
                                        </TableCell>
                                        <TableCell width={500} align={"center"}>
                                            {item.description}
                                        </TableCell>
                                        <TableCell align={"center"}>
                                            {item.originFile}
                                        </TableCell>
                                        <TableCell align={"center"}>
                                            <div className='addUserTable'>
                                                {/*<Tooltip title="Edytuj">*/}
                                                {/*    <button onClick={() => {*/}
                                                {/*        hardwareIDHandler(item.id)*/}
                                                {/*        openModal('edit')*/}
                                                {/*    }}><EditIcon/>*/}
                                                {/*    </button>*/}
                                                {/*</Tooltip>*/}
                                                <Tooltip title="Wyświetl">
                                                    <button onClick={() => {
                                                        hardwareIDHandler(item.id)
                                                        openModal('show')
                                                    }}><RemoveRedEyeIcon/></button>
                                                </Tooltip>
                                                <Tooltip title="pobierz">
                                                    <button onClick={() => {
                                                        getKnowledgeFile(item.id)
                                                    }}><ArrowCircleDownIcon/></button>
                                                </Tooltip>

                                            </div>
                                        </TableCell>
                                    </TableRow>)
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={appState.knowledge.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage={"Elementy na stronie"}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>

    );
}
export default KnowledgeTable