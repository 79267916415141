export const otherActivitiesValidate = (date, duration) => {
    if (!date) {
        return ({
            isError: true,
            defRes: {
                data: {
                    code: 422,
                    message: 'Wybierz datę',
                },
            },
        })
    }

    if (duration < 1) {
        return ({
            isError: true,
            defRes: {
                data: {
                    code: 422,
                    message: 'Sprawdź zakres',
                },
            },
        })
    }
    return ({
        isError: false,
        defRes: null,
    })
}