import {
    CLEAR_SETTLEMENTS_STATE,
    GET_SETTLEMENTS_INFO,
    SET_GLOBAL_SETTLEMENT_PERIOD,
    GET_SETTLEMENTS_LIST,
    GET_SETTLEMENTS_DATES,
    SETTLEMENT_COMPLETED,
    SETTLEMENT_CONFIRMED,
} from '../types';

const handlers = {
    [GET_SETTLEMENTS_INFO]: (state, {payload}) => ({...state, ...payload}),
    [GET_SETTLEMENTS_LIST]: (state, {payload}) => ({...state, settlementsList: payload}),
    [GET_SETTLEMENTS_DATES]: (state, {payload}) => ({...state, settlementsDates: payload}),
    [SET_GLOBAL_SETTLEMENT_PERIOD]: (state, {payload}) => ({...state, globalSettlementPeriod: payload}),
    [SETTLEMENT_COMPLETED]: (state) => ({...state, settlementCompleted: true}),
    [SETTLEMENT_CONFIRMED]: (state) => ({...state, settlementCompleted: false}),
    [CLEAR_SETTLEMENTS_STATE]: (state, {payload}) => ({...payload}),
    DEFAULT: state => state,
}

export const settlementsReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}