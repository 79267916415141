import {dateFormat} from "./helpfunc";

export const implementationPartsNormalizer = (comment, localPartInfo, makers, dateStart, dateEnd, patientId, type) => {
    const procedureChecks = []

    localPartInfo.forEach(procedure =>
        procedure.values.forEach(element =>
            element.values.forEach(task =>
                procedureChecks.push({
                    checkbox: task.name,
                    checked: task.checked,
                    amount: task.amount === '' ? null : parseInt(task.amount),
                }),
            )))

    const editedMakers = []

    makers.forEach(maker => editedMakers.push({
        id: maker,
    }))

    const patient = {id: patientId}

    return {comment, type, patient, procedureChecks, makers: editedMakers, dateStart: dateFormat(dateStart, false).split('-').join('-'), dateEnd:  dateFormat(dateEnd, false).split('-').join('-')}
}
