import React, {useContext, useEffect, useState} from "react";
import {
    Button,
    Stack,
    Grid,
    Typography,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    Paper,
    Divider,
    MenuList
} from "@mui/material";
import {equalizerData, isZipCode} from "../../../../utils/helpfunc";
import FormControl from "@mui/material/FormControl";
import {regions} from "../../../../utils/regionsNormalizer";
import {roleNormaliser} from "../../../../utils/roleNormalizer";
import {AppContext} from "../../../../context/app/appContext";
import GlobalLoader from "../../../shared/ui/GlobalLoader";
import {hospitalDataInit} from "./helper";
import {ADD_OPTION, EDIT_OPTION, SHOW_OPTION} from "../../../../data/consts/modalOptions";

const AddHospitalModal = ({closeModal, modalOption, hospitalID}) => {
    const {
        appState,
        addInstitution,
        getUsersFromInstitutions,
        getInstitutionInfo,
        editInstitution,
    } = useContext(AppContext);

    const [newHospitalData, setNewHospitalData] = useState(hospitalDataInit)

    useEffect(() => {
            getUsersFromInstitutions();

            switch (modalOption) {
                case ADD_OPTION:
                    setNewHospitalData(hospitalDataInit);
                    break;
                case SHOW_OPTION:
                    getInstitutionInfo(hospitalID);
                    getUsersFromInstitutions();
                    break;
                case EDIT_OPTION:
                    getInstitutionInfo(hospitalID);
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line
        , [modalOption])


    useEffect(() => {
        if (appState.showInstitutionInfo) {
            if (modalOption === SHOW_OPTION || modalOption === EDIT_OPTION) {
                setNewHospitalData({...appState.showInstitutionInfo});
            }
        }
    }, [appState.showInstitutionInfo, modalOption])

    const formValueHandler = (name, value) => setNewHospitalData(prev => ({...prev, [name]: value}))

    const submitHandler = (e) => {
        e.preventDefault()
        modalOption === ADD_OPTION && addInstitution(newHospitalData) && closeModal()
        modalOption === EDIT_OPTION && editInstitution(newHospitalData, hospitalID) && closeModal()
    }
    return (
        newHospitalData ?
            <div className='customModal'>
                <Stack component="div" className="customModal__addMenu">
                    <Typography variant='h4' mb={2}>
                        {
                            modalOption === ADD_OPTION ? 'Dodaj placówkę '
                                : modalOption === SHOW_OPTION ? 'Dane placówki' : 'Edytuj placówkę'
                        }
                    </Typography>
                    <form onSubmit={(e) => submitHandler(e)}>
                        <Grid container spacing={2} display="flex" direction="column" wrap="nowrap"
                              className="customModal__scrollArea">
                            <Grid item>
                                <TextField
                                    required
                                    label="Nazwa"
                                    name='name'
                                    value={newHospitalData.name}
                                    onChange={(e) => formValueHandler(e.target.name, e.target.value)}
                                    fullWidth
                                    disabled={(modalOption === SHOW_OPTION)}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    required
                                    label="Kod placówki"
                                    name='identifier'
                                    value={newHospitalData.identifier}
                                    onChange={(e) => formValueHandler(e.target.name, e.target.value)}
                                    fullWidth
                                    disabled={(modalOption === SHOW_OPTION)}

                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    required
                                    label="Adres"
                                    name='address'
                                    value={newHospitalData.address}
                                    onChange={(e) => formValueHandler(e.target.name, e.target.value)}
                                    fullWidth
                                    disabled={(modalOption === SHOW_OPTION)}
                                />
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth>
                                    <InputLabel id="addUser-label">Województwo</InputLabel>
                                    <Select
                                        labelId="addUser-label"
                                        name="voivodeship"
                                        value={newHospitalData.voivodeship}
                                        label="Województwo"
                                        disabled={(modalOption === SHOW_OPTION)}
                                        onChange={(e) => formValueHandler(e.target.name, e.target.value)}
                                    >
                                        <MenuItem value="" disabled={true}><em>Wybierz z listy</em></MenuItem>
                                        {regions.map((el => (
                                            <MenuItem
                                                key={el}
                                                value={el}
                                            >
                                                {el}
                                            </MenuItem>
                                        )))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <TextField
                                    required
                                    label="Kod pocztowy"
                                    name='zipCode'
                                    error={!isZipCode(newHospitalData.zipCode) && newHospitalData.zipCode.length >= 5}
                                    value={newHospitalData.zipCode}
                                    onChange={(e) => formValueHandler(e.target.name, e.target.value)}
                                    fullWidth
                                    disabled={(modalOption === SHOW_OPTION)}

                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    required
                                    label="Miasto"
                                    name='city'
                                    value={newHospitalData.city}
                                    onChange={(e) => formValueHandler(e.target.name, e.target.value)}
                                    fullWidth
                                    disabled={(modalOption === SHOW_OPTION)}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                  required
                                  label="Adres dostawy"
                                  name='delivery'
                                  value={newHospitalData.delivery}
                                  onChange={(e) => formValueHandler(e.target.name, e.target.value)}
                                  fullWidth
                                  disabled={(modalOption === SHOW_OPTION)}

                                />
                            </Grid>
                            {modalOption === 'show' ?

                                <Grid item marginBottom={2}>
                                    <Paper mt={2}
                                           sx={{
                                               width: '100%',
                                               border: '0.5px solid #9e9e9e',
                                               color: 'text.disabled',
                                               borderBottom: 'none',
                                           }}>
                                        <Typography p={1.5}>Użytkownicy:</Typography>
                                        <Divider/>

                                        <MenuList>
                                            {
                                                !!newHospitalData.roles && !!appState.institutionsUserList && equalizerData(newHospitalData.roles, appState.institutionsUserList).map((el, index) =>

                                                    <MenuItem key={index} sx={{cursor: 'default'}}> {el} </MenuItem>
                                                )
                                            }

                                        </MenuList>

                                    </Paper>
                                </Grid>
                                :
                                <Grid item marginBottom={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="addHospital-label">Opiekun</InputLabel>
                                        <Select
                                            name="roles"
                                            multiple
                                            // value={[97]}
                                            value={newHospitalData.roles}
                                            label="Opiekun"
                                            disabled={(modalOption === SHOW_OPTION)}
                                            onChange={(e) => formValueHandler(e.target.name, e.target.value)}
                                        >
                                            <MenuItem value="" disabled={true}><em>Wybierz z listy</em></MenuItem>

                                            {appState.institutionsUserList && appState.institutionsUserList.map((el => (
                                                <MenuItem
                                                    key={el.id}
                                                    value={el.id}
                                                >
                                                    {el.name} {el.surname} - ({roleNormaliser(el.role)})
                                                </MenuItem>
                                            )))}
                                        </Select>
                                    </FormControl>
                                </Grid>}

                        </Grid>
                        <div
                            className={`customModal__addMenu-buttonArea ${modalOption === SHOW_OPTION? 'alignButton' : ''} `}>
                            <Button onClick={closeModal} variant="contained" color="primary">Powrót</Button>
                            {modalOption !== SHOW_OPTION && <Button type="submit" variant="contained" color="primary">
                                {modalOption === ADD_OPTION ? 'Dodaj' : 'Zapisz'}
                            </Button>}
                        </div>
                    </form>
                </Stack>
            </div>
            :
            <GlobalLoader/>
    )
}

export default AddHospitalModal;