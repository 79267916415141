import React, {useContext, useEffect, useState} from 'react';
import {
    Button,
    Grid,
    MenuItem, MenuList,
    Stack,
    Typography,
} from '@mui/material';
import {AppContext} from "../../../../context/app/appContext";
import {ADD_OPTION, SHOW_OPTION} from "../../../../data/consts/modalOptions";


const InteractionsAdminModal = ({closeModal, userID, modalOption}) => {
    const {appState} = useContext(AppContext);

    const [messageData, setMessageData] = useState('')

    useEffect(() => {
        if (modalOption === SHOW_OPTION && appState.interactions) {
            setMessageData(...appState.interactions.filter((el, index) => index === userID))
        }
    }, [modalOption, userID, appState.interactions])

    return <div className="customModal ">
        <Stack component="div" className="customModal__addMenu ">
            <Typography variant="h4" mb={2}>
                {
                    modalOption === ADD_OPTION ? ''
                        : modalOption === SHOW_OPTION ? 'Podgląd zdarzenia' : ''
                }
            </Typography>
            <Grid container spacing={2} display="flex" direction="column" wrap="nowrap"
                  className="customModal__scrollArea">
                <Grid item>
                    <MenuList>
                        <Typography variant="subtitle1">Data interakcji:</Typography>
                        <MenuItem>{messageData.createdAt}</MenuItem>
                    </MenuList>
                </Grid>
                {modalOption === SHOW_OPTION ? <Grid item>
                    <Typography variant="subtitle1">Uczestnik:</Typography>
                    <MenuList>
                        {messageData.users && messageData.users.map((institution, index) => (
                            <MenuItem
                                key={index}
                                value={institution.id}
                            >
                                {institution}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Grid> : null}
                <Grid item>
                    <MenuList>
                        <Typography variant="subtitle1">Placówka:</Typography>
                        <MenuItem>{messageData.hospitalName}</MenuItem>
                    </MenuList>

                </Grid>
                <Grid item>
                    <MenuList>
                        <Typography variant="subtitle1">Pacjent:</Typography>
                        <MenuItem>{messageData.patient}</MenuItem>
                    </MenuList>

                </Grid>
                <Grid item>
                    <MenuList>
                        <Typography variant="subtitle1">Rodzaj interakcji:</Typography>
                        <MenuItem>{messageData.type}</MenuItem>
                    </MenuList>

                </Grid>
            </Grid>
            <div className={`customModal__addMenu-buttonArea ${modalOption === SHOW_OPTION ? 'alignButton' : ''} `}>
                <Button onClick={closeModal} variant="contained" color="primary">Powrót</Button>
                {
                    modalOption !== SHOW_OPTION &&
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary">
                        {modalOption === ADD_OPTION ? 'Wyślij' : 'Edytuj'}
                    </Button>
                }
            </div>
        </Stack>
    </div>
}

export default InteractionsAdminModal;
