import React, {useContext, useEffect} from 'react';
import {Typography} from '@mui/material';
import {AuthContext} from '../../../context/auth/authContext';

const Logout = () => {
    const {logout} = useContext(AuthContext);

    useEffect(() => {
        logout()
    }, [logout])

    return (
        <div>
            <Typography variant='h4'/>
        </div>
    )
}

export default Logout;