import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from '../../../context/app/appContext';
import {
    Button, Grid, Stack, TextField, Typography,
} from '@mui/material';

import GlobalLoader from '../../../components/shared/ui/GlobalLoader';
import {isZipCode} from '../../../utils/helpfunc';
import {AuthContext} from '../../../context/auth/authContext';


const ProfileEditModal = ({closeModal, userID}) => {

    const {
        getProfileInfoForUser, editProfileInfoByUser, appState,
    } = useContext(AppContext);
    const {authState} = useContext(AuthContext)

    const [userData, setUserData] = useState({
        bankAccount: '', city: '', zipCode: '', street: '',
    })

    useEffect(() => {
        getProfileInfoForUser()
// eslint-disable-next-line
    }, [])

    useEffect(() => {

        if (appState.showProfileInfoForUser) {
            setUserData({...appState.showProfileInfoForUser})
        }
    }, [appState.showProfileInfoForUser])


    useEffect(() => {
        // console.log('userData', userData)
        // console.log(userID)
    }, [userData, userID, authState.role])

    const formValueHandler = (name, value) => setUserData(prev => ({...prev, [name]: value}))

    const submitHandler = (e) => {
        e.preventDefault()
        editProfileInfoByUser(userData)
        closeModal()
    }

    return userData ? <div className="customModal ">
        <Stack component="div" className="customModal__addMenu ">
            <Typography variant="h4" mb={2}>
                Edytuj profil
            </Typography>
            <form onSubmit={(e) => submitHandler(e)}>
                <Grid container spacing={2} display="flex" direction="column" wrap="nowrap"
                      className="customModal__scrollArea">
                    <Grid item>
                        <TextField
                            required={(authState.role === 'ADS')}
                            label="Konto bankowe"
                            name="bankAccount"
                            value={userData.bankAccount || ''}
                            onChange={(e) => formValueHandler(e.target.name, e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            required
                            label="Ulica"
                            name="street"
                            value={userData.street || ''}
                            onChange={(e) => formValueHandler(e.target.name, e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            required={(authState.role === 'ADS')}
                            label="Kod pocztowy"
                            name="zipCode"
                            error={!isZipCode(userData.zipCode) && userData.length > 5}
                            value={userData.zipCode || ''}
                            onChange={(e) => formValueHandler(e.target.name, e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            required={(authState.role === 'ADS')}
                            label="Miasto"
                            name="city"
                            value={userData.city || ''}
                            onChange={(e) => formValueHandler(e.target.name, e.target.value)}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <div className={'customModal__addMenu-buttonArea'}>
                    <Button onClick={closeModal} variant="contained" color="primary">Powrót</Button>
                    <Button type="submit" variant="contained" color="primary"> Wyślij
                    </Button>
                </div>
            </form>
        </Stack>
    </div> : <GlobalLoader/>
}

export default ProfileEditModal;
