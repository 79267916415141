import React from 'react';

const Content = ({routes}) => {

    return (
        <div className='content'>
            {routes}
        </div>
    )
}

export default Content;