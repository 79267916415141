import React from 'react';
import {Button, Grid, TextField, Typography} from '@mui/material';
import {DatePickerComponent} from "../../shared/ui/DatePickerComponent";

const PegSubModal = ({pegs, addPegsTableRow, pegsTableHandler, delPegsTableRow, modalOption}) => {
    return (<>
        <Grid item container mt={2} xs={7} lg={5.8} justifyContent='space-between'>
            <Grid item xs={4.5}>
                <Typography style={{fontSize: "1rem"}} sx={{borderBottom: "solid 1px #ccc", fontWeight: "bold"}}>Dane
                    PEG-a:</Typography>
            </Grid>
            <Grid item xs={6.8}>
                <Button variant="contained" size='small' fullWidth sx={{backgroundColor: "#228B21"}}
                        onClick={addPegsTableRow}>Dodaj kolejny PEG</Button>
            </Grid>
        </Grid>
        <Grid
            item
            xs={12}
            container
            direction="column"
        >
            {
                pegs.map((el, index) =>
                    <Grid key={index} container mt={1} p={2} borderRadius={'5px'} bgcolor="#F5F5F5"
                          justifyContent='space-between'>
                        <Grid item xs={12} lg={5.8} mt={1}>
                            <TextField required name="serialNumber" label='PEG NR SERII' value={el.serialNumber}
                                       disabled={modalOption === 'show'} fullWidth
                                       onChange={(e) => pegsTableHandler(e.target.name, e.target.value, el, index)}/>
                        </Grid>
                        <Grid item xs={12} lg={5.8} mt={1}>
                            <DatePickerComponent
                                allowSameDateSelection
                                disableFuture
                                label="Data założenia:"
                                value={el.installDate}
                                disabled={modalOption === 'show'}
                                onChange={(newValue) => pegsTableHandler('installDate', newValue, el, index)}
                            />
                        </Grid>
                        {
                            modalOption !== 'show' &&
                            <Grid container mt={2} alignItems="center" justifyContent="end">
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        sx={{backgroundColor: '#D32F2F'}}
                                        className="formButton"
                                        onClick={() => delPegsTableRow(index)}
                                    >
                                        Usuń pole
                                    </Button>
                                </Grid>
                            </Grid>
                        }

                    </Grid>,
                )
            }
        </Grid>
    </>)
}

export default PegSubModal;